import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles/requisition.module.css';
import stylesPo from '../../styles/purchaseOrders.module.css';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import GridItem from '../../components/Admins/PurchaseOrders/View/GridItem';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import { Controller, useForm } from 'react-hook-form';
import Toast from '../../components/UI/General/Toast';
import spmsServiceService from '../../services/spmsService.service';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import { useStore } from '../../store/store';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import BackButton from '../../components/shared/BackButton';
import Pagination from '../../components/Admins/ApprovalWorkflow/Overview/Pagination';
import Top from '../../components/Admins/PurchaseOrders/View/Top';
import Icon from '../../components/UI/General/Icon';
import { regExps } from '../../utils/regExps';
import Textarea from '../../components/UI/Forms/Textarea';
import CommentsTab from '../../components/Admins/PurchaseOrders/Tabs/CommentsTab';

const RequisitionHistory = ({ fetchHistory, handleDate }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 10;
  const endIndex = startIndex + 10;

  const nextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };
  const prevPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await fetchHistory(currentPage, 10);
        setData(response.data.data.content);
        setTotalRows(response.data.data.totalElements);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [fetchHistory, currentPage]);

  return (
    <div className={styles.history}>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <>
          {data.map((item) => (
            <div className={styles.historyItem}>
              <div className={styles.historyEvent}>
                <span>{item.actor.name}</span> <b>{item.event.split(' ')[0].toLowerCase()}</b>{' '}
                <span>{item.event.split(' ').splice(1).join(' ')}</span>
              </div>
              <div className={styles.historyDate}>{handleDate(item.createdAt)}</div>
            </div>
          ))}
        </>
      )}
      <Pagination
        changePage={changePage}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        itemsLength={totalRows}
        startIndex={startIndex}
        endIndex={endIndex}
      />
    </div>
  );
};

const RequisitionView = () => {
  const { defaultCurrency } = useStore((state) => state.activeCompany);
  const activeCompany = useStore((state) => state.activeCompany);
  const user = useStore((state) => state.user);
  const { requisitionId } = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState('overview');
  const [trigger, setTrigger] = useState(false);
  const [values, setValues] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const location = useLocation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      requisitionState: '',
      title: '',
      requester: '',
      notes: [],
      requestNotes: '',
      companyId: '',
      departmentId: '',
      requestOnBehalfOf: '',
      vendorId: '',
      currency: '',
      approver: '',
      requisitionItems: [],
      attachments: [],
      deliveryDate: '',
    },
    values,
  });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  let isApprover = useRef(0);
  let isCurrentUserApproved = useRef(false);

  const accessRequisition = useAccessAllowed('Requests');

  const subtotal = useMemo(() => {
    return values?.requisitionItems
      ? values?.requisitionItems.reduce(
          (acc, current) => acc + (parseFloat(current.subtotal) || 0),
          0,
        )
      : 0;
  }, [values]);
  const taxTotal = useMemo(() => {
    return values?.requisitionItems
      ? values?.requisitionItems.reduce(
          (acc, current) => acc + (parseFloat(current.taxTotal) || 0),
          0,
        )
      : 0;
  }, [values]);
  const totalCost = useMemo(() => {
    return values?.requisitionItems
      ? values?.requisitionItems.reduce(
          (acc, current) => acc + (parseFloat(current.totalCost) || 0),
          0,
        )
      : 0;
  }, [values]);

  const getData = useCallback(async (page, size) => {
    const search = {
      module: 'Requisitions',
      moduleId: requisitionId,
      userId: user.id,
    };
    return await spmsServiceService.getSystemAuditLog(size, page, activeCompany.id, search);
  }, []);

  const onSubmit = (data, type) => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    // if (!data.budget?.value && type === 'approved') {
    //   setError('budget', {
    //     type: 'pattern',
    //     message: 'Budget is required for approving',
    //   });
    //   return;
    // }
    // const updateData = {
    //   requisitionState: 'PENDING_APPROVAL',
    //   title: data.title,
    //   companyId: data.company.id,
    //   locationId: data.location.id,
    //   departmentId: data.department.id,
    //   budget: {
    //     id: data.budget?.value,
    //   },
    //   currency: {
    //     rate: 1,
    //   },
    //   requester: data.requester,
    //   attachments: data.attachments,
    //   deliveryDate: data.deliveryDate,
    //   vendorId: data.vendor.id,
    //   category: data.category,
    //   subCategory: data.subCategory,
    //   requisitionItems: data.requisitionItems.map((item) => ({
    //     ...item,
    //     generalLedgerId: item.glAccount.id,
    //     taxId: item.tax.id,
    //   })),
    // };
    // spmsServiceService
    //   .verifyPurchaseOrderBudget(updateData)
    //   .then((_res) => {
    const newData = {
      reason: data.requestNotes,
      approved: type === 'approved' ? true : type === 'rejected' ? false : undefined,
      itemsApproved: data.requisitionItems.map((el) => el.id),
    };
    if (type === 'rejected' && data.requestNotes === '') {
      setTab('notes');
      setError('requestNotes', {
        type: 'focus',
        message: 'Please add the reason for rejecting the request',
      });
    } else {
      spmsServiceService
        .approveRequisition(requisitionId, newData)
        .then((r) => {
          console.log(r);
          setToast({
            opened: true,
            message: 'Requisition ' + type,
            type: 'success',
            cb: () => {
              navigate('/approve/requisitions');
              setIsButtonsBlocked(false);
            },
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    }
    // })
    // .catch((err) => {
    //   console.log(err);
    //   setToast({
    //     opened: true,
    //     message:
    //       err.response.data.message !== undefined
    //         ? err.response.data.message
    //         : err.response.data.error,
    //     type: 'fail',
    //   });
    // });
  };

  const submitForApproval = () => {
    setToast((item) => ({ ...item, opened: false }));
    if (!values?.attachments.length) {
      setToast({
        opened: true,
        message: 'Documents are required to submit for approval',
        type: 'fail',
        cb: () => setToast((item) => ({ ...item, opened: false })),
      });
      return;
    }
    spmsServiceService
      .submitForApprovalRequisition(requisitionId)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Requisition submitted for approval successfully',
          type: 'success',
          cb: () => navigate('/requisitions'),
        });
      })
      .catch((reason) => {
        setToast({
          opened: true,
          message:
            reason.response.data.message !== undefined
              ? reason.response.data.message
              : reason.response.data.error,
          type: 'fail',
        });
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      return await spmsServiceService.getRequisition(requisitionId);
    };
    if (requisitionId) {
      fetchData().then((r) => {
        if (r.data.message === 'Operation Successful') {
          isApprover.current = r.data.data.approval?.approvers?.filter((item) => item.userId === user.id).length ?? 0;
          isCurrentUserApproved.current = r.data.data.approval?.approvers?.find((item) => item.userId === user.id)?.status === 'APPROVED' ?? false;
          setValues(r.data.data);
        }
      });
    }
  }, [requisitionId, user, trigger]);

  const handleDate = (createdAt) => {
    const date = new Date(createdAt);
    const diff = new Date() - date;
    const daysGone = diff / (1000 * 60 * 60 * 24);
    if (daysGone >= 1) return `${Math.round(daysGone)} day${daysGone > 1 && 's'} ago`;
    const hoursGone = diff / (1000 * 60 * 60);
    if (hoursGone >= 1) return `${Math.round(hoursGone)} hour${hoursGone > 1 && 's'} ago`;
    const minutesGone = diff / (1000 * 60);
    if (minutesGone >= 1) return `${Math.round(minutesGone)} minut${minutesGone > 1 && 'es'} ago`;
    return 'just now';
  };

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div>
            <BackButton />
          </div>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>
                {`${values?.title} (${values?.requestNo})`}
              </Text>
              <Text type="body-1" weight={500}>
                {`${values?.vendor?.legalName} - ${totalCost} ${values?.currency?.code} `}
              </Text>
            </div>
            <div className={generalStyles.addItemButton}>
              {!!accessRequisition?.create &&
              (values?.requisitionState === 'DRAFT' || values?.requisitionState === 'REJECTED') ? (
                <>
                  <Link to={`/requisitions/edit/${requisitionId}`}>
                    <Button.Main $mid $style="blue" type="button">
                      Edit
                    </Button.Main>
                  </Link>
                  <Button.Main $mid $style="blue" type="submit" onClick={submitForApproval}>
                    Submit For Approval
                  </Button.Main>
                </>
              ) : null}
              {values?.requisitionState === 'PENDING_APPROVAL' &&
                accessRequisition?.approve &&
                location?.state?.canApprove &&
                isApprover.current > 0 &&
                !isCurrentUserApproved.current && (
                  <>
                    <Button.Main
                      $mid
                      $style="green"
                      type="submit"
                      form="requisitionForm"
                      disabled={isButtonsBlocked}
                      onClick={handleSubmit((data) => onSubmit(data, 'approved'))}
                    >
                      Approve
                    </Button.Main>
                    <Button.Main
                      $mid
                      $style="red"
                      type="submit"
                      form="requisitionForm"
                      disabled={isButtonsBlocked}
                      onClick={handleSubmit((data) => onSubmit(data, 'rejected'))}
                    >
                      Deny
                    </Button.Main>
                  </>
                )}
            </div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('overview')}>Overview</span>
            <span onClick={() => setTab('notes')}>Notes</span>
            <span onClick={() => setTab('documents')}>Documents</span>
            {values?.requisitionState === 'PENDING_APPROVAL' && (
              <span onClick={() => setTab('approval')}>Approval</span>
            )}
            <span onClick={() => setTab('history')}>History</span>
          </TabsSlider>
        </div>
        <br />
        {tab === 'overview' && values && (
          <>
            <Top data={values} errors={errors} control={control} />
            <br />
            <br />
            <div className={styles.itemsTable}>
              <div className={accessRequisition?.approve && location?.state?.canApprove && isApprover.current > 0 ? styles.itemsTableHead + ' ' + styles.itemsTableHeadReview : styles.itemsTableHead}>
                {accessRequisition?.approve && location?.state?.canApprove && isApprover.current > 0 ? <div/> : null}
                <Text type="body-2" weight={500}>
                  Item Name
                </Text>
                <Text type="body-2" weight={500}>
                  Vendor Code
                </Text>
                <Text type="body-2" weight={500}>
                  Unit Of Measure
                </Text>
                <Text type="body-2" weight={500}>
                  Account
                </Text>
                <Text type="body-2" weight={500}>
                  Quantity
                </Text>
                <Text type="body-2" weight={500}>
                  Unit Price
                </Text>
                <Text type="body-2" weight={500}>
                  Tax
                </Text>
                <Text type="body-2" weight={500}>
                  Subtotal
                </Text>
              </div>
              <div className={styles.tableBody}>
                {values?.requisitionItems?.map((item) => (
                  <GridItem
                    key={item.id}
                    data={item}
                    id={item.id}
                    currency={values?.budget?.currency}
                    exchangeRate={values?.currency?.rate}
                    budget={values?.budget}
                    showBudget={accessRequisition?.approve && location?.state?.canApprove && isApprover.current > 0}
                  />
                ))}
              </div>
            </div>
            <div className={stylesPo.summaryWrapper}>
              <Summary
                subtotal={subtotal}
                taxTotal={taxTotal}
                totalCost={totalCost}
                currency={values?.currency?.code}
                currencyBudget={defaultCurrency}
              />
            </div>
          </>
        )}
        {tab === 'notes' && values && (
          <CommentsTab
            notes={values.notes}
            setIsButtonsBlocked={setIsButtonsBlocked}
            isButtonsBlocked={isButtonsBlocked}
            refetch={() => setTrigger((trigger) => !trigger)}
            setToast={setToast}
            module="request"
          />
        )}
        {tab === 'documents' && <QuotesTab data={values?.attachments} />}
        {tab === 'history' && <RequisitionHistory handleDate={handleDate} fetchHistory={getData} />}
        {tab === 'approval' && <ApprovalTab data={values?.approval?.approvers} />}
      </Box>
      <div className={generalStyles.pageButtons}></div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default RequisitionView;
