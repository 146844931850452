import { useForm } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import { useStore } from '../../store/store.js';
import spmsServiceService from '../../services/spmsService.service.js';

import Documents from '../../components/UI/Forms/Documents.jsx';
import { useEffect, useState } from 'react';

const VendorDocsOnboarding = ({ setToast, vendorData }) => {
  const user = useStore((state) => state.user);
  const setSteps = useStore((state) => state.setSteps);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    getValues,
  } = useForm({ mode: 'onChange' });

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = function () {
        resolve(filereader.result);
      };
      filereader.onerror = function (error) {
        console.log('Error: ', error);
        reject(error);
      };
    });
  };
  useEffect(() => {
    clearErrors();
  }, [getValues('vendorAttachments')]);

  const onSubmit = async (data) => {
    setIsButtonsBlocked(true);
    const files = data.vendorAttachments.map(async (item) => ({
      name: item.name.split(' ').join('-'),
      document: await readFile(item.attachment),
    }));

    const attachments = await Promise.all(files).then((result) => result);
    const requestBody = { attachments };
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .createVendorDoc(user.externalId, requestBody)
      .then((_res) => {
        if (!!vendorData && !!vendorData?.invited && !!vendorData?.onboardedByCompanyId) {
          spmsServiceService
            .submitVendorApproval(vendorData.onboardedByCompanyId, vendorData.id)
            .then((_res) => {
              setToast({
                opened: true,
                message: 'Onboarding complete',
                type: 'success',
                cb: () => setIsButtonsBlocked(false),
              });
              setSteps();
            })
            .catch((err) => {
              setIsButtonsBlocked(false);
              setToast({
                opened: true,
                message: err.response.data.message,
                type: 'fail',
              });
            });
        } else {
          setToast({
            opened: true,
            message: 'Onboarding complete',
            type: 'success',
            cb: () => setIsButtonsBlocked(false),
          });
          setSteps();
        }
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const files = (files) =>
    files.map((file) => ({
      id: null,
      attachment: file,
      name: file.name,
      type: 'VENDOR_DOC',
    }));

  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Mandatory Documents
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text inherit type="h5" weight={600}>
            Add your mandatory documents
          </Text>
        </GradientText>
      </div>
      <div>
        <form className={styles.inputs}>
          <div className="inp-container">
            <Documents
              control={control}
              fieldName="vendorAttachments"
              filesSchema={files}
              $isVertical
              required
              error={errors?.attachments?.length ? errors?.attachments[0].message : ''}
            />
            {errors.vendorAttachments && (
              <p className="error-message">{errors.vendorAttachments?.root?.message}</p>
            )}
          </div>
        </form>
        <Button.Main
          $primary
          $full
          $style="blue"
          $iconRight
          type="submit"
          disabled={isButtonsBlocked}
          onClick={handleSubmit(onSubmit)}
        >
          {/* {company && !isDirty ? 'Continue' : 'Save & Continue'} */}
          Complete Profile Setup
        </Button.Main>
      </div>
    </div>
  );
};

export default VendorDocsOnboarding;
