import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from '../styles/loggedInLayout.module.css';
import Icon from './UI/General/Icon';
import Dropdown from './UI/General/Dropdown';
import Text from './UI/Typography/Text';
import { useStore } from '../store/store';
import { useShallow } from 'zustand/react/shallow';
import { useAccessAllowed } from '../hooks/useAccessAllowed';
import { useSubscriptionAllowed } from '../hooks/useSubscriptionAllowed';
import spmsServiceService from '../services/spmsService.service.js';
import UpgradePlan from './shared/UpgradePlan';

// const superAdminMenu = [
//   {
//     list: [
//       {
//         link: '/dashboard',
//         icon: 'dashboard',
//         title: 'Dashboard',
//         show: true,
//       },
//       {
//         link: '/sales',
//         icon: 'sales',
//         title: 'Sales',
//         show: true,
//       },
//       {
//         icon: 'customers',
//         title: 'Customers',
//         submenu: [
//           {
//             link: '/customers/overview',
//             title: 'Overview',
//             show: true,
//           },
//           {
//             link: '/customers/onboard-new-customer',
//             title: 'Onboard new Customer',
//             show: true,
//           },
//         ],
//       },
//       {
//         icon: 'agents',
//         title: 'Agents',
//         show: true,
//         submenu: [
//           {
//             link: '/agents/overview',
//             title: 'Overview',
//             show: true,
//           },
//           {
//             link: '/agents/create',
//             title: 'Create new Agent',
//             show: true,
//           },
//         ],
//       },
//       {
//         icon: 'vendors',
//         title: 'Vendors',
//         show: true,
//         submenu: [
//           {
//             link: '/vendors',
//             title: 'Overview',
//             show: true,
//           },
//           {
//             link: '/vendors/create',
//             title: 'Create',
//             show: false,
//           },
//           {
//             link: '/vendors/pending-approval',
//             title: 'Pending Approval',
//             show: true,
//           },
//           {
//             link: '/vendors/approved',
//             title: 'Approved',
//             show: true,
//           },
//           {
//             link: '/vendors/archived',
//             title: 'Archived',
//             show: true,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Management',
//     list: [
//       {
//         icon: 'users',
//         title: 'Users Management',
//         show: true,
//         submenu: [
//           {
//             link: '/users/overview/active',
//             title: 'Overview',
//             show: true,
//           },
//           {
//             link: '/users/add',
//             title: 'Create new Users',
//             show: true,
//           },
//         ],
//       },
//       {
//         icon: 'reports',
//         link: '/reports',
//         title: 'Reports',
//         show: true,
//       },
//     ],
//   },
//   {
//     title: 'System Configuration',
//     list: [
//       {
//         icon: 'subscription-packages-and-coupons',
//         title: 'Subscription Packages and coupons',
//         show: true,
//         submenu: [
//           {
//             link: '/subscription-packages-and-coupons/overview',
//             title: 'Overview',
//             show: true,
//           },
//           {
//             link: '/subscription-packages-and-coupons/create-a-new-package',
//             title: 'Create a new Package',
//             show: true,
//           },
//         ],
//       },
//       {
//         icon: 'integrations',
//         link: '/integrations',
//         title: 'Integrations',
//         show: true,
//       },
//       {
//         icon: 'currencies',
//         link: '/currencies',
//         title: 'Currencies',
//         show: true,
//       },
//       {
//         icon: 'legal-regions',
//         link: '/legal-regions',
//         title: 'Legal Regions',
//         show: true,
//       },
//       {
//         icon: 'roles-permissions',
//         link: '/system-roles-and-permissions',
//         title: 'System Roles and Permissions',
//         show: true,
//       },
//       {
//         icon: 'dashboard',
//         title: 'Notification Templates',
//         show: true,
//         submenu: [
//           {
//             link: '/notification-templates/create',
//             title: 'Create',
//             show: true,
//           },
//           {
//             link: '/notification-templates/overview/active',
//             title: 'Active',
//             show: true,
//           },
//         ],
//       },
//     ],
//   },
// ];

// const agentMenu = [
//   {
//     list: [
//       {
//         link: '/dashboard',
//         icon: 'dashboard',
//         title: 'Dashboard',
//         show: true,
//       },
//       {
//         link: '/sales',
//         icon: 'sales',
//         title: 'Sales',
//         show: true,
//       },
//       {
//         icon: 'customers',
//         title: 'Customers',
//         show: true,
//         submenu: [
//           {
//             link: '/customers/overview',
//             title: 'Overview',
//             show: true,
//           },
//           {
//             link: '/customers/onboard-new-customer',
//             title: 'Onboard new Customer',
//             show: true,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     title: 'Management',
//     list: [
//       {
//         link: '/reports',
//         icon: 'reports',
//         title: 'Reports',
//         show: true,
//       },
//     ],
//   },
// ];

const vendorMenu = [
  {
    list: [
      {
        title: 'Dashboard',
        icon: 'dashboard',
        link: '/dashboard',
        show: true,
      },
      {
        title: 'Orders',
        icon: 'vendor-orders',
        link: '/vendor-orders',
        show: true,
      },
      {
        title: 'Invoices',
        icon: 'invoices',
        link: '/vendor-invoices',
        show: true,
      },
    ],
  },
  {
    title: 'Manage',
    list: [
      {
        title: 'Customers',
        icon: 'clients',
        link: '/vendor-clients',
        show: true,
      },
      {
        title: 'Catalog',
        icon: 'dashboard',
        link: '/vendor-catalog',
        show: true,
      },
      {
        title: 'Profile',
        icon: 'userProfile',
        link: '/vendor-profile',
        show: true,
      },
    ],
  },
];

const SideBar = ({ showMenu }) => {
  const user = useStore((state) => state.user);
  const company = useStore(useShallow((state) => state.company));
  const activeCompany = useStore((state) => state.activeCompany);
  const [menu, setMenu] = useState(null);
  const [canCreateUser, setCanCreateUser] = useState(true);
  const isConfiguration = useStore((state) => state.isConfiguration);
  const setIsConfiguration = useStore((state) => state.setIsConfiguration);
  const access = useAccessAllowed([
    'Requests',
    'Purchase_Orders',
    'Vendor_Management',
    'Budget_Management',
    'Payment',
    'Receipt',
    'Invoice',
    'Insights',
    'Configuration',
  ]);

  const accessSubscription = useSubscriptionAllowed();

  useEffect(() => {
    if (['Basic', 'Standard'].includes(accessSubscription?.plan?.name)) {
      spmsServiceService.getMetrics(company.id).then((res) => {
        const metrics = res.data.data;
        const users = metrics.invitedUsers + metrics.activeUsers;
        setCanCreateUser(accessSubscription?.plan?.maxUsers > users);
      });
    }
  }, [accessSubscription]);

  const clientAdminEndUserMenu = useMemo(
    () => [
      {
        list: [
          {
            icon: 'dashboard',
            link: '/dashboard',
            title: `${isConfiguration?.value ? 'Back to' : ''} Dashboard`,
            show: true,
          },
          {
            icon: 'requests',
            title: 'Requests',
            link: '/requisitions',
            show:
              access?.requests?.viewAllCompany ||
              access?.requests?.viewAllDepartmentLocation ||
              access?.requests?.viewOwn,
          },
          {
            icon: 'requests',
            title: 'Approve',
            link: () => {
              if (access?.requests?.approve) {
                return '/approve/requisitions';
              } else if (access?.budget_management?.approve) {
                return '/approve/budget';
              } else if (access?.vendor_management?.approve) {
                return '/approve/vendor';
              } else if (access?.invoice?.approve) {
                return '/approve/invoices';
              } else {
                return '/dashboard';
              }
            },
            show:
              access?.requests?.approve ||
              access?.budget_management?.approve ||
              access?.vendor_management?.approve ||
              access?.invoice?.approve,
          },
          {
            icon: 'purchase-orders',
            title: 'Procure',
            link: '/purchase-orders/overview/approved',
            show: access?.purchase_orders?.viewAllCompany || access?.purchase_orders?.viewOwn,
          },
          {
            icon: 'receiving',
            title: 'Receiving',
            link: '/receiving/overview/not_fulfilled',
            show: access?.receipt?.viewAllCompany || access?.receipt?.viewOwn,
          },
        ],
      },
      {
        title: 'Account Payble',
        list: [
          {
            icon: 'invoices',
            title: 'Invoice',
            link: '/invoices/overview/outstanding',
            show: access?.invoice?.viewAllCompany || access?.invoice?.viewOwn,
          },
          {
            icon: 'payments',
            title: 'Payment',
            link: '/payments/overview/outstanding',
            show: access?.payment?.viewAllCompany || access?.payment?.viewOwn,
          },
        ],
      },
      {
        title: 'Manage',
        list: [
          {
            icon: 'vendors',
            title: 'Vendors',
            link: '/vendors',
            show: access?.vendor_management?.viewAllCompany || access?.vendor_management?.viewOwn,
          },
          {
            icon: 'budgets',
            title: 'Budgets',
            link: '/budgets/draft',
            show: access?.budget_management?.viewAllCompany || access?.budget_management?.viewOwn,
            // isRestricted: !accessSubscription?.plan?.maxProjectBudgets,
          },
          {
            icon: 'reports',
            title: 'Insights',
            link: '/insights',
            show: access?.insights?.viewAllCompany || access?.insights?.viewOwn,
          },
          {
            icon: 'reports',
            link: '/reports',
            title: 'Reports',
            show: false,
            // show: access?.reports?.viewAll || access?.reports?.viewOwn,
          },
        ],
      },
    ],
    [company, activeCompany, access, accessSubscription, isConfiguration],
  );

  const configurationMenu = useMemo(
    () => [
      {
        list: [
          {
            icon: 'dashboard',
            link: '/dashboard',
            title: 'Dashboard',
            show: true,
          },
        ],
      },
      {
        title: 'Configuration',
        list: [
          {
            link: '/company-profile',
            icon: 'companies',
            title: 'Company Profile',
            show: access?.configuration?.viewAllCompany,
          },
          {
            link: '/locations',
            icon: 'locations',
            title: 'Locations',
            show: access?.configuration?.viewAllCompany,
          },
          {
            link: '/department-setup',
            icon: 'departments',
            title: 'Departments',
            show: access?.configuration?.viewAllCompany,
          },
          {
            link: '/gl-setup',
            icon: 'gl',
            title: 'Account Codes',
            show: access?.configuration?.viewAllCompany,
          },
          {
            icon: 'tax',
            link: '/taxes',
            title: 'Taxes',
            show: access?.configuration?.viewAllCompany,
          },
          {
            icon: 'notification',
            link: '/notification',
            title: 'Notifications',
            // isRestricted: !accessSubscription?.plan?.allowCustomNotificationManagement,
            show: access?.configuration?.viewAllCompany,
          },
          {
            icon: 'approval-workflow',
            link: '/approval',
            title: 'Approval Workflow',
            // show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
            show: true,
          },

          {
            icon: 'dashboard',
            link: '/notification-templates/overview/active',
            title: 'Notification Templates',
            // show: access?.configuration?.viewAll || access?.configuration?.viewOwn,
            show: true,
          },
        ],
      },
      {
        title: 'User Management',
        list: [
          {
            icon: 'users',
            link: '/users/overview/active',
            title: 'Users Overview',
            show: access?.configuration?.viewAllCompany,
          },
          {
            icon: 'roles-permissions',
            link: '/user-roles-and-permissions/active',
            title: 'Roles and Permissions',
            show: access?.configuration?.viewAllCompany,
          },
        ],
      },
    ],
    [activeCompany, access, isConfiguration],
  );

  useEffect(() => {
    let menu = user.roles.map((el) => el.role?.name) ?? [];
    const hasConfiguration = menu.includes('CLIENT_ADMIN');
    switch (true) {
      case menu.includes('VENDOR'):
        menu = vendorMenu;
        break;
      default:
        menu = clientAdminEndUserMenu;
    }
    if (hasConfiguration) {
      setMenu(isConfiguration?.value ? configurationMenu : clientAdminEndUserMenu);
    } else {
      setMenu(menu);
    }
  }, [user, clientAdminEndUserMenu, isConfiguration]);

  return (
    <aside className={!showMenu ? styles.sidebar : styles.sidebar + ' ' + styles.sidebarOpened}>
      <div className={styles.sidebarLogo}>
        <Link to="/dashboard">
          <img src="/assets/img/login_register/spendproLogo.svg" alt="SpendPro-Logo" />
        </Link>
      </div>
      <div className={styles.sidebarMenu}>
        {menu && menu.length > 0
          ? menu.map((item, i) => {
              let menuList;
              let nullCounter = 0;
              if (item.list && item.list.length > 0) {
                menuList = item.list.map((item, index) => {
                  if (!!item.show) {
                    // if (item.isRestricted) {
                    //   return (
                    //     <div className={styles.sidebarMenuLink} key={index}>
                    //       <Icon $icon={item.icon} $width={32} $height={32} />
                    //       <span>{item.title}</span>
                    //       <UpgradePlan
                    //         text={`${item.title} are available on Standard and Premium plans.`}
                    //       />
                    //     </div>
                    //   );
                    // } else {
                    return (
                      <div key={index} className={styles.sidebarLinkContainer}>
                        <NavLink
                          to={typeof item.link === 'function' ? item.link() : item.link}
                          onClick={() => {
                            item.link === '/dashboard' &&
                              isConfiguration?.value &&
                              setIsConfiguration(false);
                          }}
                          className={({ isActive }) =>
                            isActive
                              ? styles.sidebarMenuLink + ' ' + styles.active
                              : styles.sidebarMenuLink
                          }
                        >
                          <Icon $color="none" $icon={item.icon} $width={20} $height={20} />
                          <Text weight={500}>{item.title}</Text>
                        </NavLink>
                      </div>
                    );
                  } else {
                    nullCounter++;
                  }
                });
              }
              return nullCounter === item.list.length ? null : (
                <div className={styles.sidebarMenuColumn} key={i}>
                  {item.title !== undefined ? (
                    <Text weight={500} type="body-1" className={styles.sidebarMenuHeading}>
                      {item.title}
                    </Text>
                  ) : null}
                  {menuList}
                </div>
              );
            })
          : null}
      </div>
    </aside>
  );
};

export default SideBar;
