import React, { useEffect, useState } from 'react';
import SignUp from '../components/Auth/SingUp';
import Verify from '../components/Auth/Verify';
import Box from '../components/UI/General/Box';
import Text from '../components/UI/Typography/Text';
import style from '../styles/log_reg.module.css';
import GradientText from '../components/UI/General/GradientText';
import SignUpVendor from '../components/Auth/SignUpVendor';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';

const Registration = () => {
  const [status, setStatus] = useState('sign-up');
  const [username, setUsername] = useState(null);
  const [dataToRemember, setDataToRemember] = useState(null);
  const [searchParams, _setSearchParams] = useSearchParams();
  const type = searchParams.get('type');
  const { vendorId } = useParams();
  useEffect(() => {
    if (type === 'vendor' || vendorId) setStatus('sign-up-vendor');
  }, [type]);

  const screens = {
    'sign-up': (
      <SignUp
        setStatus={setStatus}
        setUsername={setUsername}
        setDataToRemember={setDataToRemember}
        dataToRemember={dataToRemember}
      />
    ),
    verify: <Verify setStatus={setStatus} username={username} />,
    'sign-up-vendor': (
      <SignUpVendor
        setStatus={setStatus}
        setUsername={setUsername}
        setDataToRemember={setDataToRemember}
        dataToRemember={dataToRemember}
      />
    ),
  };

  return (
    <div className={style.auth}>
      <div className={style.authInner}>
        <div className={style.motto}>
          <div>
            <Link to='/'>
              <img src="/assets/img/login_register/spendproLogo.svg" alt="SpendPro-Logo" />
            </Link>
          </div>
          <GradientText $from="#7ea4ef" $to="#dcdfe6">
            <Text className={style.mottoText} type="h3" weight={600}>
              Spendpro improves customer service, offering a better solution for your business.
              Start your free trial today and explore the benefits. We'll guide you to get the most
              out of it.
            </Text>
          </GradientText>
        </div>
        <Box $noPadding $radius={40} className={style.authContainer} $noOverflow>
          <div className={style.authContent}>{screens[status]}</div>
        </Box>
      </div>
    </div>
  );
};

export default Registration;
