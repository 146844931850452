import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../../styles/vendor.module.css';
import Text from '../../components/UI/Typography/Text';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import { useEffect } from 'react';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import TabsSlider from '../../components/UI/General/TabsSlider';
import BackButton from '../../components/shared/BackButton';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';

const ActiveDashboard = () => {
  const { vendorId, companyId } = useParams();
  const [activeVendorData, setActiveVendorData] = useState(null);
  const [tab, setTab] = useState('vendor details');
  const vendorContact = activeVendorData?.vendor?.vendorContacts?.find(
    (contact) => contact.contactType === 'ENTERED_BY_VENDOR',
  );
  const primaryContact = activeVendorData?.vendor?.vendorContacts?.find(
    (contact) => contact.contactType === 'ENTERED_BY_COMPANY',
  );
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  useEffect(() => {
    spmsServiceService
      .getCompanyVendorById(companyId, vendorId)
      .then((res) => {
        setActiveVendorData(res.data.data);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message ? err.response.data.message : 'Something Went Wrong',
          type: 'fail',
        });
      });
  }, [vendorId]);

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div>
            <BackButton />
          </div>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>
                {`${activeVendorData?.vendor?.legalName} (${readableTitleFromBackend(
                  activeVendorData?.approvalStatus,
                )})`}
              </Text>
              <Text type="body-1" weight={500}>
                {`${activeVendorData?.vendor?.category} - ${activeVendorData?.vendor?.subCategory}`}
              </Text>
            </div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('vendor details')}>Vendor Details</span>
            <span onClick={() => setTab('documents')}>Documents</span>
          </TabsSlider>
        </div>
        {tab === 'request details' && activeVendorData && (
          <div className={styles.vendorDetails}>
            <div className={`${styles.inReviewInfo} `}>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Requestor
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.requester?.name}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Location
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.locations[0]?.name}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Department
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.departments[0]?.name}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Products & Services
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.purchaseDescription}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Benefits vendor offers
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.benefitsFromVendor}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    NDA signed
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.ndaSigned ? 'Yes' : 'No'}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === 'vendor details' && activeVendorData && (
          <div className={styles.vendorDetails}>
            <div className={`${styles.inReviewInfo} ${generalStyles.underline}`}>
              <Text type="subtitle" weight={500}>
                Legal Information
              </Text>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Legal Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.legalName}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Trading Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.tradingName ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Legal Structure
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.legalStructure ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Tax Identification Number
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.taxIdentificationNumber ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Country of Incorporation
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.countryOfIncorporation}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Registration Number
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.registrationNo ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Tier
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {readableTitleFromBackend(activeVendorData?.tier) ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Vendor Code
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendorCode ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Invoice Treshold
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.invoiceThresholdProperties
                      ? `${activeVendorData?.invoiceThresholdProperties.invoiceThresholdValue} (${activeVendorData?.invoiceThresholdProperties.invoiceThresholdType})`
                      : '-'}
                  </Text>
                </div>
              </div>
            </div>
            <div className={`${styles.inReviewInfo} ${generalStyles.underline}`}>
              <Text type="subtitle" weight={500}>
                Payment Information
              </Text>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Bank Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor
                      ? activeVendorData.vendor.vendorFinancialInfo?.bankName
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Account Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor
                      ? activeVendorData.vendor.vendorFinancialInfo?.accountName
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Account Number/IBAN
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor
                      ? activeVendorData.vendor.vendorFinancialInfo?.accountIban
                      : '-'}
                  </Text>
                </div>

                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Account Currency
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor
                      ? activeVendorData.vendor.vendorFinancialInfo?.currency?.code
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Sortcode/Routing
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor
                      ? activeVendorData.vendor.vendorFinancialInfo?.sortCodeRouting
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    SWIFT Code
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor
                      ? activeVendorData.vendor.vendorFinancialInfo?.swiftBic
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Bank Address
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor
                      ? activeVendorData.vendor.vendorFinancialInfo?.bankAddress
                      : '-'}
                  </Text>
                </div>
              </div>
            </div>
            <div className={`${styles.inReviewInfo} ${generalStyles.underline}`}>
              <Text type="subtitle" weight={500}>
                Contact Information
              </Text>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Company Email
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {primaryContact?.email ? primaryContact?.email : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Contact Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {primaryContact?.name ? primaryContact?.name : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Web URL
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.websiteUrl ? vendorContact?.websiteUrl : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Contact Email
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.email}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Contact Phone
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.phoneNumber}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    LinkedIn
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.socialAccounts?.linkedIn
                      ? vendorContact?.socialAccounts?.linkedIn
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Facebook
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.socialAccounts?.fb ? vendorContact?.socialAccounts?.fb : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Instagram
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.socialAccounts?.instagram
                      ? vendorContact?.socialAccounts?.instagram
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    (X) twitter
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.socialAccounts?.twitter
                      ? vendorContact?.socialAccounts?.twitter
                      : '-'}
                  </Text>
                </div>
              </div>
            </div>
            <div className={`${styles.inReviewInfo}`}>
              <Text type="subtitle" weight={500}>
                Registered Address
              </Text>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Address 1
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.address?.addressLine2}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Address 2
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.address?.streetAddress}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Address 3
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.address?.addressLine3 ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    City/Town
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.address?.city}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    State/Region
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.address?.state}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Country
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.address?.country}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    ZIP/Postal Code
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.address?.zipCode}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Timezone
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {activeVendorData?.vendor?.timezone}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === 'documents' && activeVendorData && (
          <QuotesTab
            data={[...activeVendorData.attachments, ...activeVendorData.vendor.attachments]}
          />
        )}
      </Box>
      {toast.opened && <Toast type={toast.type} message={toast.message} opened={toast.opened} />}
    </>
  );
};

export default ActiveDashboard;
