import React from "react";
import style from "../../styles/log_reg.module.css";
import Text from "../UI/Typography/Text";

import * as Button from "../UI/Forms/Button";

const CheckEmail = ({ setStatus, setUserName }) => {
	const handleRetrunLogin = () => {
		setStatus("sign-in");
	};
	return (
		<div className={style.authContent}>
			<div className={style.authHeader + " " + style.cnpHeader}>
				<Text type="h2" weight={600}>
					Check your Email
				</Text>
				<Text type="subtitle">
					If we find an account matching this information, you will
					receive a password reset link to your email
				</Text>
			</div>
			<Button.Main
				$primary
				$full
				$style="blue"
				$iconRight
				type="button"
				onClick={handleRetrunLogin}
			>
				<span>Return to Login</span>
			</Button.Main>
		</div>
	);
};
export default CheckEmail;
