import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import { useForm } from 'react-hook-form';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import Box from '../../components/UI/General/Box';
import { reducedText } from '../../utils/reducedText';
import AddButton from '../../components/shared/AddButton';
import Search from '../../components/UI/General/Search';
import Filter from '../../components/UI/General/Filter';
import SpmsService from '../../services/spmsService.service';
import Status from '../../components/UI/Typography/Status';
import { nf } from '../../utils/nf';
import s2pmsVendorPortal from '../../services/vendorPortal.service';

const VendorInvoice = () => {
  const navigate = useNavigate();
  const {
    control,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const [filterText, setFilterText] = useState(undefined);
  const activeCompany = 'd413f637-3ac1-4d02-b70d-2228d314a6dd';
  const user = useStore((state) => state.user);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    if (!!user?.externalId){
      s2pmsVendorPortal.getVendorCompanies(user.externalId).then(r => {
        if (r.data.message === "Operation Successful"){
          const newData = r.data.data.map(el => ({label: el.company.name, value: el.company.id}))
          console.log(newData);
          const newFilterData = [
            {
              name: 'customer',
              label: 'Customer',
              isMulti: false,
              options: newData,
            },
            {
              name: 'status',
              label: 'Status',
              isMulti: false,
              options: [
                { label: 'New', value: 'NEW' },
                { label: 'Not Invoiced', value: 'NOT_INVOICED' },
                { label: 'Pending Approval', value: 'PENDING_APPROVAL' },
                { label: 'Approved', value: 'APPROVED' },
                { label: 'Rejected', value: 'REJECTED' },
                { label: 'Partially Paid', value: 'PARTIALLY_PAID' },
                { label: 'Paid', value: 'PAID' },
                { label: 'Cancelled', value: 'CANCELLED' },
              ],
            }
          ]
          setFilterData(newFilterData)
        }
      })
    }
  }, [user])

  const columns = useMemo(
    () => [
      {
        name: 'PO Number',
        wrap: true,
        sortable: true,
        selector: (row) => row?.purchaseOrderNo,
        cell: (row) => (
          <span data-content={'PO Number'} className={generalStyles.tableValue}>
            <Link className={generalStyles.tableLink} to={'/purchase-orders/' + row.id} state={{ tabToSwitch: 'invoices' }}>
              <span>{row?.purchaseOrderNo}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'Customer',
        wrap: true,
        sortable: true,
        selector: (row) => row?.company?.name,
        cell: (row) => {
          return (
            <span data-content={'Customer'} className={generalStyles.tableValue}>
              <span>{row?.company?.name}</span>
            </span>
          );
        },
      },
      {
        name: 'Location',
        cell: (row) => (
          <span data-content={'Location'} className={generalStyles.tableValue}>
            <span>{row?.locationName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.branchName,
      },
      {
        name: 'Department',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row?.department?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.department?.name,
      },
      {
        name: 'Status',
        sortable: true,
        selector: (row) => row?.invoiceStatus,
        cell: (row) => (
          <span
            data-content={'Status'}
            className={generalStyles.tableValue}
            data-tag="allowRowEvents"
          >
            <Status value={row?.invoiceStatus} pill />
          </span>
        ),
      },
      {
        name: 'Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return (
            <span data-content={'Amount'} className={generalStyles.tableValue}>
              <span>{cur + ' ' + nf.format(row?.total)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.total,
      },
    ],
    [],
  );

  const getData = useCallback((page, size) => {
      const filterValues = getValues();
      const search = {
        invoiceStatus: filterValues.status?.value ?? undefined,
        purchaseOrderState: 'APPROVED',
        purchaseOrderNo: filterText,
        departmentId: filterValues.department?.value,
        locationId: filterValues.location?.value,
        vendorId: user?.externalId,
        requesterId: filterValues.requester?.value,
      };
      return SpmsService.getPurchaseOrders(size, page, search);
    }, [filterText, triggerRefetch, user],
  );

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        placeholder="Search by PO Number"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText, resetPaginationToggle],
  );

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <Text type="subtitle" weight={500}>Invoice Overview</Text>
        <br/>
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}>
            {filterCmp}
            <div className={generalStyles.filters}>
              <Filter
                control={control}
                filterData={filterData}
                setTriggerRefetch={setTriggerRefetch}
                setValue={setValue}
              />
            </div>
          </div>
          {/*<div className={generalStyles.addItemButton}>*/}
          {/*  <AddButton $click={() => navigate('/requisitions/create')} $text="create Invoice" />*/}
          {/*</div>*/}
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
      </Box>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default VendorInvoice;
