import { Controller, useForm, useWatch } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import Select from 'react-select';
import CountryAndRegion from '../../components/UI/Forms/CountryAndRegion.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import { useStore } from '../../store/store.js';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import spmsServiceService from '../../services/spmsService.service.js';
import requestsServiceService from '../../services/requestsService.service.js';
import * as yup from 'yup';
import { regExps } from '../../utils/regExps.js';

const legalStructureOptions = [
  { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
  { label: 'Partnership', value: 'Partnership' },
  { label: 'Limited Liability Company (LLC)', value: 'Limited Liability Company' },
  { label: 'Corporation', value: 'Corporation' },
  { label: 'Cooperative (Co-op)', value: 'Cooperative' },
  { label: 'Public Limited Company (PLC)', value: 'Public Limited Company' },
  { label: 'Joint Venture', value: 'Joint Venture' },
  { label: 'Limited Liability Partnership (LLP)', value: 'Limited Liability Partnership' },
];

const legalInfoSchema = yup.object().shape({
  legalName: yup
    .string()
    .required('Legal name is required')
    .matches(/^[a-zA-Z0-9@&()-\s]+$/, 'Invalid characters')
    .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
    .max(50, '50 characters max'),
  tradingName: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Registration number cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9@&()-\s]+$/, 'Invalid characters')
    .max(50, '50 characters max'),
  legalStructure: yup.object().shape({
    value: yup.string().required('Legal Structure is required'),
  }),
  countryOfIncorporation: yup.object().shape({
    value: yup.string().required('Country is required'),
  }),
  registrationNo: yup
    .string()
    .required('Registration Number is required')
    .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters')
    .max(30, 'Registration Number must be at most 30 characters'),
  taxIdentificationNumber: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Tax Number number cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters')
    .max(30, 'Tax Number must be at most 30 characters'),
  category: yup.object().shape({
    value: yup.string().required('Category is required'),
  }),
  subCategory: yup.object().shape({
    value: yup.string().required('Sub Category is required'),
  }),
});

const VendorLegalOnboarding = ({ nextStep, setToast, vendorData }) => {
  const user = useStore((state) => state.user);
  const updateUser = useStore((state) => state.updateUser);
  const setSteps = useStore((state) => state.setSteps);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({ mode: 'onChange', resolver: yupResolver(legalInfoSchema) });
  const categoryWatcher = useWatch({ control, name: 'category' });

  useEffect(() => {
    if (!vendorData) return;
    setValue('legalName', vendorData.legalName);
    setValue('category', { label: vendorData.category, value: vendorData.category });
    setValue('subCategory', { label: vendorData.subCategory, value: vendorData.subCategory });
  }, [vendorData]);

  useEffect(() => {
    spmsServiceService.getVendorCategories().then((res) => {
      const categories = res.data.data;
      setVendorCategories(categories);
    });
  }, []);

  const onSubmit = async (data) => {
    if (!isDirty) {
      nextStep();
      return;
    }
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    let requestBody = {
      legalName: data.legalName,
      tradingName: data.tradingName,
      countryOfIncorporation: data.countryOfIncorporation.value,
      legalStructure: data.legalStructure.value,
      taxIdentificationNumber: data.taxIdentificationNumber,
      registrationNo: data.registrationNo,
      category: data.category.value,
      subCategory: data.subCategory.value,
    };
    const vendorId = user.externalId;
    if (vendorId) {
      spmsServiceService
        .editVendorDetails(vendorId, requestBody)
        .then((_res) => {
          requestsServiceService
            .updateUserInfo(user.id, {
              ...user,
              firstName: data.legalName,
            })
            .then((_result) => {
              setToast({
                opened: true,
                message: 'Saved',
                type: 'success',
                cb: () => {
                  setIsButtonsBlocked(false);
                  nextStep();
                },
              });
              updateUser({
                ...user,
                firstName: data.legalName,
                lastName: '',
              });
              setSteps();
            })
            .catch((err) => {
              setToast({
                opened: true,
                message: err.response.data.message,
                type: 'fail',
              });
              setIsButtonsBlocked(false);
            });
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
          setIsButtonsBlocked(false);
        });
    } else
      spmsServiceService
        .createVendor(requestBody)
        .then((res) => {
          requestsServiceService
            .updateUserInfo(user.id, {
              ...user,
              externalId: res.data.data.id,
              firstName: data.legalName,
            })
            .then((_result) => {
              setToast({
                opened: true,
                message: 'Saved',
                type: 'success',
                cb: () => {
                  nextStep();
                  setIsButtonsBlocked(false);
                },
              });
              updateUser({
                ...user,
                externalId: res.data.data.id,
                firstName: data.legalName,
              });
              setSteps();
            })
            .catch((err) => {
              setToast({
                opened: true,
                message: err.response.data.message,
                type: 'fail',
              });
              setIsButtonsBlocked(false);
            });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
  };

  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Legal Information
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text inherit type="h5" weight={600}>
            Add your legal information
          </Text>
        </GradientText>
      </div>
      <div>
        <form className={styles.inputs}>
          <div className="inp-container">
            <Controller
              name="legalName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Legal name *"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.legalName && <p className="error-message">{errors.legalName?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="tradingName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Trading name"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.tradingName && <p className="error-message">{errors.tradingName?.message}</p>}
          </div>
          <div className={generalStyles.fieldsTwo}>
            <div className="inp-container">
              <Controller
                name="legalStructure"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    classNamePrefix="react-select"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    isSearchable
                    placeholder="Select Legal Structure *"
                    options={legalStructureOptions}
                    menuPortalTarget={document.body}
                    menuPosition={'absolute'}
                    menuPlacement={'bottom'}
                    menuShouldScrollIntoView={false}
                  />
                )}
              />
              {errors.legalStructure && (
                <p className="error-message">{errors.legalStructure?.value.message}</p>
              )}
            </div>
            <CountryAndRegion
              countryFieldName="countryOfIncorporation"
              control={control}
              isDisable={false}
              errors={errors}
              isCountryRequired
            />
            <div className="inp-container">
              <Controller
                name="taxIdentificationNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Tax Identification Number *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.taxIdentificationNumber && (
                <p className="error-message">{errors.taxIdentificationNumber?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="registrationNo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Registration Number *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.registrationNo && (
                <p className="error-message">{errors.registrationNo?.message}</p>
              )}
            </div>
          </div>
          <div className="inp-container">
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Category *"
                  options={Array.from(new Set(vendorCategories.map((cat) => cat.category))).map(
                    (category) => ({
                      label: category,
                      value: category,
                    }),
                  )}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('subCategory', null);
                  }}
                />
              )}
            />
            {errors.category && <p className="error-message">{errors.category?.value.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="subCategory"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Sub Category *"
                  options={vendorCategories
                    .filter((category) => category.category === categoryWatcher?.value)
                    .map((subcat) => ({
                      label: subcat.subCategory,
                      value: subcat.subCategory,
                    }))}
                />
              )}
            />
            {errors.subCategory && (
              <p className="error-message">{errors.subCategory?.value.message}</p>
            )}
          </div>
        </form>
        <Button.Main
          $primary
          disabled={isButtonsBlocked}
          $full
          $style="blue"
          $iconRight
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {/* {company && !isDirty ? 'Continue' : 'Save & Continue'} */}
          Save & Continue
        </Button.Main>
      </div>
    </div>
  );
};

export default VendorLegalOnboarding;
