import styled, { css } from 'styled-components';

const colors = {
  dark: {
    bg: '#145FF51A',
    color: '#ffffff',
    bdr: '#145FF51A',
  },
  blue: {
    bg: '#145FF5',
    color: '#ffffff',
    bdr: '#145FF5',
    disabled: '#6E9BF5',
  },
  green: {
    bg: '#00C46F',
    color: '#ffffff',
    bdr: '#00C46F',
    disabled: '#56e3a6',
  },
  red: {
    bg: '#F75656',
    color: '#ffffff',
    bdr: '#F75656',
    disabled: '#fe8383',
  },
  pink: {
    bg: '#C85D9F',
    color: '#ffffff',
    bdr: '#C85D9F',
    disabled: '#ea9ecd',
  },
  gray: {
    bg: '#DDDDDD',
    color: '#00000099',
    bdr: '#DDDDDD',
  },
  blank: {
    bg: '#ffffff',
    color: '#4B5865',
    bdr: '#C9C9C9',
    disabled: '#e0e0e0',
  },
  white: {
    bg: '#ffffff',
    color: '#ACAEC9',
    bdr: '#ffffff',
  },
  lightGrayishBlue: {
    bg: '#145FF50D',
    color: '#ACAEC9',
    bdr: '#ECF8F8',
  },
  budgetOk: {
    bg: 'rgba(0,196,11,0.1)',
    color: '#00C46F',
    bdr: 'rgba(0,196,11,0.1)',
  },
  budgetHigh: {
    bg: '#FFD8D8',
    color: '#F75656',
    bdr: '#FFD8D8',
  },
  budgetDefault: {
    bg: '#E7E7E7',
    color: '#A1A1A1',
    bdr: '#E7E7E7',
  },
  pagination: {
    bg: '#ffffff',
    color: '#000000',
    bdr: '#dddddd',
  },
  pagination_active: {
    bg: '#ffffff',
    color: '#145FF5',
    bdr: '#145FF5',
  },
};
const hoverColors = {
  dark: {
    bg: '#ffffff',
    color: '#145FF51A',
    bdr: '#145FF51A',
  },
  blue: {
    bg: '#ffffff',
    color: '#145FF5',
    bdr: '#145FF5',
  },
  green: {
    bg: '#ffffff',
    color: '#00C46F',
    bdr: '#00C46F',
  },
  red: {
    bg: '#ffffff',
    color: '#F75656',
    bdr: '#F75656',
  },
  pink: {
    bg: '#ffffff',
    color: '#C85D9F',
    bdr: '#C85D9F',
  },
  gray: {
    bg: '#ffffff',
    color: '#000000',
    bdr: '#DDDDDD',
  },
  blank: {
    bg: '#145FF50D',
    color: '#4B5865',
    bdr: '#C9C9C9',
  },
  white: {
    bg: '#ffffff',
    color: '#ACAEC9',
    bdr: '#ffffff',
  },
  lightGrayishBlue: {
    bg: '#ECF8F8',
    color: '#ACAEC9',
    bdr: '#ECF8F8',
  },
  budgetOk: {
    bg: 'rgba(0,196,11,0.1)',
    color: '#00C46F',
    bdr: 'rgba(0,196,11,0.1)',
  },
  budgetHigh: {
    bg: '#FFD8D8',
    color: '#F75656',
    bdr: '#FFD8D8',
  },
  budgetDefault: {
    bg: '#E7E7E7',
    color: '#A1A1A1',
    bdr: '#E7E7E7',
  },
  pagination: {
    bg: '#ffffff',
    color: '#145FF5',
    bdr: '#145FF5',
  },
  pagination_active: {
    bg: '#ffffff',
    color: '#145FF5',
    bdr: '#145FF5',
  },
};

const baseButtonStyle = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ $full }) => ($full ? '100%' : 'auto')};
  padding: 0;
  font-family: 'BDO', sans-serif;
  background-color: ${({ $style, $reverseColor }) =>
    $style ? colors[$style][$reverseColor ? 'color' : 'bg'] : '#595858'};
  border: ${({ $style }) => ($style ? `1px solid ${colors[$style].bdr}` : `1px solid #595858`)};
  color: ${({ $style, $reverseColor }) =>
    $style ? colors[$style][$reverseColor ? 'bg' : 'color'] : '#ffffff'};
  white-space: nowrap;
  & a {
    font-weight: inherit !important;
    color: ${({ $style }) => ($style ? colors[$style].color : '#ffffff')};
  }

  ${({ $iconLeft, $iconRight, $full }) => {
    if ($full && $iconRight) {
      return css`
        & svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
        }
      `;
    }
    if ($full && $iconLeft) {
      return css`
        & svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 16px;
        }
      `;
    }
    if ($iconLeft) {
      return css`
        & svg {
          margin-right: 12px;
        }
      `;
    }
    if ($iconRight) {
      return css`
        & svg {
          margin-left: 12px;
        }
      `;
    }
  }}
  ${({ $variant }) => {
    switch ($variant) {
      case 'round':
        return css`
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          border-radius: 50px;
        `;
      case 'circle':
        return css`
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        `;
      default:
        return css`
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          border-radius: 6px;
        `;
    }
  }}
  ${({ disabled }) => {
    if (disabled) {
      return css`
        border: none;
        opacity: 0.75;
        background-color: ${({ $style }) => ($style ? colors[$style].disabled : '#595858')};
        cursor: not-allowed;
        & svg {
          fill: #595858;
        }
      `;
    } else {
      return css`
        &:hover {
          background-color: ${({ $style, $reverseColor }) =>
            $style ? hoverColors[$style][$reverseColor ? 'color' : 'bg'] : '#ffffff'};
          border: ${({ $style }) =>
            $style ? `1px solid ${hoverColors[$style].bdr}` : `1px solid #595858`};
          color: ${({ $style, $reverseColor }) =>
            $style ? hoverColors[$style][$reverseColor ? 'bg' : 'color'] : '#595858'};
          cursor: pointer;
          & svg {
            fill: ${({ $style }) => ($style ? hoverColors[$style].color : '#595858')};
          }
        }
      `;
    }
  }}
`;
export const Main = styled.button`
  ${baseButtonStyle};
  transition: all ease-in 0.2s;
  min-width: ${({ $primary }) => ($primary ? '170px' : 'auto')};
  height: ${({ $primary, $mid }) => ($primary ? '50px' : $mid ? ' 36px' : '30px')};
  padding: ${({ $primary, $mid }) => ($primary ? '0 20px' : $mid ? '16px 28px' : '0 16px')};
  gap: ${({ $group }) => ($group ? '16px' : '0px')};
  font-size: ${({ $primary, $mid }) => ($primary ? '20px' : $mid ? '16px' : '14px')};
  font-weight: ${({ $primary, $mid }) => ($primary ? '500' : $mid ? '600' : '500')};
  letter-spacing: 0.6px;
  text-transform: capitalize;
`;

export const Action = styled.button`
  ${baseButtonStyle};
  display: flex;
  width: ${({ $width }) => ($width ? $width + 'px' : '26px')};
  height: ${({ $height }) => ($height ? $height + 'px' : '26px')};
`;

export const ActionLabeled = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-family: 'BDO', sans-serif;
  text-transform: capitalize;
  color: #145ff5;
  cursor: pointer;
  & p {
    white-space: nowrap;
  }
`;
