import React, { useEffect, useState } from 'react';
import Box from '../../components/UI/General/Box';
import generalStyles from '../../styles/general.module.css';
import BackButton from '../../components/shared/BackButton';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Label from '../../components/UI/Forms/Label';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { regExps } from '../../utils/regExps';
import Input from '../../components/UI/Forms/Input';
import spmsServiceService from '../../services/spmsService.service';
import { unitOptions } from '../../constants/unitOptions';
import s2pmsVendorPortal from '../../services/vendorPortal.service';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';

const types = [{
  label: 'Product',
  value: 'PRODUCT',
},{
  label: 'Service',
  value: 'SERVICE',
}]

const VendorCatalogAdd = () => {
  const navigate = useNavigate();
  const { catalogItemId } = useParams();
  const user = useStore((state) => state.user);
  const [values, setValues] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const catalogItemDefault = {
    type: "",
    title: "",
    description: "",
    refCode: "",
    category: "",
    subcategory: "",
    unitOfMeasure: "",
    unitPrice: "",
    sku: ""
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: catalogItemDefault,
    values,
  });

  const categoryWatcher = useWatch({ control, name: 'category' });

  useEffect(() => {
    spmsServiceService.getVendorCategories().then((r) => {
      if (r.data.message === 'Operation Successful') {
        const categories = r.data.data;
        setVendorCategories(categories);
      }
    });
  }, []);

  useEffect(() => {
    const fetchItem = async ()=> {
      await s2pmsVendorPortal.getProduct(catalogItemId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          const { category, subCategory, unitOfMeasure, type, ...rest } = r.data.data
          const newValues = {
            ...rest,
            category: {
              label: vendorCategories?.find(el => el.category === category)?.category,
              value: vendorCategories?.find(el => el.category === category)?.category
            },
            subcategory: {
              label: vendorCategories?.find(el => el.subCategory === subCategory)?.subCategory,
              value: vendorCategories?.find(el => el.subCategory === subCategory)?.subCategory,
            },
            unitOfMeasure: unitOptions.find(el => el.value === unitOfMeasure),
            type: types.find(el => el.value === type)
          }
          setValues(newValues)
        }
      });
    }
    if (catalogItemId && !!vendorCategories && !!unitOptions){
      fetchItem()
    }
  },[catalogItemId, vendorCategories, unitOptions])

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const newData = {
      vendorId: user?.externalId,
      type: data.type.value,
      title: data.title,
      description: data.description,
      refCode: data.refCode,
      category: data.category.value,
      subCategory: data.subcategory.value,
      unitOfMeasure: data.unitOfMeasure.value,
      unitPrice: data.unitPrice,
      sku: data.sku
    }
    console.log(newData);
    if (!!catalogItemId){
      s2pmsVendorPortal.updateProduct(catalogItemId, newData).then(r => {
        console.log(r);
        setToast({
          opened: true,
          message: 'Catalog item updated successfully',
          type: 'success',
          cb: () => navigate('/vendor-catalog'),
        });
      }).catch((reason) => {
        console.log(reason);
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
          type: 'fail',
        });
      });
    }else {
      s2pmsVendorPortal.createProduct(newData).then(r => {
        console.log(r);
        setToast({
          opened: true,
          message: 'Catalog item created successfully',
          type: 'success',
          cb: () => navigate('/vendor-catalog'),
        });
      }).catch((reason) => {
        console.log(reason);
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
          type: 'fail',
        });
      });
    }
  }

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top + ' ' + generalStyles.underline}>
          <div><BackButton /></div>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>{catalogItemId ? `Edit ${values?.title} (${values?.refCode})` : 'Add New Catalog Item'}</Text>
            </div>
            <div className={generalStyles.addItemButton}>
              <Button.Main
                $mid
                $style="blue"
                type="submit"
                onClick={handleSubmit((data) => onSubmit(data))}
                disabled={!isDirty || !isValid || isButtonsBlocked}
              >
                Save
              </Button.Main>
              <Button.Main $mid $style="gray" type="button" onClick={()=> navigate('/vendor-catalog')}>
                Discard
              </Button.Main>
            </div>
          </div>
        </div>
        <br/>
        <form id="productForm">
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Label $isRequired $title="Type" />
              <Controller
                name="type"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Type is required',
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Type"
                    menuPortalTarget={document.body}
                    menuPosition={'absolute'}
                    menuPlacement={'bottom'}
                    menuShouldScrollIntoView={false}
                    options={types}
                  />
                )}
              />
              {errors.type && <p className="error-message">{errors.type?.value.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Title is required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    onlyAllowed: (v) => regExps.poRequisitionsTitle.test(v) || 'Alphabetic, numeric, &().- characters and space only',
                    onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Title of product / service"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Title"
                    $labelRequired
                    $tooltip="Title of product / service"
                    {...field}
                  />
                )}
              />
              {errors.title && <p className="error-message">{errors.title?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="description"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Description is required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    onlyAllowed: (v) => regExps.poRequisitionsTitle.test(v) || 'Alphabetic, numeric, &().- characters and space only',
                    onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter short description of the product / service"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Description"
                    $labelRequired
                    $tooltip="Short description of the product / service"
                    {...field}
                  />
                )}
              />
              {errors.description && <p className="error-message">{errors.description?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="refCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Reference Code is required',
                  }
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Reference Code"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Reference Code"
                    $labelRequired
                    $tooltip="Catalog reference code/ID"
                    {...field}
                  />
                )}
              />
              {errors.refCode && <p className="error-message">{errors.refCode?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $isRequired $title="Category" $tooltipText="product primary category" />
              <Controller
                name="category"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Category is required',
                  }
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Category"
                    menuPortalTarget={document.body}
                    menuPosition={'absolute'}
                    menuPlacement={'bottom'}
                    menuShouldScrollIntoView={false}
                    options={Array.from(new Set(vendorCategories.map((cat) => cat.category))).map(
                      (category) => ({
                        label: category,
                        value: category,
                      }),
                    )}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('subcategory', null);
                    }}
                  />
                )}
              />
              {errors.category && <p className="error-message">{errors.category?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $isRequired $title="Sub Category" $tooltipText="product sub category linked to category" />
              <Controller
                name="subcategory"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Subcategory is required',
                  }
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Sub Category"
                    menuPortalTarget={document.body}
                    menuPosition={'absolute'}
                    menuPlacement={'bottom'}
                    menuShouldScrollIntoView={false}
                    options={vendorCategories
                      .filter((category) => category.category === categoryWatcher?.value)
                      .map((subcat) => ({
                        label: subcat.subCategory,
                        value: subcat.subCategory,
                      }))}
                  />
                )}
              />
              {errors.subcategory && <p className="error-message">{errors.subcategory?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $isRequired $title="Unit of Measure" $tooltipText="select list of unit of measure" />
              <Controller
                name="unitOfMeasure"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Unit of Measure is required',
                  }
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select unit of measure"
                    menuPortalTarget={document.body}
                    menuPosition={'absolute'}
                    menuPlacement={'bottom'}
                    menuShouldScrollIntoView={false}
                    options={unitOptions}
                  />
                )}
              />
              {errors.unitOfMeasure && <p className="error-message">{errors.unitOfMeasure?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="unitPrice"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Unit Price is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter price per unit (ex vat)"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Unit Price"
                    $labelRequired
                    $tooltip="price per unit (ex vat)"
                    {...field}
                  />
                )}
              />
              {errors.unitPrice && <p className="error-message">{errors.unitPrice?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="sku"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter price per unit (ex vat)"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="SKU"
                    {...field}
                  />
                )}
              />
              {errors.sku && <p className="error-message">{errors.sku?.message}</p>}
            </div>
          </div>
        </form>
      </Box>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default VendorCatalogAdd;
