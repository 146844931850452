import React, {useEffect, useState} from 'react';
import s2pmsService from "../../services/spmsService.service";
import {useStore} from "../../store/store";
import style from "../../styles/insights.module.css"
import {useAccessAllowed} from "../../hooks/useAccessAllowed";
import ChartOverall from "../../components/Admins/Insights/ChartOverall";
import Text from '../../components/UI/Typography/Text';
import ChartVendors from '../../components/Admins/Insights/ChartVendors';

const periods = [
  {label: "last 3 months", value: 3},
  {label: "last 6 months", value: 6},
  {label: "last 12 months", value: 12},
]

const Insights = () => {
  const activeCompany = useStore((state) => state.activeCompany);
  const accessReports = useAccessAllowed('Insights')
  const [departments, setDepartments] = useState([])

  useEffect(() => {
    s2pmsService.getDepartments(activeCompany.id).then(r => {
      if (r.data.message === "Operation Successful"){
        console.log(r.data.data)
        const temp = r.data.data.map(el => ({label: el.name, value: el.id}))
        temp.splice(0, 0, {label: "All", value: 0});
        setDepartments(temp)
      }
    }).catch(err => {
      console.log(err)
    })
  },[activeCompany])

  return (
    <div>
      <div className={style.title}>
        <Text type="subtitle" weight={500}>Overview</Text>
        <Text type="body-1" className={style.colorSecondary}>Spend Summary</Text>
      </div>
      {accessReports?.viewAllCompany || accessReports?.viewOwn ? (
        <div className={style.chartsGrid}>
          <ChartOverall departments={departments} periods={periods} companyId={activeCompany?.id} currency={activeCompany?.defaultCurrency}/>
          {/*<ChartsGl departments={departments} periods={periods} companyId={activeCompany?.id} currency={activeCompany?.defaultCurrency}/>*/}
          <ChartVendors departments={departments} periods={periods} companyId={activeCompany?.id} currency={activeCompany?.defaultCurrency}/>
          {/*<ChartsDepartment periods={periods} companyId={activeCompany?.id} currency={activeCompany?.defaultCurrency}/>*/}
        </div>
      ) : (
        <div>No permissions to view</div>
      )}
    </div>
  );
}

export default Insights
