import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
  background-color: #145ff53d;
  border-radius: 25px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div`
  width: ${(props) => props.progress}%;
  height: 12px;
  background-color: #145ff5;
  border-radius: 25px;
`;
