import { useState } from 'react';
import Text from '../../components/UI/Typography/Text';
import generalStyles from '../../styles/general.module.css';
import ProfileAccount from '../../components/Admins/Profile/ProfileAccount';
import ProfileLegalEntity from '../../components/Admins/Profile/ProfileLegalEntity';
import ProfilePassword from '../../components/Admins/Profile/ProfilePassword';
import ProfileHoliday from '../../components/Admins/Profile/ProfileHoliday';
import TabsSlider from '../../components/UI/General/TabsSlider';
import Box from '../../components/UI/General/Box';
import BackButton from '../../components/shared/BackButton';
import * as Button from '../../components/UI/Forms/Button';
import { useForm } from 'react-hook-form';
import requestsService from '../../services/requestsService.service';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import { Auth } from 'aws-amplify';

const Profile = () => {
  const user = useStore((state) => state.user);
  const { updateUser } = useStore((state) => state);
  const [tab, setTab] = useState('account');
  const [files, setFiles] = useState(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmitHandlers = {
    account: (data) => {
      setToast((item) => ({ ...item, opened: false }));
      console.log(data);
      const { firstName, lastName, phoneNumber, position } = data;
      const newData = {
        firstName,
        lastName,
        phoneNumber,
        position,
        profileImage: files !== null ? files : '',
      };
      console.log('newData', newData);

      requestsService
        .updateProfile(user.id, newData)
        .then((r) => {
          if (r.data.message === 'Operation Successful') {
            updateUser(r.data.data);
            setToast({
              opened: true,
              message: 'Your account has been updated',
              type: 'success',
            });
          }
        })
        .catch((reason) => {
          setToast({
            opened: true,
            message: reason.response.data.errors[0].errorMessage,
            type: 'fail',
          });
        });
    },
    password: async (data) => {
      setToast((item) => ({ ...item, opened: false }));
      try {
        const user = await Auth.currentAuthenticatedUser();
        const res = await Auth.changePassword(user, data.currentPassword, data.newPassword);
        setToast({
          opened: true,
          message: 'Your password changed successfully',
          type: 'success',
        });
      } catch (err) {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      }
    },
  };

  return (
    <Box $mobExtend $asHolder>
      <div className={generalStyles.top}>
        <div>
          <BackButton to="/dashboard" />
        </div>
        <div className={generalStyles.title}>
          <div className={generalStyles.titleText}>
            <Text type="subtitle" weight={500}>
              My Profile
            </Text>
          </div>
          <div className={generalStyles.addItemButton}>
            <Button.Main
              $mid
              $style="blue"
              type="button"
              onClick={handleSubmit(onSubmitHandlers[tab])}
            >
              Save
            </Button.Main>
          </div>
        </div>
      </div>
      <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
        <TabsSlider>
          <span
            onClick={() => {
              setTab('account');
              reset();
            }}
          >
            Account
          </span>
          <span
            onClick={() => {
              setTab('password');
              reset();
            }}
          >
            Change Password
          </span>
        </TabsSlider>
      </div>

      {tab === 'account' && (
        <ProfileAccount control={control} errors={errors} reset={reset} setFiles={setFiles} />
      )}

      {tab === 'password' && (
        <ProfilePassword
          control={control}
          errors={errors}
          reset={reset}
          dirtyFields={dirtyFields}
          getValues={getValues}
        />
      )}
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default Profile;
