import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../../../styles/requisition.module.css';
import DataTableBase from '../../../UI/General/DataTableBase';
import { Link, useParams } from 'react-router-dom';
import Text from '../../../UI/Typography/Text';
import spmsServiceService from '../../../../services/spmsService.service';
import moment from 'moment';
import { downloadExternal } from '../../../../utils/downloadExternal';
import generalStyles from '../../../../styles/general.module.css';
import Status from '../../../UI/Typography/Status';
import { useStore } from '../../../../store/store';
import Icon from '../../../UI/General/Icon';
import * as Button from '../../../UI/Forms/Button';
import { Controller, useForm } from 'react-hook-form';
import SelectUsers from '../../../shared/SelectUsers';
import Label from '../../../UI/Forms/Label';
import Documents from '../../../UI/Forms/Documents';
import Toast from '../../../UI/General/Toast';
import Input from '../../../UI/Forms/Input';
import DatePicker from 'react-datepicker';
import TabsSlider from '../../../UI/General/TabsSlider';
import QuotesTab from './QuotesTab';

const ReceivingItem = ({ item, index, control, errors, clearRow, disableClear }) => {
  const [showNote, setShowNote] = useState(false);

  return (
    <>
      <div className={styles.itemsReceiving}>
        <Text type="body-2" weight={400}>
          {item.description}
        </Text>
        <Text type="body-2" weight={400}>
          {item.uom}
        </Text>
        <div className="inp-container">
          <Controller
            name={`poItems.${index}.passedQuantity`}
            control={control}
            defaultValue={''}
            rules={{
              required: {
                value: item.receivedQuantity < item.quantity,
                message: 'Required',
              },
              maxLength: {
                value: 10,
                message: 'Maximum 10 characters',
              },
              validate: {
                onlyAllowed: (v) => /^[0-9]*$/.test(v) || 'Numeric characters only',
                onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                $small
                disabled={item.receivedQuantity >= item.quantity}
                placeholder="Enter Passed Items"
                className={errors.hasOwnProperty(field.name) && 'error'}
              />
            )}
          />
          {errors?.poItems?.[index]?.passedQuantity && (
            <p className="error-message">{errors?.poItems?.[index]?.passedQuantity?.message}</p>
          )}
        </div>
        <div className="inp-container">
          <Controller
            name={`poItems.${index}.failedQuantity`}
            control={control}
            defaultValue={''}
            rules={{
              required: {
                value: item.receivedQuantity < item.quantity,
                message: 'Required',
              },
              maxLength: {
                value: 10,
                message: 'Maximum 10 characters',
              },
              validate: {
                onlyAllowed: (v) => /^[0-9]*$/.test(v) || 'Numeric characters only',
                onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                $small
                disabled={item.receivedQuantity >= item.quantity}
                placeholder="Enter Failed Items"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors?.poItems?.[index]?.failedQuantity && (
            <p className="error-message">{errors?.poItems?.[index]?.failedQuantity?.message}</p>
          )}
        </div>
        <Text type="body-2" weight={400}>
          {`${item.receivedQuantity}/${item.quantity}`}
        </Text>
        <div className={styles.itemActions}>
          <Button.Action
            $variant="circle"
            $style="lightGrayishBlue"
            $width={32}
            $height={32}
            onClick={() => setShowNote(!showNote)}
            type="button"
          >
            <Icon $width={20} $height={20} $icon="add-note" $color="#145FF5" />
          </Button.Action>
          <Button.Action
            $variant="circle"
            $style="lightGrayishBlue"
            $width={32}
            $height={32}
            type="button"
            disabled={disableClear}
            onClick={() => clearRow(index)}
          >
            <Icon $width={20} $height={20} $icon="delete" $color="#145FF5" />
          </Button.Action>
        </div>
      </div>
      {showNote ? (
        <div className={styles.itemNote}>
          <Text weight={500} type={'body-1'} className={styles.itemNoteLabel}>
            Notes
          </Text>
          <Text weight={500} type={'body-2'}>
            {item?.itemNotes === '' ? 'There is no notes to display' : item?.itemNotes}
          </Text>
        </div>
      ) : null}
    </>
  );
};

const ReceivingTab = ({ values, ...rest }) => {
  const { orderId } = useParams();
  const user = useStore((state) => state.user);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isButtonBlocked, setIsButtonsBlocked] = useState(false);
  const [receivedId, setReceivedId] = useState(null);
  const [createMod, setCreateMod] = useState(false);
  const [tab, setTab] = useState('items');
  const [receivedItemDocs, setReceivedItemsDocs] = useState([]);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (orderId !== null) {
      spmsServiceService.getReceiveItemsPurchaseOrder(orderId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log('r.data.data', r.data.data);

          setTableData(r.data.data);
        }
      });
    }
  }, [triggerRefetch]);

  // useEffect(() => {
  //   if (!values) return;
  //   console.log(' values.purchaseOrderItems', values.purchaseOrderItems);
  //   setValue(
  //     'poItems',
  //     values.purchaseOrderItems.map((item) => ({ purchaseOrderItemId: item.id })),
  //   );
  // }, [triggerRefetch, values]);

  const setEditItem = (receivedItem) => {
    const itemValues = {
      receivedBy: {
        label: receivedItem.receivedBy.name,
        value: receivedItem.receivedBy.name,
        userId: receivedItem.receivedBy.userId,
      },
      receivedDate: new Date(receivedItem.receivedDate),
      poItems: values.purchaseOrderItems.map((item) => {
        const reqItem = receivedItem.receivedItems.find(
          (reqItem) => reqItem.purchaseOrderItem.id === item.id,
        );
        return {
          id: reqItem.id,
          purchaseOrderItemId: reqItem.purchaseOrderItem.id,
          passedQuantity: reqItem.passedQuantity,
          failedQuantity: reqItem.failedQuantity,
        };
      }),
    };
    setReceivedItemsDocs(receivedItem?.attachments);
    setCreateMod(true);
    setReceivedId(receivedItem.id);
    reset(itemValues);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Received Date',
        sortable: true,
        selector: (row) => row.receivedDate,
        format: (row) => moment(row.receivedDate).format('DD/MM/YYYY'),
        cell: (row) => (
          <span data-content={'Received Date'}>
            {moment(row.receivedDate).format('DD/MM/YYYY') ?? '-'}
          </span>
        ),
      },
      {
        name: 'Action By',
        sortable: true,
        selector: (row) => row?.createdBy?.name,
        cell: (row) => (
          <span
            data-tag="allowRowEvents"
            data-content={'Action By'}
            className={generalStyles.tableValue}
          >
            <span data-tag="allowRowEvents">{row?.createdBy?.name ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Status',
        sortable: true,
        selector: (row) => row?.fullfillmentStatus,
        cell: (row) => (
          <>
            <span
              data-tag="allowRowEvents"
              data-content={'Status'}
              className={generalStyles.tableValue}
            >
              <Text data-tag="allowRowEvents" weight={500} type={'body-2'}>
                <Status value={row?.fullfillmentStatus} pill />
              </Text>
            </span>
            <div>
              {row?.createdBy?.userId === user.id && !rest.viewOnly ? (
                <div style={{ display: 'flex', gap: 8 }}>
                  <Button.Action
                    $variant="circle"
                    $style="lightGrayishBlue"
                    $width={32}
                    $height={32}
                    onClick={() => {
                      setEditItem(row);
                    }}
                    type="button"
                  >
                    <Icon $width={20} $height={20} $icon="edit" $color="#4B5865" />
                  </Button.Action>
                  <Button.Action
                    $variant="circle"
                    $style="lightGrayishBlue"
                    $width={32}
                    $height={32}
                    onClick={() => deleteItem(row?.id)}
                    type="button"
                  >
                    <Icon $width={20} $height={20} $icon="delete" $color="#4B5865" />
                  </Button.Action>
                </div>
              ) : null}
            </div>
          </>
        ),
      },
    ],
    [],
  );

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    let newReceivingAttachments = [];
    if (data.attachments !== undefined && data.attachments.length > 0) {
      const files = data.attachments.map(async (item) => ({
        name: item.name.split(' ').join('-'),
        document: await getBase64(item.attachment),
      }));
      newReceivingAttachments = await Promise.all(files).then((result) => result);
    }
    const requestBody = {
      receivedDate: new Date(data.receivedDate).toISOString(),
      receivedBy: {
        userId: data.receivedBy.userId,
      },
      notes: '',
      items: data.poItems
        .map((item) => ({
          id: item.id,
          purchaseOrderItemId: item.purchaseOrderItemId,
          passedQuantity: parseInt(item.passedQuantity),
          failedQuantity: parseInt(item.failedQuantity),
        }))
        .filter((poItem) => poItem.passedQuantity !== ''),
      attachments: newReceivingAttachments,
    };
    if (receivedId) {
      spmsServiceService
        .updateReceiveItemsPurchaseOrder(orderId, receivedId, requestBody)
        .then((r) => {
          setToast({
            opened: true,
            message: 'Received Items has been updated successfully',
            type: 'success',
            cb: () => {
              setIsButtonsBlocked(false);
            },
          });
          reset({}, { keepValues: false });
          setTab('items');
          setTriggerRefetch((state) => !state);
          setCreateMod(false);
          rest.$trigger((state) => state + 1);
        })
        .catch((reason) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.errors[0].errorMessage,
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createReceiveItemsPurchaseOrder(orderId, requestBody)
        .then((r) => {
          setTab('items');
          setToast({
            opened: true,
            message: 'Received Items has been submitted successfully',
            type: 'success',
            cb: () => setIsButtonsBlocked(false),
          });
          reset({}, { keepValues: false });
          setTriggerRefetch((state) => !state);
          setCreateMod(false);
          rest.$trigger((state) => state + 1);
        })
        .catch((reason) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.errors[0].errorMessage,
            type: 'fail',
          });
        });
    }
  };
  const files = (files) =>
    files.map((file) => ({
      attachment: file,
      name: file.name,
    }));

  const receiveAll = () => {
    const currentItems = getValues('poItems');
    setValue(
      'poItems',
      currentItems.map((item) => {
        const reqItem = values.purchaseOrderItems.find(
          (reqItem) => reqItem.id === item.purchaseOrderItemId,
        );
        const pendingQuantity = reqItem.quantity - reqItem.receivedQuantity;
        return reqItem.receivedQuantity >= reqItem.quantity
          ? { ...item }
          : { ...item, passedQuantity: pendingQuantity, failedQuantity: 0 };
      }),
    );
  };
  const deleteItem = (itemId) => {
    rest.setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .deleteReceiveItemPurchaseOrder(orderId, itemId)
      .then(() => {
        rest.setToast({
          opened: true,
          message: 'Received Item has been deleted successfully',
          type: 'success',
          cb: () => {
            setTriggerRefetch((triggerRefetch) => !triggerRefetch);
            rest.$trigger((state) => state + 1);
          },
        });
      })
      .catch((err) => {
        rest.setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const clearRow = (index) => {
    setValue(`poItems.${index}.passedQuantity`, '');
    setValue(`poItems.${index}.failedQuantity`, '');
  };

  const discardCreate = () => {
    setCreateMod(false);
    reset({}, { keepValues: false });
    if (receivedId) setReceivedId(null);
    setReceivedItemsDocs([]);
    setTab('items');
  };

  return (
    <div>
      <div className={generalStyles.top}>
        {!createMod && values?.fulfillmentStatus !== 'FULFILLED' && (
          <div className={styles.createButton}>
            <Button.Main
              $mid
              $style="blue"
              type="submit"
              onClick={() => {
                setValue(
                  'poItems',
                  values.purchaseOrderItems.map((item) => ({ purchaseOrderItemId: item.id })),
                );
                setCreateMod(true);
              }}
              disabled={false}
            >
              Add New
            </Button.Main>
          </div>
        )}
        <>
          {values && createMod && (
            <div className={generalStyles.top}>
              <div className={`${generalStyles.title} `}>
                <div className={generalStyles.titleText}>
                  <Text type="subtitle" weight={500}>
                    {receivedId ? 'Edit' : 'Create'} Receipt
                  </Text>
                </div>
                <div className={generalStyles.addItemButton}>
                  {values?.fulfillmentStatus !== 'FULFILLED' && (
                    <Button.Main
                      $mid
                      $style="blue"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      disabled={isButtonBlocked}
                    >
                      Save
                    </Button.Main>
                  )}
                  <Button.Main
                    disabled={isButtonBlocked}
                    $mid
                    $style="blue"
                    type="submit"
                    onClick={discardCreate}
                  >
                    Discard
                  </Button.Main>
                </div>
              </div>
              <div className={generalStyles.fieldsThree}>
                <>
                  <div>
                    <div className="inp-container">
                      <Label $title="Received Date" />
                      <Controller
                        name="receivedDate"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Received Date is required',
                          },
                          maxLength: {
                            value: 10,
                            message: 'Maximum 10 characters',
                          },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                          <DatePicker
                            {...rest}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            placeholderText="Select Date"
                            selected={rest.value}
                            wrapperClassName="custom-datepicker"
                            disabled={values.fulfillmentStatus === 'FULFILLED'}
                            customInput={
                              <Input
                                {...rest}
                                className={errors.hasOwnProperty(rest.name) && 'error'}
                              />
                            }
                            onChange={(e) => {
                              rest.onChange(e);
                              trigger(rest.name);
                            }}
                          />
                        )}
                      />
                      {errors.receivedDate && (
                        <p className="error-message">{errors.receivedDate?.message}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="inp-container">
                      <Label $title="Receiver" />
                      <Controller
                        name="receivedBy"
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: {
                            value: true,
                            message: 'Receiver is required',
                          },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                          <SelectUsers
                            {...rest}
                            selectRef={ref}
                            isDisabled={values.fulfillmentStatus === 'FULFILLED'}
                            className={
                              errors.hasOwnProperty(rest.name)
                                ? 'react-select-container error'
                                : 'react-select-container'
                            }
                          />
                        )}
                      />
                      {errors.receivedBy && (
                        <p className="error-message">{errors.receivedBy?.message}</p>
                      )}
                    </div>
                  </div>
                </>
              </div>

              <div className={` ${generalStyles.underline}`}>
                <TabsSlider selected={tab}>
                  <span onClick={() => setTab('items')}>Items</span>
                  <span onClick={() => setTab('documents')}>Documents</span>
                </TabsSlider>
              </div>
              {values?.fulfillmentStatus !== 'FULFILLED' && tab === 'documents' && (
                <div>
                  <QuotesTab data={receivedItemDocs} />
                  <br />
                  <Label $title="Upload Good received note" $isRequired />
                  <Documents
                    control={control}
                    fieldName="attachments"
                    filesSchema={files}
                    error={errors?.attachments?.message || errors?.attachments?.root?.message}
                    clearErrors={clearErrors}
                  />
                </div>
              )}
              {tab === 'items' && (
                <div>
                  {values?.fulfillmentStatus !== 'FULFILLED' && (
                    <div className={styles.itemsAdd}>
                      <Button.Main $style="blue" $reverseColor type="button" onClick={receiveAll}>
                        <Text weight={400}>Receive All</Text>
                      </Button.Main>
                    </div>
                  )}
                  <div className={styles.itemsContainer}>
                    <div className={styles.itemsReceiving}>
                      <Text type="body-2" weight={500}>
                        Item Name
                      </Text>
                      <Text type="body-2" weight={500}>
                        Unit Of Measure
                      </Text>
                      <Text type="body-2" weight={500}>
                        Pass
                      </Text>
                      <Text type="body-2" weight={500}>
                        Failed
                      </Text>
                      <Text type="body-2" weight={500}>
                        Received
                      </Text>
                    </div>
                    <>
                      {values.purchaseOrderItems.map((item, index) => (
                        <ReceivingItem
                          key={item.id}
                          item={item}
                          index={index}
                          control={control}
                          errors={errors}
                          clearRow={clearRow}
                          disableClear={values?.fulfillmentStatus === 'FULFILLED'}
                        />
                      ))}
                    </>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
        <div className={styles.receivingTable}>
          {!createMod && <DataTableBase data={tableData} columns={columns} pagination={false} />}
        </div>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </div>
  );
};

export default ReceivingTab;
