import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';

const SelectLocations = (props) => {
  const activeCompany = useStore((state) => state.activeCompany);

  const fetchLocations = async (search, loadedOptions, { page }) => {
    const result = await spmsServiceService
      .getLocations(activeCompany.id, page, 10, {
        name: search,
        active: true,
      })
      .catch((err) => {
        console.log(err);
        return undefined;
      });
    const finalData = result?.data?.data?.content?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    const hasMore =
      finalData !== undefined && result.data.data.totalElements > 0
        ? loadedOptions.length < result.data.data.totalElements
        : false;
    return {
      options: finalData || [],
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      {...props}
      debounceTimeout={500}
      loadOptions={fetchLocations}
      classNamePrefix="react-select"
      isSearchable={true}
      placeholder="Select Location"
      menuPortalTarget={document.body}
      menuPosition={'absolute'}
      menuPlacement={'bottom'}
      menuShouldScrollIntoView={false}
      additional={{
        page: 0,
      }}
    />
  );
};

export default SelectLocations;
