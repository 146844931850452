import React from 'react';
import * as Button from '../UI/Forms/Button';
import Icon from '../UI/General/Icon';
import Text from '../UI/Typography/Text';

const AddButton = (props) => {
  return (
    <Button.Main $style="blue" type="button" onClick={props.$click}>
      <Icon $width={20} $height={20} $icon="add" $color="white" />
      <Text weight={400}>{props.$text ? props.$text : 'Add'}</Text>
    </Button.Main>
  );
};

export default AddButton;
