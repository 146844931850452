import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import generalStyles from '../../styles/general.module.css';
import BackButton from '../../components/shared/BackButton';
import Text from '../../components/UI/Typography/Text';
import TabsSlider from '../../components/UI/General/TabsSlider';
import Box from '../../components/UI/General/Box';
import * as Button from '../../components/UI/Forms/Button';
import { useStore } from '../../store/store';
import integrationsService from '../../services/integrations.service';
import IntegrationEdit from '../../components/Admins/Integrations/IntegrationEdit';
import IntegrationView from '../../components/Admins/Integrations/IntegrationView';

const integrationOptions = [
  {label:'Option 1: with sage 300c PO module',value:'INTEGRATION_WITH_PO'},
  {label:'Option 2: without sage 300c PO module',value:'INTEGRATION_WITHOUT_PO'}
]

const Integration = () => {
  const { integrationName } = useParams();
  const [tab, setTab] =useState('integration setup')
  const [edit, setEdit] = useState(false)

  const activeCompany = useStore((state) => state.activeCompany);
  const [values, setValues] = useState(null);

  useEffect(() => {
    if (!!integrationName && !!activeCompany?.id){
      integrationsService.getCompanyIntegrations(activeCompany?.id).then(r => {
        if (r.data.message === "Operation Successful"){
          const integration = r.data.data.find(item => item.type.toLowerCase() === integrationName.toLowerCase())
          if (!!integration){
            const newValues = {
              integrationCompany: {label: integration.properties.find(el => el.key === "ORG_ID")?.value, value: integration.properties.find(el => el.key === "ORG_ID")?.value},
              integrationOption: integration.properties.find(el => el.key === "INTEGRATION_WITH_PO")?.value === true ? integrationOptions[0] : integrationOptions[1],
              vendorUrl: integration.properties.find(el => el.key === "VENDOR_BASE_URL")?.value,
              poUrl: integration.properties.find(el => el.key === "PO_BASE_URL")?.value,
              glUrl: integration.properties.find(el => el.key === "GL_BASE_URL")?.value,
              apUrl: integration.properties.find(el => el.key === "AP_BASE_URL")?.value,
              username: integration.properties.find(el => el.key === "LOGIN_ID")?.value,
              password: integration.properties.find(el => el.key === "PASSWORD")?.value,
              dynamicSql: integration.properties.find(el => el.key === "DYNAMIC_SQL_URL")?.value,
            }
            console.log(newValues);
            setValues(newValues)
          }
        }
      });
    }
  },[activeCompany, integrationName])

  return (
    <Box $mobExtend $asHolder>
      <div className={generalStyles.top}>
        <div><BackButton /></div>
        <div className={generalStyles.title}>
          <Text type="subtitle" weight={500}>Sage 300c ERP Integration</Text>
          <div className={generalStyles.addItemButton}>
            {!!edit ? (
              <>
                <Button.Main
                  $mid
                  $style="gray"
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Discard
                </Button.Main>
              </>
            ) : (
              <Button.Main
                $mid
                $style="blue"
                type="button"
                onClick={() => setEdit(true)}
              >
                {!!values ? 'Edit' : 'Create Integration'}
              </Button.Main>
            )}
          </div>
        </div>
      </div>
      <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
        <TabsSlider selected={tab}>
          <span onClick={() => setTab('integration setup')}>Integration Setup</span>
          {!!values && (<span onClick={() => setTab('logs')}>Logs</span>)}
        </TabsSlider>
      </div>

      {(tab === 'integration setup' && !!edit) && <IntegrationEdit integrationName={integrationName} values={values}/>}
      {(tab === 'integration setup' && !edit) && <IntegrationView values={values}/>}
      {(tab === 'logs' && !!values) && <div>Logs will be here</div>}
    </Box>
  );
};

export default Integration;
