import styled from 'styled-components';

export const Menu = styled.div`
  padding: 6px 0;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background: #fff;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);
`;
export const MenuItem = styled.div`
  padding: 6px 12px;
  min-width: 130px;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
  &:hover {
    background-color: #f3f7ff;
    cursor: pointer;
  }
`;
