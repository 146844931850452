import { axiosInstance, baseUrl, s2pmsBaseUrl } from './API';

class SpmsService {
  getCompanies(size, page) {
    return axiosInstance.get(s2pmsBaseUrl + '/company?page=' + page + '&size=' + size);
  }
  getCompany(id) {
    return axiosInstance.get(baseUrl + '/company/' + id);
  }
  activateCompany(companyId) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/activate');
  }
  archiveCompany(companyId) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/archive');
  }
  getCompanyBranches(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + id + '/branches');
  }
  getCompanyContacts(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/get-contacts');
  }
  updateCompanyContacts(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/update-contacts', data);
  }
  activateBranch(companyId, branchId) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/activate-branch/' + branchId,
    );
  }
  createDummyCompany(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/demo/create-demo-company', data);
  }

  archiveBranch(companyId, branchId) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/archive-branch/' + branchId,
    );
  }
  getLocations(companyId, page = 0, size = 30) {
    return axiosInstance.get(
      baseUrl + '/location/' + companyId + '?&page=' + page + '&size=' + size,
    );
  }
  getLocation(companyId, locationId) {
    return axiosInstance.get(baseUrl + '/location/' + companyId + '/' + locationId);
  }
  searchLocations(companyId, page = 0, size = 30, data) {
    return axiosInstance.post(
      baseUrl + '/location/' + companyId + '/filter?&page=' + page + '&size=' + size,
      data,
    );
  }

  createLocation(companyId, data) {
    return axiosInstance.post(baseUrl + '/location/' + companyId + '/create', data);
  }
  activateLocation(companyId, locationId) {
    return axiosInstance.post(baseUrl + '/location/' + companyId + '/' + locationId + '/activate');
  }
  archiveLocation(companyId, locationId) {
    return axiosInstance.post(baseUrl + '/location/' + companyId + '/' + locationId + '/archive');
  }
  updateLocation(companyId, data) {
    return axiosInstance.patch(baseUrl + '/location/' + companyId + '/update', data);
  }
  getTaxList(companyId) {
    return axiosInstance.get(baseUrl + '/company/' + companyId + '/tax');
  }
  createTax(companyId, data) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/tax/create', data);
  }
  updateTax(companyId, data) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/tax/update', data);
  }
  getTax(companyId, id) {
    return axiosInstance.get(baseUrl + '/company/' + companyId + '/tax/' + id);
  }
  archiveTax(companyId, id) {
    return axiosInstance.delete(baseUrl + '/company/' + companyId + '/tax/' + id + '/archive');
  }
  activateTax(companyId, id) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/tax/' + id + '/activate');
  }

  getRequisitions(size, page, searchBody) {
    return axiosInstance.post(baseUrl + '/requisitions?size=' + size + '&page=' + page, searchBody);
  }
  getRequisition(id) {
    return axiosInstance.get(baseUrl + '/requisitions/' + id);
  }
  createRequisition(data) {
    return axiosInstance.post(baseUrl + '/requisitions/create', data);
  }
  updateRequisition(id, data) {
    return axiosInstance.post(baseUrl + '/requisitions/' + id + '/update', data);
  }
  discardRequisition(id) {
    return axiosInstance.delete(baseUrl + '/requisitions/' + id + '/delete');
  }
  recallRequisition(id, reason) {
    return axiosInstance.post(baseUrl + '/requisitions/' + id + '/recall?reason=' + reason);
  }
  submitForApprovalRequisition(id) {
    return axiosInstance.post(baseUrl + '/requisitions/' + id + '/submitForApproval');
  }
  approveRequisition(id, data) {
    return axiosInstance.post(baseUrl + '/requisitions/' + id + '/approve', data);
  }
  addRequisitionNote(id, data) {
    return axiosInstance.post(baseUrl + '/requisitions/' + id + '/notes', data);
  }
  updateRequisitionNote(id, noteId, data) {
    return axiosInstance.post(
      baseUrl + '/requisitions/' + id + '/notes/' + noteId + '/update',
      data,
    );
  }
  deleteRequisitionNote(id, noteId) {
    return axiosInstance.delete(baseUrl + '/requisitions/' + id + '/notes/' + noteId + '/delete');
  }

  getPurchaseOrders(size, page, searchBody) {
    return axiosInstance.post(
      baseUrl + '/purchase-orders?size=' + size + '&page=' + page,
      searchBody,
    );
  }
  getPurchaseOrder(id, companyId) {
    return axiosInstance.get(
      baseUrl + '/purchase-orders/' + id,
      companyId && {
        headers: {
          'company-id': companyId,
        },
      },
    );
  }
  createPurchaseOrder(data) {
    return axiosInstance.post(baseUrl + '/purchase-orders/create', data);
  }
  updatePurchaseOrder(id, data) {
    return axiosInstance.post(baseUrl + '/purchase-orders/update/' + id, data);
  }
  deleteDocument(module, id, key) {
    return axiosInstance.delete(
      baseUrl + '/' + module + '/' + id + '/delete-document?document-key=' + key,
    );
  }
  submitForApprovalPurchaseOrder(id) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + id + '/submitForApproval');
  }
  approvePurchaseOrder(id, data) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + id + '/approve', data);
  }
  discardPurchaseOrder(id) {
    return axiosInstance.delete(baseUrl + '/purchase-orders/' + id + '/delete');
  }
  recallPurchaseOrder(id, reason) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + id + '/recall?reason=' + reason);
  }
  closePurchaseOrder(id) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + id + '/close');
  }
  getReceiveItemsPurchaseOrder(id) {
    return axiosInstance.get(baseUrl + '/purchase-orders/' + id + '/received-items');
  }

  getReceiveItemPurchaseOrder(poId, itemId) {
    return axiosInstance.get(baseUrl + '/purchase-orders/' + poId + '/received-items/' + itemId);
  }
  createReceiveItemsPurchaseOrder(id, data) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + id + '/receive-items', data);
  }
  updateReceiveItemsPurchaseOrder(id, itemId, data) {
    return axiosInstance.put(
      baseUrl + '/purchase-orders/' + id + '/received-items/' + itemId,
      data,
    );
  }
  deleteReceiveItemPurchaseOrder(orderId, itemId) {
    return axiosInstance.delete(
      baseUrl + '/purchase-orders/' + orderId + '/received-items/' + itemId,
    );
  }
  getAllPurchaseOrdersInvoices(page, size, searchBody) {
    return axiosInstance.post(
      baseUrl +
        '/purchase-orders/invoices?direction=DESC&orderBy=createdAt&page=' +
        page +
        '&size=' +
        size +
        '',
      searchBody,
    );
  }
  getPurchaseOrderInvoices(id) {
    return axiosInstance.get(baseUrl + '/purchase-orders/' + id + '/invoice');
  }
  getPurchaseOrderInvoice(poId, invoiceId) {
    return axiosInstance.get(baseUrl + '/purchase-orders/' + poId + '/invoice/' + invoiceId);
  }
  createPurchaseOrderInvoice(id, data) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + id + '/invoice', data);
  }
  approvePurchaseOrderInvoice(poId, id, body) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + poId + '/invoice/' + id, body);
  }
  updatePurchaseOrderInvoice(poId, invoiceId, data) {
    return axiosInstance.put(baseUrl + '/purchase-orders/' + poId + '/invoice/' + invoiceId, data);
  }
  deletePurchaseOrderInvoice(poId, invoiceId) {
    return axiosInstance.delete(baseUrl + '/purchase-orders/' + poId + '/invoice/' + invoiceId);
  }
  getPurchaseOrderPayments(id) {
    return axiosInstance.get(baseUrl + '/purchase-orders/' + id + '/payments');
  }
  getPurchaseOrderPayment(poId, paymentId) {
    return axiosInstance.get(baseUrl + '/purchase-orders/' + poId + '/payments/' + paymentId);
  }
  createPurchaseOrderPayment(id, data) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + id + '/payments', data);
  }
  updatePurchaseOrderPayment(id, paymentItemId, data) {
    return axiosInstance.put(
      baseUrl + '/purchase-orders/' + id + '/payments/' + paymentItemId,
      data,
    );
  }
  deletePurchaseOrderPayment(orderId, itemId) {
    return axiosInstance.delete(baseUrl + '/purchase-orders/' + orderId + '/payments/' + itemId);
  }
  addPONote(id, data) {
    return axiosInstance.post(baseUrl + '/purchase-orders/' + id + '/notes', data);
  }
  updatePONote(id, noteId, data) {
    return axiosInstance.post(
      baseUrl + '/purchase-orders/' + id + '/notes/' + noteId + '/update',
      data,
    );
  }
  deletePONote(id, noteId) {
    return axiosInstance.delete(
      baseUrl + '/purchase-orders/' + id + '/notes/' + noteId + '/delete',
    );
  }
  verifyPurchaseOrderBudget(data) {
    return axiosInstance.post(baseUrl + '/purchase-orders/verify-budget', data);
  }
  attachmentShow(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/attachments/show?id=' + id);
  }
  createCompany(data) {
    return axiosInstance.post(baseUrl + '/company/create', data);
  }
  updateCompany(data) {
    return axiosInstance.patch(baseUrl + '/company/update', data);
  }
  getBranches(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/branches');
  }
  searchBranches(companyId, page = 0, size = 30, data) {
    return axiosInstance.post(
      s2pmsBaseUrl +
        `/company/${companyId}/branches?direction=DESC&orderBy=createdAt&page=${page}&size=${size}`,
      data,
    );
  }
  createBranch(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/add-branch', data);
  }
  updateBranch(companyId, id, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/update-branch/' + id,
      data,
    );
  }
  getDepartments(companyId) {
    return axiosInstance.get(baseUrl + '/company/' + companyId + '/departments');
  }
  searchDepartments(companyId, page = 0, size = 30, data) {
    return axiosInstance.post(
      baseUrl +
        '/company/' +
        companyId +
        '/departments/search?direction=DESC&orderBy=createdAt&page=' +
        page +
        '&size=' +
        size +
        '',
      data,
    );
  }
  getDepartment(companyId, id) {
    return axiosInstance.get(baseUrl + '/company/' + companyId + '/departments/' + id);
  }
  createDepartment(companyId, data) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/departments', data);
  }
  updateDepartment(companyId, id, data) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/departments/' + id, data);
  }
  deleteDepartment(companyId, data) {
    return axiosInstance.delete(baseUrl + '/company/' + companyId + '/departments?ids=' + data);
  }
  activateDepartment(companyId, id) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/departments/activate?ids=' + id,
    );
  }
  getVendorsList(body) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/vendor/get-vendors?direction=DESC&orderBy=id&page=0&size=30',
      body,
    );
  }
  getVendors(companyId, body, page = 0, size = 30) {
    return axiosInstance.post(
      baseUrl +
        '/company/' +
        companyId +
        '/vendors/get-vendors?direction=DESC&orderBy=id&page=' +
        page +
        '&size=' +
        size +
        '',
      body,
    );
  }
  addCompanyVendor(vendorId, companyId, addCompanyVendorData) {
    return axiosInstance.post(
      baseUrl + '/vendor/' + companyId + '/' + vendorId + '/add',
      addCompanyVendorData,
    );
  }
  searchVendors(body) {
    return axiosInstance.post(baseUrl + '/vendor/search', body);
  }
  createVendor(body) {
    return axiosInstance.post(baseUrl + '/vendor', body);
  }
  createVendorCompany(companyId, body) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/vendors', body);
  }
  inviteVendor(companyId, vendorId) {
    return axiosInstance.post(baseUrl + '/vendor/' + companyId + '/invite/' + vendorId);
  }

  editVendor(companyId, vendorId, body) {
    return axiosInstance.put(baseUrl + '/company/' + companyId + '/vendors/' + vendorId, body);
  }
  approveVendor(companyId, vendorId, body) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/approve',
      body,
    );
  }
  denyVendor(companyId, vendorId) {
    return axiosInstance.post(baseUrl + '/vendor/' + companyId + '/deny/' + vendorId);
  }

  archiveVendor(companyId, vendorId) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/archive',
    );
  }
  activateVendor(companyId, vendorId) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/activate',
    );
  }
  submitVendorApproval(companyId, vendorId) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/submit-for-approval',
    );
  }
  recallVendor(companyId, vendorId, data) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/recall',
      data,
    );
  }
  deleteVendorDocument(companyId, vendorId, key) {
    return axiosInstance.delete(
      baseUrl +
        '/company/' +
        companyId +
        '/vendors/' +
        vendorId +
        '/delete-document?document-key=' +
        key,
    );
  }

  getVendor(vendorId) {
    return axiosInstance.get(baseUrl + '/vendor/id/' + vendorId);
  }
  getCompanyVendorById(companyId, vendorId) {
    return axiosInstance.get(baseUrl + '/company/' + companyId + '/vendors/' + vendorId);
  }
  getVendorCompaniesAttached(vendorId) {
    return axiosInstance.get(baseUrl + '/vendor/companies/' + vendorId);
  }
  getVendorContactDetails(companyId, vendorId) {
    return axiosInstance.get(baseUrl + '/vendor/' + companyId + '/' + vendorId + '/contacts');
  }

  addVendorNotes(companyId, vendorId, body) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/vendors/' + vendorId + '/add-notes',
      body,
    );
  }
  addVendorContactDetails(companyId, vendorId, body) {
    return axiosInstance.post(
      baseUrl + '/vendor/' + companyId + '/' + vendorId + '/contacts',
      body,
    );
  }
  createVendorContact(vendorId, body) {
    return axiosInstance.post(baseUrl + '/vendor/' + vendorId + '/contacts', body);
  }
  createVendorAddress(vendorId, body) {
    return axiosInstance.post(baseUrl + '/vendor/' + vendorId + '/address', body);
  }
  createVendorFinance(vendorId, body) {
    return axiosInstance.post(baseUrl + '/vendor/' + vendorId + '/financial-info', body);
  }
  createVendorDoc(vendorId, body) {
    return axiosInstance.post(baseUrl + '/vendor/' + vendorId + '/document', body);
  }
  deleteSpecificVendorContact(companyId, vendorId, contactId) {
    return axiosInstance.delete(
      baseUrl + '/vendor/' + companyId + '/' + vendorId + '/contacts/' + contactId,
    );
  }
  editvendorContactDetails(contactId, body) {
    return axiosInstance.put(baseUrl + '/vendor/contact/' + contactId, body);
  }
  getVendorRating(vendorId) {
    return axiosInstance.get(baseUrl + '/vendor/rating/' + vendorId);
  }
  getVendorCategories() {
    return axiosInstance.get(baseUrl + '/vendor/category');
  }
  deleteVendor(companyId, vendorId) {
    return axiosInstance.delete(baseUrl + '/vendor/' + companyId + '/' + vendorId);
  }
  editVendorDetails(vendorId, body) {
    return axiosInstance.put(baseUrl + '/vendor/' + vendorId, body);
  }
  getGlAccounts(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/gl-accounts?companyId=' + companyId);
  }
  getFilteredGlAccounts(companyId, page = 0, size = 30, search) {
    return axiosInstance.post(
      baseUrl + '/gl-accounts/filter?&page=' + page + '&size=' + size + '&companyId=' + companyId,
      search,
    );
  }
  getGlAccount(id) {
    return axiosInstance.get(baseUrl + '/gl-accounts/' + id);
  }
  createGlAccount(data) {
    return axiosInstance.post(baseUrl + '/gl-accounts', data);
  }
  updateGlAccount(id, data) {
    return axiosInstance.post(baseUrl + '/gl-accounts/' + id, data);
  }
  deleteGlAccount(data) {
    return axiosInstance.delete(baseUrl + '/gl-accounts?ids=' + data);
  }
  uploadGlBulk(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/gl-accounts/' + companyId + '/bulk', data);
  }
  purchaseOrder(body) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/purchase-orders?direction=DESC&orderBy=id&page=0&size=30',
      body,
    );
  }
  activateGlAccount(data) {
    return axiosInstance.post(baseUrl + '/gl-accounts/activate?ids=' + data);
  }
  getApprovalMatrix(companyId) {
    return axiosInstance.get(baseUrl + '/company/' + companyId + '/approval-matrix');
  }
  getApprovalModuleById(companyId, id, isActive) {
    return axiosInstance.get(
      baseUrl + '/company/' + companyId + '/approval-matrix/' + id + '?active-rules=' + isActive,
    );
  }
  editApprovalModuleById(companyId, id, data) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/approval-matrix/' + id, data);
  }
  editApprovalMatrix(companyId, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/approval-matrix/edit',
      data,
    );
  }
  addApprovalMatrixRule(companyId, data) {
    return axiosInstance.post(
      s2pmsBaseUrl + '/company/' + companyId + '/approval-matrix/add-rule',
      data,
    );
  }
  getApprovalMatrixRule(companyId, ruleId) {
    return axiosInstance.get(
      s2pmsBaseUrl + '/company/' + companyId + '/approval-matrix/rules/' + ruleId,
    );
  }

  archiveApprovalMatrixRule(companyId, matrixId, ruleId) {
    return axiosInstance.post(
      baseUrl +
        '/company/' +
        companyId +
        '/approval-matrix/' +
        matrixId +
        '/rules/' +
        ruleId +
        '/archive',
    );
  }
  activateApprovalMatrixRule(companyId, matrixId, ruleId) {
    return axiosInstance.post(
      baseUrl +
        '/company/' +
        companyId +
        '/approval-matrix/' +
        matrixId +
        '/rules/' +
        ruleId +
        '/activate',
    );
  }
  fetchReports(path, body, page = 0, size = 30) {
    return axiosInstance.post(
      s2pmsBaseUrl +
        '/reports/' +
        path +
        '?direction=DESC&orderBy=id&page=' +
        page +
        '&size=' +
        size,
      body,
    );
  }
  getMetrics(companyId) {
    return axiosInstance.get(baseUrl + '/metrics/dashboard/' + companyId);
  }
  getNotifications(companyId) {
    return axiosInstance.get(baseUrl + '/company/' + companyId + '/notifications');
  }
  searchNotifications(companyId, page = 0, size = 30, data) {
    return axiosInstance.post(
      baseUrl +
        '/company/' +
        companyId +
        '/notifications/filter?direction=DESC&orderBy=createdAt&page=' +
        page +
        '&size=' +
        size +
        '',
      data,
    );
  }
  getNotification(companyId, id) {
    return axiosInstance.get(baseUrl + '/company/' + companyId + '/notifications/' + id);
  }
  createNotification(companyId, data) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/notifications', data);
  }
  editNotification(companyId, id, data) {
    return axiosInstance.post(baseUrl + '/company/' + companyId + '/notifications/' + id, data);
  }
  activateNotification(companyId, id) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/notifications/' + id + '/activate',
    );
  }
  archiveNotification(companyId, id) {
    return axiosInstance.post(
      baseUrl + '/company/' + companyId + '/notifications/' + id + '/archive',
    );
  }
  getAttachmentsByKey(key) {
    return axiosInstance.get(baseUrl + '/attachments?key=' + key);
  }

  subscriptionForCompany(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans/company/' + companyId);
  }
  subscriptionPlanById(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans/' + id);
  }
  getAllSubscriptionsPlans() {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans');
  }
  getSubscriptionInvoices(companyId) {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans/invoices/' + companyId);
  }
  downloadSubscriptionInvoice(id) {
    return axiosInstance.get(s2pmsBaseUrl + '/subscription-plans/invoices/download/' + id, {
      responseType: 'blob',
    });
  }
  changeCompanySubscription(companyId, data) {
    return axiosInstance.post(s2pmsBaseUrl + '/subscription-plans/' + companyId + '/change', data);
  }
  getSystemAuditLog(size, page, companyId, data) {
    return axiosInstance.post(
      baseUrl +
        '/audit/' +
        companyId +
        '?direction=DESC&orderBy=createdAt&page=' +
        page +
        '&size=' +
        size,
      data,
    );
  }
  insightsOverallSpent(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/reports/overall-spend', data);
  }
  insightsVendorSpent(data) {
    return axiosInstance.post(s2pmsBaseUrl + '/reports/vendor-spend', data);
  }
}
export default new SpmsService();
