import { useStore } from './store';

export const userSlice = (set) => ({
  user: null,
  updateUser: (data) => set({ user: data }),
  isConfiguration: JSON.parse(localStorage.getItem('isConfiguration')),
  setIsConfiguration: (isConfiguration) => {
    set({ isConfiguration: { value: isConfiguration } });
    localStorage.setItem('isConfiguration', JSON.stringify({ value: isConfiguration }));
  },
  reset: () => {
    set({ user: null });
    useStore.setState({
      company: null,
      companyId: null,
      stepsDone: false,
      activeCompany: null,
      fullCompanies: [],
    });
  },
});
