import React, { useEffect, useState } from 'react';
import styles from '../styles/home.module.css';
import * as Button from '../components/UI/Forms/Button';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Input from '../components/UI/Forms/Input';
import Toast from '../components/UI/General/Toast';
import s2pmsEmail from '../services/s2pmsEmail.service';
import GradientText from '../components/UI/General/GradientText';
import Text from '../components/UI/Typography/Text';
import Icon from '../components/UI/General/Icon';
import { regExps } from '../utils/regExps.js';
import styled from 'styled-components';
import GoogleReCaptcha from '../components/UI/Forms/GoogleReCaptcha';
import CryptoJS from 'crypto-js';

const Image = styled.img`
  position: absolute;
  top: ${({ $top }) => $top + '%'};
  bottom: ${({ $bottom }) => $bottom + '%'};
  left: ${({ $left }) => $left + '%'};
  right: ${({ $right }) => $right + '%'};
`;

function Home() {
  const navigate = useNavigate();
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      country: '',
      message: '',
    },
  });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  let count = 0

  const onSubmit = (data) => {
    // if (!captchaResponse) return;
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));
    const newData = {
      user: {
        email: data.email,
        name: `${data.name} ${data.surname}`,
      },
      country: data.country?.label,
      message: data.message,
    };
    console.log(newData);
    s2pmsEmail.sendEmail(newData).then((r) => {
      console.log(r);
      // recaptchaRef.current.reset();
      count++
      console.log(count);
      if (count === 1){
        window.dataLayer.push({
          event: 'newsletter_subscribe',
          email: CryptoJS.AES.encrypt(data.email, process.env.REACT_APP_AES_SECRET).toString()      // SHA-256 hashed unique e-mail address from backend
        });
        console.log(window.dataLayer);
      }
      setToast({
        opened: true,
        message: 'Your email has been sent successfully',
        type: 'success',
      });
    }).catch(err => {
      setIsButtonsBlocked(false)
      setToast({
        opened: true,
        message: 'Error while sending a message',
        type: 'fail',
      });
    });
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  const pricingData = [
    {
      title: 'Free for Life',
      price: 0,
      benefits: [
        'Up to 2 users',
        'Standard Role Based Access',
        'Default Approver - RQ/PO Management',
      ],
    },
    {
      title: 'Starter',
      price: 10,
      benefits: ['Up to 5 users', 'Standard Role Based Access', 'Standard RQ/PO Management'],
    },
    {
      title: 'Growth',
      price: 15,
      benefits: [
        'Up to 10 users',
        'Advanced Role Based Access',
        'Advanced - max 3 rules RQ/PO Management',
      ],
      isActive: true,
    },
    {
      title: 'Enterprise',
      price: 20,
      benefits: [
        'Unlimited users',
        'Advanced Role Based Access',
        'Advanced - Unlimited rules RQ/PO Management',
      ],
    },
  ];

  const handlePriceClick = (plan, price) => {
    window.dataLayer.push({
      event: 'get_started_click',
      PricingPlans: {
        plans: [
          {
            plan_name: plan,             // Name of the first pricing plan //'Free for Life' or 'Starter' or 'Growth' or 'Enterprise'
            price: price,                               // Price of the first plan // '0' or '10' or '15' or '20'
            billing_cycle: 'monthly',               // Billing cycle for the plan
          }
        ]
      }
    });
    console.log(window.dataLayer);
    navigate('/register')
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.headerInner}>
            <label htmlFor="showMobileMenu" className={styles.mobileMenu}>
              <span />
              <span />
              <span />
            </label>
            <div className={styles.headerLogo}>
              {/* <a href="#hero"> */}
              <img src="assets/img/home/logo.svg" alt="SpendPro" />
              {/* </a> */}
            </div>
            <input type="checkbox" name="showMobileMenu" id="showMobileMenu" />
            <ul className={styles.headerMenu}>
              <li>
                <a href="#hero">Home</a>
              </li>
              <li>
                <a href="#features">Features</a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
            <div className={styles.headerActions}>
              <Link to="/login"><button className={styles.loginButton}>
                Login
              </button></Link>
              <Link to="/register">
                <Button.Main className={styles.registerButton} $primary $style="blue">
                  Start Free Trial
                </Button.Main>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section className={styles.hero} id="hero">
          <div className={styles.container}>
            <div className={styles.heroInner}>
              <div className={styles.heroInfo}>
                <h1 className={styles.heroTitle}>Revolutionise your procurement processes!</h1>
                <div className={styles.heroDescription}>
                  Solutions for your business with user-friendly interface Intelligent Spend
                  Management
                </div>
                <Link to="/register">
                  <button className={styles.gradientButton}>
                    Start Free Trial
                    <Icon $icon="chevron-right" $width={24} $height={24} />
                  </button>
                </Link>
              </div>
              <div className={styles.heroVideo}>
                <iframe type="text/html" className={styles.iframe}
                        src="https://www.youtube.com/embed/pUrDcsBFE6o?controls=1&playsinline=1"
                        frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.features} id="features">
          <div className={styles.container}>
            <div className={styles.featuresItems}>

              <div className={styles.featuresItem}>
                <img src="assets/img/home/features-full-1.png" alt="Increased Efficiency"/>
                <div className={styles.featuresBlock}>
                  <div className={styles.featuresTitle}>
                    Increased Efficiency
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Streamlined Processes
                      </div>
                      <div className={styles.featuresDescription}>
                        Automation speeds up procurement, from supplier selection to payment, reducing manual work.
                      </div>
                    </div>
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Fewer Errors
                      </div>
                      <div className={styles.featuresDescription}>
                        Automation minimizes human errors in tasks like data entry and invoice matching.
                      </div>
                    </div>
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Time Savings
                      </div>
                      <div className={styles.featuresDescription}>
                        User spends less time on admin tasks and more on strategic decisions due to automated workflows.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.featuresItem}>
                <img src="assets/img/home/features-full-2.png" alt="Improved Visibility and Transparency"/>
                <div className={styles.featuresBlock}>
                  <div className={styles.featuresTitle}>
                    Improved Visibility and Transparency
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Real-Time Data
                      </div>
                      <div className={styles.featuresDescription}>
                        Access up-to-date information on orders, compliance, and supplier performance for better decisions.
                      </div>
                    </div>
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Audit Trails
                      </div>
                      <div className={styles.featuresDescription}>
                        Track all transactions for accountability and traceability.
                      </div>
                    </div>
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Centralized information
                      </div>
                      <div className={styles.featuresDescription}>
                        All procurement data is stored in one place for easy access and review.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.featuresItem}>
                <img src="assets/img/home/features-full-3.png" alt="Enhanced Cost Control and Savings"/>
                <div className={styles.featuresBlock}>
                  <div className={styles.featuresTitle}>
                    Enhanced Cost Control and Savings
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Better Supplier Management
                      </div>
                      <div className={styles.featuresDescription}>
                        Monitor performance, negotiate better terms, and ensure compliance for improved pricing.
                      </div>
                    </div>
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Spend Visibility
                      </div>
                      <div className={styles.featuresDescription}>
                        Gain insights into spending patterns to identify savings, reduce maverick spending, and enforce policies.
                      </div>
                    </div>
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Invoice Matching
                      </div>
                      <div className={styles.featuresDescription}>
                        Automatically match invoices to orders, ensuring payments are made only for authorized goods, reducing overpayment risk.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.featuresItem}>
                <img src="assets/img/home/features-full-4.png" alt="Reduced Fraud Risk"/>
                <div className={styles.featuresBlock}>
                  <div className={styles.featuresTitle}>
                    Reduced Fraud Risk
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Approval Workflows
                      </div>
                      <div className={styles.featuresDescription}>
                        Automation ensures invoices and payments follow proper approval channels, reducing fraud risk.
                      </div>
                    </div>
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Supplier Verification
                      </div>
                      <div className={styles.featuresDescription}>
                        Integrates with databases to verify suppliers and ensure payments are legitimate.
                      </div>
                    </div>
                  </div>
                  <div className={styles.featuresRow}>
                    <div className={styles.featuresCheck}>
                      <img src="assets/img/home/check.svg" alt="+"/>
                    </div>
                    <div className={styles.featuresText}>
                      <div className={styles.featuresHeading}>
                        Segregation of Duties
                      </div>
                      <div className={styles.featuresDescription}>
                        Enforces responsibility separation, minimizing fraud opportunities.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className={styles.pricing} id="pricing">
          <img
            className={styles.pricingBackground}
            src="assets/img/home/feature-ball.svg"
            alt="check-icon"
          />
          <div className={styles.container}>
            <div className={styles.pricingCta}>
              <h2 className={styles.h2}>Plans designed for any kind of procurement goal</h2>
            </div>

            <div className={styles.pricingTable}>
              {pricingData.map((col, index) => (
                <div key={index} className={styles.pricingCol}>
                  <div className={styles.pricingTableHead}>
                    <div className={styles.pricingTableHeadTop}>
                      <h3 className={styles.pricingTableHeadTitle}>
                        <GradientText $from="#313439" $to="#3778FF">
                          {col.title}
                        </GradientText>
                      </h3>
                      {col.isActive && <span className={styles.rainbowText}>Most Popular</span>}
                    </div>
                    <div className={styles.pricingTableHeadContent}>
                      <div className={styles.pricingTableHeadPrice}>
                        <span>${col.price}</span>
                        <span className={styles.pricingTableHeadPricePeriod}>/month</span>
                      </div>
                      <div className={styles.pricingTableHeadPriceDescription}>
                        <span onClick={() => handlePriceClick(col.title, col.price)}>
                          <Button.Main $full $primary $style="blue">
                            Get Started
                          </Button.Main>
                        </span>
                      </div>
                      <div className={styles.pricingBenefits}>
                        {col.benefits.map((benefit, index) => (
                          <div key={index} className={styles.pricingBenefit}>
                            <div>
                              <img src="assets/img/general/check.svg" alt="check-icon" />
                            </div>
                            <Text weight={400} type={'body-1'}>
                              {benefit}
                            </Text>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className={styles.cta}>
          <div className={styles.container}>
            <div className={styles.ctaColumns}>
              <div className={styles.ctaLeft}>
                <div className={styles.ctaContent}>
                  <span className={styles.ctaHead}>start now!</span>
                  <div className={styles.ctaInfo}>
                    <h2 className={styles.h2}>Start and Make a Difference in your Company</h2>
                    <p className={styles.p}>Create a free account and request a free demo</p>
                  </div>
                </div>
                <Link to="/register">
                  <button className={styles.gradientButton} $primary $style="pink">
                    Start Free Trial
                    <Icon $icon="chevron-right" $width={24} $height={24} />
                  </button>
                </Link>
              </div>
              <div className={styles.ctaRight}>
                <div className={styles.ctaUserPo}>
                  <img src="assets/img/home/userPo.png" alt="user-po" />
                </div>
                <div className={styles.ctaPoOverview}>
                  <img src="assets/img/home/poOverview.png" alt="po-overview" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.footerContainer}>
            <div>
              <img src="assets/img/home/logo.svg" alt="SpendPro" />
            </div>
            <div className={styles.footerInner}>
              <div className={styles.footerGeneral}>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.footerForm}>
                  <Text weight={400} type="h3" className={styles.footerFormTitle}>
                    Subscribe to our newsletter
                  </Text>
                  <div className="inp-container">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Email is required',
                        },
                        pattern: {
                          value: regExps.email,
                          message: 'Email is not valid',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter your email"
                          className={`${styles.footerFormInput} ${
                            errors.hasOwnProperty(field.email) && 'error'
                          }`}
                          {...field}
                        />
                      )}
                    />
                    {errors.email && <p className="error-message">{errors.email?.message}</p>}
                  </div>
                  {/*<GoogleReCaptcha setCaptchaResponse={setCaptchaResponse}/>*/}
                  {/*<br/>*/}
                  <Button.Main
                    $primary
                    $full
                    $style="blue"
                    $iconRight
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isButtonsBlocked || !isValid || !isDirty}
                  >
                    Subscribe
                  </Button.Main>
                </form>
                <Link to="/register?type=vendor">Register as a Vendor</Link>
              </div>
              <div className={styles.footerStores}>
                <Link to="https://apps.apple.com/" target="_blank" rel="noreferrer" onClick={() => {
                  window.dataLayer.push({
                    event: 'app_store_click',
                    button_location: 'button_location'
                  });
                  console.log(window.dataLayer);
                }}>
                  <img src="assets/img/home/apple.png" alt="apple store" />
                </Link>
                <Link to="https://play.google.com/" target="_blank" rel="noreferrer" onClick={() => {
                  window.dataLayer.push({
                    event: 'google_play_click',
                    button_location: 'button_location'
                  });
                  console.log(window.dataLayer);
                }}>
                  <img src="assets/img/home/google.png" alt="google store" />
                </Link>
              </div>
              <div className={styles.footerInfo}>
                <a href={'tel:713-524-9524'} onClick={() => {
                  window.dataLayer.push({
                    event: 'phone_click',
                    button_location: 'button_location'
                  });
                  console.log(window.dataLayer);
                }}>713-524-9524</a>
                <a href={'mailto:info@spendpro.com'} onClick={() => {
                  window.dataLayer.push({
                    event: 'email_click',
                    button_location: 'button_location'
                  });
                  console.log(window.dataLayer);
                }}>info@spendpro.com</a>
                <span onClick={() => {
                  window.dataLayer.push({
                    event: 'location_click',
                    address: 'address',
                    cick_type: 'cick_type'      // Such as click on map or click on address
                  });
                  console.log(window.dataLayer);
                }}>1oo Abc Street, 11th floor, City, South Africa</span>
              </div>
              <div className={styles.footerSocials}>
                <a href="/" target="_blank" rel="noreferrer">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5398_5946)">
                      <path
                        d="M18.1168 9.22497C18.0752 8.79997 17.7168 8.48329 17.2918 8.48329H11.0002C10.5418 8.48329 10.1668 8.85829 10.1668 9.31662V10.7416C10.1668 11.2 10.5418 11.575 11.0002 11.575H14.7585C14.6668 12.3416 14.1668 13.5 13.0585 14.275C12.3502 14.7666 11.4085 15.1083 10.1668 15.1083C10.1085 15.1083 10.0585 15.1083 10.0002 15.1C7.87517 15.0333 6.07517 13.6083 5.42517 11.65C5.25017 11.125 5.15017 10.575 5.15017 9.99996C5.15017 9.42496 5.25017 8.86662 5.41683 8.34995C5.46683 8.19995 5.52516 8.04997 5.59183 7.89997C6.3585 6.17497 8.0335 4.9583 10.0002 4.89996C10.0502 4.89163 10.1085 4.89162 10.1668 4.89162C11.3585 4.89162 12.2502 5.28328 12.8752 5.71661C13.2002 5.94161 13.6335 5.89162 13.9168 5.61662L15.0752 4.4833C15.4418 4.12496 15.4085 3.51661 14.9918 3.21661C13.6668 2.24161 12.0502 1.66663 10.1668 1.66663C10.1085 1.66663 10.0585 1.66663 10.0002 1.67497C6.8085 1.7333 4.06683 3.5833 2.72516 6.2583C2.1585 7.39163 1.8335 8.65829 1.8335 9.99996C1.8335 11.3416 2.1585 12.6083 2.72516 13.7416H2.7335C4.07516 16.4166 6.81683 18.2666 10.0002 18.325C10.0585 18.3333 10.1085 18.3333 10.1668 18.3333C12.4168 18.3333 14.3085 17.5916 15.6835 16.3166C17.2585 14.8583 18.1668 12.725 18.1668 10.1833C18.1668 9.82496 18.1502 9.51663 18.1168 9.22497Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5398_5946">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" onClick={() => {
                  window.dataLayer.push({
                    event: 'facebook_click',
                    button_location: 'button_location'
                  });
                  console.log(window.dataLayer);
                }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.999 14.1898C19.999 17.8298 17.829 19.9998 14.189 19.9998H12.999C12.449 19.9998 11.999 19.5498 11.999 18.9998V13.2298C11.999 12.9598 12.219 12.7298 12.489 12.7298L14.249 12.6998C14.389 12.6898 14.509 12.5898 14.539 12.4498L14.889 10.5398C14.919 10.3598 14.779 10.1898 14.589 10.1898L12.459 10.2198C12.179 10.2198 11.959 9.99977 11.949 9.72977L11.909 7.27975C11.909 7.11975 12.039 6.97977 12.209 6.97977L14.609 6.93976C14.779 6.93976 14.909 6.80977 14.909 6.63977L14.869 4.23975C14.869 4.06975 14.739 3.93976 14.569 3.93976L11.869 3.97977C10.209 4.00977 8.88904 5.36975 8.91904 7.02975L8.96902 9.77975C8.97902 10.0598 8.75903 10.2798 8.47903 10.2898L7.27902 10.3098C7.10902 10.3098 6.97903 10.4397 6.97903 10.6097L7.00903 12.5098C7.00903 12.6798 7.13902 12.8098 7.30902 12.8098L8.50903 12.7898C8.78903 12.7898 9.00901 13.0098 9.01901 13.2798L9.10901 18.9798C9.11901 19.5398 8.66901 19.9998 8.10901 19.9998H5.80902C2.16902 19.9998 -0.000976562 17.8297 -0.000976562 14.1797V5.80975C-0.000976562 2.16975 2.16902 -0.000244141 5.80902 -0.000244141H14.189C17.829 -0.000244141 19.999 2.16975 19.999 5.80975V14.1898Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a href="https://www.instagram.com/" target="_blank" rel="noreferrer" onClick={() => {
                  window.dataLayer.push({
                    event: 'instagramm_click',
                    button_location: 'button_location'
                  });
                  console.log(window.dataLayer);
                }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5398_5952)">
                      <path
                        d="M13.4915 1.66663H6.50817C3.47484 1.66663 1.6665 3.47496 1.6665 6.50829V13.4833C1.6665 16.525 3.47484 18.3333 6.50817 18.3333H13.4832C16.5165 18.3333 18.3248 16.525 18.3248 13.4916V6.50829C18.3332 3.47496 16.5248 1.66663 13.4915 1.66663ZM9.99984 13.2333C8.2165 13.2333 6.7665 11.7833 6.7665 9.99996C6.7665 8.21663 8.2165 6.76663 9.99984 6.76663C11.7832 6.76663 13.2332 8.21663 13.2332 9.99996C13.2332 11.7833 11.7832 13.2333 9.99984 13.2333ZM14.9332 5.73329C14.8915 5.83329 14.8332 5.92496 14.7582 6.00829C14.6748 6.08329 14.5832 6.14163 14.4832 6.18329C14.3832 6.22496 14.2748 6.24996 14.1665 6.24996C13.9415 6.24996 13.7332 6.16663 13.5748 6.00829C13.4998 5.92496 13.4415 5.83329 13.3998 5.73329C13.3582 5.63329 13.3332 5.52496 13.3332 5.41663C13.3332 5.30829 13.3582 5.19996 13.3998 5.09996C13.4415 4.99163 13.4998 4.90829 13.5748 4.82496C13.7665 4.63329 14.0582 4.54163 14.3248 4.59996C14.3832 4.60829 14.4332 4.62496 14.4832 4.64996C14.5332 4.66663 14.5832 4.69163 14.6332 4.72496C14.6748 4.74996 14.7165 4.79163 14.7582 4.82496C14.8332 4.90829 14.8915 4.99163 14.9332 5.09996C14.9748 5.19996 14.9998 5.30829 14.9998 5.41663C14.9998 5.52496 14.9748 5.63329 14.9332 5.73329Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5398_5952">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a href="https://www.twitter.com/" target="_blank" rel="noreferrer" onClick={() => {
                  window.dataLayer.push({
                    event: 'twitter_click',
                    button_location: 'button_location'
                  });
                  console.log(window.dataLayer);
                }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7553 7.19515C16.7354 7.00893 16.712 6.85969 16.6928 6.75334L18.2632 4.39775C18.362 4.24964 18.3557 4.05517 18.2477 3.91371C18.1396 3.77225 17.9536 3.71505 17.7848 3.77134L15.6009 4.49931C15.4855 4.32717 15.3143 4.10012 15.0862 3.872C14.6241 3.40992 13.8993 2.91663 12.9165 2.91663C11.8977 2.91663 11.1437 3.20178 10.6054 3.66992C10.0725 4.13325 9.79148 4.74053 9.64772 5.31557C9.50415 5.88987 9.49137 6.45505 9.5121 6.86972C9.51815 6.99065 9.52712 7.1001 9.53685 7.19482C8.49089 7.41725 7.39643 7.07652 6.32429 6.44025C5.14204 5.73863 4.05829 4.71913 3.21116 3.872C3.09366 3.75451 2.9176 3.71805 2.76311 3.77923C2.60862 3.84041 2.50526 3.98753 2.50007 4.15361C2.3939 7.55096 3.09432 11.6631 6.25916 13.7483C4.928 14.4684 3.62288 14.8043 2.03151 15.0032C1.84932 15.026 1.70351 15.1653 1.67251 15.3463C1.64151 15.5273 1.73263 15.7072 1.89686 15.7893C6.19577 17.9388 11.7664 17.7834 14.9165 13.5833C16.2223 11.8422 16.6599 10.0966 16.7693 8.78456C16.8239 8.12929 16.7967 7.58169 16.7553 7.19515Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className={styles.copyrights}>
              <div className={styles.copyrightsBlock}>
                <span>© {new Date().getFullYear()} SpendPro</span>
                <span>All right reserved</span>
              </div>
              <div className={styles.copyrightsBlock}>
                <Link to="/terms" target="_blank">Terms of Service</Link>
                <Link to="/privacy" target="_blank">Privacy Policy</Link>
                <Link to="/cookie-policy" target="_blank">Cookie Policy</Link>
                <Link to="/acceptable-use-policy" target="_blank">Acceptable Use Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </>
  );
}

export default Home;
