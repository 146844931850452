import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import GridItem from '../../components/Admins/PurchaseOrders/View/GridItem';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import { useForm } from 'react-hook-form';
import Toast from '../../components/UI/General/Toast';
import spmsServiceService from '../../services/spmsService.service';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import { useStore } from '../../store/store';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import BackButton from '../../components/shared/BackButton';
import Top from '../../components/Admins/PurchaseOrders/View/Top';
import DataTableBase from '../../components/UI/General/DataTableBase';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';

const VendorInReview = () => {
  const activeCompany = useStore((state) => state.activeCompany);
  const user = useStore((state) => state.user);
  const { companyId, vendorId } = useParams();
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const navigate = useNavigate();
  const [tab, setTab] = useState('overview');
  const [values, setValues] = useState(null);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
  });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const accessVendor = useAccessAllowed('Vendor_Management');

  useEffect(() => {
    if (vendorId) {
      spmsServiceService.getCompanyVendorById(activeCompany.id, vendorId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          setValues(r.data.data);
        }
      });
    }
  }, [vendorId, user]);

  const getData = useCallback(
    async (page, size) => {
      const requestBody = {
        category: values?.vendor?.category,
        vendorStatus: 'APPROVED',
      };

      return await spmsServiceService.getVendors(activeCompany.id, requestBody, page, size);
    },
    [values],
  );

  const columns = [
    {
      name: 'Code',
      sortable: true,
      selector: (row) => row.vendorCode,
      cell: (row) => (
        <Link className={generalStyles.tableLink} to={`*`} weight={500}>
          <span data-content={'Code'} className={generalStyles.tableValue}>
            <span>{'VENDOR CODE'}</span>
          </span>
        </Link>
      ),
    },
    {
      name: 'Name',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) => (
        <span data-content={'Name'}>
          <span>{'LEGAL NAME'}</span>
        </span>
      ),
    },
    {
      name: 'Tier',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) => (
        <span data-content={'Tier'}>
          {/* <span>{row.vendor.legalName}</span> */}
          <span>Tier</span>
        </span>
      ),
    },
    {
      name: 'Category',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) => (
        <span data-content={'Category'}>
          {/* <span>{row.vendor.legalName}</span> */}
          <span>Category</span>
        </span>
      ),
    },
    {
      name: 'Subcategory',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) => (
        <span data-content={'Subcategory'}>
          {/* <span>{row.vendor.legalName}</span> */}
          <span>Subcategory</span>
        </span>
      ),
    },
  ];

  const inviteVendor = () => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    spmsServiceService
      .inviteVendor(activeCompany.id, vendorId)
      .then((r) => {
        console.log(r);
        setToast({
          opened: true,
          message: 'Vendor invited',
          type: 'success',
          cb: () => {
            navigate('/vendors', { state: { tab: 'new requests' } });
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const getInReviewInfo = () => {
    if (!values) return [];
    return [
      { name: 'Requester', value: values.requester?.name },
      { name: 'Location', value: values?.locations[0]?.name },
      { name: 'Department', value: values?.departments[0]?.name },
      { name: 'Vendor Name', value: values.vendor?.legalName },
      { name: 'Category', value: values.vendor?.category },
      { name: 'Subcategory', value: values.vendor?.subCategory },
      {
        name: 'Website URL',
        value:
          values?.vendorContacts[0]?.websiteUrl === '' ? '-' : values?.vendorContacts[0]?.websiteUrl,
      },
      {
        name: 'Contact Name',
        value: values?.vendorContacts[0]?.name === '' ? '-' : values?.vendorContacts[0]?.name,
      },
      {
        name: 'Contact Email',
        value: values?.vendorContacts[0]?.email === '' ? '-' : values?.vendorContacts[0]?.email,
      },
      { name: 'Products', value: values.purchaseDescription },
      { name: 'Benefit vendor offers', value: values.benefitsFromVendor },
      { name: 'NDA Signed', value: values.ndaSigned ? 'Yes' : 'No' },
    ];
  };

  const submitForApproval = () => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    spmsServiceService
      .submitVendorApproval(companyId, vendorId)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Vendor submited for approval successfully',
          type: 'success',
          cb: () => {
            navigate('/vendors');
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };
  const denyVendor = () => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    spmsServiceService
      .denyVendor(companyId, vendorId)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Vendor denied successfully',
          type: 'success',
          cb: () => {
            navigate('/vendors', { state: { tab: 'new requests' } });
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div>
            <BackButton />
          </div>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>
                {`${values?.vendor?.legalName} (${values?.approvalStatus})`}
              </Text>
              <Text type="body-1" weight={500}>
                {`${values?.vendor?.category} - ${values?.vendor?.subCategory}`}
              </Text>
            </div>
            <div className={generalStyles.addItemButton}>
              {accessVendor?.approve && (
                <>
                  {values?.vendor?.category === 'IT & Technology' &&
                    values?.vendor?.category === 'Software' && (
                      <Button.Main
                        $mid
                        $style="blue"
                        type="submit"
                        form="requisitionForm"
                        disabled={isButtonsBlocked}
                        onClick={submitForApproval}
                      >
                        Submit for Review
                      </Button.Main>
                    )}
                  <Button.Main
                    $mid
                    $style="red"
                    type="submit"
                    form="requisitionForm"
                    disabled={isButtonsBlocked}
                    onClick={denyVendor}
                  >
                    Deny
                  </Button.Main>
                  <Button.Main
                    $mid
                    $style="green"
                    type="submit"
                    form="requisitionForm"
                    disabled={isButtonsBlocked}
                    onClick={inviteVendor}
                  >
                    Invite to Portal
                  </Button.Main>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('overview')}>Overview</span>
            <span onClick={() => setTab('documents')}>Documents</span>
          </TabsSlider>
        </div>
        <br />
        <div className={styles.vendorDetails}>
          {tab === 'overview' && values && (
            <div className={`${styles.inReviewInfo} ${generalStyles.underline}`}>
              <div className={generalStyles.fieldsThree}>
                {getInReviewInfo().map((item, i) => (
                  <div key={i} className={styles.inReviewInfoItem}>
                    <Text weight={500} type="body-2">
                      {item.name}
                    </Text>
                    <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                      {item.value}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          )}
          {tab === 'documents' && <QuotesTab data={values?.attachments} />}
        </div>
        <div className={styles.prefferedVendorsGrid}>
          <Text weight={500} type={'body-1'}>
            Below a list of matching preferred vendors for your company
          </Text>
          <DataTableBaseRemote fetchData={getData} columns={columns} selectableRows={false} />
        </div>
      </Box>
      <div className={generalStyles.pageButtons}></div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default VendorInReview;
