import Select from 'react-select';
import generalStyles from '../../../../styles/general.module.css';
import { Controller } from 'react-hook-form';
import Label from '../../../UI/Forms/Label';
import React from 'react';

const Approvers = ({ control, errors, approversOptions, tab }) => {
  return (
    <div className={generalStyles.fieldsThree}>
      <div className="inp-container">
        <Label
          $title="Default Final Approver"
          $tooltipText="Select the user that should be the final approver"
        />
        <Controller
          name={`${tab}-defaultApprover`}
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Default Approver is required',
            },
          }}
          render={({ field }) => (
            <Select
              {...field}
              className={
                errors.hasOwnProperty(field.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
              classNamePrefix="react-select"
              isSearchable={false}
              placeholder="Default Approver"
              options={approversOptions}
            />
          )}
        />
        {errors.defaultApprover && (
          <p className="error-message">{errors.defaultApprover.message}</p>
        )}
      </div>
    </div>
  );
};

export default Approvers;
