import React, { useState } from "react";
import LoginCmp from "../components/Auth/Login";
import NewPassword from "../components/Auth/NewPassword";
import Topt from "../components/Auth/Topt";
import ForgetPassword from "../components/Auth/ForgetPassword";
import ResetPassword from "../components/Auth/ResetPassword";
import ForgotResetPassword from "../components/Auth/ForgotResetPassword";
import CheckEmail from "../components/Auth/CheckEmail";
import style from '../styles/log_reg.module.css';
import GradientText from '../components/UI/General/GradientText';
import Text from '../components/UI/Typography/Text';
import Box from '../components/UI/General/Box';
import { Link } from 'react-router-dom';

const Login = () => {
	const [status, setStatus] = useState("sign-in");
	const [user, setUser] = useState(null);
	const [username, setUserName] = useState(null);

	return (
		<div className={style.auth}>
			<div className={style.authInner}>
				<div className={style.motto}>
					<div>
						<Link to='/'>
							<img src="/assets/img/login_register/spendproLogo.svg" alt="SpendPro-Logo" />
						</Link>
					</div>
					<GradientText $from="#7ea4ef" $to="#dcdfe6">
						<Text className={style.mottoText} type="h3" weight={600}>
							Spendpro improves customer service, offering a better solution for your business.
							Start your free trial today and explore the benefits. We'll guide you to get the most
							out of it.
						</Text>
					</GradientText>
				</div>
				<Box $radius={40} className={style.authContainer} $noPadding $noOverflow>
					{status === "sign-in" ? (
						<LoginCmp setStatus={setStatus} setUser={setUser} />
					) : null}
					{status === "new-password" ? (
						<NewPassword setUser={setUser} setStatus={setStatus} user={user} />
					) : null}
					{status === "topt" ? <Topt user={user} /> : null}
					{status === "forgot" ? (
						<ForgetPassword setStatus={setStatus} setUserName={setUserName} />
					) : null}
					{status === "check-email" && (
						<CheckEmail setStatus={setStatus} setUserName={setUserName} />
					)}
					{status === "reset-password" ? (
						<ResetPassword setStatus={setStatus} username={username} />
					) : null}
					{status === "forgot-reset-password" ? (
						<ForgotResetPassword setStatus={setStatus} username={username} />
					) : null}
				</Box>
			</div>
		</div>
	);
};

export default Login;
