import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Button from '../../components/UI/Forms/Button';
import Textarea from '../../components/UI/Forms/Textarea';

import spmsServiceService from '../../services/spmsService.service';

import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';

import Label from '../../components/UI/Forms/Label';
import Text from '../../components/UI/Typography/Text';
import { regExps } from '../../utils/regExps';
import moment from 'moment';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import SelectDepartments from '../../components/shared/SelectDepartments';
import SelectLocations from '../../components/shared/SelectLocations';
import SelectGls from '../../components/shared/SelectGls';

const validationSchema = yup.object().shape({
  notes: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Notes cannot be just empty spaces', (value) => value?.trim() !== '')
    .matches(regExps.notes, "Only alpha characters, numbers and - . , & : # ( ) + / '")
    .max(500, 'Notes must be at most 500 characters'),

  glAccountId: yup.object().shape({
    label: yup.string().required('Required'),
    text: yup.string().required('Required'),
    value: yup.string().required('Required'),
  }),
  departmentId: yup.lazy((value) => {
    if (!value) return yup.string().required('Required');
    return yup.object().shape({
      value: yup.string().required('Required'),
    });
  }),
  locationId: yup.lazy((value) => {
    if (!value) return yup.string().required('Required');
    return yup.object().shape({
      value: yup.string().required('Required'),
    });
  }),
  glBudgetValue: yup.string().required('Required'),
  monthlyAllocations: yup.array().of(
    yup.object({
      amount: yup.string().required('This field is required'),
    }),
  ),
});

const AddNewGl = ({
  setGlMode,
  setToast,
  monthsToRender,
  updateMonthFields,
  glMode,
  editableGl,
  handleRowClick,
  handleGlLine,
  currency,
  glItems,
}) => {
  const [totalAmountErr, setTotalAmountErr] = useState(false);
  const [focus, setFocus] = useState(false);

  const allMonths = moment.months().map((month) => month.toLowerCase());

  const getMonthNameByIndex = (data) => {
    const [month, year] = data.split('-');
    const monthString = readableTitleFromBackend(allMonths[parseFloat(month) - 1]);
    return `${monthString} ${year}`;
  };
  const {
    handleSubmit,
    control,
    setValue,
    unregister,
    formState: { errors, isDirty, isValid },
    reset,
    trigger,
    clearErrors,
    getValues,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      notes: '',
      monthlyAllocations: [],
    },
  });
  const monthlyAllocations = useWatch({ name: 'monthlyAllocations', control });

  useEffect(() => {
    updateMonthFields();
  }, []);

  useEffect(() => {
    if (!editableGl) return;
    const editGLItem = {
      departmentId: {
        value: editableGl?.departmentId,
        label: editableGl?.departmentName,
      },
      glAccountId: {
        label: editableGl.glAccountName,
        text: editableGl.glAccountDescription,
        value: editableGl.glAccountId,
      },
      locationId: {
        value: editableGl.locationId,
        label: editableGl.locationName,
      },
      notes: editableGl?.notes && editableGl?.notes,
      glBudgetValue: editableGl.glBudgetValue,
      monthlyAllocations: editableGl.allocations,
    };
    reset({
      ...editGLItem,
    });
  }, [editableGl, monthsToRender]);

  const fixStringBudgetValue = (value) =>
    typeof value === 'string' ? parseFloat(value.replace(/,/g, ''), 10) : value === '' ? 0 : value;

  const onSubmitGLItems = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    const { locationId, departmentId, glAccountId, glBudgetValue, notes, monthlyAllocations } =
      data;
    if (
      glMode.type === 'add' &&
      glItems.find(
        (glItem) =>
          glItem.glAccountId === glAccountId.value &&
          glItem.departmentId === departmentId.value &&
          glItem.locationId === locationId.value,
      )
    ) {
      setToast({
        opened: true,
        message: 'Unique combiantion of Gl Code, Department and Location is required',
        type: 'fail',
        cb: () => setToast((item) => ({ ...item, opened: false })),
      });
      return;
    }

    const budgetValue = parseFloat(glBudgetValue.split(' ').at(-1));
    const requestBody = {
      glBudgetValue: budgetValue,
      glAccountDescription: glAccountId?.text,
      glAccountId: glAccountId?.value,
      glAccountName: glAccountId?.label,
      locationName: locationId?.label,
      departmentName: departmentId?.label,
      departmentId: departmentId?.value,
      locationId: locationId?.value,
      notes: notes,
      allocations: monthlyAllocations.map((month) => ({
        ...month,
        amount: fixStringBudgetValue(month.amount),
      })),
    };
    if (editableGl) requestBody.id = editableGl.id;
    handleGlLine(requestBody);
    reset();
  };

  const handleGlBudgetValueChange = (floatValue) => {
    setValue('glBudgetValue', floatValue);
    const monthsAmount = monthsToRender?.length;
    const dividedValue = (floatValue / monthsAmount).toFixed(2);
    trigger('glBudgetValue');
    clearErrors(monthsToRender);
    unregister('monthlyAllocations');
    const updatedMonths = monthsToRender.map((month) => ({ month, amount: dividedValue }));
    if (dividedValue * monthsToRender.length !== floatValue) {
      const lastMonth = updatedMonths[updatedMonths.length - 1];
      const difference = (floatValue - dividedValue * monthsToRender.length).toFixed(2);
      const resultAmount = +dividedValue + +difference;
      updatedMonths[updatedMonths.length - 1] = { ...lastMonth, amount: resultAmount };
    }
    console.log('updatedMonths', updatedMonths);

    setValue('monthlyAllocations', updatedMonths);
  };

  const onMonthBudgetChange = (field, month, amount) => {
    const amountNumber = amount.split(' ').at(-1);
    field.onChange({ month, amount: amountNumber });
    setTotalAmountErr(false);
    const monthlyAllocations = getValues('monthlyAllocations');
    totalAmountErr && clearErrors(monthsToRender);
    const updatedBudget = monthlyAllocations.reduce((acc, curr) => {
      const currNumber = curr?.amount ? fixStringBudgetValue(curr.amount) : 0;
      return acc + currNumber;
    }, 0);
    setValue('glBudgetValue', updatedBudget);
    trigger('monthlyAllocations');
  };

  const searchByFirstCharacter = (option, inputValue) =>
    option.data.label.description.toLowerCase().includes(inputValue.toLowerCase());

  return (
    <form
      className={styles.glForm}
      style={glMode.type === 'add' ? { border: '1px solid #d9d9d9', marginBottom: 30 } : {}}
      onSubmit={handleSubmit(onSubmitGLItems)}
    >
      <div className={generalStyles.fieldsFour}>
        <div className="inp-container">
          <Label $title="Account Code" $isRequired $tooltipText="GL linked to the budget" />
          <Controller
            name="glAccountId"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <SelectGls {...rest} selectRef={ref} className={'react-select-container'} />
            )}
          />
          <p className="error-message">{errors?.glAccountId?.label?.message}</p>
        </div>
        <div className="inp-container">
          <Label $title="Department" $isRequired $tooltipText="Department linked to the budget" />
          <Controller
            name="departmentId"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <SelectDepartments {...rest} selectRef={ref} className={'react-select-container'} />
            )}
          />
          <p className="error-message">
            {errors?.departmentId?.message ?? errors?.departmentId?.value?.message}
          </p>
        </div>
        <div className="inp-container">
          <Label $title="Location" $isRequired $tooltipText="Location linked to the budget" />
          <Controller
            name="locationId"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <SelectLocations {...rest} selectRef={ref} className={'react-select-container'} />
            )}
          />
          <p className="error-message">
            {errors?.locationId?.message ?? errors?.locationId?.value?.message}
          </p>
        </div>
        <div className="inp-container">
          <Label
            $title="Account Code Budget Value"
            $isRequired
            $tooltipText="Value of the GL Line Budget"
          />
          <Controller
            name="glBudgetValue"
            control={control}
            render={({ field: { ref, ...other } }) => (
              <NumericFormat
                {...other}
                prefix={currency + ' '}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                decimalScale={2}
                placeholder="Enter Account Code Budget Value"
                style={errors.hasOwnProperty(other.name) ? { borderColor: '#cd3e27' } : {}}
                className={styles.numberInput}
                onValueChange={({ floatValue }) => {
                  if (focus) {
                    handleGlBudgetValueChange(floatValue);
                  }
                }}
              />
            )}
          />
          <p className="error-message">{errors?.glBudgetValue?.message}</p>
        </div>
      </div>
      <div>
        <div className={generalStyles.fieldsSix}>
          {monthsToRender?.map((month, index) => {
            return (
              <div className="inp-container" key={month}>
                <Label $title={getMonthNameByIndex(month)} $isRequired />
                <Controller
                  name={`monthlyAllocations[${index}]`}
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <NumericFormat
                      {...other}
                      value={monthlyAllocations ? monthlyAllocations[index]?.amount : ''}
                      allowLeadingZeros
                      thousandSeparator=","
                      placeholder="2500.00"
                      prefix={currency + ' '}
                      decimalScale={2}
                      style={
                        errors?.monthlyAllocations && errors.monthlyAllocations[index]
                          ? { borderColor: '#cd3e27' }
                          : {}
                      }
                      className={styles.numberInput}
                      onChange={(e) => {
                        onMonthBudgetChange(other, month, e.target.value);
                      }}
                    />
                  )}
                />
                {errors?.monthlyAllocations && (
                  <p className="error-message">
                    {errors.monthlyAllocations[index]?.amount?.message}
                  </p>
                )}
              </div>
            );
          })}
        </div>
        {totalAmountErr && (
          <p className={`error-message ${styles.totalAmountError}`}>
            The total amount does not match the budget value
          </p>
        )}
      </div>

      <div className="inp-container">
        <Controller
          name="notes"
          control={control}
          rules={{
            maxLength: {
              value: 500,
              message: 'Maximum 500 characters',
            },
            validate: {
              allowed: (v) => regExps.notes.test(v) || 'Only Alpha and Numerical characters',
            },
          }}
          render={({ field }) => {
            return (
              <Textarea
                placeholder="Enter notes"
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="Notes"
                $counter
                $low
                $counterMax={500}
                {...field}
              />
            );
          }}
        />
        <p style={{ transform: 'translate(0, -20px)' }} className="error-message">
          {errors?.notes?.message}
        </p>
      </div>
      <div className={generalStyles.pageButtons}>
        <Button.Main disabled={!isDirty} $primary $style="blue" type="submit">
          Save
        </Button.Main>
        <Button.Main
          $primary
          $style="gray"
          type="button"
          onClick={() => {
            glMode?.type !== 'edit' && setGlMode({ type: null, active: false });
            handleRowClick && handleRowClick();
            reset();
          }}
        >
          Discard
        </Button.Main>
      </div>
    </form>
  );
};

export default AddNewGl;
