import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from './UI/General/Dropdown';
import Icon from './UI/General/Icon';
import styles from '../styles/loggedInLayout.module.css';
import Text from './UI/Typography/Text';
import s2pmsEmail from '../services/s2pmsEmail.service';
import moment from 'moment';
import Switch from './UI/Forms/Switch';
import { useStore } from '../store/store';

const Notifications = () => {
  const user = useStore((state) => state.user);
  const [status, setStatus] = useState(false);
  const [data, setData] = useState(undefined);
  const [count, setCount] = useState(0);

  useEffect(() => {
    s2pmsEmail.getAllNotifications(user.id, status).then((r) => {
      if (r.data.message === 'Operation Successful') {
        setData(r.data.data.content);
        if (status === false) {
          setCount(r.data.data.content.length);
        }
      }
    });
  }, [status, user]);

  const Item = ({ data }) => (
    <div
      className={styles.notificationsItem}
      {...(data.read === false && { onClick: () => handleUpdate(data.id) })}
    >
      <div className={styles.notificationsContent}>
        <Link to={data.url}>
          <Text weight={600} type="body-2">
            {data.message.split('SpendPro | ').join('')}
          </Text>
        </Link>
        <Text weight={500} type="body-2" style={{ color: '#ACAEC9', whiteSpace: 'nowrap' }}>
          {moment(data.timestamp).fromNow()}
        </Text>
      </div>
      {data.read === false && (
        <span className={styles.notificationsState}>
          <span />
        </span>
      )}
    </div>
  );

  const handleUpdate = (id) => {
    s2pmsEmail.updateNotification(id).then((r) => {
      setData((state) => state.filter((el) => el.id !== id));
      setCount((state) => state - 1);
    });
  };

  return (
    <Dropdown collapsible>
      <Dropdown.Header className={'test'}>
        <div className={styles.notificationsTop}>
          <Icon $icon="notification-header" $width={20} $height={20} $color="none" />
          {count > 0 && (
            <div className={styles.notificationsCounter}>
              <Text weight={400} type={'body-3'}>
                {count}
              </Text>
            </div>
          )}
        </div>
      </Dropdown.Header>
      <Dropdown.Body>
        <div className={styles.notifications}>
          <div className={styles.notificationsHeader}>
            <Text type="h4" weight={600}>
              Notifications
            </Text>
            <Switch
              label={<Text weight={500}>{!status ? 'Show read only' : 'Show unread only'}</Text>}
              $tooltip={'Show Notifications according to their status'}
              $size={20}
              $reverse
              onClick={() => setStatus((state) => !state)}
              defaultChecked={status}
            />
          </div>
          {data !== undefined && data.length > 0 ? (
            <div className={styles.notificationsList}>
              {data.map((el) => (
                <Item key={el.id} data={el} />
              ))}
            </div>
          ) : null}
        </div>
      </Dropdown.Body>
    </Dropdown>
  );
};

export default Notifications;
