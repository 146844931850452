import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import style from "../../../styles/insights.module.css";
import Text from "../../UI/Typography/Text";
import Select from "react-select";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Label
} from 'recharts';
import s2pmsService from "../../../services/spmsService.service";
import moment from "moment";
import TableNoData from "../../UI/General/TableNoData";
import Box from '../../UI/General/Box';
import { nf } from '../../../utils/nf';
import s2pmsVendorPortal from '../../../services/vendorPortal.service';

const COLORS = ['#3B82F6', '#14B8A6', '#EC4899', '#F59E0B', '#6366F1'];

const ChartCustomers = ({periods, userId, currency}) => {
  const [chartData, setChartData] = useState(null)
  const {handleSubmit, control} = useForm({
    mode: 'onChange',
    defaultValues: {
      period: periods[0],
    }
  });

  const loadData = useCallback((start, end = new Date()) => {
    if (userId) {
      let currentDate = moment();
      let previousMonthDate = currentDate.subtract(start, 'month');
      let search = {
        startDate: previousMonthDate,
        endDate: end
      }
      s2pmsVendorPortal.dashboardTopCustomers(userId, search).then(r => {
        if (r.data.message === "Operation Successful") {
          console.log(r.data.data);
          const customerSpends = r.data.data.customerSpends?.map(el => ({...el, fill: "purple"}));
          let data = {...r.data.data, customerSpends }
          setChartData(data)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }, [userId])

  const onSubmit = (data) => {
    loadData(data?.period?.value)
  }

  useEffect(() => {
    loadData(periods[0].value)
  }, [periods])

  return (
    <Box $mobExtend $asHolder $noOverflow className={style.box}>
      <form className={style.chartBlock}>
        <div className={style.groupHead}>
          <div className={style.groupTitle}>
            <Text type={'subtitle'} weight={500}>Top 10 Customers</Text>
          </div>
          <div className={style.actionsHorizontal}>
            <Controller
              name={'period'}
              control={control}
              render={({ field}) => (
                <Select
                  {...field}
                  className={'react-select-container small'}
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Period"
                  options={periods}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
          </div>
        </div>
        {chartData?.customerSpends && chartData?.customerSpends?.length > 0 ? (
          <div className={style.pieWrapper}>
            <ResponsiveContainer width="100%" aspect={1}>
              <PieChart width={400} height={400}>
                <Pie
                  dataKey="totalOrderValue"
                  data={chartData?.customerSpends}
                  innerRadius={71}
                  outerRadius={95}
                  fill="#8884d8"
                  startAngle={90}
                  endAngle={450}
                >
                  {chartData?.customerSpends.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                  ))}
                  <Label value={nf.format(chartData?.totalOrderValue)} position="centerBottom" fontSize='24px' fill={'#222529'}/>
                  <Label value="Approved Spend" position="centerTop" style={{transform: "translateY(6px)" }} fontSize='12px' fill={'#8C97A7'}/>
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <div className={style.legend}>
              <div className={style.legendHead}>
                <span>BY CUSTOMER</span>
                <span>APPROVED</span>
              </div>
              <div className={style.legendItems}>
                {chartData?.customerSpends?.map((el, index) => (
                  <div className={style.legendRow} key={index}>
                    <div className={style.legendItem}>
                      <span className={style.legendItemBullet} style={{backgroundColor: COLORS[index % COLORS.length] }}/>
                      <span className={style.legendItemText}>{el?.companyName}</span>
                    </div>
                    <span>{currency} {el?.totalOrderValue}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <TableNoData/>
        )}

      </form>
    </Box>
  );
};

export default ChartCustomers;
