import React, { useState, useEffect } from 'react';
import Box from '../../components/UI/General/Box';
import style from '../../styles/vendorPortal.module.css';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store/store';
import Text from '../../components/UI/Typography/Text';
import TabsSlider from '../../components/UI/General/TabsSlider';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import s2pmsVendorPortal from '../../services/vendorPortal.service';

const VendorAccountView = () => {
  const navigate = useNavigate();
  const { externalId: vendorId } = useStore((state) => state.user);
  const [tab, setTab] = useState('profile');
  const [values, setValues] = useState(null)

  useEffect(() => {
    if (!vendorId) return;
    s2pmsVendorPortal.getProfile(vendorId).then(r => {
      if (r.data.message === "Operation Successful"){
        const vendorContacts = r.data.data.vendorContacts?.find(el => el?.contactType === 'ENTERED_BY_VENDOR')
       setValues({ ...r.data.data, vendorContacts })
      }
    }).catch(err => {
      console.log(err);
    })
  }, [vendorId]);
  console.log(values);
  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <div className={generalStyles.top}>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>Profile Overview</Text>
            </div>
            <div className={generalStyles.addItemButton}>
              <Button.Main
                $mid
                $style="blue"
                type="button"
                onClick={() => navigate("/vendor-profile/edit")}
              >
                Edit
              </Button.Main>
            </div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('profile')}>Profile</span>
            <span onClick={() => setTab('documents')}>Documents</span>
          </TabsSlider>
        </div>
        <div>
          {tab === 'profile' && (
            <>
              <div className={style.section + ' ' + generalStyles.underline}>
                <Text type={'subtitle'} weight={500}>Legal</Text>
                <div className={generalStyles.fieldsThree}>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Legal Name <Text weight={400} className={style.valueColor}>{values?.legalName ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Trading Name <Text weight={400} className={style.valueColor}>{values?.tradingName ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Legal Type <Text weight={400} className={style.valueColor}>{values?.legalStructure ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Tax Identification Number <Text weight={400} className={style.valueColor}>{values?.taxIdentificationNumber ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Country of Incorporation <Text weight={400} className={style.valueColor}>{values?.countryOfIncorporation ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Reg Number <Text weight={400} className={style.valueColor}>{values?.registrationNo ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Category <Text weight={400} className={style.valueColor}>{values?.category ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Subcategory <Text weight={400} className={style.valueColor}>{values?.subCategory ?? '-'}</Text></Text>
                </div>
              </div>
              <div className={style.section + ' ' + generalStyles.underline}>
                <Text type={'subtitle'} weight={500}>Contact</Text>
                <div className={generalStyles.fieldsThree}>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Website <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.websiteUrl ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Business Email <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.email ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Business Phone <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.phoneNumber ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Contact Name <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.name ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Contact Email <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.email ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>LinkedIn <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.socialAccounts?.linkedIn ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Facebook <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.socialAccounts?.fb ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Instagram <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.socialAccounts?.instagram ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Twitter <Text weight={400} className={style.valueColor}>{values?.vendorContacts?.socialAccounts?.twitter ?? '-'}</Text></Text>
                </div>
              </div>
              <div className={style.section + ' ' + generalStyles.underline}>
                <Text type={'subtitle'} weight={500}>Address</Text>
                <div className={generalStyles.fieldsThree}>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Address 1 <Text weight={400} className={style.valueColor}>{values?.address?.streetAddress ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Address 2 <Text weight={400} className={style.valueColor}>{values?.address?.addressLine2 ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Address 3 <Text weight={400} className={style.valueColor}>{values?.address?.addressLine3 ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>City/Town <Text weight={400} className={style.valueColor}>{values?.address?.city ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>State/Region <Text weight={400} className={style.valueColor}>{values?.address?.state ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Country <Text weight={400} className={style.valueColor}>{values?.address?.country ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Postal/ZIP Code <Text weight={400} className={style.valueColor}>{values?.address?.zipCode ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Timezone <Text weight={400} className={style.valueColor}>{values?.timezone ?? '-'}</Text></Text>
                </div>
              </div>
              <div className={style.section}>
                <Text type={'subtitle'} weight={500}>Financial</Text>
                <div className={generalStyles.fieldsThree}>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Bank Name <Text weight={400} className={style.valueColor}>{values?.vendorFinancialInfo?.bankName ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Account Name <Text weight={400} className={style.valueColor}>{values?.vendorFinancialInfo?.accountName ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Account Number <Text weight={400} className={style.valueColor}>{values?.vendorFinancialInfo?.accountIban ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Sorting Code <Text weight={400} className={style.valueColor}>{values?.vendorFinancialInfo?.sortCodeRouting ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>SWIFT Code <Text weight={400} className={style.valueColor}>{values?.vendorFinancialInfo?.swiftBic ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Address <Text weight={400} className={style.valueColor}>{values?.vendorFinancialInfo?.bankAddress ?? '-'}</Text></Text>
                  <Text type={'body-2'} weight={600} className={style.sectionItem}>Currency <Text weight={400} className={style.valueColor}>{values?.vendorFinancialInfo?.currency?.code ?? '-'}</Text></Text>
                </div>
              </div>
            </>
          )}
          {tab === 'documents' && (
            <div className={style.attachmentsBlockItem}>
              {!!values?.attachments?.length && <QuotesTab data={values?.attachments} />}
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export default VendorAccountView;
