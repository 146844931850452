import React, { useEffect, useState } from 'react';
import styles from '../../../../styles/requisition.module.css';
import Icon from '../../../UI/General/Icon';
import * as Button from '../../../UI/Forms/Button';
import Input from '../../../UI/Forms/Input';
import Text from '../../../UI/Typography/Text';
import Textarea from '../../../UI/Forms/Textarea';
import { Controller, useWatch } from 'react-hook-form';
import Label from '../../../UI/Forms/Label';
import { regExps } from '../../../../utils/regExps';
import { nf } from '../../../../utils/nf';

const InvoiceItem = ({
  index,
  field,
  control,
  getValues,
  setValue,
  taxes,
  name,
  viewOnly,
  isVendor,
  ...props
}) => {
  const formValues = useWatch({
    name: name,
    control,
  });

  const qtyUnitChange = () => {
    const quantityValue = getValues([`${name}.${index}.quantityInvoiced`]);
    const unitPriceValue = getValues([`${name}.${index}.unitPrice`]);
    const percents = getValues([`${name}.${index}.taxId.value.percentage`]);
    const dv = parseFloat(quantityValue) * parseFloat(unitPriceValue);
    if (!isNaN(dv)) {
      setValue(`${name}.${index}.subtotal`, dv.toFixed(2));
    }
    const subtotalValue = parseFloat(getValues([`${name}.${index}.subtotal`]));
    if (!isNaN(subtotalValue) && percents) {
      setValue(
        `${name}.${index}.taxTotal`,
        parseFloat((percents / 100) * subtotalValue).toFixed(2),
      );
    }
    const taxTotalValue = parseFloat(getValues([`${name}.${index}.taxTotal`]));
    if (!isNaN(taxTotalValue) && !isNaN(subtotalValue)) {
      setValue(`${name}.${index}.totalCost`, parseFloat(subtotalValue + taxTotalValue).toFixed(2));
    }
  };

  const [showNote, setShowNote] = useState(false);
  const [edit, setEdit] = useState(true);

  useEffect(() => {
    if (viewOnly) {
      setEdit(false);
    }
  }, [viewOnly]);

  useEffect(() => {
    if (props.full > 0) {
      setValue(
        `${name}.${index}.quantityInvoiced`,
        parseInt(getValues([`${name}.${index}.receivedQuantity`])),
        { shouldValidate: true, shouldDirty: true },
      );
      qtyUnitChange();
    }
  }, [props.full]);

  console.log(field);

  return (
    <div className={styles.item}>
      <div className={isVendor ? styles.itemInvoiceInner + ' ' + styles.forVendor : styles.itemInvoiceInner}>
        <div className="inp-container">
          <Label className={styles.label} $title="Description" />
          <Text weight={500} type={'body-2'}>
            {field.description}
          </Text>
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="Unit" />
          <Text
            weight={500}
            type={'body-2'}
            title={field?.purchaseOrderItem?.uom ?? field?.uom}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {field?.purchaseOrderItem?.uom ?? field?.uom}
          </Text>
        </div>
        {!isVendor && (
          <div className="inp-container">
            <Label className={styles.label} $title="GL" />
            <Text weight={500} type={'body-3'}>
              {field.glAccount.glCode}
            </Text>
            <Text
              weight={500}
              type={'body-3'}
              title={field.glAccount.glCode}
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {field.glAccount.description}
            </Text>
          </div>
        )}
        <div className="inp-container">
          <Label className={styles.label} $title="Received Quantity" />
          <Text weight={500} type={'body-2'}>
            {field.receivedQuantity}/{field.quantity ?? field.purchaseOrderItem.quantity}
          </Text>
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="Invoiced" />
          {!viewOnly ? (
            <>
              <Input
                $small
                {...props.register(`${name}.${index}.quantityInvoiced`, {
                  valueAsNumber: true,
                  required: {
                    value: true,
                    message: 'Qty Invoiced is required',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum 10 characters',
                  },
                  min: {
                    value: 0,
                    message: 'Min 0',
                  },
                  validate: {
                    allowed: (v) => /^[0-9]*$/.test(v) || 'Numerical values only',
                  },
                  onChange: () => qtyUnitChange(),
                })}
                className={props.errors?.[name]?.[index]?.quantityInvoiced ? 'error' : ''}
                type="text"
                placeholder="Enter Qty Invoiced"
                defaultValue={0}
                disabled={!edit}
              />
              {props.errors?.[name]?.[index]?.quantityInvoiced && (
                <p className="error-message">
                  {props.errors?.[name]?.[index]?.quantityInvoiced?.message}
                </p>
              )}
            </>
          ) : (
            <Text weight={500} type={'body-2'}>
              {field.quantityInvoiced}
            </Text>
          )}
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="Unit price" />
          {!viewOnly ? (
            <>
              <Input
                $small
                {...props.register(`${name}.${index}.unitPrice`, {
                  valueAsNumber: true,
                  required: {
                    value: true,
                    message: 'Unit price is required',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum 10 characters',
                  },
                  min: {
                    value: 0.005,
                    message: 'Min 0.005',
                  },
                  validate: {
                    allowed: (v) => /^[0-9]+([.][0-9]{1,3})?$/.test(v) || 'Max depth 0.000',
                  },
                  onChange: () => qtyUnitChange(),
                })}
                defaultValue=""
                className={props.errors?.[name]?.[index]?.unitPrice ? 'error' : ''}
                type="text"
                placeholder="Enter Unit Price"
                disabled={!edit}
              />
              {props.errors?.[name]?.[index]?.unitPrice && (
                <p className="error-message">{props.errors?.[name]?.[index]?.unitPrice?.message}</p>
              )}
            </>
          ) : (
            <Text weight={500} type={'body-2'}>
              {nf.format(field.unitPrice)}
            </Text>
          )}
        </div>
        <div className="inp-container">
          <Label className={styles.label} $title="Tax" />
          <Text weight={500} type={'body-2'}>
            {field.taxId?.type}
          </Text>
        </div>
        <div>
          <Label className={styles.label} $title="Subtotal" />
          <input
            type="hidden"
            {...props.register(`${name}.${index}.subtotal`, { valueAsNumber: true })}
          />
          <input
            type="hidden"
            {...props.register(`${name}.${index}.taxTotal`, { valueAsNumber: true })}
          />
          <input
            type="hidden"
            {...props.register(`${name}.${index}.totalCost`, { valueAsNumber: true })}
          />
          <input
            type="hidden"
            {...props.register(`${name}.${index}.id`, { value: formValues[index].id })}
          />
          <Text type="body-2" weight={500}>
            {nf.format(formValues[index].subtotal)}
          </Text>
        </div>
        <div className={styles.itemActions}>
          <Button.Action
            $variant="circle"
            $style="lightGrayishBlue"
            $width={32}
            $height={32}
            onClick={() => setShowNote(!showNote)}
            type="button"
          >
            <Icon $width={20} $height={20} $icon="add-note" $color="#45BABC" />
          </Button.Action>
        </div>
      </div>
      {showNote ? (
        <div className={styles.itemInvoiceNote}>
          {!viewOnly ? (
            <div className="inp-container">
              <Controller
                name={`${name}.${index}.itemNotes`}
                control={control}
                rules={{
                  pattern: {
                    value: regExps.notes,
                    message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £",
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    $label="Notes"
                    $low
                    $small
                    placeholder="Notes text goes here"
                    className={props.errors?.[name]?.[index]?.itemNotes && 'error'}
                    disabled={!edit}
                  />
                )}
              />
              {props.errors?.[name]?.[index]?.itemNotes && (
                <p className="error-message">{props.errors?.[name]?.[index]?.itemNotes?.message}</p>
              )}
            </div>
          ) : (
            <>
              <Label $title="Notes" />
              <Text weight={500} type={'body-2'}>
                {field.itemNotes === undefined || field.itemNotes === ''
                  ? 'There is no notes to display'
                  : field.itemNotes}
              </Text>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default InvoiceItem;
