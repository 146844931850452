import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../../styles/vendor.module.css';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import { useEffect } from 'react';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { useStore } from '../../store/store';
import { regExps } from '../../utils/regExps';
import BackButton from '../../components/shared/BackButton';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import Input from '../../components/UI/Forms/Input';
import Select from 'react-select';
import Label from '../../components/UI/Forms/Label';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';

const tierOptions = [
  { label: 'Tier 1', value: 'tier_1' },
  { label: 'Tier 2', value: 'tier_2' },
  { label: 'Tier 3', value: 'tier_3' },
  { label: 'Tier 4', value: 'tier_4' },
];
const invoiceOptions = [
  { label: 'Percentage %', value: 'percentage' },
  { label: 'Amount', value: 'amount' },
];

const PendingDashboard = () => {
  const [tab, setTab] = useState('vendor details');
  const { vendorId, companyId } = useParams();
  const [pendingVendorData, setPendingVendorData] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const vendorContact = pendingVendorData?.vendor?.vendorContacts?.find(
    (contact) => contact.contactType === 'ENTERED_BY_VENDOR',
  );
  const primaryContact = pendingVendorData?.vendor?.vendorContacts?.find(
    (contact) => contact.contactType === 'ENTERED_BY_COMPANY',
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm();
  const accessVendor = useAccessAllowed('Vendor_Management');
  const invoiceType = useWatch({ control, name: 'invoiceThresholdType' });

  const navigate = useNavigate();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const approveVendor = async (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const { attachments, ...rest } = pendingVendorData;
    try {
      const requestBody = {
        ...rest,
        vendorCode: data.vendorCode,
        tier: data.tier.value,
        invoiceThresholdProperties: {
          invoiceThresholdType: data.invoiceThresholdType.value,
          invoiceThresholdValue: data.invoiceThresholdValue,
        },
      };
      await spmsServiceService.editVendor(companyId, vendorId, requestBody);
      await spmsServiceService.approveVendor(companyId, vendorId, {
        approved: true,
      });
      setToast({
        opened: true,
        message: 'Approved',
        type: 'success',
        cb: () => {
          navigate('/approve/vendor');
          setIsButtonsBlocked(false);
        },
      });
    } catch (err) {
      setIsButtonsBlocked(false);
      setToast({
        opened: true,
        message: err.response.data.message || 'Something Went Wrong',
        type: 'fail',
      });
    }
  };

  const denyVendor = () => {
    spmsServiceService.denyVendor(companyId, vendorId).then((_res) => {
      setToast({
        opened: true,
        message: 'Vendor denied successfully',
        type: 'success',
        cb: () => navigate('/approve/vendor'),
      });
    });
  };

  useEffect(() => {
    spmsServiceService
      .getCompanyVendorById(companyId, vendorId)
      .then((res) => {
        setPendingVendorData(res.data.data);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message ? err.response.data.message : 'Something Went Wrong',
          type: 'fail',
        });
      });
  }, [vendorId]);

  // const isButtonVisible = () => {
  //   if (!pendingVendorData.approval) return;
  //   const userApproval = pendingVendorData.approval.approvers.find(
  //     (approver) => approver.userId === user.id,
  //   );
  //   return userApproval.status === 'PENDING_APPROVAL';
  // };

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div>
            <BackButton />
          </div>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>
                {`${pendingVendorData?.vendor?.legalName} (${readableTitleFromBackend(
                  pendingVendorData?.approvalStatus,
                )})`}
              </Text>
              <Text type="body-1" weight={500}>
                {`${pendingVendorData?.vendor?.category} - ${pendingVendorData?.vendor?.subCategory}`}
              </Text>
            </div>
            <div className={generalStyles.addItemButton}>
              {accessVendor?.approve && (
                <>
                  <Button.Main
                    $mid
                    $style="green"
                    type="submit"
                    form="requisitionForm"
                    disabled={isButtonsBlocked}
                    onClick={() => {
                      setTab('vendor details');
                      handleSubmit(approveVendor)();
                    }}
                  >
                    Approve
                  </Button.Main>
                  <Button.Main
                    $mid
                    $style="red"
                    disabled={isButtonsBlocked}
                    type="submit"
                    form="requisitionForm"
                    onClick={denyVendor}
                  >
                    Deny
                  </Button.Main>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('vendor details')}>Vendor Details</span>
            <span onClick={() => setTab('request details')}>Request Details</span>
            <span onClick={() => setTab('documents')}>Documents</span>
            <span onClick={() => setTab('approval')}>Approval</span>
          </TabsSlider>
        </div>
        {tab === 'request details' && pendingVendorData && (
          <div className={styles.vendorDetails}>
            <div className={`${styles.inReviewInfo} `}>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Requestor
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.requester?.name}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Location
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.locations[0]?.name}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Department
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.departments[0]?.name}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Products & Services
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.purchaseDescription}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Benefits vendor offers
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.benefitsFromVendor}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    NDA signed
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.ndaSigned ? 'Yes' : 'No'}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === 'vendor details' && pendingVendorData && (
          <div className={styles.vendorDetails}>
            <div className={`${styles.inReviewInfo} ${generalStyles.underline}`}>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Legal Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.legalName}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Trading Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.tradingName ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Legal Structure
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.legalStructure ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Tax Identification Number
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.taxIdentificationNumber ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Country of Incorporation
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.countryOfIncorporation}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Registration Number
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.registrationNo ?? '-'}
                  </Text>
                </div>
                <div className="inp-container">
                  <Label $title="Tier" $isRequired />
                  <Controller
                    name="tier"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Tier is required',
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={
                          errors.hasOwnProperty(field.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                        classNamePrefix="react-select"
                        isSearchable={false}
                        placeholder="Select Tier"
                        options={tierOptions}
                      />
                    )}
                  />
                  {errors.tier && <p className="error-message">{errors.tier?.message}</p>}
                </div>
                <div className="inp-container">
                  <Controller
                    name="vendorCode"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: 'Code is required',
                      },
                      maxLength: {
                        value: 30,
                        message: 'Maximum 30 characters',
                      },
                      validate: {
                        allowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid Code',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        type="text"
                        placeholder="Enter Vendor Code"
                        $label="Vendor Code"
                        $labelRequired
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        {...field}
                      />
                    )}
                  />
                  {errors.vendorCode && (
                    <p className="error-message">{errors.vendorCode?.message}</p>
                  )}
                </div>
                <div>
                  <Label $title="Invoice Threshold" $isRequired />
                  <div className={generalStyles.fieldsTwo}>
                    <div className="inp-container">
                      <Controller
                        name="invoiceThresholdType"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Invoice type is required',
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className={
                              errors.hasOwnProperty(field.name)
                                ? 'react-select-container error'
                                : 'react-select-container'
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors('invoiceThresholdValue');
                              setValue('invoiceThresholdValue', undefined);
                            }}
                            classNamePrefix="react-select"
                            isSearchable={false}
                            placeholder="Select Invoice"
                            options={invoiceOptions}
                          />
                        )}
                      />
                      {errors.invoiceThresholdType && (
                        <p className="error-message">{errors.invoiceThresholdType?.message}</p>
                      )}
                    </div>
                    <div className="inp-container">
                      <Controller
                        name="invoiceThresholdValue"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: 'Invoice value is required',
                          },
                          maxLength: {
                            value: invoiceType?.value === 'percentage' ? 3 : 10,
                            message: `Maximum ${
                              invoiceType?.value === 'percentage' ? '3' : '10'
                            } characters`,
                          },
                          validate: {
                            allowed: (v) =>
                              invoiceType?.value === 'percentage'
                                ? (/^[0-9]+$/.test(v) && parseInt(v)) <= 100 ||
                                  'Cannot be more that 100'
                                : /^[0-9]+$/.test(v) || 'Numerical chearacters only',
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            type="text"
                            placeholder="Enter Invoice"
                            className={errors.hasOwnProperty(field.name) && 'error'}
                            {...field}
                          />
                        )}
                      />
                      {errors.invoiceThresholdValue && (
                        <p className="error-message">{errors.invoiceThresholdValue?.message}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.inReviewInfo} ${generalStyles.underline}`}>
              <Text type="subtitle" weight={500}>
                Payment Information
              </Text>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Bank Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor
                      ? pendingVendorData.vendor.vendorFinancialInfo.bankName
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Account Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor
                      ? pendingVendorData.vendor.vendorFinancialInfo.accountName
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Account Number/IBAN
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor
                      ? pendingVendorData.vendor.vendorFinancialInfo.accountIban
                      : '-'}
                  </Text>
                </div>

                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Account Currency
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor
                      ? pendingVendorData.vendor.vendorFinancialInfo.currency.code
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Sortcode/Routing
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor
                      ? pendingVendorData.vendor.vendorFinancialInfo.sortCodeRouting
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    SWIFT Code
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor
                      ? pendingVendorData.vendor.vendorFinancialInfo.swiftBic
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Bank Address
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor
                      ? pendingVendorData.vendor.vendorFinancialInfo.bankAddress
                      : '-'}
                  </Text>
                </div>
              </div>
            </div>
            <div className={`${styles.inReviewInfo} ${generalStyles.underline}`}>
              <Text type="subtitle" weight={500}>
                Contact Information
              </Text>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Company Email
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {primaryContact.email ? primaryContact.email : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Contact Name
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {primaryContact.name ? primaryContact.name : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Web URL
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.websiteUrl ? vendorContact?.websiteUrl : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Contact Email
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.email}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Contact Phone
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.phoneNumber}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    LinkedIn
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.socialAccounts?.linkedIn
                      ? vendorContact?.socialAccounts?.linkedIn
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Facebook
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.socialAccounts?.fb ? vendorContact?.socialAccounts?.fb : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Instagram
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.socialAccounts?.instagram
                      ? vendorContact?.socialAccounts?.instagram
                      : '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    (X) twitter
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {vendorContact?.socialAccounts?.twitter
                      ? vendorContact?.socialAccounts?.twitter
                      : '-'}
                  </Text>
                </div>
              </div>
            </div>
            <div className={`${styles.inReviewInfo}`}>
              <Text type="subtitle" weight={500}>
                Registered Address
              </Text>
              <div className={generalStyles.fieldsThree}>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Address 1
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.address?.addressLine2}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Address 2
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.address?.streetAddress}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Address 3
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.address?.addressLine3 ?? '-'}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    City/Town
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.address?.city}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    State/Region
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.address?.state}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Country
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.address?.country}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    ZIP/Postal Code
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.address?.zipCode}
                  </Text>
                </div>
                <div className={styles.inReviewInfoItem}>
                  <Text weight={500} type="body-2">
                    Timezone
                  </Text>
                  <Text style={{ color: '#8A8C8F' }} weight={400} type="body-2">
                    {pendingVendorData?.vendor?.timezone}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === 'documents' && pendingVendorData && (
          <QuotesTab
            data={[...pendingVendorData.attachments, ...pendingVendorData.vendor.attachments]}
          />
        )}
        {tab === 'approval' && pendingVendorData && (
          <ApprovalTab data={pendingVendorData.approval.approvers} />
        )}
      </Box>
      {toast.opened && (
        <Toast type={toast.type} message={toast.message} opened={toast.opened} cb={toast.cb} />
      )}
    </>
  );
};

export default PendingDashboard;
