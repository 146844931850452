import React from 'react';
import { Controller } from 'react-hook-form';
import Textarea from '../../../UI/Forms/Textarea';
import Documents from '../../../UI/Forms/Documents';
import styles from '../../../../styles/purchaseOrders.module.css';
import generalStyles from '../../../../styles/general.module.css';
import TabsSlider from '../../../UI/General/TabsSlider';
import { regExps } from '../../../../utils/regExps';

const Docs = ({
  errors,
  control,
  nameNotes,
  nameFiles,
  labelNotes,
  labelFiles,
  tab,
  setTab,
  clearErrors,
  requiredRef,
}) => {
  const files = (files) =>
    files.map((file) => ({
      attachment: file,
      name: file.name,
    }));

  return (
    <>
      <TabsSlider selected={tab} $small>
        <span onClick={() => setTab('Notes')}>{labelNotes}</span>
        <span onClick={() => setTab('Documents')}>{labelFiles}</span>
      </TabsSlider>
      <div className={styles.attachmentsBlock}>
        {tab === 'Notes' && (
          <>
            <div className="inp-container">
              <Controller
                name={nameNotes}
                control={control}
                rules={{
                  pattern: {
                    value: regExps.notes,
                    message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £",
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    $low
                    $counter
                    $counterMax={500}
                    placeholder="Notes text goes here"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                  />
                )}
              />
              {errors[nameNotes] && <p className="error-message">{errors[nameNotes]?.message}</p>}
            </div>
          </>
        )}
        {tab === 'Documents' && (
          <div className="inp-container">
            <Documents
              control={control}
              fieldName={nameFiles}
              filesSchema={files}
              error={errors?.[nameFiles]?.message || errors?.[nameFiles]?.root?.message}
              required={requiredRef.current}
              clearErrors={clearErrors}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Docs;
