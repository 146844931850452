import DataTableBase from '../../components/UI/General/DataTableBase';
import styles from '../../styles/budgets.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text';
import AddNewGl from './AddNewGl';

const ExpandedComponent = ({ data, monthsToRender }) => {
  return (
    <div className={styles.expandedGlContainer}>
      <div className={generalStyles.fieldsSix}>
        {monthsToRender?.map((month, index) => (
          <div className={styles.expandedGlLine} key={index}>
            <Text weight={600}>{month}:</Text>
            <Text weight={500}>{data[month?.toLowerCase()] || '-'}</Text>
          </div>
        ))}
      </div>
      <div className={styles.expandedGlLine}>
        <Text weight={600}>Notes:</Text>
        <Text weight={500}>{data.notes || '-'}</Text>
      </div>
    </div>
  );
};

const GlListView = ({
  companyId,
  budgetId,
  monthsToRender,
  updateMonthFields,
  setGlMode,
  glMode,
  setToast,
  glItems,
  setEditableGl,
  editableGl,
  columns,
  handleGlLine,
  currency,
}) => {
  const handleRowClick = (_row) => {
    updateMonthFields();
    setEditableGl(null);
    setGlMode({ type: null, active: false });
  };
  return (
    <DataTableBase
      data={glItems || []}
      columns={columns}
      expandOnRowClicked
      expandableRows
      onRowClicked={handleRowClick}
      expandableRowExpanded={(row) => !!editableGl}
      expandableRowsComponentProps={
        glMode?.type === 'edit'
          ? {
              companyId,
              setGlMode,
              budgetId,
              setToast,
              monthsToRender,
              updateMonthFields,
              glMode,
              editableGl,
              handleRowClick,
              handleGlLine,
              glItems,
              currency,
            }
          : {
              monthsToRender,
            }
      }
      expandableRowsComponent={glMode?.type === 'edit' ? AddNewGl : ExpandedComponent}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 15, 20, 30]}
    />
  );
};

export default GlListView;
