import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const StyledContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const StyledInput = styled.input`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  min-width: 228px;
  /* padding: 0px 10px; */
  font-family: 'BDO', sans-serif;
  font-weight: 400;
  font-size: ${({ $small }) => ($small ? '12px' : '14px')};
  line-height: 1.4em;
  color: #0c0c0c;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  outline: none;
  &:disabled {
    background-color: #f4f4f4;
    border: 1px solid #e2e3e4;
  }
  &::placeholder {
    color: #0000004d;
  }
  &:focus,
  &:focus-visible {
    border-color: #145ff5;
  }
  &.success {
    border-color: #69d08f;
  }
  &.error {
    border-color: #cd3e27;
    background: #f7565626 !important;
  }
  @media (min-width: 768px) {
    height: ${({ $small }) => ($small ? '40px' : '50px')};
    /* padding: ${({ $small }) => ($small ? '4px 10px' : '4px 20px')}; */
    /* font-size: ${({ $small }) => ($small ? '12px' : '16px')}; */
  }
`;

const Search = forwardRef((props, ref) => {
  return (
    <StyledContainer>
      <Icon $color={'#fff'} $height={24} $width={24} $icon={'search'} />
      <StyledInput {...props} ref={ref} />
    </StyledContainer>
  );
});

export default Search;
