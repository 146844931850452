import React from 'react';
import { Route } from 'react-router-dom';
import VendorOrders from '../pages/VendorProfile/VendorOrders';
import OrderDetailedView from '../pages/VendorProfile/OrderDetailedView';
import VendorInvoice from '../pages/VendorProfile/VendorInvoice';
import VendorClients from '../pages/VendorProfile/VendorClients';
import VendorCatalog from '../pages/VendorProfile/VendorCatalog';
import VendorCatalogAdd from '../pages/VendorProfile/VendorCatalogAdd';
import VendorAccountView from '../pages/VendorProfile/VendorAccountView';
import VendorAccountEdit from '../pages/VendorProfile/VendorAccountEdit';

export const vendorRoutes =
  <>
    <Route path="/vendor-orders" element={<VendorOrders/>}/>
    <Route path="/vendor-orders/order/:orderId" element={<OrderDetailedView/>}/>
    <Route path="/vendor-invoices" element={<VendorInvoice/>}/>
    <Route path="/vendor-clients" element={<VendorClients/>}/>
    <Route path="/vendor-catalog" element={<VendorCatalog/>}/>
    <Route path="/vendor-catalog/add" element={<VendorCatalogAdd/>}/>
    <Route path="/vendor-catalog/edit/:catalogItemId" element={<VendorCatalogAdd/>}/>
    <Route path="/vendor-profile" element={<VendorAccountView/>}/>
    <Route path="/vendor-profile/edit" element={<VendorAccountEdit/>}/>
  </>;
