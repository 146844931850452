import React  from 'react';
import style from '../../../styles/integrations.module.css';
import generalStyles from "../../../styles/general.module.css";
import Text from '../../../components/UI/Typography/Text';

const IntegrationView = ({values}) => {
  return (
    <div className={style.tabContainer}>
      <div>
        <div className={generalStyles.fieldsThree}>
          <Text type={'body-2'} weight={600} className={style.sectionItem}>Login username <Text weight={400} className={style.valueColor}>{values?.username ?? '-'}</Text></Text>
          <Text type={'body-2'} weight={600} className={style.sectionItem}>Login password <Text weight={400} className={style.valueColor}>{values?.password ?? '-'}</Text></Text>
          <Text type={'body-2'} weight={600} className={style.sectionItem}>DynamicSQL base url<Text weight={400} className={style.valueColor}>{values?.dynamicSql ?? '-'}</Text></Text>
          <Text type={'body-2'} weight={600} className={style.sectionItem}>Integration company <Text weight={400} className={style.valueColor}>{values?.integrationCompany?.value ?? '-'}</Text></Text>
          <Text type={'body-2'} weight={600} className={style.sectionItem}>Integration option <Text weight={400} className={style.valueColor}>{values?.integrationOption?.value ?? '-'}</Text></Text>
          <Text type={'body-2'} weight={600} className={style.sectionItem}>Base URL - Vendors <Text weight={400} className={style.valueColor}>{values?.vendorUrl ?? '-'}</Text></Text>
          {!!values?.poUrl && <Text type={'body-2'} weight={600} className={style.sectionItem}>Base URL - PO <Text weight={400} className={style.valueColor}>{values?.poUrl ?? '-'}</Text></Text>}
          <Text type={'body-2'} weight={600} className={style.sectionItem}>Base URL - GL <Text weight={400} className={style.valueColor}>{values?.glUrl ?? '-'}</Text></Text>
          <Text type={'body-2'} weight={600} className={style.sectionItem}>Base URL - AP <Text weight={400} className={style.valueColor}>{values?.apUrl ?? '-'}</Text></Text>
        </div>
      </div>
    </div>
  );
};

export default IntegrationView;
