import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import styles from '../../styles/companySetup.module.css';
import * as Button from '../../components/UI/Forms/Button';
import spmsServiceService from '../../services/spmsService.service';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import { useStore } from '../../store/store';
import TabsSlider from '../../components/UI/General/TabsSlider';
import DataTableBase from '../../components/UI/General/DataTableBase';
import Box from '../../components/UI/General/Box';
import Toast from '../../components/UI/General/Toast';
import AddButton from '../../components/shared/AddButton';
import moment from 'moment';

const Taxes = () => {
  const { id } = useStore((state) => state.activeCompany);
  const [taxes, setTaxes] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        cell: (row) => (
          <span data-content={'Name'} className={generalStyles.tableValue}>
            <span>{row?.type}</span>
          </span>
        ),
        sortable: true,
      },
      {
        name: 'Country',
        cell: (row) => (
          <span data-content={'Country'} className={generalStyles.tableValue}>
            <span>{row?.countryName}</span>
          </span>
        ),
        sortable: true,
      },
      {
        name: 'Region',
        cell: (row) => (
          <span data-content={'Region'} className={generalStyles.tableValue}>
            <span>{row?.state}</span>
          </span>
        ),
      },

      // {
      //   name: 'Created Date',
      //   cell: (row) => (
      //     <span data-content={'Date'} className={generalStyles.tableValue}>
      //       <span>{moment(row.createdAt).format('DD/MM/YYYY')}</span>
      //     </span>
      //   ),
      // },
      {
        name: 'Rate %',
        cell: (row) => (
          <span data-content={'Rate %'} className={generalStyles.tableValue}>
            <span>{row?.percentage + '%'}</span>
          </span>
        ),
        sortable: true,
      },
      {
        name: 'Status',
        cell: (row) => (
          <span data-content={'Status'} className={generalStyles.tableValue}>
            <span>{row?.active ? 'Active' : 'Archived'}</span>
          </span>
        ),
        sortable: true,
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {row.active && (
                    <>
                      <Link to={'/taxes/edit/' + row.id}>
                        <MenuItem>Edit</MenuItem>
                      </Link>
                      <MenuItem onClick={() => archiveTax(row.id)}>Archive</MenuItem>
                    </>
                  )}
                  {!row.active && <MenuItem onClick={() => activateTax(row.id)}>Activate</MenuItem>}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [],
  );

  const archiveTax = (taxId) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .archiveTax(id, taxId)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Tax archived successfully',
          type: 'success',
          cb: () => setRefetch((refetch) => !refetch),
        });
      })
      .catch((err) => {
        console.log(err);
        setToast({
          opened: true,
          message: err?.message,
          type: 'fail',
        });
      });
  };

  const activateTax = (taxId) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .activateTax(id, taxId)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Tax activated successfully',
          type: 'success',
          cb: () => setRefetch((refetch) => !refetch),
        });
      })
      .catch((err) => {
        console.log(err);
        setToast({
          opened: true,
          message: err?.message,
          type: 'fail',
        });
      });
  };

  useEffect(() => {
    spmsServiceService.getTaxList(id).then((res) => {
      if (res.data.status === 'success') {
        setTaxes(res.data.data);
      } else {
        setToast({
          opened: true,
          message: res.data.message,
          type: 'fail',
        });
      }
    });
  }, [id, refetch]);

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={styles.title}>
          <Text type="subtitle" weight={500}>
            Taxes
          </Text>
        </div>
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}></div>
          <div className={generalStyles.addItemButton}>
            <AddButton $click={() => navigate('/taxes/add')} />
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBase
            data={taxes}
            columns={columns}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 50]}
          />
        </Suspense>
      </Box>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Taxes;
