import React, { memo } from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import reqStyles from '../../../../styles/requisition.module.css';

import Text from '../../../UI/Typography/Text';
import { useLocation } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import SelectBudgets from '../../../shared/SelectBudgets';

const Top = memo(({ data, errors, control }) => {
  const location = useLocation();

  return (
    <div className={styles.top}>
      <div className={styles.topCol}>
        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Requester
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={400} type="body-2">
              {data?.requester?.name}
            </Text>
          </div>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Category
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={400} type="body-2">
              {data?.category}
            </Text>
          </div>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Description
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={400} type="body-2">
              {data.title}
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.topCol}>
        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Location
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={500} type="body-2">
              {data?.location?.name}
            </Text>
          </div>
        </div>

        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Sub Category
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={500} type="body-2">
              {data?.subCategory}
            </Text>
          </div>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Required By
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={500} type="body-2">
              {data?.deliveryDate}
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.topCol}>
        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Department
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={500} type="body-2">
              {data?.department?.name}
            </Text>
          </div>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Vendor
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={500} type="body-2">
              {data?.vendor?.legalName}
            </Text>
          </div>
        </div>
        <div className={styles.topRow}>
          <Text weight={600} type="body-2">
            Budget
          </Text>
          <div className={reqStyles.requisitionValue}>
            <Text weight={500} type="body-2">
              {data?.budget?.name}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Top;
