import React, { useEffect, useMemo, useRef, useState } from 'react';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Text from '../../components/UI/Typography/Text';
import Box from '../../components/UI/General/Box';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import DatePicker from 'react-datepicker';
import Item from '../../components/Admins/Requisition/Item';
import styles from '../../styles/requisition.module.css';
import Documents from '../../components/UI/Forms/Documents';
import moment from 'moment';
import spmsServiceService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import getExchangeRate from '../../services/getExchangeRate';
import { regExps } from '../../utils/regExps';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import SelectUsers from '../../components/shared/SelectUsers';
import SelectLocations from '../../components/shared/SelectLocations';
import SelectDepartments from '../../components/shared/SelectDepartments';
import SelectVendors from '../../components/shared/SelectVendors';
import SelectBudgets from '../../components/shared/SelectBudgets';
import stylesPo from '../../styles/purchaseOrders.module.css';
import Select from 'react-select';
import AddButton from '../../components/shared/AddButton';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import BackButton from '../../components/shared/BackButton';
import Modal from '../../components/UI/Modal/Modal';
import Textarea from '../../components/UI/Forms/Textarea';

const RequisitionCreate = () => {
  const navigate = useNavigate();
  const { requisitionId } = useParams();
  const user = useStore((state) => state.user);
  const activeCompany = useStore((state) => state.activeCompany);
  const [values, setValues] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const [allVendorsUncut, setAllVendorsUncut] = useState([]);
  const [vendorCurrency, setVendorCurrency] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [updateAfterDeleteFile, setUpdateAfterDeleteFile] = useState(0);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false);
  const accessSubscription = useSubscriptionAllowed();
  const itemDefault = {
    id: null,
    requisitionId: null,
    description: '',
    generalLedgerId: '',
    taxId: '',
    quantity: 0,
    unitPrice: 0,
    subtotal: 0,
    taxTotal: 0,
    totalCost: 0,
    itemNotes: '',
  };

  const requisitionDefault = {
    requisitionState: '',
    title: '',
    companyId: { label: activeCompany.name, value: activeCompany.id },
    locationId: '',
    departmentId: '',
    requester: '',
    vendorId: '',
    currency: '',
    approver: '',
    requisitionItemDTOS: [itemDefault],
    requisitionAttachments: [],
    deliveryDate: '',
    requestNotes: '',
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: requisitionDefault,
    values,
  });

  const { fields, append, remove } = useFieldArray({
    name: 'requisitionItemDTOS',
    control,
  });
  const formValues = useWatch({ name: 'requisitionItemDTOS', control });
  const companyFormValues = useWatch({ name: 'companyId', control });
  const vendorWatcher = useWatch({ control, name: 'vendorId' });
  // const isNewVendor = vendorWatcher?.value === 'new';
  const categoryWatcher = useWatch({ control, name: 'category' });
  const subCategoryWatcher = useWatch({ control, name: 'subcategory' });

  const subtotal = useMemo(() => {
    return formValues
      ? formValues.reduce((acc, current) => acc + (parseFloat(current.subtotal) || 0), 0)
      : 0;
  }, [formValues]);
  const taxTotal = useMemo(() => {
    return formValues
      ? formValues.reduce((acc, current) => acc + (parseFloat(current.taxTotal) || 0), 0)
      : 0;
  }, [formValues]);
  const totalCost = useMemo(() => {
    return formValues
      ? formValues.reduce((acc, current) => acc + (parseFloat(current.totalCost) || 0), 0)
      : 0;
  }, [formValues]);

  useEffect(() => {
    spmsServiceService
      .getVendorCategories()
      .then((res) => {
        const categories = res.data.data;
        if (!res?.data?.data?.length) {
          setVendorCategories([]);
          return;
        }
        setVendorCategories(categories);
      })
      .catch((err) => {
        setVendorCategories([]);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        spmsServiceService
          .getVendors(activeCompany?.id, { vendorStatus: 'APPROVED', active: true }, 0, 1000)
          .then((r) => {
            if (r.data.message === 'Operation Successful') {
              setAllVendorsUncut(r.data.data.content);
            }
          })
          .catch((err) => {
            console.log(err);
          }),
        spmsServiceService
          .getTaxList(activeCompany?.id)
          .then((r) => {
            const temp = r.data.data
              .filter((item) => item.active === true)
              .map((item) => ({
                value: { percentage: item.percentage, id: item.id },
                type: item.type,
              }));
            temp.splice(0, 0, {
              value: { id: null, percentage: 0 },
              type: 'No tax',
            });
            setTaxes(temp);
          })
          .catch((err) => {
            console.log(err);
          }),
      ]);
    };
    if (activeCompany !== null) {
      fetchData();
    }
  }, [activeCompany]);

  useEffect(() => {
    setValue('requester', {
      label: user.firstName + ' ' + user.lastName,
      value: user.username,
      userId: user.id,
    });
    const userCompany = user.customCompanies.find(
      (company) => company.companyId === activeCompany.id,
    );
    if (userCompany && !requisitionId) {
      if (userCompany.locationId) {
        spmsServiceService
          .getLocation(userCompany.companyId, userCompany.locationId)
          .then((res) => {
            const location = res.data.data;
            setValue('locationId', { label: location.name, value: location.id });
          });
      }
      if (userCompany.departmentId) {
        spmsServiceService
          .getDepartment(userCompany.companyId, userCompany.departmentId)
          .then((res) => {
            const department = res.data;
            setValue('departmentId', { label: department.name, value: department.id });
          });
      }
    }
    setValue('companyId', { label: activeCompany.name, value: activeCompany.id });
    if (companyFormValues?.value !== activeCompany?.id) {
      if (formValues?.length > 0) {
        for (let i = 0; i < formValues.length; i++) {
          setValue(`requisitionItemDTOS.${i}.generalLedgerId`, '');
        }
      }
    }
  }, [activeCompany]);

  useEffect(() => {
    if (!vendorWatcher?.value || requisitionId) return;
    for (let i = 0; i < formValues.length; i++) {
      setValue(`requisitionItemDTOS.${i}.vendorCode`, vendorWatcher.vendorCode);
    }
  }, [vendorWatcher, formValues.length]);

  useEffect(() => {
    const fetchRequisition = async () => {
      await spmsServiceService.getRequisition(requisitionId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data);
          const {
            requisitionId,
            deliveryDate,
            department,
            location,
            company,
            currency,
            requisitionItems,
            vendor,
            requester,
            subCategory,
            budget,
            ...other
          } = r.data.data;
          let editRequisitionItems;
          if (requisitionItems !== undefined) {
            if (requisitionItems.length > 0) {
              editRequisitionItems = requisitionItems.map((item) => ({
                ...item,
                generalLedgerId: {
                  label: item.glAccount.glCode,
                  value: item.glAccount.id,
                  text: item.glAccount.description,
                },
                unitType: {
                  label: item.uom,
                  value: item.uom,
                },
                taxId: taxes?.filter((el) => el.value.id === (item?.tax?.id || null))[0],
              }));
            }
          }
          const reqSubCategory = vendorCategories.find((cat) => cat.subCategory === subCategory);
          const editValues = {
            ...other,
            companyId: { label: company.name, value: company.id },
            locationId: { label: location.name, value: location.id },
            departmentId: { label: department.name, value: department.id },
            requester: {
              label: requester.name,
              value: requester.email,
              userId: requester.userId,
            },
            budget: budget ? { label: budget.name, value: budget.id } : undefined,
            vendorId: { label: vendor.legalName, value: vendor.id, currency: currency.code },
            requisitionItemDTOS: editRequisitionItems,
            deliveryDate:
              deliveryDate !== undefined ? moment(deliveryDate, 'DD/MM/YYYY').toDate() : '',
            category: { label: reqSubCategory.category, value: reqSubCategory.category },
            subcategory: { label: reqSubCategory.subCategory, value: reqSubCategory.subCategory },
          };
          setValues(editValues);
          const vendorCurrency = allVendorsUncut.find(
            (item) => item?.vendor?.id === vendor.id,
          )?.currency;
          setVendorCurrency(vendorCurrency);
        }
      });
    };
    if (requisitionId && !!vendorCategories.length) {
      fetchRequisition();
    }
  }, [requisitionId, taxes, allVendorsUncut, updateAfterDeleteFile, vendorCategories]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data, formType) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));

    requiredRef.current = false;
    const newRequisitionItemDTOS =
      data.requisitionItemDTOS !== undefined && data.requisitionItemDTOS.length > 0
        ? data.requisitionItemDTOS.map((item) => ({
            ...item,
            generalLedgerId: item.generalLedgerId.value,
            taxId: item.taxId.value.id,
            uom: item.unitType.value,
          }))
        : [];
    let newPurchaseOrderAttachments = [];
    if (data.requisitionAttachments !== undefined && data.requisitionAttachments.length > 0) {
      const files = data.requisitionAttachments.map(async (item) => ({
        name: item.name.split(' ').join('-'),
        document: await getBase64(item.attachment),
      }));
      newPurchaseOrderAttachments = await Promise.all(files).then((result) => result);
    }
    if (
      (!newPurchaseOrderAttachments || !newPurchaseOrderAttachments?.length) &&
      !values?.attachments.length &&
      formType === 'PENDING_APPROVAL'
    ) {
      setIsButtonsBlocked(false);
      requiredRef.current = true;
      setError('requisitionAttachments', { type: 'focus', message: 'Files required' });
      return;
    }
    const exchangeRate = await getExchangeRate(
      data.vendorId.currency,
      activeCompany.defaultCurrency,
      1,
    );

    const newData = {
      requisitionState: !!data.requisitionState ? data.requisitionState : 'DRAFT',
      title: data.title,
      companyId: data.companyId.value,
      locationId: data.locationId.value,
      budget: { id: data.budget.value },
      departmentId: data.departmentId.value,
      requester: {
        userId: data.requester.userId,
        email: data.requester.value,
        name: data.requester.label,
      },
      currency: {
        code: data.vendorId.currency,
        rate: exchangeRate,
      },
      attachments: newPurchaseOrderAttachments,
      deliveryDate: moment(data.deliveryDate).format('DD/MM/YYYY'),
      vendorId: data.vendorId.value,
      requestNotes: data.requestNotes,
      category: data.category.value,
      subCategory: data.subcategory.value,
      requisitionItems: newRequisitionItemDTOS,
    };
    if (requisitionId) {
      await spmsServiceService
        .updateRequisition(requisitionId, newData)
        .then((r) => {
          console.log(r);
          if (
            formType === 'PENDING_APPROVAL' &&
            newRequisitionItemDTOS.length > 0 &&
            requisitionId !== undefined
          ) {
            spmsServiceService
              .submitForApprovalRequisition(requisitionId)
              .then((r) => {
                setToast({
                  opened: true,
                  message: 'Requisition submitted for approval successfully',
                  type: 'success',
                  cb: () => navigate('/requisitions'),
                });
              })
              .catch((reason) => {
                setIsButtonsBlocked(false);
                setToast({
                  opened: true,
                  message:
                    reason.response.data.message !== undefined
                      ? reason.response.data.message
                      : reason.response.data.error,
                  type: 'fail',
                });
              });
          } else {
            setToast({
              opened: true,
              message:
                values?.requisitionState === 'REJECTED'
                  ? 'This requisition will now be available in draft'
                  : 'Requisition updated successfully',
              type: 'success',
              cb: () => navigate('/requisitions'),
            });
          }
        })
        .catch((reason) => {
          console.log(reason);
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.error,
            type: 'fail',
          });
        });
    } else {
      await spmsServiceService
        .createRequisition(newData)
        .then((r) => {
          console.log(r);
          if (r.data.message === 'Operation Successful') {
            if (
              formType === 'PENDING_APPROVAL' &&
              newRequisitionItemDTOS.length > 0 &&
              r.data.data.id !== undefined
            ) {
              spmsServiceService
                .submitForApprovalRequisition(r.data.data.id)
                .then((r) => {
                  setToast({
                    opened: true,
                    message: 'Requisition submitted for approval successfully',
                    type: 'success',
                    cb: () => navigate('/requisitions'),
                  });
                })
                .catch((reason) => {
                  setIsButtonsBlocked(false);
                  setToast({
                    opened: true,
                    message:
                      reason.response.data.message !== undefined
                        ? reason.response.data.message
                        : reason.response.data.error,
                    type: 'fail',
                  });
                });
            } else {
              setToast({
                opened: true,
                message: 'Requisition created successfully',
                type: 'success',
                cb: () => navigate('/requisitions'),
              });
            }
          }
        })
        .catch((reason) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.error,
            type: 'fail',
          });
        });
    }
  };

  const files = (files) =>
    files.map((file) => ({
      attachment: file,
      name: file.name,
    }));

  const deleteDocument = (key) => {
    spmsServiceService
      .deleteDocument('requisitions', requisitionId, key)
      .then(() => {
        setToast({
          opened: true,
          message: 'Document has been deleted',
          type: 'success',
          cb: () => setToast((item) => ({ ...item, opened: false })),
        });
        setUpdateAfterDeleteFile((state) => state + 1);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
          cb: () => setToast((item) => ({ ...item, opened: false })),
        });
      });
  };

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <div className={generalStyles.top}>
          <div>
            <BackButton />
          </div>
          <div className={generalStyles.title}>
            <Text type="subtitle" weight={500}>
              {!!requisitionId ? 'Edit' : 'Create'} purchase request
            </Text>
            <div className={generalStyles.addItemButton}>
              <Button.Main
                $mid
                $style="blue"
                type="submit"
                form="reqForm"
                onClick={handleSubmit((data) => onSubmit(data, 'DRAFT'))}
                disabled={
                  !isDirty ||
                  !isValid ||
                  isButtonsBlocked ||
                  formValues === undefined ||
                  formValues.length < 1
                }
              >
                {requisitionId !== undefined ? 'Save' : 'Save as draft'}
              </Button.Main>
              <Button.Main
                $mid
                $style="blue"
                type="submit"
                form="reqForm"
                onClick={handleSubmit((data) => onSubmit(data, 'PENDING_APPROVAL'))}
                disabled={
                  (!isDirty && values?.requisitionState === 'REJECTED') ||
                  !isValid ||
                  isButtonsBlocked ||
                  formValues === undefined ||
                  formValues.length < 1
                }
              >
                Submit for Approval
              </Button.Main>
              <Button.Main
                $mid
                $style="gray"
                type="button"
                onClick={() => {
                  // const path = values?.requisitionState === 'REJECTED' ? 'rejected' : 'drafts';
                  // navigate('/requisitions/overview/' + path);
                  setShowModal(true);
                }}
              >
                Discard
              </Button.Main>
            </div>
          </div>
        </div>
        <form id="reqForm">
          <div className={generalStyles.underline}>
            <div className={generalStyles.fieldsThree}>
              <div className="inp-container">
                <Label
                  $title="Requester"
                  $tooltipText="The user you are raising the requisition on behalf of"
                />
                <Controller
                  name="requester"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Requester is required',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <SelectUsers
                      {...rest}
                      selectRef={ref}
                      className={
                        errors.hasOwnProperty(rest.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      isClearable={true}
                    />
                  )}
                />
              </div>
              <div className="inp-container">
                <Label
                  $title="Department"
                  $tooltipText="Department requesting the items"
                  $isRequired
                />
                <Controller
                  name="departmentId"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Department is required',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <SelectDepartments
                      {...rest}
                      selectRef={ref}
                      className={
                        errors.hasOwnProperty(rest.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                    />
                  )}
                />
                {errors.departmentId && (
                  <p className="error-message">{errors.departmentId?.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Label
                  $title="Location"
                  $tooltipText="Physical location items will be delivered to"
                  $isRequired
                />
                <Controller
                  name="locationId"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Location is required',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <SelectLocations
                      {...rest}
                      selectRef={ref}
                      className={
                        errors.hasOwnProperty(rest.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                    />
                  )}
                />
                {errors.locationId && <p className="error-message">{errors.locationId?.message}</p>}
              </div>
              <div className="inp-container">
                <Label $isRequired $title="Category" />
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={
                        errors.hasOwnProperty(field.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Select Category"
                      options={Array.from(new Set(vendorCategories.map((cat) => cat.category))).map(
                        (category) => ({
                          label: category,
                          value: category,
                        }),
                      )}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue('subcategory', null);
                      }}
                    />
                  )}
                />
                {errors.category && (
                  <p className="error-message">{errors.category?.value.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Label $isRequired $title="Sub Category" />
                <Controller
                  name="subcategory"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={
                        errors.hasOwnProperty(field.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      classNamePrefix="react-select"
                      isDisabled={!categoryWatcher?.value}
                      isSearchable={false}
                      placeholder="Select Sub Category"
                      options={vendorCategories
                        .filter((category) => category.category === categoryWatcher?.value)
                        .map((subcat) => ({
                          label: subcat.subCategory,
                          value: subcat.subCategory,
                        }))}
                    />
                  )}
                />
                {errors.subcategory && (
                  <p className="error-message">{errors.subcategory?.value.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Label
                  $title="Prefered Vendor"
                  $tooltipText="Preferred vendor to supply items"
                  $isRequired
                />
                <Controller
                  name="vendorId"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Vendor is required',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <SelectVendors
                      {...rest}
                      selectRef={ref}
                      isDisabled={!categoryWatcher?.value || !subCategoryWatcher?.value}
                      extraFilters={{
                        category: categoryWatcher?.value,
                        subCategory: subCategoryWatcher?.value,
                      }}
                      className={
                        errors.hasOwnProperty(rest.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                    />
                  )}
                />
                {errors.vendorId && <p className="error-message">{errors.vendorId?.message}</p>}
              </div>
              <div className="inp-container">
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Name is required',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Maximum 50 characters',
                    },
                    validate: {
                      onlyAllowed: (v) =>
                        regExps.poRequisitionsTitle.test(v) ||
                        'Alphabetic, numeric, &().- characters and space only',
                      onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Description"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      $label="Description"
                      $labelRequired
                      $tooltip="Descriptive title for requisition"
                      {...field}
                    />
                  )}
                />
                {errors.title && <p className="error-message">{errors.title?.message}</p>}
              </div>
              <div className="inp-container">
                <Controller
                  name="deliveryDate"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Delivery Date is required',
                    },
                    maxLength: {
                      value: 10,
                      message: 'Maximum 10 characters',
                    },
                  }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      placeholderText="Select Date"
                      selected={field.value}
                      wrapperClassName="custom-datepicker"
                      customInput={
                        <Input
                          {...field}
                          $label="Required By"
                          $labelRequired
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $tooltip="Desired delivery date of items"
                        />
                      }
                      onChange={(e) => {
                        field.onChange(e);
                        trigger(field.name);
                      }}
                    />
                  )}
                />
                {errors.deliveryDate && (
                  <p className="error-message">{errors.deliveryDate?.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Label
                  $title="Budget"
                  $tooltipText="Physical location items will be delivered to"
                  $isRequired
                />
                <Controller
                  name="budget"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Budget is required',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <SelectBudgets
                      {...rest}
                      selectRef={ref}
                      className={
                        errors.hasOwnProperty(rest.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                    />
                  )}
                />
                {errors.budget && <p className="error-message">{errors.budget?.message}</p>}
              </div>
            </div>
            <br />
          </div>
          <div className={styles.items}>
            <div className={styles.itemsAdd}>
              <AddButton $click={() => append(itemDefault)} />
            </div>
            <div className={styles.itemsTable}>
              <div className={styles.itemsTableHead}>
                <Text type="body-2" weight={500}>
                  Item Name
                </Text>
                <Text type="body-2" weight={500}>
                  Vendor Code
                </Text>
                <Text type="body-2" weight={500}>
                  Unit Of Measure
                </Text>
                <Text type="body-2" weight={500}>
                  Account
                </Text>
                <Text type="body-2" weight={500}>
                  Quantity
                </Text>
                <Text type="body-2" weight={500}>
                  Unit Price
                </Text>
                <Text type="body-2" weight={500}>
                  Tax
                </Text>
                <Text type="body-2" weight={500}>
                  Subtotal
                </Text>
              </div>
              <div className={styles.itemsTableBody}>
                {fields.map((field, index) => {
                  return (
                    <Item
                      key={field.id}
                      field={field}
                      index={index}
                      register={register}
                      errors={errors}
                      remove={remove}
                      append={append}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      taxes={taxes}
                      name="requisitionItemDTOS"
                    />
                  );
                })}
              </div>
            </div>

            <div className={stylesPo.summaryWrapper}>
              <div className={styles.requestNotes}>
                <div className="inp-container">
                  <Controller
                    name="requestNotes"
                    control={control}
                    rules={{
                      pattern: {
                        value: regExps.notes,
                        message: "Only alpha characters, numbers and - . , & : # ( ) + / '",
                      },
                      maxLength: {
                        value: 500,
                        message: 'Maximum 500 characters',
                      },
                    }}
                    render={({ field }) => (
                      <Textarea
                        {...field}
                        $low
                        $small
                        placeholder="Notes text goes here"
                        className={errors?.requestNotes && 'error'}
                      />
                    )}
                  />
                  {errors?.requestNotes && (
                    <p className="error-message">{errors?.requestNotes?.message}</p>
                  )}
                </div>
              </div>
              <Summary
                subtotal={subtotal}
                taxTotal={taxTotal}
                totalCost={totalCost}
                currency={vendorCurrency ?? vendorWatcher?.currency}
              />
            </div>+
          </div>
          <div className={styles.attachmentsBlock}>
            <div className={styles.attachmentsBlockItem}>
              {!!values?.attachments?.length && (
                <QuotesTab data={values?.attachments} deleteDocument={deleteDocument} />
              )}
              <div>
                <Label $title="Upload supporting documentation" $isRequired />
                <Documents
                  control={control}
                  fieldName="requisitionAttachments"
                  filesSchema={files}
                  error={
                    errors?.requisitionAttachments?.message ||
                    errors?.requisitionAttachments?.root?.message
                  }
                  required={requiredRef.current}
                  clearErrors={clearErrors}
                />
              </div>
            </div>
          </div>
        </form>
      </Box>
      <Modal
        $show={showModal}
        $close={() => setShowModal(false)}
        $title="Discard"
        $radius={16}
        $closableOutside
      >
        <div className={generalStyles.subscriptionModal}>
          <Text>Do you want to continue to permanently delete this entry?</Text>
          <div className={generalStyles.modalButtons}>
            <Button.Main $primary $style={'blue'} onClick={() => setShowModal(false)} type="button">
              No
            </Button.Main>
            <Button.Main $primary $style={'blank'} onClick={() => navigate(-1)} type="button">
              Yes
            </Button.Main>
          </div>
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default RequisitionCreate;
