import React from 'react';
import {Link} from 'react-router-dom'
import Text from '../../components/UI/Typography/Text';
import generalStyles from '../../styles/general.module.css'
import style from '../../styles/integrations.module.css'

const Integrations = () => {
  return (
    <div>
      <div className={style.titleBlock}>
        <Text weight={500} type={'h3'}>Integrations</Text>
        <Text type={'body-1'} className={style.subtitle}>SpendPro seamlessly integrates the below enterprise Accounting and Enterprise Resource Planning (ERP) solutions</Text>
      </div>
      <div className={generalStyles.fieldsThree}>
        {['sage', 'something'].map(item => (
          <div className={style.itemBox} key={item}>
            <img className={style.itemLogo} src="https://media.istockphoto.com/id/1372285799/uk/%D0%B2%D0%B5%D0%BA%D1%82%D0%BE%D1%80%D0%BD%D1%96-%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F/%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BD%D0%B5-%D0%B7%D0%B0%D0%B1%D0%B5%D0%B7%D0%BF%D0%B5%D1%87%D0%B5%D0%BD%D0%BD%D1%8F-erp.jpg?s=1024x1024&w=is&k=20&c=pNyOxcrMD1Xwnd_DoLuuwzq4XNvhO7uWXb1CR907OxA=" alt="Epr system name"/>
            <Link to={'/integrations/'+item} className={'link'}>See more</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Integrations;
