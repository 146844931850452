import { axiosInstance, baseUrl } from './API';

const s2pmsVendorPortal = {
  dashboardTotalOrders: (vendorId, data) => axiosInstance.post(baseUrl + '/vendor/' + vendorId + '/profile/metrics/total-orders-value', data),
  dashboardTopCustomers: (vendorId, data) => axiosInstance.post(baseUrl + '/vendor/' + vendorId + '/profile/metrics/top-customers', data),
  dashboardOrdersSummary: (vendorId) => axiosInstance.get(baseUrl + '/vendor/' + vendorId + '/profile/orders-summary'),

  getCatalog: (page, size, data) => axiosInstance.post(baseUrl + '/product-catalog/filter?direction=DESC&orderBy=createdAt&page=' + page + '&size=' + size + '', data),
  getProduct: (productId) => axiosInstance.get(baseUrl + '/product-catalog/' + productId),
  createProduct: (data) => axiosInstance.post(baseUrl + '/product-catalog', data),
  updateProduct: (productId, data) => axiosInstance.post(baseUrl + '/product-catalog/' + productId, data),
  archiveProduct: (productId) => axiosInstance.delete(baseUrl + '/product-catalog/' + productId),
  activateProduct: (productId) => axiosInstance.post(baseUrl + '/product-catalog/' + productId + '/activate'),
  getTemplate: () => axiosInstance.get(baseUrl + '/product-catalog/template/download'),
  getVendorCompanies: (vendorId) => axiosInstance.get(baseUrl + '/vendor/companies/' + vendorId),

  getProfile: (vendorId) => axiosInstance.get(baseUrl + '/vendor/' + vendorId + '/profile'),
  updateProfile: (vendorId, data) => axiosInstance.post(baseUrl + '/vendor/' + vendorId + '/profile', data),
};

export default s2pmsVendorPortal;
