import React from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styles from '../styles/loggedInLayout.module.css';
import Dropdown from './UI/General/Dropdown';
import Text from './UI/Typography/Text';
import Icon from './UI/General/Icon';
import { useStore } from '../store/store';
import { useShallow } from 'zustand/react/shallow';
import Notifications from './Notifications';
import { useSubscriptionAllowed } from '../hooks/useSubscriptionAllowed';
import Input from './UI/Forms/Input';
import CryptoJS from 'crypto-js';

function Header({ setShowSidebarMenu, setShowHeaderMenu, showHeaderMenu, showSidebarMenu }) {
  const showCompanyDropdown = useStore((state) => state.showCompanyDropdown);
  const user = useStore((state) => state.user);
  const stepsDone = useStore(useShallow((state) => state.stepsDone));
  const fullCompanies = useStore((state) => state.fullCompanies);
  const activeCompany = useStore((state) => state.activeCompany);
  const setActiveCompany = useStore((state) => state.setActiveCompany);
  const setIsConfiguration = useStore((state) => state.setIsConfiguration);
  const currentSubscription = useSubscriptionAllowed();

  async function logout() {
    await Auth.signOut().then(() => {
      sessionStorage.removeItem('selfsetup');
      window.dataLayer.push({
        event: 'sign_out',
        method: 'method',
        userId: CryptoJS.AES.encrypt(user.id, process.env.REACT_APP_AES_SECRET).toString(),    // SHA-256 hashed unique user id from backend
        email: CryptoJS.AES.encrypt(user.email, process.env.REACT_APP_AES_SECRET).toString()      // SHA-256 hashed unique e-mail address from backend
      });
      console.log(window.dataLayer);
    });
  }

  const updateCompanyDetails = (companyId) => {
    setActiveCompany(companyId);
  };

  const isVendor = user?.roles?.map(el => el?.role?.name).includes('VENDOR');
  const isClientAdmin = user?.roles?.filter((r) => r?.company?.id === activeCompany?.id)?.map(el => el?.role?.name).includes('CLIENT_ADMIN');

  return (
    <header className={styles.header}>
      <label
        className={
          !showSidebarMenu ? styles.mobileMenu : styles.mobileMenu + ' ' + styles.mobileMenuOpened
        }
        onClick={() => setShowSidebarMenu((state) => !state)}
      >
        <span />
        <span />
        <span />
      </label>
      <Link to="/dashboard" className={styles.mobileLogo}>
        <img src="/assets/img/login_register/spendproLogo.svg" alt="SpendPro-Logo" />
      </Link>
      <label
        className={
          !showHeaderMenu
            ? styles.mobileMenu + ' ' + styles.right
            : styles.mobileMenu + ' ' + styles.right + ' ' + styles.mobileMenuOpened
        }
        onClick={() => setShowHeaderMenu((state) => !state)}
      >
        <span />
        <span />
        <span />
      </label>
      <div
        className={
          !showHeaderMenu ? styles.headerInner : styles.headerInner + ' ' + styles.headerInnerOpened
        }
      >
        <div className={styles.headerWelcome}>
          <Text type="h3" weight={400}>
            Hello, {user.firstName}!
          </Text>
          <span>Your personal account</span>
        </div>
        <div className={styles.headerOptions}>
          <div className={styles.headerSearch}>
            <Input
              type="text"
              // value=""
              placeholder="Search"
              $small
              // className={errors.hasOwnProperty(field.name) && 'error'}
              // {...field}
            />
          </div>
          {isClientAdmin ? (
            <div className={styles.headerBlock}>
              <Dropdown collapsible>
                <Dropdown.Header $activeClass={styles.active}>
                  <Icon $color="none" $icon="settings" $width={20} $height={20} />
                </Dropdown.Header>
                <Dropdown.Body>
                  <div className={styles.headerDrop}>
                    <div className={styles.headerDropSection}>
                      <Text weight={500} type="body-1">
                        Settings
                      </Text>
                    </div>
                    <div className={styles.headerDropSection}>
                      <div className={styles.headerDropTitle}>
                        <Text weight={500} type="body-2">
                          SYSTEM
                        </Text>
                      </div>
                      <div className={styles.headerDropLinks}>
                        <Link to="/company-profile" onClick={() => setIsConfiguration(true)}>
                          <Text id="menu-link" type="body-2" weight={400}>
                            Configuration
                          </Text>
                        </Link>
                        <Link to="/integrations">
                          <Text id="menu-link" type="body-2" weight={400}>
                            Integrations
                          </Text>
                        </Link>
                        <Link to="/">
                          <Text id="menu-link" type="body-2" weight={400}>
                            Audit Log
                          </Text>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.headerDropSection}>
                      <div className={styles.headerDropTitle}>
                        <Text weight={500} type="body-2">
                          USER MANAGEMENT
                        </Text>
                      </div>
                      <Link to="/users/overview/active" onClick={() => setIsConfiguration(true)}>
                        <Text id="menu-link" type="body-2" weight={400}>
                          Users
                        </Text>
                      </Link>
                      <Link to="/user-roles-and-permissions/active" onClick={() => setIsConfiguration(true)}>
                        <Text id="menu-link" type="body-2" weight={400}>
                          Roles and Permissions
                        </Text>
                      </Link>
                    </div>
                    <div className={styles.headerDropSection}>
                      <div className={styles.headerDropTitle}>
                        <Text id="menu-link" weight={500} type="body-2">
                          BILLING
                        </Text>
                      </div>
                      <Link to="/">
                        <Text type="body-2" weight={400}>
                          Overview
                        </Text>
                      </Link>
                    </div>
                  </div>
                </Dropdown.Body>
              </Dropdown>
            </div>
          ) : null}
          <div className={styles.headerBlock}>
            <Notifications />
          </div>
          <div className={styles.headerBlock}>
            <Dropdown collapsible>
              <Dropdown.Header $activeClass={styles.active}>
                <div className={styles.headerUser}>
                  <div className={styles.headerAvatar}>
                    {user.profileImage !== null ? (
                      <img
                        src={'data:image/jpg;base64,' + user.profileImage}
                        alt={user.firstName + ' ' + user.lastName}
                        title={user.firstName + ' ' + user.lastName}
                      />
                    ) : (
                      <Text weight={600} type="subtitle">
                        {user.firstName && user.lastName
                          ? user.firstName.charAt(0) + user.lastName.charAt(0)
                          : 'V'}
                      </Text>
                    )}
                  </div>
                </div>
              </Dropdown.Header>
              <Dropdown.Body>
                <div className={styles.headerDrop}>
                  <div className={styles.headerDropSection}>
                    <div className={styles.headerDropUserInfo}>
                      <div className={styles.headerAvatar}>
                        {user.profileImage !== null ? (
                          <img
                            src={'data:image/jpg;base64,' + user.profileImage}
                            alt={user.firstName + ' ' + user.lastName}
                            title={user.firstName + ' ' + user.lastName}
                          />
                        ) : (
                          <Text weight={600} type="subtitle">
                            {user.firstName && user.lastName
                              ? user.firstName.charAt(0) + user.lastName.charAt(0)
                              : 'V'}
                          </Text>
                        )}
                      </div>
                      <div>
                        <Text type="body-2" weight={600}>
                          {user && `${user.firstName} ${user.lastName ? user.lastName : ''}`}
                        </Text>
                        <span>
                          <Text type="body-3" weight={500}>
                            {user && user.email}
                          </Text>
                        </span>
                      </div>
                    </div>
                    {!isVendor ? (
                      <Link to="/users/profile">
                        <Text type="body-2" weight={500}>
                          Manage Account
                        </Text>
                      </Link>
                    ) : null}
                  </div>
                  <div className={styles.headerDropSection}>
                    <Text as="a" className="active" type="body-2" weight={500} onClick={logout}>
                      Sign out
                    </Text>
                  </div>
                </div>
              </Dropdown.Body>
            </Dropdown>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
