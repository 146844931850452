import React from 'react';
import style from '../styles/termsPolicy.module.css';
import Text from '../components/UI/Typography/Text';

const CookiePolicy = () => {
  return (
    <div className={style.wrapper}>
      <header className={style.header}>
        <img src="/assets/img/login_register/spendproLogo.svg" alt="spend pro" title="spend pro"/>
      </header>
      <main>
        <section className={style.content}>
          <Text type={'h2'}>SpendPro Cookie Policy</Text>
          <Text type={'body-1'}>Effective Date: February 14, 2025</Text>
          <Text type={'body-1'}>We use cookies to help improve your experience of our website at <a href="https://www.spendpro.io">https://www.spendpro.io</a>. This cookie policy is part of SpendPro's privacy policy. It covers the use of cookies between your device and our site.</Text>
          <Text type={'body-1'}>We also provide basic information on third-party services we may use, who may also use cookies as part of their service. This policy does not cover their cookies.</Text>
          <Text type={'body-1'}>If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from <a href="https://www.spendpro.io">https://www.spendpro.io</a>. In such a case, we may be unable to provide you with some of your desired content and services.</Text>
          <Text type={'h3'}>What is a cookie?</Text>
          <Text type={'body-1'}>A cookie is a small piece of data that a website stores on your device when you visit. It typically contains information about the website itself, a unique identifier that allows the site to recognise your web browser when you return, additional data that serves the cookie’s purpose, and the lifespan of the cookie itself.</Text>
          <Text type={'body-1'}>Cookies are used to enable certain features (e.g. logging in), track site usage (e.g. analytics), store your user settings (e.g. time zone, notification preferences), and to personalise your content (e.g. advertising, language).</Text>
          <Text type={'body-1'}>Cookies set by the website you are visiting are usually referred to as first-party cookies. They typically only track your activity on that particular site.</Text>
          <Text type={'body-1'}>Cookies set by other sites and companies (i.e. third parties) are called third-party cookies. They can be used to track you on other websites that use the same third-party service.</Text>
          <Text type={'h3'}>How Can You Control Our Website's Use of Cookies?</Text>
          <Text type={'body-1'}>You have the right to decide whether to accept or reject cookies on our Website. You can manage your cookie preferences in our Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with the services on our Website.</Text>
          <Text type={'body-1'}>You may also be able to set or amend your cookie preferences by managing your web browser settings. As each web browser is different, please consult the instructions provided by your web browser (typically in the &quot;help&quot; section). If you choose to refuse or disable cookies you may still use the Website, though some of the functionality of the Website may not be available to you.</Text>
          <Text type={'h3'}>How Often Will We Update This Cookie Policy?</Text>
          <Text type={'body-1'}>We may update this Cookie Policy from time to time in order to reflect any changes to the cookies and related technologies we use, or for other operational, legal or regulatory reasons.</Text>
          <Text type={'body-1'}>Each time you use our Website, the current version of the Cookie Policy will apply. When you use our Website, you should check the date of this Cookie Policy (which appears at the top of this document) and review any changes since the last version.</Text>
          <Text type={'h3'}>Where Can You Obtain Further Information?</Text>
          <Text type={'body-1'}>For any questions or concerns regarding our Cookie Policy, you may contact us using the following details:</Text>
          <Text type={'body'}>Privacy Officer<br /><a href="mailto:support@spendpro.io">support@spendpro.io</a></Text>
        </section>
      </main>
    </div>
  );
};

export default CookiePolicy;
