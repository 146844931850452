import { useMemo } from 'react';
import styles from '../../../../styles/approvalWorkflow.module.css';
import generalStyles from '../../../../styles/general.module.css';
import DataTableBase from '../../../UI/General/DataTableBase';
import Icon from '../../../UI/General/Icon';
import * as Button from '../../../UI/Forms/Button.jsx';

const UsersList = ({ users, type, deleteUser, currency }) => {
  const noLoa = type !== 'LOA';
  const userColumns = useMemo(() => {
    const columns = [
      {
        name: 'User',
        sortable: true,
        cell: (row) => (
          <span data-content={'User'} className={generalStyles.tableValue}>
            <span>{row.userName}</span>
          </span>
        ),
      },
      {
        name: 'Currency',
        sortable: true,
        cell: () => (
          <span data-content={'User'} className={generalStyles.tableValue}>
            <span>{currency}</span>
          </span>
        ),
      },

      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div style={{ right: '10px' }} className={generalStyles.actionMenuHolder}>
            <Button.Action
              $variant="circle"
              $style="lightGrayishBlue"
              $width={40}
              $height={40}
              onClick={() => deleteUser(row.id)}
              type="button"
            >
              <Icon $width={20} $height={20} $icon="delete" $color="#F24638" />
            </Button.Action>
          </div>
        ),
      },
    ];
    // if (!noLoa) {
    //   columns.splice(1, 0, {
    //     name: 'LOA',
    //     sortable: true,
    //     cell: (row) => (
    //       <span data-content={'LOA'} className={generalStyles.tableValue}>
    //         <span>{row.loa}</span>
    //       </span>
    //     ),
    //   });
    // }
    return columns;
  }, [noLoa, users, currency]);

  return (
    <div className={styles.userList}>
      <DataTableBase
        data={users || []}
        columns={userColumns}
        defaultSortFieldId={type && 2}
        defaultSortAsc={false}
      />
    </div>
  );
};

export default UsersList;
