import styled from 'styled-components';
import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';
import { useStore } from '../../../store/store';
import SelectDepartments from '../../shared/SelectDepartments';
import SelectLocations from '../../shared/SelectLocations';
import SelectVendors from '../../shared/SelectVendors';
import SelectUsers from '../../shared/SelectUsers';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from './Icon';

const Counter = styled.div`
  display: flex;
  min-width: 18px;
  height: 18px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  background: #145ff5;
  font-size: 10px;
  color: #fff;
`;

const StyledValueContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledContainer = styled.div`
  cursor: pointer !important;
  display: flex;
  padding: 0px 8px 0px 16px;
  border-radius: 4px;
  /* border: 0.5px solid #e0e0e0; */
  /* background: ${({ $isOpen }) => ($isOpen ? '#145ff518' : '#fff')}; */
  border: 0.5px solid #145ff5;
  &:hover {
    /* background: ${({ $isOpen }) => ($isOpen ? '#145ff518' : '#f2f2f2')}; */
  }
`;
const ClearButton = styled.button`
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  align-items: center;
  background: none;
  border: none;
  font-family: inherit;
  color: #aaaaaa;
  font-weight: 400;
  font-size: 14px;
  gap: 8px;
  &:hover {
    /* background: #145ff518; */
  }
`;

const SelectControl = (props) => {
  return (
    <components.Control {...props}>
      <StyledContainer $isOpen={props.menuIsOpen}>{props.children}</StyledContainer>
    </components.Control>
  );
};
const MenuList = (props) => {
  const handleSelected = () => {
    if (!props.isMulti) return props.children;
    const selectedOptions =
      props.children?.filter?.((childNode) => childNode.props.isSelected) ?? [];
    const nonSelectedOptions =
      props.children?.filter?.((childNode) => !childNode.props.isSelected) ?? [];
    return [...selectedOptions, ...nonSelectedOptions];
  };

  return <components.MenuList {...props}>{handleSelected()}</components.MenuList>;
};

const ValueContainer = (props) => {
  return (
    <components.ValueContainer {...props}>
      <StyledValueContainer>
        {props.children}
        {!!props.getValue().length && <Counter>{props.getValue().length}</Counter>}
      </StyledValueContainer>
    </components.ValueContainer>
  );
};

const Filter = ({ control, filterData, setTriggerRefetch, setValue }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const clearFilters = () => {
    filterData.forEach((filter) => {
      setValue(filter.name, filter.isMulti ? [] : '');
    });
    navigate(location.pathname, { replace: true, state: null });
    setTriggerRefetch((state) => !state);
  };

  const appearanceProps = {
    controlShouldRenderValue: false,
    components: {
      ValueContainer: ValueContainer,
      Control: SelectControl,
      MenuList: MenuList,
    },
    styles: {
      container: (base) => ({
        ...base,
        border: 'none',
        minHeight: '0px !important',
      }),
      valueContainer: (base) => ({
        ...base,
        padding: '0px !important',
      }),
      control: (base) => ({ ...base, border: 'none', minHeight: '0px !important' }),
      group: (base) => ({ ...base, padding: 0 }),
      input: (base) => ({ ...base, fontSize: 12 }),
      placeholder: (base) => ({
        ...base,
        fontSize: 12,
        color: '#0000004D !important',
        fontWeight: 400,
      }),
      menu: (base) => ({
        ...base,
        minWidth: 168,
      }),

      option: (base) => ({
        ...base,
        padding: 6,
        fontSize: 12,
        color: '#4B5865',
      }),
      clearIndicator: () => ({
        display: 'none',
      }),
      multiValueRemove: () => ({
        display: 'none',
      }),
    },
  };

  const asyncComponents = {
    department: (ref, rest) => (
      <SelectDepartments
        {...rest}
        selectRef={ref}
        className={'react-select-container'}
        onChange={(e) => {
          rest.onChange(e);
          setTriggerRefetch((trigger) => !trigger);
        }}
        {...appearanceProps}
      />
    ),
    location: (ref, rest) => (
      <SelectLocations
        {...rest}
        selectRef={ref}
        className={'react-select-container'}
        onChange={(e) => {
          rest.onChange(e);
          setTriggerRefetch((trigger) => !trigger);
        }}
        {...appearanceProps}
      />
    ),
    vendor: (ref, rest) => (
      <SelectVendors
        {...rest}
        selectRef={ref}
        className={'react-select-container'}
        onChange={(e) => {
          rest.onChange(e);
          setTriggerRefetch((trigger) => !trigger);
        }}
        {...appearanceProps}
      />
    ),
    user: (ref, rest) => (
      <SelectUsers
        {...rest}
        selectRef={ref}
        className={'react-select-container'}
        onChange={(e) => {
          rest.onChange(e);
          setTriggerRefetch((trigger) => !trigger);
        }}
        {...appearanceProps}
      />
    ),
  };

  return (
    <>
      {filterData.map((filter) => (
        <Controller
          key={filter.name}
          // {...props}
          name={filter.name}
          control={control}
          render={({ field: { ref, ...rest } }) =>
            filter.fetchType && asyncComponents[filter.fetchType] ? (
              asyncComponents[filter.fetchType](ref, rest)
            ) : (
              <Select
                {...rest}
                ref={ref}
                placeholder={filter.label}
                classNamePrefix="react-select"
                isSearchable={filter.isMulti}
                isMulti={filter.isMulti}
                closeMenuOnSelect={!filter.isMulti}
                isDisabled={filter.isDisabled}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                onChange={(e) => {
                  rest.onChange(e);
                  setTriggerRefetch((trigger) => !trigger);
                }}
                components={{
                  ValueContainer: ValueContainer,
                  Control: SelectControl,
                  MenuList: MenuList,
                }}
                options={filter.options}
                styles={{
                  container: (base) => ({
                    ...base,
                    border: 'none',
                    minHeight: '0px !important',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: '0px !important',
                  }),
                  control: (base) => ({ ...base, border: 'none', minHeight: '0px !important' }),
                  group: (base) => ({ ...base, padding: 0 }),
                  input: (base) => ({ ...base, fontSize: 12 }),
                  placeholder: (base) => ({
                    ...base,
                    fontSize: 12,
                    color: '#0000004D !important',
                    fontWeight: 400,
                  }),
                  menu: (base) => ({
                    ...base,
                    minWidth: 168,
                  }),

                  option: (base) => ({
                    ...base,
                    padding: 6,
                    fontSize: 12,
                    color: '#4B5865',
                  }),
                  clearIndicator: () => ({
                    display: 'none',
                  }),
                  multiValueRemove: () => ({
                    display: 'none',
                  }),
                  // ...props.styles,
                }}
              />
            )
          }
        />
      ))}
      <ClearButton onClick={clearFilters}>
        <Icon $color="none" $icon="close" $width={20} $height={20} />
        Clear filters
      </ClearButton>
    </>
  );
};

export default Filter;
