import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import style from "../../../styles/insights.module.css";
import Text from "../../UI/Typography/Text";
import Select from "react-select";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Label
} from 'recharts';
import s2pmsService from "../../../services/spmsService.service";
import moment from "moment";
import TableNoData from "../../UI/General/TableNoData";
import Box from '../../UI/General/Box';
import { nf } from '../../../utils/nf';

const COLORS = ['#3B82F6', '#14B8A6', '#EC4899', '#F59E0B', '#6366F1'];

const ChartVendors = ({departments, periods, companyId, currency}) => {
  const [values, setValues] = useState(null)
  const [chartData, setChartData] = useState(null)
  const {handleSubmit, control} = useForm({
    mode: 'onChange',
    defaultValues: {
      period: periods[0],
      department: departments[0]
    },
    values
  });

  const loadData = useCallback((departmentId, start, end = new Date()) => {
    if (companyId) {
      let currentDate = moment();
      let previousMonthDate = currentDate.subtract(start, 'month');
      let search = {
        companyId: companyId,
        startDate: previousMonthDate,
        endDate: end
      }
      if (departments.length > 0 && departmentId !== 0){
        search.departmentId = departmentId
      }
      s2pmsService.insightsVendorSpent(search).then(r => {
        if (r.data.message === "Operation Successful") {
          console.log(r.data.data);
          const monthlySpendReport = r.data.data.monthlySpendReport?.map(el => ({...el, fill: "purple"}));
          let data = {...r.data.data, monthlySpendReport }
          setChartData(data)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }, [companyId, departments])

  const onSubmit = (data) => {
    loadData(data?.department?.value, data?.period?.value)
  }

  useEffect(() => {
    setValues({department: departments[0]})
  }, [departments])

  useEffect(() => {
    loadData(undefined, periods[0].value)
  }, [periods])

  return (
    <Box $mobExtend $asHolder $noOverflow className={style.box}>
      <form className={style.chartBlock}>
        <div className={style.groupHead}>
          <div className={style.groupTitle}>
            <Text type={'subtitle'} weight={500}>Top 10 Spend by Vendor</Text>
            <Text type={'body-2'} className={style.colorSecondary}>Breakdown by vendor</Text>
          </div>
          <div className={style.actions}>
            <Controller
              name={'period'}
              control={control}
              render={({ field}) => (
                <Select
                  {...field}
                  className={'react-select-container small'}
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Period"
                  options={periods}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
            <Controller
              name={'department'}
              control={control}
              render={({ field}) => (
                <Select
                  {...field}
                  className={'react-select-container small'}
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Department"
                  options={departments}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
          </div>
        </div>
        {chartData?.monthlyVendorSpend && chartData?.monthlyVendorSpend?.length > 0 ? (
          <div className={style.pieWrapper}>
            <ResponsiveContainer width="100%" aspect={1}>
              <PieChart width={400} height={400}>
                <Pie
                  dataKey="totalSpentAmount"
                  data={chartData?.monthlyVendorSpend}
                  innerRadius={71}
                  outerRadius={95}
                  fill="#8884d8"
                  startAngle={90}
                  endAngle={450}
                >
                  {chartData?.monthlyVendorSpend.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                  ))}
                  <Label value={nf.format(chartData?.totalSpentAmount)} position="centerBottom" fontSize='24px' fill={'#222529'}/>
                  <Label value="Approved Spend" position="centerTop" style={{transform: "translateY(6px)" }} fontSize='12px' fill={'#8C97A7'}/>
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <div className={style.legend}>
              <div className={style.legendHead}>
                <span>BY VENDOR</span>
                <span>APPROVED</span>
              </div>
              <div className={style.legendItems}>
                {chartData?.monthlyVendorSpend?.map((el, index) => (
                  <div className={style.legendRow} key={index}>
                    <div className={style.legendItem}>
                      <span className={style.legendItemBullet} style={{backgroundColor: COLORS[index % COLORS.length] }}/>
                      <span className={style.legendItemText}>{el?.vendorName}</span>
                    </div>
                    <span>{currency} {el?.totalSpentAmount}</span>
                  </div>
                ))}
              </div>
              {/*<span className={style.legendLink} onClick={() => {alert('vendors will be shown soon')}}>View all vendors</span>*/}
            </div>
          </div>
        ) : (
          <TableNoData/>
        )}

      </form>
    </Box>
  );
};

export default ChartVendors;
