import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import Input from '../../UI/Forms/Input';
import Label from '../../UI/Forms/Label';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import File from '../../UI/Forms/File';
import { useStore } from '../../../store/store';
import generalStyles from '../../../styles/general.module.css';
import styles from '../../../styles/systemRolesPermissions.module.css';
import Text from '../../UI/Typography/Text';
import DataTableBase from '../../UI/General/DataTableBase';
import spmsServiceService from '../../../services/spmsService.service';
import { regExps } from '../../../utils/regExps';

const ProfileAccount = ({ errors, control, reset, setFiles }) => {
  const user = useStore((state) => state.user);
  const [tableData, setTableData] = useState([]);

  const columns = useMemo(
    () => [
      {
        name: 'Company Name',
        selector: (row) => row.companyName ?? '-',
        sortable: true,
      },
      {
        name: 'LOA',
        selector: (row) => row.limitOfAuthority ?? '-',
        sortable: true,
      },
      {
        name: 'Location',
        selector: (row) => row.locationName ?? '-',
        sortable: true,
      },
      {
        name: 'Department',
        selector: (row) => row.departmentName ?? '-',
        sortable: true,
      },
      {
        name: 'Roles',
        cell: (row) => {
          const roles = user.roles
            .filter((el) => el.company?.id === row.companyId)
            .map((item) => item.role.name);
          return (
            <div className={styles.roles}>
              {!!roles.length ? roles.map((item, index) => <span key={index}>{item}</span>) : '-'}
            </div>
          );
        },
      },
    ],
    [user],
  );

  const readFile = (event) => {
    if (event.target.files[0] !== undefined) {
      let filereader = new FileReader();
      filereader.readAsDataURL(event.target.files[0]);
      filereader.onload = function () {
        setFiles(filereader.result.split(';base64,')[1]);
      };
      filereader.onerror = function (error) {
        console.log('Error: ', error);
      };
    } else {
      setFiles(null);
    }
  };

  useEffect(() => {
    const initData = async () => {
      const data = await Promise.all(
        user.customCompanies.map(async (item) => {
          const data = await Promise.all([
            spmsServiceService
              .getDepartment(item.companyId, item.departmentId)
              .then((r) => {
                return r.data.name;
              })
              .catch(() => null),
            spmsServiceService
              .getLocation(item.companyId, item.locationId)
              .then((r) => {
                if (r.data.status === 'success') {
                  return r.data.data.name;
                }
              })
              .catch((err) => {
                console.log(err);
              }),
          ]);
          return {
            limitOfAuthority: item.limitOfAuthority,
            departmentName: data[0],
            companyId: item.companyId,
            companyName: item.companyName,
            locationName: data[1],
          };
        }),
      );
      setTableData(data);
    };
    initData();
  }, []);

  useEffect(() => {
    if (user !== null) {
      const { firstName, lastName, position, email, phoneNumber, profileImage } = user;
      reset({
        firstName: !!firstName ? firstName : '',
        lastName: !!lastName ? lastName : '',
        position: !!position ? position : '',
        email: !!email ? email : '',
        phoneNumber: !!phoneNumber ? phoneNumber : '',
        profileImage: !!profileImage ? profileImage : '',
      });
    }
  }, [user]);

  return (
    <form>
      <div className={generalStyles.fieldsThree}>
        <div className="inp-container">
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'First Name is required',
              },
              maxLength: {
                value: 50,
                message: 'Maximum 50 characters',
              },
              pattern: {
                value: /^[-'a-zA-Z\s]+$/,
                message: 'First name is not valid',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter First Name"
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="First Name"
                $labelRequired
                {...field}
              />
            )}
          />
          {errors.firstName && <p className="error-message">{errors.firstName.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Last Name is required',
              },
              maxLength: {
                value: 50,
                message: 'Maximum 50 characters',
              },
              pattern: {
                value: /^[-'A-Za-z\s]*$/,
                message: 'Last name is not valid',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter Last Name"
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="Last Name"
                $labelRequired
                {...field}
              />
            )}
          />
          {errors.lastName && <p className="error-message">{errors.lastName.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="position"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
              maxLength: {
                value: 30,
                message: 'Maximum 30 characters',
              },
              validate: {
                allowed: (v) => /^[A-Za-z\s]*$/.test(v) || 'Alphabetic characters and space only',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter Position"
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="Position"
                {...field}
              />
            )}
          />
          {errors.position && <p className="error-message">{errors.position.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
              pattern: {
                value: regExps.email,
                message: 'Email is not valid',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Enter Email Address"
                className={errors.hasOwnProperty(field.name) && 'error'}
                $label="Email Address"
                disabled={true}
                {...field}
              />
            )}
          />
          {errors.email && <p className="error-message">{errors.email.message}</p>}
        </div>
        <div className="inp-container">
          <Label $title="Phone Number" />
          <Controller
            name="phoneNumber"
            defaultValue=""
            rules={{
              validate: {
                isValid: (value) =>
                  (isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || '')) ||
                  'Enter a valid phone number',
              },
            }}
            control={control}
            render={({ field }) => (
              <PhoneInput
                {...field}
                placeholder="Enter Phone Number"
                limitMaxLength={true}
                international={true}
                className={errors.hasOwnProperty(field.name) ? 'error' : ''}
              />
            )}
          />
          {errors.phoneNumber && <p className="error-message">{errors.phoneNumber.message}</p>}
        </div>
        <div className="inp-container">
          <Label $title="Profile Photo" />
          <Controller
            name="profileImage"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isValid: (value) => {
                  if (value?.name) {
                    return (
                      ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp'].includes(
                        value.name.split('.').pop(),
                      ) || 'Wrong file type'
                    );
                  }
                },
              },
            }}
            render={({ field }) => (
              <File
                {...field}
                value={field.value.filename}
                $isInvalid={errors.hasOwnProperty(field.name)}
                $picture={
                  field.value !== '' && field.value instanceof Blob
                    ? URL.createObjectURL(field.value)
                    : field.value !== '' && !(field.value instanceof Blob)
                    ? 'data:image/jpg;base64,' + field.value
                    : ''
                }
                $small
                onChange={(e) => {
                  field.onChange({ target: { value: e.target.files[0], name: field.name } });
                  readFile(e);
                }}
                accept="image/png, image/jpeg"
              />
            )}
          />
          {errors.profileImage && <p className="error-message">{errors.profileImage.message}</p>}
        </div>
      </div>
      <Text weight={600} type={'h3'} style={{ margin: '32px 0' }}>
        Companies I belong to:
      </Text>
      <DataTableBase data={tableData} columns={columns} />
    </form>
  );
};

export default ProfileAccount;
