import React, { useEffect, useState } from 'react';
import style from '../../styles/log_reg.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text';
import Input, { Password } from '../../components/UI/Forms/Input';
import * as Button from '../../components/UI/Forms/Button';
import 'react-phone-number-input/style.css';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Toast from '../UI/General/Toast';
import PasswordTest from '../UI/Forms/PasswordTest';
import requestsService from '../../services/requestsService.service';
import useGetUserLocation from '../../hooks/useGetUserLocation';
import { regExps } from '../../utils/regExps';
import GradientText from '../UI/General/GradientText';
import CryptoJS from 'crypto-js';

const SignUp = ({ setStatus, setUsername, setDataToRemember, dataToRemember }) => {
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (!dataToRemember) return;
    reset(dataToRemember);
  }, []);

  let count = 0

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    requestsService
      .checkUserExist(data.email)
      .then(() => {
        requestsService
          .registerNewUser(data)
          .then((r) => {
            console.log(r);
            if (r.data.message === "Operation Successful"){
              count++
              if (count === 1){
                window.dataLayer.push({
                  event: 'sign_up',
                  method: 'method',
                  userId: CryptoJS.AES.encrypt(r.data.data.id, process.env.REACT_APP_AES_SECRET).toString(),    // SHA-256 hashed unique user id from backend
                  email: CryptoJS.AES.encrypt(r.data.data.email, process.env.REACT_APP_AES_SECRET).toString()      // SHA-256 hashed unique e-mail address from backend
                });
                console.log(window.dataLayer);
              }
              setUsername(data.email);
              setStatus('verify');
              setDataToRemember(data);
            }
          })
          .catch((error) => {
            console.log('singup error');
            setIsButtonsBlocked(false);
            setToast({
              opened: true,
              message:
                error.response.data.message !== undefined
                  ? error.response.data.message
                  : error.response.data.error,
              type: 'fail',
            });
          });
      })
      .catch((error) => {
        console.log('check user Error');
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message:
            error.response.data.message !== undefined
              ? error.response.data.message
              : error.response.data.error,
          type: 'fail',
        });
      });
  };

  return (
    <>
      <div className={style.logo}>
        <img src="assets/img/login_register/logo_small.svg" alt="Poms" />
      </div>
      <div className={style.authHeader}>
        <Text type="h2" weight={600}>
          Let's get started
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text className={style.authHeaderSubText} type="h5" weight={400}>
            Create your free account today!
          </Text>
        </GradientText>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={style.authForm}>
        <div className={style.inputs}>
          <div className={generalStyles.fieldsTwo}>
            <div className="inp-container">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'First name is required',
                  },
                  pattern: {
                    value: /^[-'a-zA-Z\s]+$/,
                    message: 'First name is not valid',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: (value) => value.trim() !== '' || 'First name is required',
                }}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="First name *"
                    $iconName={'user'}
                    $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.firstName && <p className="error-message">{errors.firstName?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Last name is required',
                  },
                  pattern: {
                    value: /^[-'a-zA-Z\s]+$/,
                    message: 'Last name is not valid',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Last name *"
                    $iconName={'user'}
                    $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.lastName && <p className="error-message">{errors.lastName?.message}</p>}
            </div>
          </div>
          <div className="inp-container">
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Email is required',
                },
                maxLength: {
                  value: 50,
                  message: 'Email must be at most 50 characters',
                },
                pattern: {
                  value: regExps.email,
                  message: 'Email is not valid',
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="email"
                  placeholder="Business Email *"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.email && <p className="error-message">{errors.email?.message}</p>}
          </div>
          <div className="inp-container">
            {showModalPassword && errors.password && (
              <div className={style.passwordTest}>
                <PasswordTest name={'password'} isDirty={dirtyFields} control={control} />
              </div>
            )}
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Password is required',
                },
                minLength: {
                  value: 8,
                  message: 'At least 8 characters',
                },
                maxLength: {
                  value: 35,
                  message: 'Maximum 35 characters',
                },
                validate: {
                  oneLetter: (v) => /[a-z]+/.test(v),
                  oneCapitalLetter: (v) => /[A-Z]+/.test(v),
                  oneNumber: (v) => /\d+/.test(v),
                  oneSpecialChar: (v) => /[!@#$%&?]+/.test(v),
                  onlyAllowed: (v) => /^[A-Za-z0-9!@#$%&?]*$/.test(v),
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <Password
                  placeholder="Password *"
                  {...field}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  onFocus={() => {
                    trigger('password');
                    setShowModalPassword(true);
                  }}
                  onBlur={() => setShowModalPassword(false)}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className={style.btn}>
          <Button.Main
            $primary
            $full
            $style="blue"
            type="submit"
            disabled={(!isDirty && !dataToRemember) || !isValid || isButtonsBlocked}
          >
            <span>Create Free Account</span>
          </Button.Main>
        </div>
        <div className={style.btnBefore}>
          <Text type="body-3" weight={400}>
            By signing up you agree to our <Link to="/terms"  target="_blank">Terms of Use</Link> and{' '}
            <Link to="/privacy"  target="_blank">Privacy Policy.</Link>
          </Text>
        </div>
      </form>
      <div className={style.btnAfter}>
        <Text type="body-1" weight={400}>
          Already have an account?
        </Text>
        <Button.ActionLabeled
          $primary
          $full
          $style="blue"
          type="button"
          onClick={() => setStatus('sign-up')}
        >
          <Link to="/login">Sign in</Link>
        </Button.ActionLabeled>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default SignUp;
