import { useNavigate } from 'react-router-dom';
import * as Button from '../UI/Forms/Button.jsx';
import Text from '../UI/Typography/Text.jsx';

const BackButton = ({ to }) => {
  const navigate = useNavigate();
  return (
    <Button.ActionLabeled
      $style="blue"
      onClick={() => {
        navigate(to ? to : -1);
      }}
      type="button"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3334 11H3.66669M3.66669 11L9.16669 16.5M3.66669 11L9.16669 5.5"
          stroke="#145FF5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <Text weight={500}>Back</Text>
    </Button.ActionLabeled>
  );
};

export default BackButton;
