import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';
import * as Button from '../../components/UI/Forms/Button';
import budgetServiceService from '../../services/budgetService.service';
import BudgetDetailsGlList from './BudgetDetailsGlList';
import Select from 'react-select';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import TabsSlider from '../../components/UI/General/TabsSlider';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import PieChartComponent from '../../components/UI/General/PieChartComponent';
import BackButton from '../../components/shared/BackButton';

const BudgetDetails = () => {
  const { companyId, budgetId } = useParams();
  const [glItems, setGlItems] = useState([]);
  const [monthsToRender, setMonthsToRender] = useState([]);
  const [mainBudget, setMainBudget] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);
  const [budget, setBudget] = useState(null);
  const [tab, setTab] = useState('overview');

  const calculateChartData = () => {
    if (!budget) return;
    return [
      { name: 'Allocated Unpaid', value: budget.budgetValue - budget.totalPOApprovedAmount },
      { name: 'Allocated Paid', value: budget.totalPOApprovedAmount },
    ];
  };
  useEffect(() => {
    budgetServiceService.getBudgetById(budgetId, companyId).then((res) => {
      setMainBudget(res.data.data);
      setLatestVersion(
        !!res.data.data?.versions.length ? res.data.data?.versions[0].version + 1 : '1',
      );
      setBudget(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (!budget) return;
    const months = moment.months();
    const startDate = new Date(budget.startDate);
    const endDate = new Date(budget.endDate);

    if (startDate && endDate) {
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endMonth = endDate.getMonth();
      const diffMonths = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
      const newMonthsToRender = [];
      for (let i = 0; i < diffMonths; i++) {
        const currentMonth = (startMonth + i) % 12;
        newMonthsToRender.push(months[currentMonth]);
      }
      setMonthsToRender(newMonthsToRender);
    } else {
      setMonthsToRender([]);
    }
  }, [budget]);

  const getGlItems = async () => {
    budgetServiceService.getBugetGLItems(budgetId, companyId).then((response) => {
      const formattedGLs = response?.data?.data?.glLineItems;
      setGlItems(formattedGLs);
    });
  };

  const createVersionOptions = () => {
    if (!mainBudget) return [];
    return [
      { label: `Version ${latestVersion}`, value: budgetId },
      ...mainBudget?.versions?.map((version) => ({
        label: `Version ${version.version}`,
        value: version.id,
      })),
    ];
  };

  if (!mainBudget) return <></>;

  return (
    <Box $mobExtend $asHolder>
      <div className={generalStyles.top}>
        <div>
          <BackButton />
        </div>
        <div className={generalStyles.title}>
          <div className={generalStyles.titleText}>
            <Text type="subtitle" weight={500}>
              {budget?.name}
            </Text>
          </div>
        </div>
      </div>
      <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
        <TabsSlider selected={tab}>
          <span onClick={() => setTab('overview')}>Overview</span>
          <span onClick={() => setTab('notes')}>Notes</span>
          <span onClick={() => setTab('history')}>History</span>
          <span onClick={() => setTab('approval ')}>Approval </span>
        </TabsSlider>
      </div>
      <div>
        {tab === 'overview' && (
          <>
            <div className={`${generalStyles.fieldsTwo} ${styles.budgetAdd}`}>
              <div className={styles.savedBudgetData}>
                <div className={styles.savedField}>
                  <Text weight={600}>Currency</Text>
                  <Text type="subtitle">{budget?.currency}</Text>
                </div>
                <div className={styles.savedField}>
                  <Text weight={600}>Start Date</Text>
                  <Text type="subtitle">{moment(budget?.startDate).format('DD/MM/YYYY')}</Text>
                </div>
                <div className={styles.savedField}>
                  <Text weight={600}>End Date</Text>
                  <Text type="subtitle">{moment(budget?.endDate).format('DD/MM/YYYY')}</Text>
                </div>
                <div className={styles.savedField}>
                  <Text weight={600}>Budget Amount</Text>
                  <Text type="subtitle">{`${budget?.currency} ${budget?.budgetValue}`}</Text>
                </div>
                <div className={styles.savedField}>
                  <Text weight={600}>Version</Text>
                  <Select
                    defaultValue={{ label: `Version ${latestVersion}`, value: budgetId }}
                    className={`react-select-container ${styles.budgetVersion}`}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    onChange={(e) => {
                      budgetServiceService
                        .getBudgetById(e.value, companyId)
                        .then((res) => setBudget(res.data.data));
                    }}
                    placeholder="Select Version"
                    options={createVersionOptions()}
                  />
                </div>
              </div>
              {budget && (
                <PieChartComponent
                  // totalAmount={budget?.budgetValue}
                  totalAmount={budget?.totalPOApprovedAmount}
                  currency={budget?.currency}
                  piechartData={calculateChartData()}
                />
              )}
            </div>
            <div className={styles.glTable}>
              <BudgetDetailsGlList
                companyId={companyId}
                getGlItems={getGlItems}
                monthsToRender={monthsToRender}
                glItems={glItems}
                budget={budget}
              />
            </div>
          </>
        )}
        {tab === 'notes' && (
          <>
            {!budget?.notes?.length ? (
              <Text>No data to display</Text>
            ) : (
              <NotesTab data={budget?.notes} />
            )}
          </>
        )}
        {tab === 'history' && <HistoryTab data={budget?.history} />}
        {tab === 'approval ' && <ApprovalTab data={budget?.approval?.approvers} />}
      </div>

      <div className={styles.approvedBudgetNotes}></div>
    </Box>
  );
};

export default BudgetDetails;
