import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../General/Icon';
import Tooltip from '../General/Tooltip';

const Mark = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ $size }) => ($size ? $size + 'px' : '26px')};
  height: ${({ $size }) => ($size ? $size + 'px' : '26px')};
  border: 1px solid #145ff5;
  color: #ffffff;
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
`;

const CheckboxLabel = styled.label`
  display: ${({ $reverse }) => ($reverse ? 'flex' : 'inline-flex')};
  align-items: center;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
  justify-content: ${({ $reverse }) => ($reverse ? 'space-between' : 'flex-start')};
  margin-bottom: 0;
  &:hover {
    cursor: ${({ disabled }) => !disabled && 'pointer'};
  }
  &:hover ${Mark} {
    border-color: ${({ disabled }) => !disabled && '#145ff5'};
  }
  & input[type='radio'] {
    display: none;
  }
  & input[type='radio'] + ${Mark} {
    margin-right: ${({ $reverse }) => ($reverse ? 0 : '8px')};
  }
  & input[type='radio'].error + ${Mark} {
    border-color: #cd3e27;
  }
  & input[type='radio']:checked + ${Mark} {
    border-color: #145ff5;
    color: #145ff5;
  }
`;
const TextLabel = styled.span`
  color: ${({ disabled }) => disabled && '#d9d9d9'};
`;

const Required = styled.span`
  color: #cd3e27;
`;

const Radio = forwardRef((props, ref) => {
  const { $size, $reverse, disabled, $labelRequired, $tooltip, ...other } = props;
  return (
    <CheckboxLabel onClick={(e) => e.stopPropagation()} $reverse={$reverse} disabled={disabled}>
      <input disabled={disabled} type="radio" {...other} ref={ref} />
      <Mark $size={$size}>
        <Icon $icon="radio" $width={$size ? $size - 2 : 24} $height={$size ? $size - 2 : 24} />
      </Mark>
      {other.label && other.label !== '' ? (
        <TextLabel disabled={disabled}>{other.label}</TextLabel>
      ) : null}
      {$labelRequired && <Required>*</Required>}
      {$tooltip && <Tooltip text={$tooltip} />}
    </CheckboxLabel>
  );
});

export default Radio;
