import { axiosInstance, baseUrl, s2pmsBaseUrl } from './API';

class BudgetService {
  getAllBudgetData(companyId, size, page) {
    const url = `${baseUrl}/company/${companyId}/budget`;
    let queryParams = '';
    if (size) {
      queryParams += `size=${size}`;
    }

    if (page) {
      queryParams += `${queryParams ? '&' : ''}page=${page}`;
    }

    if (queryParams) {
      return axiosInstance.get(`${url}?${queryParams}`);
    } else {
      return axiosInstance.get(url);
    }
  }

  searchBudgets(companyId, page = 0, size = 30, data) {
    return axiosInstance.post(
      baseUrl +
        `/company/${companyId}/budget/search?direction=DESC&orderBy=createdAt&page=${page}&size=${size}`,
      data,
    );
  }

  createBudget(data, companyId) {
    return axiosInstance.post(baseUrl + `/company/${companyId}/budget/create`, data);
  }

  editBudgetItem(data, companyId, budgetId) {
    return axiosInstance.post(baseUrl + `/company/${companyId}/budget/edit/${budgetId}`, data);
  }

  addBugetGlItems(data, companyId, budgetId) {
    return axiosInstance.post(
      s2pmsBaseUrl + `/company/${companyId}/budget/${budgetId}/line-item`,
      data,
    );
  }

  getBudgetById(budgetId, companyId) {
    return axiosInstance.get(baseUrl + `/company/${companyId}/budget/${budgetId}`);
  }

  getBugetGLItems(budgetId, companyId) {
    return axiosInstance.get(baseUrl + `/company/${companyId}/budget/${budgetId}`);
  }

  editBugetGLItems(data, companyId, budgetId, glId) {
    return axiosInstance.post(
      s2pmsBaseUrl + `/company/${companyId}/budget/${budgetId}/line-item/${glId}`,
      data,
    );
  }

  bugetApproval(data, companyId, budgetId) {
    return axiosInstance.post(baseUrl + `/company/${companyId}/budget/approval/${budgetId}`, data);
  }

  sendBudgetForApproval(data, companyId, budgetId) {
    return axiosInstance.post(
      baseUrl + `/company/${companyId}/budget/submit-approval/${budgetId}`,
      data,
    );
  }

  archiveBudgets(data, companyId, budgetIds) {
    const queryString = budgetIds.map((id) => `ids=${id}`).join('&');
    return axiosInstance.post(
      baseUrl + `/company/${companyId}/budget/toggle-active?${queryString}`,
    );
  }
  recallBudget(companyId, budgetId, data) {
    return axiosInstance.post(baseUrl + `/company/${companyId}/budget/recall/${budgetId}`, data);
  }
  deleteBudget(companyId, budgetId) {
    return axiosInstance.post(baseUrl + `/company/${companyId}/budget/delete/${budgetId}`);
  }
}

export default new BudgetService();
