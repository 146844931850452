import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTableBase from '../../UI/General/DataTableBase';
import moment from 'moment';
import Dropdown from '../../UI/General/Dropdown';
import generalStyles from '../../../styles/general.module.css';
import * as Button from '../../UI/Forms/Button';
import Icon from '../../UI/General/Icon';
import { Menu, MenuItem } from '../../UI/General/Menu';

const Users = ({ roleUsers, filterText }) => {
  const [initialTable, setInitialTable] = useState();
  const [table, setTable] = useState();

  const columns = useMemo(
    () => [
      {
        name: 'Full Name',
        selector: (row) => row.firstName + ' ' + row.lastName,
        sortable: true,
        wrap: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        format: (row) => `${row.email.slice(0, 15)}...`,
        wrap: true,
      },
      {
        name: 'Company',
        cell: (row) => row.company,
        sortable: true,
      },
      {
        name: 'Status',
        selector: (row) => (row.enabled ? 'true' : 'false'),
      },
      {
        name: 'createdDate',
        selector: (row) => row.createdDate,
        format: (row) => moment(row.createdDate).format('DD/MM/YYYY'),
        sortable: true,
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <Dropdown collapsible className={generalStyles.actionMenu}>
            <Dropdown.Header>
              <Button.Action $style="white" $width={32} $height={32} type="button">
                <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
              </Button.Action>
            </Dropdown.Header>
            <Dropdown.Body>
              <Menu className={generalStyles.actionMenuList}>
                <MenuItem onClick={() => removeUser(row.email)}>Remove User</MenuItem>
              </Menu>
            </Dropdown.Body>
          </Dropdown>
        ),
      },
    ],
    [],
  );

  const removeUser = (email) => {
    console.log(email);
  };

  const filterData = useCallback(() => {
    if (initialTable !== undefined) {
      const updData = initialTable.filter(
        (item) =>
          item.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
          item.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
          item.email.toLowerCase().includes(filterText.toLowerCase()),
      );
      setTable(updData);
    }
  }, [filterText, initialTable]);

  useEffect(() => {
    if (roleUsers !== undefined) {
      setInitialTable(roleUsers);
      setTable(roleUsers);
    }
  }, [roleUsers]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  return (
    <DataTableBase
      data={table}
      columns={columns}
      selectableRows
      selectableRowsHighlight
      onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
        console.log(allSelected);
        console.log(selectedCount);
        console.log(selectedRows);
      }}
      pagination={roleUsers !== undefined && roleUsers.length > 10}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 50]}
    />
  );
};

export default Users;
