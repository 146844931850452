import React, { useEffect, useState } from 'react';
import style from '../../styles/log_reg.module.css';
import Text from '../UI/Typography/Text';
import * as Button from '../UI/Forms/Button';
import { Controller, useForm } from 'react-hook-form';
import requestsServiceService from '../../services/requestsService.service';
import Toast from '../UI/General/Toast';
import { InputCode } from '../UI/Forms/Input';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import GradientText from '../UI/General/GradientText';

const Verify = ({ username, setStatus }) => {
  const [countDown, setCountDown] = useState(30);
  const [searchParams, setSearchParams] = useSearchParams();
  const { vendorId } = useParams();
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const type = searchParams.get('type');

  const navigate = useNavigate();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    setFocus,
    setValue,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (countDown > 0) {
      const timer = setTimeout(() => setCountDown(countDown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countDown]);

  const resendConfirmation = () => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .resendConfirmation(username)
      .then((res) => {
        if (res.status === 200 && res.data.message === 'Operation Successful') {
          setCountDown(30);
          setToast({
            opened: true,
            message: res.data.data,
            type: 'success',
            cb: () => setToast((toast) => ({ ...toast, opened: false })),
          });
        }
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err?.response?.data?.errorMessage,
          type: 'fail',
          cb: () => setToast((toast) => ({ ...toast, opened: false })),
        });
      });
  };

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .confirmSignUp(username, data.code.join(''))
      .then((res) => {
        setToast({
          opened: true,
          message: res.data.data,
          type: 'success',
          cb: () => navigate('/login'),
        });
      })
      .catch((error) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message:
            error.response.data.message !== undefined
              ? error.response.data.message
              : error.response.data.error,
          type: 'fail',
        });
      });
  };

  return (
    <>
      <div className={style.authHeaderFull}>
        <Text type="h2">Your account has not been confirmed.</Text>
        <GradientText $from="#313439B2" $to="#3778ffb2">
          <Text className={style.authHeaderSubText} type="body-1" weight={500}>
            To confirm your account, enter the 6-digit code that we sent to{' '}
            <a href={`mailto:${username}`}>{username}</a>. Please check you Spam folder as well.
          </Text>
        </GradientText>
      </div>
      <form className={style.authForm}>
        <div className={style.inputsCode}>
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <Controller
                key={index}
                name={`code.${index}`}
                control={control}
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 1,
                  pattern: /^[0-9]*$/,
                }}
                valueAsNumber
                defaultValue=""
                render={({ field }) => (
                  <InputCode
                    {...field}
                    type="text"
                    maxLength={1}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d$/.test(value)) {
                        setValue(`code.${index}`, value, { shouldValidate: true });
                        if (index < 5) {
                          setFocus(`code.${index + 1}`);
                        }
                      } else {
                        setValue(`code.${index}`, '', { shouldValidate: true });
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        if (!field.value && index > 0) {
                          setFocus(`code.${index - 1}`);
                        }
                      }
                      if (/^\d$/.test(e.key) && field.value) {
                        setValue(`code.${index}`, e.key, { shouldValidate: true });
                        if (index < 5) {
                          setFocus(`code.${index + 1}`);
                        }
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            ))}
        </div>
        <div className={style.btn + ' ' + style.btnBefore}>
          <Button.Main
            disabled={countDown !== 0}
            $primary
            $full
            $style="blank"
            $group
            type="button"
            onClick={() => {
              resendConfirmation();
            }}
          >
            {countDown !== 0 && (
              <span>
                <Text weight={400} type="body-1">
                  00:{String(countDown).padStart(2, '0')}
                </Text>
              </span>
            )}
            <span>
              <Text weight={400} type="body-1">
                Resend code
              </Text>
            </span>
          </Button.Main>
          <Button.Main
            onClick={handleSubmit(onSubmit)}
            $primary
            $full
            $style="blue"
            type="submit"
            disabled={!isValid || isButtonsBlocked}
          >
            Confirm & Sign in
          </Button.Main>
        </div>
      </form>
      {/*<Button.ActionLabeled*/}
      {/*  $primary*/}
      {/*  $full*/}
      {/*  $style="blue"*/}
      {/*  type="button"*/}
      {/*  onClick={() => setStatus(type === 'vendor' || vendorId ? 'sign-up-vendor' : 'sign-up')}*/}
      {/*>*/}
      {/*  <Text type="body-1" weight={600}>*/}
      {/*    Use different email address*/}
      {/*  </Text>*/}
      {/*</Button.ActionLabeled>*/}
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Verify;
