import React from 'react';
import { readableTitleFromBackend } from '../../../utils/readableTitleFromBackend';
import styled, { css } from 'styled-components';

const Span = styled.span`
  color: ${({ $color }) => makeRgba($color)};
  ${({ $pill }) => {
    if ($pill) {
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${({ $fit }) => $fit ? 'fit-content' : '140px'};
        padding: ${({ $fit }) => $fit ? '0 8px' : '0'};
        height: 30px;
        background-color: ${({ $color }) => makeRgba($color, 0.15)};
      `;
    }
  }}
`;
const makeRgba = (colors, opacity = 1) => `rgba(${colors[0]},${colors[1]},${colors[2]},${opacity})`;

const checkStatus = (status) => {
  let color;
  switch (status) {
    case 'DRAFT':
    case 'NOT_FULFILLED':
    case 'NOT_INVOICED':
    case 'OUTSTANDING':
      color = [172, 174, 201];
      break;
    case 'PENDING_APPROVAL':
    case 'PARTIALLY_FULFILLED':
    case 'PARTIALLY_PAID':
      color = [244, 153, 0];
      break;
    case 'APPROVED':
    case 'FULFILLED':
    case 'PAID':
      // color = [20, 95, 245];
      color = [0, 196, 111];
      break;
    case 'REJECTED':
    case 'CLOSED':
      color = [197, 21, 21];
      break;
    case 'Variance':
      color = 'aqua';
      break;
    default:
      color = [172, 174, 201];
  }
  return color;
};

const Status = ({ value, wordCount, pill, text, ...rest }) => {
  return (
    <Span $pill={pill} $fit={rest?.fit} $color={checkStatus(value)} {...rest}>
      {readableTitleFromBackend(text ?? value, wordCount)}
    </Span>
  );
};

export default Status;
