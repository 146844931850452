import { useStore } from '../../store/store';
import CompanyContent from '../../components/Admins/ApprovalWorkflow/Overview/CompanyContent';
import companyStyles from '../../styles/companySetup.module.css';

const ApprovalWorkflow = () => {
  const company = useStore((state) => state.company);

  return (
    <div className={companyStyles.accordionItems}>
      <CompanyContent companyId={company.id} />
    </div>
  );
};

export default ApprovalWorkflow;
