import Modal from '../../components/UI/Modal/Modal';
import { Controller, useForm } from 'react-hook-form';
import Textarea from '../../components/UI/Forms/Textarea';
import generalStyles from '../../styles/general.module.css';
import budgetServiceService from '../../services/budgetService.service';
import * as Button from '../../components/UI/Forms/Button';
import React from 'react';

const ActionModal = ({ setHandledBudget, handledBudget, companyId, setToast, refetch }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm();
  const closeModal = () => {
    reset();
    setHandledBudget((handledBudget) => ({ ...handledBudget, budget: { id: null, name: null } }));
  };
  const handleBudgetAction = async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    if (handledBudget.type === 'Recall') {
      const recallReason = { recallReason: data.notes };
      await budgetServiceService
        .recallBudget(companyId, handledBudget.budget.id, recallReason)
        .then((_res) => {
          closeModal();
          refetch();
          setToast({
            opened: true,
            message: `Budget ${handledBudget.type.toLowerCase()}ed successfully`,
            type: 'success',
          });
        })
        .catch((error) => {
          setToast({
            opened: true,
            message: error.toString(),
            type: 'fail',
          });
        });
    } else
      await budgetServiceService
        .deleteBudget(companyId, handledBudget.budget.id)
        .then((_res) => {
          closeModal();
          refetch();
          setToast({
            opened: true,
            message: `Budget ${handledBudget.type.toLowerCase()}ed successfully`,
            type: 'success',
          });
        })
        .catch((error) => {
          setToast({
            opened: true,
            message: error.toString(),
            type: 'fail',
          });
        });
  };
  return (
    <Modal
      $show={!!handledBudget.budget.id}
      $close={closeModal}
      $title={`${handledBudget.type} ${handledBudget.budget.name}`}
      $radius={12}
    >
      <div className={generalStyles.modalContainer}>
        {handledBudget.type === 'Discard' ? (
          <div>Do you want to continue deleting this item?</div>
        ) : (
          <div className="inp-container">
            <Controller
              name="notes"
              control={control}
              rules={{
                maxLength: { value: 500, message: 'Maximum 500 characters' },
                required: {
                  value: true,
                  message: 'Recall reason is required',
                },
                validate: {
                  notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                  allowed: (v) =>
                    /^[A-Za-z0-9\s]*$/.test(v) || 'Only Alpha and Numerical characters',
                },
              }}
              render={({ field }) => (
                <Textarea
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  {...field}
                  $counter
                  $counterMax={500}
                  placeholder="Enter the reason for recall"
                />
              )}
            />
            {errors.notes && (
              <p style={{ transform: 'translate(0, -15px)' }} className="error-message">
                {errors.notes.message}
              </p>
            )}
          </div>
        )}
        <div className={generalStyles.modalButtons}>
          <Button.Main
            onClick={handleSubmit(handleBudgetAction)}
            $primary
            $style="blue"
            disabled={!isValid}
            type="submit"
            form="cForm"
          >
            Continue
          </Button.Main>
          <Button.Main onClick={closeModal} $primary $style="gray" type="submit" form="cForm">
            Close
          </Button.Main>
        </div>
      </div>
    </Modal>
  );
};

export default ActionModal;
