import React, { useEffect, useState } from 'react';
import style from '../../styles/log_reg.module.css';
import Text from '../../components/UI/Typography/Text';
import Input, { Password } from '../../components/UI/Forms/Input';
import * as Button from '../../components/UI/Forms/Button';
import 'react-phone-number-input/style.css';
import { useForm, Controller } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import Toast from '../UI/General/Toast';
import PasswordTest from '../UI/Forms/PasswordTest';
import requestsService from '../../services/requestsService.service';
import { regExps } from '../../utils/regExps';
import GradientText from '../UI/General/GradientText';
import CryptoJS from 'crypto-js';

const SignUpVendor = ({ setStatus, setUsername, setDataToRemember, dataToRemember }) => {
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const { vendorId } = useParams();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    reset,
    formState: { errors, isDirty, isValid, dirtyFields },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (!dataToRemember) return;
    reset(dataToRemember);
  }, []);

  let count = 0

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = { ...data };
    if (vendorId) {
      requestBody.externalId = vendorId;
    }
    requestsService
      .checkUserExist(data.email)
      .then(() => {
        requestsService
          .registerNewUserAsVendor(requestBody)
          .then((res) => {
            count++
            if (count === 1){
              window.dataLayer.push({
                event: 'sign_up',
                method: 'method',
                userId: CryptoJS.AES.encrypt(res.data.data.id, process.env.REACT_APP_AES_SECRET).toString(),    // SHA-256 hashed unique user id from backend
                email: CryptoJS.AES.encrypt(res.data.data.email, process.env.REACT_APP_AES_SECRET).toString()      // SHA-256 hashed unique e-mail address from backend
              });
              console.log(window.dataLayer);
            }
            console.log('res', res);
            const user = res.data.data;
            setUsername(requestBody.email);
            setStatus('verify');
            setDataToRemember(requestBody);
            if (vendorId) {
              requestsService.updateUserInfo(user.id, {
                ...user,
                externalId: vendorId,
              });
            }
          })
          .catch((error) => {
            console.error(error);
            setIsButtonsBlocked(false);
            setToast({
              opened: true,
              message: 'error',
              type: 'fail',
            });
          });
      })
      .catch((error) => {
        console.log('check user Error');
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message:
            error.response.data.message !== undefined
              ? error.response.data.message
              : error.response.data.error,
          type: 'fail',
        });
      });
  };

  return (
    <>
      <div className={style.logo}>
        <img src="assets/img/login_register/logo_small.svg" alt="Poms" />
      </div>
      <div className={style.authHeader}>
        <Text type="h2" weight={600}>
          Register for SpendPro Vendor Portal
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text className={style.authHeaderSubText} type="h5" weight={400}>
            Start setting up your account
          </Text>
        </GradientText>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={style.authForm}>
        <div className={style.inputs}>
          <div className="inp-container">
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Email is required',
                },
                maxLength: {
                  value: 50,
                  message: 'Email must be at most 50 characters',
                },
                pattern: {
                  value: regExps.email,
                  message: 'Email is not valid',
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="email"
                  placeholder="Business Email *"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.email && <p className="error-message">{errors.email?.message}</p>}
          </div>
          <div className="inp-container" style={{ position: 'relative' }}>
            {showModalPassword && errors.password && (
              <div className={style.passwordTest}>
                <PasswordTest name={'password'} isDirty={dirtyFields} control={control} />
              </div>
            )}
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Password is required',
                },
                minLength: {
                  value: 8,
                  message: 'At least 8 characters',
                },
                maxLength: {
                  value: 35,
                  message: 'Maximum 35 characters',
                },
                validate: {
                  oneLetter: (v) => /[a-z]+/.test(v),
                  oneCapitalLetter: (v) => /[A-Z]+/.test(v),
                  oneNumber: (v) => /\d+/.test(v),
                  oneSpecialChar: (v) => /[!@#$%&?]+/.test(v),
                  onlyAllowed: (v) => /^[A-Za-z0-9!@#$%&?]*$/.test(v),
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <Password
                  placeholder="Password *"
                  {...field}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  onFocus={() => {
                    trigger('password');
                    setShowModalPassword(true);
                  }}
                  onBlur={() => setShowModalPassword(false)}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className={style.btn}>
          <Button.Main
            $primary
            $full
            $style="blue"
            type="submit"
            disabled={(!isDirty && !dataToRemember) || !isValid || isButtonsBlocked}
          >
            <span>Sign Up</span>
          </Button.Main>
        </div>
        <div className={style.btnBefore}>
          <Text type="body-3" weight={400}>
            By signing up you are indirectly accepting our{' '}
            <Link to="/terms"  target="_blank">Terms and Conditions.</Link>
          </Text>
        </div>
      </form>
      <div className={style.btnAfter}>
        <Text type="body-1" weight={400}>
          Already have an account?
        </Text>
        <Button.ActionLabeled $primary $full $style="blue" type="button">
          <Link to="/login">Log In</Link>
        </Button.ActionLabeled>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default SignUpVendor;
