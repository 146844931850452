import s2pmsService from '../services/spmsService.service';

export const setupSlice = (set, get) => ({
  showCompanyDropdown: true,
  switchCompanyDropdown: (cValue) => {
    set({ showCompanyDropdown: cValue });
  },
  steps: [
    {
      number: 1,
      text: 'Company',
      link: '/company-setup',
      type: 'company',
      done: false,
    },
    {
      number: 2,
      text: 'Location',
      link: '/company-setup?section=branch',
      type: 'location',
      done: false,
    },
    {
      number: 3,
      text: 'Department',
      link: '/department-setup',
      type: 'department',
      done: false,
    },
    {
      number: 4,
      text: 'Account Code',
      link: '/gl-setup',
      type: 'gl',
      done: false,
    },
  ],
  vendorSteps: [
    {
      number: 1,
      text: 'Legal Information',
      type: 'legal',
      done: false,
    },
    {
      number: 2,
      text: 'Contact information',
      type: 'contact',
      done: false,
    },
    {
      number: 3,
      text: 'Address Information',
      type: 'address',
      done: false,
    },
    {
      number: 4,
      text: 'Financial Information',
      type: 'financial',
      done: false,
    },
    {
      number: 5,
      text: 'Required documents',
      type: 'docs',
      done: false,
    },
  ],
  stepsDone: false,
  checkDone: () => {
    const isUserVendor = get().user.roles.find((role) => role.role.name === 'VENDOR');
    const currentSteps = isUserVendor ? 'vendorSteps' : 'steps';
    if (get()[currentSteps].filter((item) => item.done === false).length > 0) {
      set({ stepsDone: false });
    } else {
      set({ stepsDone: true });
    }
  },
  setSteps: async () => {
    const isUserVendor = get().user.roles.find((role) => role.role.name === 'VENDOR');
    const currentSteps = isUserVendor ? 'vendorSteps' : 'steps';
    const updateState = (type, value) => {
      const newSteps = get()[currentSteps].map((item) =>
        item.type === type ? { ...item, done: value } : item,
      );
      set({ [currentSteps]: newSteps });
    };
    if (!isUserVendor) {
      if (get().company !== null) {
        updateState('company', true);
        await s2pmsService.getMetrics(get().company.id).then((r) => {
          if (r.data.message === 'Operation Successful') {
            const metrics = r.data.data;
            updateState('location', metrics.hasLocations);
            updateState('department', metrics.hasDepartments);
            updateState('gl', metrics.hasGl);
            get().checkDone();
          }
        });
      } else {
        updateState('company', false);
        updateState('location', false);
        updateState('department', false);
        updateState('gl', false);
      }
    } else {
      const vendorId = get().user.externalId;
      if (!vendorId) {
        updateState('legal', false);
        updateState('contact', false);
        updateState('address', false);
        updateState('financial', false);
        updateState('docs', false);
        get().checkDone();
      } else {
        const res = await s2pmsService.getVendor(vendorId);
        const vendorData = res.data.data;
        const legal =
          !!vendorData.legalName &&
          !!vendorData.category &&
          !!vendorData.subCategory &&
          !!vendorData.registrationNo &&
          !!vendorData.legalStructure &&
          !!vendorData.countryOfIncorporation;
        updateState('legal', legal);
        const contact =
          !!vendorData.vendorContacts.length &&
          vendorData.vendorContacts?.find((contact) => contact.contactType === 'ENTERED_BY_VENDOR');
        updateState('contact', contact);
        const address = !!vendorData?.address;
        updateState('address', address);
        const financial = !!vendorData?.vendorFinancialInfo;
        updateState('financial', financial);
        const docs = !!vendorData.attachments.length;
        updateState('docs', docs);
        get().checkDone();
      }
    }
  },
});
