import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useStore } from '../../store/store';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/companySetup.module.css';
import generalStyles from '../../styles/general.module.css';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import Toast from '../../components/UI/General/Toast';
import spmsServiceService from '../../services/spmsService.service';
import * as Button from '../../components/UI/Forms/Button.jsx';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import LocationAutocomplete from '../../components/shared/LocationAutocomplete.jsx';
import { regExps } from '../../utils/regExps.js';

const AddLocation = () => {
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
  });
  const companyId = useStore((state) => state.company?.id);
  const { id: locationId } = useParams();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!locationId) return;
    const getLocationData = async () => {
      spmsServiceService.getLocation(companyId, locationId).then((res) => {
        const location = res.data.data;
        reset({
          ...location,
        });
      });
    };
    getLocationData();
  }, []);

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    let requestBody = {
      ...data,
    };
    if (locationId) {
      requestBody = { ...requestBody, id: locationId };
      spmsServiceService
        .updateLocation(companyId, requestBody)
        .then((res) => {
          setToast({
            opened: true,
            message: 'Saved',
            type: 'success',
            cb: () => navigate('/locations'),
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    } else
      spmsServiceService
        .createLocation(companyId, requestBody)
        .then((res) => {
          setToast({
            opened: true,
            message: 'Location created',
            type: 'success',
            cb: () => navigate('/locations'),
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
  };

  return (
    <div>
      <Box $mobExtend $asHolder $noOverflow>
        <div className={styles.title}>
          <Text weight={500} type="subtitle">
            {!locationId ? 'Add' : 'Edit'} Location
          </Text>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form} id="cForm">
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Location is required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Location must be at most 50 characters',
                  },
                  pattern: {
                    value: regExps.alphaNumericalSpecial,
                    message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Location Name"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Location Name"
                    $labelRequired
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Email must be at most 50 characters',
                  },
                  pattern: {
                    value: regExps.email,
                    message: 'Email is not valid',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Email Address"
                    $labelRequired
                    {...field}
                  />
                )}
              />
              {errors.email && <p className="error-message">{errors.email?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $isRequired $title="Phone Number" />
              <Controller
                name="phoneNumber"
                rules={{
                  required: {
                    value: true,
                    message: 'Phone number is required',
                  },
                  validate: {
                    isValid: (value) =>
                      (isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || '')) ||
                      'Enter a valid phone number',
                  },
                }}
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    placeholder="Enter Phone Number"
                    limitMaxLength={true}
                    international={true}
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                  />
                )}
              />
              {errors.phoneNumber && <p className="error-message">{errors.phoneNumber.message}</p>}
            </div>
            <LocationAutocomplete
              $hasLabel
              control={control}
              errors={errors}
              setValue={setValue}
              $fullFields
            />
          </div>
          <div className={generalStyles.pageButtons} style={{ marginTop: 24 }}>
            <Button.Main
              $primary
              $style={'blue'}
              type="submit"
              form="cForm"
              disabled={!isDirty || !isValid || isButtonsBlocked}
            >
              <Text weight={400}>Save</Text>
            </Button.Main>
            <Button.Main
              $primary
              onClick={() => navigate('/locations')}
              type="button"
              $style={'gray'}
            >
              Discard
            </Button.Main>
          </div>
        </form>
      </Box>
      {toast.opened ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </div>
  );
};

export default AddLocation;
