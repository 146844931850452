import { useCallback, useEffect, useMemo, useState } from 'react';
import Dropdown from '../../UI/General/Dropdown';
import * as Button from '../../UI/Forms/Button.jsx';
import Icon from '../../UI/General/Icon.jsx';
import { Menu, MenuItem } from '../../UI/General/Menu';
import generalStyles from '../../../styles/general.module.css';
import styles from '../../../styles/notifications.module.css';
import Box from '../../UI/General/Box';
import { Link, useNavigate } from 'react-router-dom';
import spmsServiceService from '../../../services/spmsService.service.js';
import Toast from '../../UI/General/Toast.jsx';
import AddButton from '../../shared/AddButton.jsx';
import TabsSlider from '../../UI/General/TabsSlider.jsx';
import DataTableBaseRemote from '../../UI/General/DataTableBaseRemote';
import Input from '../../UI/Forms/Input.jsx';
import Text from '../../UI/Typography/Text.jsx';

const CompanyNotification = ({ companyId }) => {
  const [tab, setTab] = useState('all');
  const [filterText, setFilterText] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(filterText);
  const [trigger, setTrigger] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();

  const getData = useCallback(
    async (page, size) => {
      const requestBody = {
        name: debouncedValue,
      };
      // if (tab !== 'all') {
      //   requestBody.active = tab === 'active';
      // }
      return await spmsServiceService.searchNotifications(companyId, page, size, requestBody);
    },
    [tab, debouncedValue, trigger],
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const handleColumnValues = (value) => {
    return value
      .split('_')
      .map((word) => word.slice(0, 1) + word.slice(1).toLowerCase())
      .join(' ');
  };

  const columns = useMemo(
    () => [
      {
        name: 'Notification Name',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <Link
            className={generalStyles.tableLink}
            to={`/notification/${companyId}/edit/${row.id}`}
          >
            <span data-content={'Notification Name'}>
              <span>{row.name}</span>
            </span>
          </Link>
        ),
      },
      {
        name: 'Module',
        sortable: true,
        selector: (row) => row.module,
        cell: (row) => (
          <span data-content={'Module'} className={generalStyles.tableValue}>
            <span>{handleColumnValues(row.module)}</span>
          </span>
        ),
      },
      {
        name: 'Event',
        sortable: true,
        selector: (row) => row.triggerEvent,
        cell: (row) => (
          <span data-content={'Event'} className={generalStyles.tableValue}>
            <span>{handleColumnValues(row.triggerEvent)}</span>
          </span>
        ),
      },
      {
        name: 'Users',
        cell: (row) => (
          <div data-content={'Users'} className={generalStyles.tableValue}>
            <div className={styles.users}>
              {row.recipients.map((user) => (
                <span key={user}>{user.name}</span>
              ))}
            </div>
          </div>
        ),
      },
      {
        name: 'Status',
        cell: (row) => (
          <div data-content={'Status'} className={generalStyles.tableValue}>
            {row.status}
          </div>
        ),
      },

      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => {
          return (
            <div className={generalStyles.actionMenuHolder}>
              <Dropdown collapsible className={generalStyles.actionMenu}>
                <Dropdown.Header>
                  <Button.Action $style="white" $width={32} $height={32}>
                    <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                  </Button.Action>
                </Dropdown.Header>
                <Dropdown.Body>
                  {row.status === 'ACTIVE' ? (
                    <Menu className={generalStyles.actionMenuList}>
                      <MenuItem
                        onClick={() => navigate(`/notification/${companyId}/edit/${row.id}`)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => archiveNotification(row.id)}>Archive</MenuItem>
                    </Menu>
                  ) : (
                    <Menu className={generalStyles.actionMenuList}>
                      <MenuItem onClick={() => activateNotification(row.id)}>Activate</MenuItem>
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [tab],
  );

  const archiveNotification = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .archiveNotification(companyId, id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Notification archived',
          type: 'success',
        });
        setTrigger((trigger) => !trigger);
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.toString(),
          type: 'fail',
        });
      });
  };
  const activateNotification = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .activateNotification(companyId, id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Notification activated',
          type: 'success',
        });
        setTrigger((trigger) => !trigger);
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.toString(),
          type: 'fail',
        });
      });
  };

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#fff"
        placeholder="Search by Name"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <Box $radius={12} $mobExtend $asHolder>
      <div className={styles.title}>
        <Text type="subtitle" weight={500}>
          Notifications
        </Text>
      </div>
      <div className={generalStyles.tabSection}>
        <TabsSlider>
          {/* <span onClick={() => setTab('all')}>All</span>
          <span onClick={() => setTab('active')}>Active</span>
          <span onClick={() => setTab('archived')}>Archive</span> */}
        </TabsSlider>
        <div className={generalStyles.addItemButton}>
          <AddButton $click={() => navigate(`/notification/${companyId}/add`)} />
        </div>
      </div>
      {/* <div className={generalStyles.search}>{filterCmp}</div> */}
      <DataTableBaseRemote
        columns={columns}
        selectableRows={false}
        paginationResetDefaultPage={false}
        fetchData={getData}
      />
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default CompanyNotification;
