import { useStore } from '../../store/store';
import companyStyles from '../../styles/companySetup.module.css';
import CompanyNotification from '../../components/Admins/Notification/CompanyNotification';

const NotificationList = () => {
  const company = useStore((state) => state.company);

  return (
    <div className={companyStyles.accordionItems}>
      <CompanyNotification companyId={company.id} />
    </div>
  );
};

export default NotificationList;
