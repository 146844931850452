import React, { memo } from 'react';
import Text from '../../../UI/Typography/Text';
import styles from '../../../../styles/purchaseOrders.module.css';
import styled, { css } from 'styled-components';

const Step = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #8a8c8f;
  color: ${({ $pending }) => ($pending ? '#e89a24' : '#8a8c8f')};
  position: relative;
  ${({ $last }) => {
    if (!$last) {
      return css`
        &:after {
          content: '';
          position: absolute;
          top: 14px;
          left: 100%;
          width: 63px;
          height: 1px;
          background-color: #8a8c8f;
        }
      `;
    }
  }}
  ${({ $completed, $pending }) => {
    if ($completed) {
      return css`
        border: none !important;
      `;
    } else if ($pending) {
      return css`
        border: 1px solid #e89a24;
      `;
    }
  }}
`;

const StepText = styled.div`
  min-width: 58px;
  text-align: center;
  color: ${({ $completed, $pending }) => ($completed ? '#00c46f' : $pending ? '#e89a24' : '')};
`;

const StatusBar = memo(({ data }) => {
  const tableData = [
    {
      completed: data.purchaseOrderState === 'APPROVED',
      text: 'Approved',
    },
    {
      completed: data.fulfillmentStatus === 'FULFILLED',
      pending: data.fulfillmentStatus === 'PARTIALLY_FULFILLED',
      text: 'Received',
    },
    {
      completed: data.invoiceStatus === 'APPROVED',
      pending: data.invoiceStatus === 'PENDING_APPROVAL',
      text: 'Invoiced',
    },
    {
      completed: data.paymentStatus === 'PAID',
      pending: data.paymentStatus === 'PARTIALLY_PAID',
      text: 'Paid',
    },
    {
      completed: data.purchaseOrderState === 'CLOSED',
      text: 'Closed',
    },
  ];

  return (
    <div className={styles.statusBar}>
      {tableData.map((item, index) => (
        <div key={item.text} className={styles.statusBarStep}>
          <Step
            $completed={item.completed}
            $pending={item.pending}
            $last={index === tableData.length - 1}
          >
            {item.completed ? (
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0ZM19.8295 10.4545L13.125 17.159L10.1705 14.2045C9.73115 13.7652 9.01884 13.7652 8.5795 14.2045C8.14017 14.6438 8.14017 15.3562 8.5795 15.7955L12.3295 19.5455C12.7688 19.9848 13.4812 19.9848 13.9205 19.5455L21.4205 12.0455C21.8598 11.6062 21.8598 10.8938 21.4205 10.4545C20.9812 10.0152 20.2688 10.0152 19.8295 10.4545Z"
                  fill="#00C46F"
                />
              </svg>
            ) : (
              <Text weight={400} type="body-3">
                {index + 1}
              </Text>
            )}
          </Step>

          <StepText $completed={item.completed} $pending={item.pending}>
            <Text type="body-3" weight={500}>
              {item.text}
            </Text>
          </StepText>
        </div>
      ))}
    </div>
  );
});

export default StatusBar;
