import React from 'react';
import styles from '../../../styles/companySetup.module.css';
import GlSetupBody from '../../../components/Admins/Company/Gl/GlSetupBody';
import { useStore } from '../../../store/store';

const GlSetup = () => {
  const company = useStore((state) => state.company);

  return (
    <div className={styles.accordionItems}>
      <GlSetupBody companyId={company.id} />
    </div>
  );
};

export default GlSetup;
