import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/budgets.module.css';
import * as Button from '../../components/UI/Forms/Button';
import generalStyles from '../../styles/general.module.css';

const EditApprovedGl = ({
  data,
  setEditableGl,
  setToast,
  editApprovedBudget,
  getMonthNameByIndex,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const currentMonth = moment().format('MM-YYYY');
  const allocations = watch('monthlyAllocations');

  const isFieldDisabled = (month) => {
    const currentDateFormatted = `01-${currentMonth}`;
    const monthDate = `01-${month}`;
    return moment(currentDateFormatted) >= moment(monthDate);
  };

  useEffect(() => {
    setValue('monthlyAllocations', data.allocations);
  }, []);
  const fixStringBudgetValue = (value) =>
    typeof value === 'string' ? parseFloat(value.replace(/,/g, ''), 10) : value === '' ? 0 : value;
  const onSubmitEdit = async (monthData) => {
    setToast((item) => ({ ...item, opened: false }));
    const allocations = monthData.monthlyAllocations.map((month) => ({
      ...month,
      amount: fixStringBudgetValue(month.amount),
    }));
    const updatedTotalBudget = allocations.reduce((acc, curr) => acc + curr.amount, 0);
    const updatedGlLine = {
      notes: data.notes,
      departmentId: data.departmentId,
      branchId: data.branchId,
      glAccountId: data.glAccountId,
      id: data.id,
      allocations: allocations,
      glBudgetValue: updatedTotalBudget,
    };
    editApprovedBudget(updatedGlLine);
  };

  const discard = () => {
    setEditableGl(null);
    reset();
  };
  return (
    <form className={styles.expandedGlContainer} onSubmit={handleSubmit(onSubmitEdit)}>
      <div className={styles.editBudgetSection}>
        <Text weight={600}>Budget Allocated / Budget Used - Month Wise</Text>
        <div className={generalStyles.fieldsSix}>
          {data.allocations?.map((allocation, index) => (
            <div className={styles.expandedGlLine} key={allocation.month}>
              <Text weight={600}>{getMonthNameByIndex(allocation.month)}</Text>
              {isFieldDisabled(allocation.month) ? (
                <Text weight={500}>
                  {allocation.totalPOApprovedAmount ?? 0} / {allocation.amount}
                </Text>
              ) : (
                <div className="inp-container">
                  <Controller
                    name={`monthlyAllocations[${index}]`}
                    control={control}
                    render={({ field: { ref, ...other } }) => (
                      <NumericFormat
                        {...other}
                        value={allocations ? allocations[index]?.amount : '5'}
                        allowLeadingZeros
                        thousandSeparator=","
                        placeholder="2500.00"
                        // prefix={currency + ' '}
                        decimalScale={2}
                        style={
                          errors?.monthlyAllocations && errors.monthlyAllocations[index]
                            ? { borderColor: '#cd3e27' }
                            : {}
                        }
                        className={styles.numberInput}
                        onChange={(e) => {
                          console.log(other);
                          const amountNumber = e.target.value.split(' ').at(-1);
                          other.onChange({ month: allocation.month, amount: amountNumber });
                        }}
                      />
                    )}
                  />
                  {errors?.monthlyAllocations && (
                    <p className="error-message">
                      {errors.monthlyAllocations[index]?.amount?.message}
                    </p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={generalStyles.pageButtons}>
        <Button.Main $primary $style="blue" type="submit">
          Update
        </Button.Main>
        <Button.Main $primary $style="gray" type="button" onClick={discard}>
          Discard
        </Button.Main>
      </div>
    </form>
  );
};
export default EditApprovedGl;
