import React, { useState } from 'react';
import style from "../../styles/log_reg.module.css";
import Text from "../UI/Typography/Text";
import {Controller, useForm} from "react-hook-form";
import {Password} from "../UI/Forms/Input";
import * as Button from "../UI/Forms/Button";
import {Auth} from "aws-amplify";
import PasswordTest from "../UI/Forms/PasswordTest";
import rs from '../../services/requestsService.service'
import GradientText from '../UI/General/GradientText';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Toast from '../UI/General/Toast';

const NewPassword = ({user, setStatus, setUser}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const {handleSubmit, trigger, control, formState: {errors, isDirty, isValid, dirtyFields}, getValues} = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      repeat_password: ""
    },
  });

  async function createNewPassword({password}) {
    setToast((item) => ({ ...item, opened: false }));
    if (!!searchParams?.get('token')){
      const data = {
        token: searchParams?.get('token')?.toString(),
        newPassword: password
      }
      await rs.forgotPasswordConfirm(data).then(r=>{
        console.log(r);
        if (r.data.message === "Operation Successful"){
          navigate('/login?reset=true')
          setStatus('sign-in')
        }
      }).catch(err => {
        console.log(err.response?.data?.message);
        setToast({
          opened: true,
          message: err.response?.data?.message ?? 'Error',
          type: 'fail',
        });
      })
    }else {
      const updatedUser = await Auth.completeNewPassword(
        user,
        password
      );
      const email = await updatedUser.challengeParam.userAttributes.email
      await rs.activateUser(email)
      setUser(updatedUser)
    }
  }

  return (
    <div className={style.authContent}>
      <div className={style.authHeader + " " + style.cnpHeader}>
        <Text type="h2" weight={600}>Enter your new password</Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text type="h5" weight={400}>Enter a new password below to change your password.</Text>
        </GradientText>
      </div>
      <form onSubmit={handleSubmit(createNewPassword)} className={style.authForm}>
        <div className={style.inputs +" "+style.inputsColomn}>

          <div className="inp-container">
            {showModalPassword && errors.password && (
              <div className={style.passwordTest}>
                <PasswordTest name={'password'} isDirty={dirtyFields} control={control} />
              </div>
            )}
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Password is required',
                },
                minLength: {
                  value: 8,
                  message: 'At least 8 characters',
                },
                maxLength: {
                  value: 35,
                  message: 'Maximum 35 characters',
                },
                validate: {
                  oneLetter: (v) => /[a-z]+/.test(v),
                  oneCapitalLetter: (v) => /[A-Z]+/.test(v),
                  oneNumber: (v) => /\d+/.test(v),
                  oneSpecialChar: (v) => /[!@#$%&?]+/.test(v),
                  onlyAllowed: (v) => /^[A-Za-z0-9!@#$%&?]*$/.test(v),
                },
              }}
              render={({ field }) => (
                <Password
                  placeholder="New password (8+ letters please)"
                  {...field}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  onFocus={() => {
                    trigger('password');
                    setShowModalPassword(true);
                  }}
                  onBlur={() => setShowModalPassword(false)}
                  onChange={(e) => {
                    if (dirtyFields["repeat_password"]){
                      return (field.onChange(e), trigger("repeat_password"))
                    }else {
                      return field.onChange(e)
                    }
                  }}
                />
              )}
            />
          </div>
          <div className="inp-container">
            <Controller
              name="repeat_password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Password is required"
                },
                validate: {
                  isValid: (value) => value === getValues("password") || "Password don't match"
                }
              }}
              render={({field}) => (
                <Password
                  {...field}
                  placeholder="Confirm new password"
                  $iconColor={errors.hasOwnProperty(field.name) ? "#CD3E27" : "#8A8B9D"}
                  className={errors.hasOwnProperty(field.name) && "error"}
                />
              )}
            />
            {errors.repeat_password && <p className="error-message">{errors.repeat_password?.message}</p>}
          </div>
        </div>
        <div className={style.btn}>
          <Button.Main $primary $full $style="blue" type="submit" disabled={!isDirty || !isValid}>
            Submit
          </Button.Main>
        </div>
      </form>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </div>
  );
};

export default NewPassword;
