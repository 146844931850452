import { useNavigate } from 'react-router-dom';
import Box from '../../components/UI/General/Box.jsx';
import Text from '../../components/UI/Typography/Text.jsx';
import styles from '../../styles/companySetup.module.css';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { useStore } from '../../store/store.js';
import { timezones } from '../../constants/timezones.js';
import { useEffect, useState } from 'react';
import spmsServiceService from '../../services/spmsService.service.js';

const CompanyProfile = () => {
  const navigate = useNavigate();
  const company = useStore((state) => state.company);
  const [companyLogo, setCompanyLogo] = useState(null);

  useEffect(() => {
    if (company.logo) {
      spmsServiceService.getAttachmentsByKey(company.logo.key).then((res) => {
        setCompanyLogo(res.data.data);
      });
    }
  }, []);

  const createNextPoNumber = () => {
    const values = [
      // company.purchaseOrderProperties?.poNumberFormat,
      company.purchaseOrderProperties?.poPrefix,
      company.purchaseOrderProperties?.poFormattedNumber,
    ];
    return values.filter((value) => !!value).join('-');
  };
  const createNextRQNumber = () => {
    const values = [
      // company.requisitionProperties?.requisitionNumberFormat,
      company.requisitionProperties?.requisitionPrefix,
      company.requisitionProperties?.requisitionFormattedNumber,
    ];
    return values.filter((value) => !!value).join('-');
  };

  return (
    <Box>
      <div className={styles.title}>
        <Text type="subtitle" weight={500}>
          Company Profile
        </Text>
        <Button.Main
          $style="blue"
          type="button"
          onClick={() => navigate(`/company-profile/edit/${company.id}`)}
        >
          <Text weight={400}>Edit</Text>
        </Button.Main>
      </div>
      <div className={styles.container}>
        <div className={styles.companySection}>
          <div className={generalStyles.fieldsThree}>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Legal Name
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  {company.name || '-'}
                </Text>
              </div>
            </div>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Trading Name
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  {company.tradingName || '-'}
                </Text>
              </div>
            </div>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Registration Number
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  {company.registrationNumber || '-'}
                </Text>
              </div>
            </div>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Tax Number
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  {company.taxNumber || '-'}
                </Text>
              </div>
            </div>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Country of Incorporation
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  {company.country || '-'}
                </Text>
              </div>
            </div>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Industry
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  {company.industry || '-'}
                </Text>
              </div>
            </div>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Default Currency
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  {company.defaultCurrency || '-'}
                </Text>
              </div>
            </div>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Timezone
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  {timezones.find((timezone) => timezone.label === company.timezone)?.label || '-'}
                </Text>
              </div>
            </div>
            <div>
              <div className={styles.fieldLabel}>
                <Text type="body-2" weight={400}>
                  Company Logo
                </Text>
              </div>
              <div className={styles.fieldValue}>
                <Text type="body-2" weight={400}>
                  <img src={companyLogo} alt="" />
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className={generalStyles.fieldsThree}>
          <div>
            <div className={styles.fieldLabel}>
              <Text type="body-2" weight={400}>
                Next RQ Serial Number
              </Text>
            </div>
            <div className={styles.fieldValue}>
              <Text type="body-2" weight={400}>
                {createNextRQNumber()}
              </Text>
            </div>
          </div>
          <div>
            <div className={styles.fieldLabel}>
              <Text type="body-2" weight={400}>
                Next PO Serial Number
              </Text>
            </div>
            <div className={styles.fieldValue}>
              <Text type="body-2" weight={400}>
                {createNextPoNumber()}
              </Text>
            </div>
          </div>
          <div>
            <div className={styles.fieldLabel}>
              <Text type="body-2" weight={400}>
                PO Disclaimer
              </Text>
            </div>
            <div className={styles.fieldValue}>
              <Text type="body-2" weight={400}>
                {company.purchaseOrderProperties?.poDisclaimer || '-'}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default CompanyProfile;
