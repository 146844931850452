import { Navigate, Outlet } from 'react-router-dom';
import { useStore } from '../../store/store';

const ProtectedOnboarding = () => {
  const stepsDone = useStore((state) => state.stepsDone);

  return stepsDone ? <Navigate to="/dashboard" replace /> : <Outlet />;
};

export default ProtectedOnboarding;
