import React, { useEffect, useMemo, useRef, useState } from 'react';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import Box from '../../components/UI/General/Box';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import styles from '../../styles/requisition.module.css';
import Textarea from '../../components/UI/Forms/Textarea';
import spmsServiceService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InvoiceItem from '../../components/Admins/PurchaseOrders/View/InvoiceItem';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import TabsSlider from '../../components/UI/General/TabsSlider';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import moment from 'moment';
import BackButton from '../../components/shared/BackButton';
import Modal from '../../components/UI/Modal/Modal';
import ModalInner from '../../components/UI/Modal/ModalInner';

const PurchaseOrdersInvoice = () => {
  const { orderId, invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = useStore((state) => state.activeCompany?.id);
  const user = useStore((state) => state.user);
  const userId = useStore((state) => state.user?.id);
  const [values, setValues] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const [tab, setTab] = useState('items');
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false);
  const [full, setFull] = useState(0);
  let isApprover = useRef(0);
  let isCurrentUserApproved = useRef(false);
  let isCreator = useRef(false);
  let isRoboUser = useRef(false);
  let hasRoboUser = useRef(false);

  const invoiceDefault = {
    invoiceNumber: '',
    invoiceIssueDate: '',
    invoiceReceivedDate: '',
    invoiceDueDate: '',
    invoiceNotes: '',
    totalTaxAmount: 0,
    subTotal: 0,
    totalInvoiceAmount: 0,
    invoiceItems: [],
    files: [],
  };

  const invoiceItemDefault = {
    id: null,
    description: '',
    generalLedgerId: '',
    receivedQuantity: 0,
    quantityInvoiced: 0,
    unitPrice: 0,
    taxId: '',
    subtotal: 0,
    taxTotal: 0,
    totalCost: 0,
    itemNotes: '',
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: invoiceDefault,
    values,
  });

  const accessInvoicing = useAccessAllowed('Invoice');

  const { fields, append, remove } = useFieldArray({
    name: 'invoiceItems',
    control,
  });

  const formValues = useWatch({
    name: 'invoiceItems',
    control,
  });

  const reasonWatcher = useWatch({ name: 'reason', control });

  const closeModal = () => {
    setShowModal(false);
    setValue('reason', undefined);
  };

  const quantityInvoicedSum = useMemo(() => {
    return formValues
      ? formValues.reduce((acc, current) => acc + (parseFloat(current.quantityInvoiced) || 0), 0)
      : 0;
  }, [formValues]);

  const subtotal = useMemo(() => {
    return formValues
      ? formValues.reduce((acc, current) => acc + (parseFloat(current.subtotal) || 0), 0)
      : 0;
  }, [formValues]);
  const taxTotal = useMemo(() => {
    return formValues
      ? formValues.reduce((acc, current) => acc + (parseFloat(current.taxTotal) || 0), 0)
      : 0;
  }, [formValues]);
  const totalCost = useMemo(() => {
    return formValues
      ? formValues.reduce((acc, current) => acc + (parseFloat(current.totalCost) || 0), 0)
      : 0;
  }, [formValues]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        spmsServiceService.getTaxList(companyId).then((r) => {
          if (r.data.message === 'Operation Successful') {
            console.log(r.data.data);
            const temp = r.data.data
              .filter((item) => item.active === true)
              .map((item) => ({
                value: { percentage: item.percentage, id: item.id },
                type: item.type,
              }));
            temp.splice(0, 0, {
              value: { id: '0', percentage: 0 },
              type: 'No tax',
            });
            setTaxes(temp);
          }
        }),
      ]);
    };
    if (companyId !== null || companyId !== undefined) {
      fetchData();
    }
  }, [companyId]);

  useEffect(() => {
    const fetchOrder = async () => {
      await spmsServiceService.getPurchaseOrder(orderId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data);
          const {
            purchaseOrderItems,
            currency,
            totalInvoicedAmount,
            total,
            purchaseOrderNo,
            invoiceStatus,
          } = r.data.data;
          if (invoiceId === undefined && purchaseOrderItems !== undefined) {
            if (purchaseOrderItems.length > 0) {
              setValues({
                currency,
                totalInvoicedAmount,
                invoiceStatus,
                purchaseOrderNo: purchaseOrderNo,
                invoiceItems: purchaseOrderItems.map((item) => ({
                  ...item,
                  subtotal: 0,
                  taxTotal: 0,
                  totalCost: 0,
                  taxId: taxes?.filter((el) => el.value.id === (item?.tax?.id || '0'))[0],
                })),
              });
            }
          } else {
            setValues((s) => ({
              ...s,
              totalInvoicedAmount,
              purchaseOrderNo: purchaseOrderNo,
              poTotal: total,
            }));
          }
          hasRoboUser.current = r.data.data.requester.userId !== '';
          isRoboUser.current = r.data.data.requester.userId === userId;
        }
      });
    };
    if (orderId !== undefined) {
      fetchOrder();
    }
  }, [orderId, invoiceId, taxes, userId]);

  console.log('values', values);

  useEffect(() => {
    const fetchInvoice = async () => {
      await spmsServiceService.getPurchaseOrderInvoice(orderId, invoiceId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data);
          const { invoiceItems, invoiceDueDate, invoiceIssueDate, invoiceReceivedDate, ...other } =
            r.data.data;
          const newInvoiceItems = invoiceItems.map((item) => ({
            ...item,
            id: item?.purchaseOrderItem?.id,
            subtotal: item.unitPrice * item.quantityInvoiced,
            taxTotal: item.taxAmount,
            totalCost: item.totalCost,
            description: item?.purchaseOrderItem?.description ?? item?.description,
            receivedQuantity: item?.purchaseOrderItem?.receivedQuantity ?? 0,
            generalLedgerId: {
              label: item.glAccount.glCode,
              value: item.glAccount.id,
              text: item.glAccount.description,
            },
            taxId: taxes?.filter((el) => el.value.id === (item?.tax?.id || '0'))[0],
          }));
          isApprover.current =
            r.data.data.approval?.approvers?.filter((item) => item.userId === userId).length ?? 0;
          isCurrentUserApproved.current =
            r.data.data.approval?.approvers?.find((item) => item.userId === userId)?.status ===
              'APPROVED' ?? false;
          isCreator.current = r.data.data.createdBy.userId === userId;
          setValues((s) => ({
            ...s,
            ...other,
            invoiceItems: newInvoiceItems,
            invoiceIssueDate: new Date(invoiceIssueDate),
            invoiceDueDate: new Date(invoiceDueDate),
            invoiceReceivedDate: new Date(invoiceReceivedDate),
          }));
        }
      });
    };
    if (orderId !== undefined && invoiceId !== undefined) {
      fetchInvoice();
    }
  }, [invoiceId, orderId, taxes, userId]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data, type) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const { files, invoiceItems } = data;
    const newItemInvoices =
      invoiceItems !== undefined && invoiceItems.length > 0
        ? invoiceItems.map((item) => ({
            purchaseOrderItem: { id: item.id },
            glAccount: { id: item.generalLedgerId.value },
            quantityInvoiced: item.quantityInvoiced,
            tax: { id: item.taxId.value.id },
            unitPrice: item.unitPrice,
            subTotal: item.subtotal,
            taxAmount: item.taxTotal,
            totalCost: item.totalCost,
            description: item.description,
          }))
        : [];

    let newPurchaseOrderAttachments = [];
    if (files !== undefined && files.length > 0) {
      const filesArray = files.map(async (item) => ({
        name: item.name,
        document: await getBase64(item.attachment),
      }));
      newPurchaseOrderAttachments = await Promise.all(filesArray).then((result) => result);
    }
    if (!newPurchaseOrderAttachments?.length) {
      setIsButtonsBlocked(false);
      setTab('invoices');
      requiredRef.current = true;
      setError('files', { type: 'focus', message: 'Files required' });
      return;
    }
    const newData = {
      currency: values?.currency,
      status: values?.invoiceStatus,
      invoiceNumber: data.invoiceNumber,
      invoiceIssueDate: data.invoiceIssueDate,
      invoiceReceivedDate: data.invoiceReceivedDate,
      invoiceDueDate: data.invoiceDueDate,
      notes: data.invoiceNotes === '' ? null : data.invoiceNotes,
      totalTaxAmount: taxTotal,
      subTotal: subtotal,
      totalInvoiceAmount: totalCost,
      attachments: newPurchaseOrderAttachments,
      invoiceItems: newItemInvoices,
    };
    console.log('InvoiceMode', newData);
    if (orderId && invoiceId === undefined) {
      await spmsServiceService
        .createPurchaseOrderInvoice(orderId, newData)
        .then((r) => {
          console.log(r);
          setToast({
            opened: true,
            message: 'Invoice submitted for approval successfully',
            type: 'success',
            cb: () =>
              navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: 'invoices' } }),
          });
        })
        .catch((reason) => {
          console.log(reason);
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.error,
            type: 'fail',
          });
        });
    }
    if (orderId && invoiceId && type === 'edit') {
      await spmsServiceService
        .updatePurchaseOrderInvoice(orderId, invoiceId, newData)
        .then((r) => {
          console.log(r);
          setToast({
            opened: true,
            message: 'Invoice updated successfully',
            type: 'success',
            cb: () =>
              navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: 'invoices' } }),
          });
        })
        .catch((reason) => {
          console.log(reason);
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.error,
            type: 'fail',
          });
        });
    }
  };

  const files = (files) =>
    files.map((file) => ({
      attachment: file,
      name: file.name,
    }));

  const varianceCalc = () => (totalCost > values?.poTotal ? totalCost - values?.poTotal : 0);

  const allowApprove = () =>
    isRoboUser.current ? true : isCreator.current && !hasRoboUser.current;

  const approveReject = async (action, data) => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    const body = {
      approved: action === 'approved',
    };
    if (data?.reason) {
      body.reason = data.reason;
    }
    console.log(body);
    await spmsServiceService
      .approvePurchaseOrderInvoice(orderId, invoiceId, body)
      .then((r) => {
        console.log(r);
        setToast({
          opened: true,
          message: 'Invoice ' + action + ' successfully',
          type: 'success',
          cb: () => {
            location?.state?.place === 'approve'
              ? navigate(-1)
              : navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: 'invoices' } });
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((reason) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message:
            reason.response.data.message !== undefined
              ? reason.response.data.message
              : reason.response.data.error,
          type: 'fail',
        });
      });
  };

  const showToCreator = useMemo(() => {
    return values?.approvalStatus === 'PENDING_APPROVAL' && values?.createdBy?.userId === userId;
  }, [userId, values]);

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div>
            <BackButton />
          </div>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>
                Invoice ({values?.invoiceNumber})
              </Text>
              <Text type="body-1" weight={500}>
                {values?.purchaseOrderNo}
              </Text>
            </div>
            <div className={generalStyles.addItemButton}>
              {invoiceId !== undefined &&
                values?.approvalStatus === 'PENDING_APPROVAL' &&
                accessInvoicing?.approve &&
                isApprover.current > 0 &&
                !isCurrentUserApproved.current && (
                  <>
                    <Button.Main
                      $mid
                      $style="green"
                      type="button"
                      onClick={() => approveReject('approved')}
                      disabled={isButtonsBlocked}
                    >
                      Approve
                    </Button.Main>
                    <Button.Main
                      $mid
                      $style="red"
                      type="button"
                      disabled={isButtonsBlocked}
                      onClick={() => setShowModal(true)}
                    >
                      Deny
                    </Button.Main>
                  </>
                )}
            </div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('items')}>Items</span>
            <span onClick={() => setTab('documents')}>Documents</span>
            {values?.approvalStatus && !user?.externalId ? (
              <span onClick={() => setTab('approvers')}>Approvers</span>
            ) : null}
          </TabsSlider>
        </div>

        {tab === 'items' && values && (
          <>
            <div className={generalStyles.fieldsThree}>
              <div className={styles.invoiceTopItem}>
                <Text weight={600} type={'body-2'}>
                  Issue Date
                </Text>
                <Text type={'body-2'}>{moment(values?.invoiceIssueDate).format('DD/MM/YYYY')}</Text>
              </div>
              <div className={styles.invoiceTopItem}>
                <Text weight={600} type={'body-2'}>
                  Due Date
                </Text>
                <Text type={'body-2'}>{moment(values?.invoiceDueDate).format('DD/MM/YYYY')}</Text>
              </div>
            </div>
            <br />
            <div className={styles.itemsTable}>
              <div style={{ width: '100%' }}>
                <div
                  className={
                    user?.externalId
                      ? styles.itemsInvoiceTableHead + ' ' + styles.forVendor
                      : styles.itemsInvoiceTableHead
                  }
                >
                  <Text type="body-1" weight={500}>
                    Item Name
                  </Text>
                  <Text type="body-1" weight={500}>
                    Unit
                  </Text>
                  {!user?.externalId && (
                    <Text type="body-1" weight={500}>
                      Account Code
                    </Text>
                  )}
                  <Text type="body-1" weight={500}>
                    Received
                  </Text>
                  <Text type="body-1" weight={500}>
                    Invoiced
                  </Text>
                  <Text type="body-1" weight={500}>
                    Unit Price
                  </Text>
                  <Text type="body-1" weight={500}>
                    Tax
                  </Text>
                  <Text type="body-1" weight={500}>
                    Subtotal
                  </Text>
                </div>
              </div>
              <div className={styles.itemsTableBody}>
                {fields.map((field, index) => {
                  return (
                    <InvoiceItem
                      key={field.id}
                      field={field}
                      index={index}
                      register={register}
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      taxes={taxes}
                      // viewOnly={invoiceId !== undefined && !showToCreator}
                      viewOnly={invoiceId !== undefined}
                      isVendor={user?.externalId}
                      name="invoiceItems"
                      full={full}
                      remove={remove}
                    />
                  );
                })}
              </div>
            </div>

            <div className={styles.summaryWrapper}>
              <Summary
                subtotal={subtotal}
                taxTotal={taxTotal}
                totalCost={totalCost}
                currency={values?.currency?.code}
                // totalInvoicedAmount={values?.totalInvoicedAmount}
                variance={varianceCalc()}
              />
            </div>
          </>
        )}
        {tab === 'documents' && <QuotesTab data={values?.attachments} />}
        {tab === 'approvers' && <ApprovalTab data={values?.approval?.approvers} />}
      </Box>
      <Modal
        $show={showModal}
        $close={closeModal}
        $title="Deny Invoice"
        $radius={12}
        $closableOutside
      >
        <ModalInner>
          <form
            onSubmit={handleSubmit((data) => approveReject('reject', data))}
            className={styles.modalInner}
          >
            <div className="inp-container">
              <Controller
                name="reason"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Deny reason is required',
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    placeholder="Enter the reason here"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Reason to Deny the Invoice"
                    $labelRequired
                    $counter
                    $counterMax={500}
                    {...field}
                  />
                )}
              />
              {errors.reason && <p className="error-message">{errors.reason?.message}</p>}
            </div>
            <br />
            <div className={generalStyles.pageButtons}>
              <Button.Main $primary $style="blue" type="submit" disabled={!reasonWatcher}>
                Save
              </Button.Main>
            </div>
          </form>
        </ModalInner>
      </Modal>
      {/*<Modal*/}
      {/*  $show={false}*/}
      {/*  $close={() => setShowModal(false)}*/}
      {/*  $title="Deny"*/}
      {/*  $radius={16}*/}
      {/*  $closableOutside*/}
      {/*>*/}
      {/*  <div className={generalStyles.subscriptionModal}>*/}
      {/*    <Text>Do you want to deny?</Text>*/}
      {/*    <div className={generalStyles.modalButtons}>*/}
      {/*      <Button.Main $primary $style={'blue'} onClick={() => setShowModal(false)} type="button">*/}
      {/*        No*/}
      {/*      </Button.Main>*/}
      {/*      <Button.Main $primary $style={'blank'} onClick={() => approveReject('rejected')} type="button">*/}
      {/*        Yes*/}
      {/*      </Button.Main>*/}
      {/*    </div>*/}

      {/*  </div>*/}
      {/*</Modal>*/}
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default PurchaseOrdersInvoice;
