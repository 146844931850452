import React from 'react';
import { downloadCSV } from '../../utils/csv';
import * as Button from '../UI/Forms/Button';
import Icon from '../UI/General/Icon';
import Text from '../UI/Typography/Text';
import Dropdown from '../UI/General/Dropdown';
import { Menu, MenuItem } from '../UI/General/Menu';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  position: relative;
  display: flex;
  align-items: center;
`;
const StyledMenu = styled(Menu)`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
`;

const Export = ({ data, name, getDataFunction }) => {
  const download = () => {
    if (typeof getDataFunction === 'function') {
      getDataFunction()
        .then((r) => {
          if (r?.length > 0) {
            downloadCSV(r, name);
          } else {
            console.log('No data to export');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (data?.length > 0) {
        downloadCSV(data, name);
      } else {
        alert('No data to export');
      }
    }
  };

  return (
    <StyledDropdown collapsible>
      <Dropdown.Header>
        <Button.ActionLabeled>
          <Button.Action $style="blue" $variant="circle" $width={20} $height={20} type="button">
            <Icon $width={11} $height={12} $icon="export" $color="white" />
          </Button.Action>
          <Text weight={600} type="subtitle">
            Export
          </Text>
        </Button.ActionLabeled>
      </Dropdown.Header>
      <Dropdown.Body>
        <StyledMenu>
          <MenuItem onClick={() => download()}>CSV</MenuItem>
        </StyledMenu>
      </Dropdown.Body>
    </StyledDropdown>
  );
};

export default Export;
