import React, { forwardRef, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import Text from '../Typography/Text';
import Icon from '../General/Icon';
import styled from 'styled-components';
import { useWatch } from 'react-hook-form';

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 28px;
  background-color: ${({ $isSelected }) => ($isSelected ? '#F3F7FF' : '#ffffff')};
  cursor: default;
  &:hover {
    background-color: #f3f7ff;
  }
`;

const StyledCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #145ff5;
  color: white;
`;

const StyledMultiValueWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e4e2e2;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const ClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <Text
      {...restInnerProps}
      ref={ref}
      type="body-1"
      style={{ color: '#145FF5', cursor: 'pointer', marginRight: 30 }}
    >
      Clear
    </Text>
  );
};

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <Icon $icon="search" $width={16} $height={16} $color="#fff" style={{ marginLeft: 28 }} />
      {children}
    </components.Control>
  );
};

const Option = (props) => {
  const { innerProps, innerRef } = props;
  const label = () => {
    if (typeof props.data.label === 'string') {
      if (props.data.label.split('=').length === 2) {
        return (
          <Text
            type="body-1"
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%' }}
          >
            <span>{props.data.label.split('=')[0]}</span>
            <span>{props.data.label.split('=')[1]}</span>
          </Text>
        );
      } else {
        return <Text type="body-1">{props.data.label}</Text>;
      }
    } else {
      return <Text type="body-1">{props.data.label}</Text>;
    }
  };
  return (
    <StyledOption ref={innerRef} {...innerProps} $isSelected={props.isSelected}>
      {label()}
      <StyledCircle>
        <Icon
          $icon={props.isSelected ? 'marked' : 'plus'}
          $width={20}
          $height={20}
          $color="#ffffff"
        />
      </StyledCircle>
    </StyledOption>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon $icon="close" $width={12} $height={12} />
    </components.MultiValueRemove>
  );
};

const SelectedValuesContainer = ({ isDisabled, getValue, ...props }) => {
  const { getOptionValue, formatOptionLabel, removeValue } = props.selectProps;
  const getValueLabel = (opt) => formatOptionLabel?.(opt, 'value') || opt.label;
  const getKey = (opt, index) => `${getOptionValue(opt)}-${index}`;

  const toMultiValue = (opt, index) => {
    let label = getValueLabel(opt);
    if (getValueLabel(opt).split('=').length === 2) {
      label = getValueLabel(opt).split('=')[0];
    }
    return (
      <components.MultiValue
        getValue={getValue}
        {...props}
        components={{
          Container: components.MultiValueContainer,
          Label: components.MultiValueLabel,
          Remove: MultiValueRemove,
        }}
        isDisabled={isDisabled}
        key={getKey(opt, index)}
        index={index}
        removeProps={{
          onClick: () => removeValue(opt),
          onTouchEnd: () => removeValue(opt),
          onMouseDown: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        }}
        data={opt}
      >
        {label}
      </components.MultiValue>
    );
  };

  return (
    <>
      {getValue().length > 0 ? (
        <StyledMultiValueWrapper>{getValue().map(toMultiValue)}</StyledMultiValueWrapper>
      ) : null}
    </>
  );
};

const SelectContainer = ({ children, className, innerProps, isFocused, ...commonProps }) => {
  const selectContainerProps = { ...commonProps };
  return (
    <components.SelectContainer
      className={className}
      innerProps={innerProps}
      isFocused={isFocused}
      {...selectContainerProps}
    >
      {children}
      <SelectedValuesContainer {...commonProps} />
    </components.SelectContainer>
  );
};

const MultiSelectOpened = forwardRef(({ control, ...props }, ref) => {
  const watchValue = useWatch({ control, name: props.name });
  const [value, setValue] = useState();
  const parentChangeFunction = props?.onChange;

  let components = {
    DropdownIndicator: null,
    ClearIndicator: ClearIndicator,
    Control: Control,
    Option: Option,
  };
  if (!props.noSelectContainer) {
    components.SelectContainer = SelectContainer;
  }
  const removeValue = (removed) =>
    parentChangeFunction(value.filter((v) => v.value !== removed.value));

  useEffect(() => {
    setValue(watchValue);
  }, [watchValue]);

  return (
    <Select
      {...props}
      ref={ref}
      components={components}
      classNamePrefix="react-select"
      isSearchable={true}
      isMulti
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      menuIsOpen={true}
      removeValue={removeValue}
      styles={{
        group: (base) => ({ ...base, padding: 0 }),
        groupHeading: () => ({ padding: '4px 12px' }),
        menu: () => ({
          position: 'relative',
          marginTop: 10,
          backgroundColor: '#fff',
          border: '1px solid #E4E2E2',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          overflow: 'hidden',
        }),
        menuList: (base) => ({ ...base, minHeight: 200, maxHeight: 200 }),
        multiValueLabel: () => ({
          padding: '8px 8px 8px 12px',
          fontSize: 14,
          lineHeight: '20px',
        }),
        multiValue: () => ({
          display: 'flex',
          backgroundColor: '#e4e2e2',
          borderRadius: 4,
        }),
        multiValueRemove: () => ({
          display: 'flex',
          padding: 12,
          borderRadius: 4,
          '&:hover': { background: '#C85D9F', color: '#ffffff' },
        }),
        ...props.styles,
      }}
    />
  );
});

export default MultiSelectOpened;
