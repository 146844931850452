export const regExps = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  password: /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/,
  notes: /^[A-Za-z0-9\-.,&'/#() +]+$/,
  lineItemDescription: /^[-&@.()0-9A-Za-z\s]*$/,
  poRequisitionsTitle: /^[-&.()0-9A-Za-z\s]*$/,
  companyName: /^[A-Za-z0-9\-.,&'/#() +]*$/,
  userName: /^[^0-9!@#$%&?/*(){}<>]*$/,
  glCode: /^[A-Za-z0-9\- /]*$/,
  glDescription: /^[A-Za-z\-/\s]*$/,
  alphaNumericalSpecial: /^[A-Za-z0-9\-.,&'/#() +]+$/,
  alphaNumerical: /^[A-Za-z0-9\s]*$/,
  websiteUrl: /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w-._~:/?#[\]@!$&'()*+,;=]*)?$/,
  numbers: /^[0-9]*$/,
};
