import requestsServiceService from '../services/requestsService.service';

export const countriesSlice = (set) => ({
  countries: null,
  regions: null,
  currencies: null,
  fetchCountries: async () => {
    await requestsServiceService.listCountries().then((res) => {
      const data = res.data.data.map((item) => ({
        label: item.countryName,
        value: item.countryName,
        code: item.countryCode,
        currency: item.currency,
        currencyName: item.currencyName,
      }));
      set({ countries: data });

      const separator = '-';
      const allCurrencies = data.map((country) => `${country.currencyName}${separator}${country.currency}`).sort();
      const uniqueCurrencies = [...new Set([...allCurrencies])];
      const curValues = uniqueCurrencies.map((currency) => {
        const label = currency.split(separator);
        label[label.length - 1] = `(${label[label.length - 1]})`;
        return {
          label: label.join(' '),
          value: currency.split(separator).at(-1),
        };
      });
      set({ currencies: curValues });

    });
  },
  fetchRegions: async () => {
    await requestsServiceService.regionsList().then((res) => {
      const data = res.data.data.map((item) => ({
        label: item.regionName,
        value: item.regionName,
        code: item.countryCode,
      }));
      set({ regions: data });
    });
  },
});
