import React, { useRef } from 'react';
import styled from 'styled-components';
import { useFieldArray, Controller, useWatch } from 'react-hook-form';
import Icon from '../General/Icon';
import Text from '../Typography/Text';
import { bytesToSizes } from '../../../utils/bytesToSizes';

const FileWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ $isVertical }) => ($isVertical ? '1 fr' : '1fr 1fr')};
  gap: ${({ $isVertical }) => ($isVertical ? '16px' : '30px')};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FileInputButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #145ff5;
  margin-right: 4px;
`;

const FileInput = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: ${({ $isVertical }) => ($isVertical ? '132px' : '220px')};
  margin-bottom: 0;
  padding: 35px;
  border-width: 1px;
  border-style: solid;
  border-image-source: url('/assets/img/general/border-source.png'); /* Replace with your image URL */
  border-image-slice: 20;
  border-image-repeat: round;
  border-image-width: 20px;

  /* border: ${({ $isInvalid }) => ($isInvalid ? '1px dashed #cd3e27' : '1px dashed #acaec9')}; */
  font-size: 14px;
  color: #8a8b9d;
  /* &:hover ${FileInputButton} {
    background-color: #145ff5;
    color: #ffffff;
  } */
  & input[type='file'] {
    display: none;
  }
`;

const FileActions = styled.div`
  display: flex;
`;
const FileOutput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: ${({ $isVertical }) => ($isVertical ? '132px' : '220px')};
  padding: 18px 16px;
  border: 1px solid #145ff55d;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow-x: hidden;
`;
const FileItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  background-color: #145ff50d;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
`;

const FileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
`;

const FileInfo = styled.div`
  flex: 1;
  display: flex;
  gap: 4px;
  color: #00000099;
  align-items: center;
  gap: 12px;
`;

const FileRemove = styled.div`
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: end;
`;

const validFileExtensions = [
  'pdf',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'pps',
  'ppsx',
  'odt',
  'xls',
  'xlsx',
  'PSD',
  'dot',
  'csv',
  'txt',
  'rtf',
  'html',
  'htm',
];

const checkIfFilesAreCorrectType = (files) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!validFileExtensions.includes(file.attachment.name.split('.').pop())) {
        return (valid = false);
      } else {
        return (valid = true);
      }
    });
  }
  return valid;
};

const checkFilesAreCorrectSize = (files) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (file.attachment.size < 2097152) {
        return (valid = true);
      } else {
        return (valid = false);
      }
    });
  }
  return valid;
};

const Documents = ({
  control,
  fieldName,
  filesSchema,
  isDisable,
  error,
  required,
  clearErrors,
  $isVertical,
}) => {
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
    control,
    rules: {
      required: {
        value: required,
        message: 'Files are required',
      },
      validate: {
        notAllowed: (v) =>
          checkIfFilesAreCorrectType(v) ||
          `Only ${validFileExtensions.join(', ')} files are allowed`,
        lessThan2MB: (v) => checkFilesAreCorrectSize(v) || 'Maximum file size is 2MB',
      },
    },
  });

  const filesValues = useWatch({
    name: fieldName,
    control,
  });

  const hiddenFileInput = useRef(null);

  const handleRemove = (index) => {
    remove(index);
    hiddenFileInput.current.value = '';
  };

  const handleAddDocuments = (event) => {
    if (clearErrors) {
      clearErrors(fieldName);
    }
    const uploadedFiles = Array.from(event.target.files);
    const files = filesSchema(uploadedFiles);
    append(files);
    hiddenFileInput.current.value = '';
  };

  return (
    <div style={{ position: 'relative' }}>
      <FileWrapper $isVertical={$isVertical}>
        <FileInput $isVertical={$isVertical} $isInvalid={error}>
          <svg
            width="40"
            height="34"
            viewBox="0 0 40 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="34" rx="8" fill="#145FF5" fillOpacity="0.1" />
            <g clipPath="url(#clip0_6037_4835)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8584 11.255C14.9967 10.9783 15.2092 10.7456 15.4722 10.5828C15.7352 10.42 16.0383 10.3336 16.3476 10.3333H23.6509C23.9605 10.3333 24.2639 10.4196 24.5273 10.5823C24.7906 10.7451 25.0034 10.9781 25.1417 11.255L28.0684 17.1091C28.2421 17.4558 28.3329 17.8381 28.3334 18.2258V22.8333C28.3334 23.2753 28.1578 23.6993 27.8453 24.0118C27.5327 24.3244 27.1088 24.5 26.6667 24.5H13.3334C12.8914 24.5 12.4675 24.3244 12.1549 24.0118C11.8423 23.6993 11.6667 23.2753 11.6667 22.8333V18.2266C11.6669 17.8387 11.7573 17.4561 11.9309 17.1091L14.8584 11.255ZM23.6509 12H16.3484L13.4334 17.8333H16.2501C16.5816 17.8333 16.8995 17.965 17.134 18.1994C17.3684 18.4339 17.5001 18.7518 17.5001 19.0833V19.9166C17.5001 20.0272 17.544 20.1331 17.6221 20.2113C17.7003 20.2894 17.8062 20.3333 17.9167 20.3333H22.0834C22.1939 20.3333 22.2999 20.2894 22.378 20.2113C22.4562 20.1331 22.5001 20.0272 22.5001 19.9166V19.0833C22.5001 18.7518 22.6318 18.4339 22.8662 18.1994C23.1006 17.965 23.4186 17.8333 23.7501 17.8333H26.5667L23.6509 12Z"
                fill="black"
                fillOpacity="0.2"
              />
            </g>
            <defs>
              <clipPath id="clip0_6037_4835">
                <rect width="20" height="20" fill="white" transform="translate(10 7)" />
              </clipPath>
            </defs>
          </svg>
          <FileActions>
            <FileInputButton>Click here</FileInputButton> to upload or drop files here
          </FileActions>
          <input
            ref={hiddenFileInput}
            type="file"
            multiple
            onChange={handleAddDocuments}
            disabled={isDisable}
          />
        </FileInput>
        {filesValues && filesValues.length > 0 ? (
          <FileOutput $isVertical={$isVertical}>
            {fields.map(({ id, attachment }, index) => (
              <div key={id}>
                <Controller
                  control={control}
                  name={`${fieldName}.${index}`}
                  render={() => (
                    <FileItem>
                      <FileIcon>
                        <svg
                          width="16"
                          height="18"
                          viewBox="0 0 16 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.55496 17.9732H12.4442C14.1771 17.9732 15.0392 17.0942 15.0392 15.3532V7.7528H8.97889C7.90746 7.7528 7.40532 7.24209 7.40532 6.17066V0.0267334H3.55496C1.83068 0.0267334 0.959961 0.913876 0.959961 2.6553V15.3532C0.959961 17.1024 1.83068 17.9732 3.55496 17.9732ZM9.00425 6.60602H14.9471C14.8885 6.26281 14.6457 5.9278 14.2525 5.51781L9.63175 0.822091C9.24675 0.420305 8.89532 0.177448 8.54353 0.118519V6.15423C8.54353 6.4553 8.70282 6.60602 9.00425 6.60602Z"
                            fill="#145FF5"
                            fillOpacity="0.9"
                          />
                        </svg>
                      </FileIcon>
                      <FileInfo>
                        <Text weight={400} type="body-2">
                          {attachment?.name}
                        </Text>
                        <Text type="body-3">{bytesToSizes(attachment?.size)}</Text>
                      </FileInfo>
                      <FileRemove onClick={() => handleRemove(index)}>
                        <Icon
                          onClick={() => handleRemove(index)}
                          $icon="close"
                          $width={20}
                          $height={20}
                          $color="#00000099"
                        />
                      </FileRemove>
                    </FileItem>
                  )}
                />
              </div>
            ))}
          </FileOutput>
        ) : null}
      </FileWrapper>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Documents;
