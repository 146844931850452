import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import styles from '../../styles/users.module.css';
import style from '../../styles/users.module.css';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../components/UI/Modal/Modal';
import ModalInner from '../../components/UI/Modal/ModalInner';
import Textarea from '../../components/UI/Forms/Textarea';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';
import modalStyles from '../../styles/log_reg.module.css';
import Toast from '../../components/UI/General/Toast';
import Box from '../../components/UI/General/Box';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import { reducedText } from '../../utils/reducedText';
import AddButton from '../../components/shared/AddButton';
import { nf } from '../../utils/nf';
import Search from '../../components/UI/General/Search';
import Filter from '../../components/UI/General/Filter';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';

const Requisitions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [filterText, setFilterText] = useState(undefined);
  const activeCompany = useStore((state) => state.activeCompany);
  const user = useStore((state) => state.user);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [recallId, setRecallId] = useState(undefined);
  const [itemId, setItemId] = useState(undefined);
  const [showRecallModal, setShowRecallModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [toExport, setToExport] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [filterData, setFilterData] = useState([
    {
      name: 'vendor',
      label: 'Vendor',
      isMulti: false,
      fetchType: 'vendor',
    },
    {
      name: 'status',
      label: 'Status',
      isMulti: false,
      options: [
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Pending Approval', value: 'PENDING_APPROVAL' },
        { label: 'Draft', value: 'DRAFT' },
        { label: 'Denied', value: 'REJECTED' },
        { label: 'Received', value: 'RECEIVED' },
      ],
    },
  ])

  const accessRequisition = useAccessAllowed('Requests');

  const openModal = (id) => {
    setRecallId(id);
    setShowRecallModal(true);
  };
  const closeModal = () => {
    reset();
    setShowRecallModal(false);
  };
  const openDiscardModal = (id) => {
    setItemId(id);
    setShowDiscardModal(true);
  };

  // const filterData = [
  //   {
  //     name: 'location',
  //     label: 'Location',
  //     isMulti: false,
  //     fetchType: 'location',
  //   },
  //   {
  //     name: 'department',
  //     label: 'Department',
  //     isMulti: false,
  //     fetchType: 'department',
  //   },
  //   {
  //     name: 'vendor',
  //     label: 'Vendor',
  //     isMulti: false,
  //     fetchType: 'vendor',
  //   },
  //   {
  //     name: 'status',
  //     label: 'Status',
  //     isMulti: false,
  //     options: [
  //       { label: 'Approved', value: 'APPROVED' },
  //       { label: 'Pending Approval', value: 'PENDING_APPROVAL' },
  //       { label: 'Draft', value: 'DRAFT' },
  //       { label: 'Denied', value: 'REJECTED' },
  //       { label: 'Received', value: 'RECEIVED' },
  //     ],
  //   },
  // ];

  useEffect(()=>{
    if (accessRequisition !== undefined && !!accessRequisition?.viewAllCompany){
      setFilterData([{
        name: 'location',
        label: 'Location',
        isMulti: false,
        fetchType: 'location',
      },
      {
        name: 'department',
        label: 'Department',
        isMulti: false,
        fetchType: 'department',
      },
      {
        name: 'vendor',
        label: 'Vendor',
        isMulti: false,
        fetchType: 'vendor',
      },
      {
        name: 'status',
        label: 'Status',
        isMulti: false,
        options: [
          { label: 'Approved', value: 'APPROVED' },
          { label: 'Pending Approval', value: 'PENDING_APPROVAL' },
          { label: 'Draft', value: 'DRAFT' },
          { label: 'Denied', value: 'REJECTED' },
          { label: 'Received', value: 'RECEIVED' },
        ],
      }])
    }
  },[accessRequisition])

  const columns = useMemo(
    () => [
      {
        name: 'Ref No',
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'Ref No'} className={generalStyles.tableValue}>
            <Link to={'/requisitions/' + row.id}>
              <span>{row?.requestNo}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'Title',
        wrap: true,
        selector: (row) => row?.title,
        sortable: true,
        cell: (row) => {
          return (
            <span data-content={'Title'} className={generalStyles.tableValue}>
              <span>{reducedText(row?.title)}</span>
            </span>
          );
        },
      },
      {
        name: 'Location',
        cell: (row) => (
          <span data-content={'Location'} className={generalStyles.tableValue}>
            <span>{row?.location?.name ?? '-'}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.location?.name,
      },
      {
        name: 'Department',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row?.department?.name ?? '-'}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.department?.name,
      },
      {
        name: 'Vendor',
        cell: (row) => (
          <span data-content={'Vendor'} className={generalStyles.tableValue}>
            <span>{row?.vendor?.legalName ?? '-'}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.vendor?.legalName,
      },
      {
        name: 'Requester',
        cell: (row) => (
          <span data-content={'Requester'} className={generalStyles.tableValue}>
            <span>{row?.requester?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.requester?.name,
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return (
            <span data-content={'Total Amount'} className={generalStyles.tableValue}>
              <span>{cur + ' ' + nf.format(row?.total)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.total,
      },
      {
        name: 'Status',
        cell: (row) => {
          return (
            <span data-content={'Status'} className={generalStyles.tableValue}>
              <span>{readableTitleFromBackend(row.requisitionState)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.requisitionState,
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        omit: !accessRequisition?.create,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {/* {(row.requisitionState === 'Draft'.toUpperCase() ||
                    row.requisitionState === 'rejected'.toUpperCase()) && (
                    <Link to={'/requisitions/edit/' + row.id}>
                      <MenuItem>Edit</MenuItem>
                    </Link>
                  )} */}
                  {row.requisitionState === 'Draft'.toUpperCase() && (
                    <MenuItem onClick={(e) => openDiscardModal(row.id)}>Discard</MenuItem>
                  )}
                  {row.requisitionState === 'PENDING_APPROVAL'.toUpperCase() && (
                    <MenuItem onClick={() => openModal(row.id)}>Recall</MenuItem>
                  )}
                  {row.requisitionState === 'Approved'.toUpperCase() && (
                    <Link to={'/requisitions/' + row.id}>
                      <MenuItem>View</MenuItem>
                    </Link>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [accessRequisition],
  );

  const discardRequisition = (id) =>
    spmsServiceService
      .discardRequisition(id)
      .then((res) => {
        setShowDiscardModal(false);
        setResetPaginationToggle(!resetPaginationToggle);
        setTriggerRefetch((state) => !state);
      })
      .catch((err) => {
        console.log(err);
      });

  const recallRequisition = (data) => {
    spmsServiceService
      .recallRequisition(recallId, data.reason)
      .then((res) => {
        setToast({
          opened: true,
          message: 'Request recalled successfully.',
          type: 'success',
          cb: navigate('/requisitions'),
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
    closeModal();
  };

  const getData = useCallback(
    (page, size) => {
      const filterValues = getValues();
      const state =
        location?.state?.status && !filterValues.status?.value
          ? location?.state?.status
          : filterValues.status?.value
          ? filterValues.status?.value
          : undefined;
      let search = {
        requisitionState: state,
        companyId: activeCompany.id,
        requestNo: filterText,
        departmentId: filterValues.department?.value,
        locationId: filterValues.location?.value,
        vendorId: filterValues.vendor?.value,
      };
      const newSearch = search
      if (!!user?.customCompanies){
        const depLoc = user?.customCompanies?.find(el => el.companyId === activeCompany.id)
        if (!!accessRequisition?.viewOwn && !!user.id && !accessRequisition?.viewAllCompany){
          search = {...newSearch, createdByUserId: user.id}
        }
        if (!!accessRequisition?.viewAllDepartmentLocation && !accessRequisition?.viewAllCompany){
          search = {...newSearch, departmentId: depLoc.departmentId, locationId: depLoc.locationId}
        }
      }
      if (!!activeCompany.id && (!!accessRequisition?.viewAllCompany || !!accessRequisition?.viewAllDepartmentLocation || !!accessRequisition?.viewOwn)){
        return spmsServiceService.getRequisitions(size, page, search);
      }
    },
    [filterText, triggerRefetch, location, accessRequisition, user, activeCompany.id],
  );

  useEffect(() => {
    if (!location?.state?.status) return;
    setValue('status', {
      label: readableTitleFromBackend(location.state.status),
      value: location.state.status,
    });
  }, [location]);

  // useEffect(() => {
  //   spmsServiceService
  //     .getRequisitions(1, 0, {
  //       companyId: activeCompany.id,
  //     })
  //     .then((r) => {
  //       console.log(r);
  //       setTotalRows(r.data.data.totalElements);
  //     });
  // }, [activeCompany.id]);

  // useEffect(() => {
  //   const getExp = async () => {
  //     const response = await spmsServiceService.getRequisitions(totalRows, 0, {
  //       companyId: activeCompany.id,
  //       title: filterText,
  //     });
  //     const csvData = response.data.data.content.map((item) => ({
  //       'Req Title': item.title,
  //       'Req No': item.requestNo,
  //       Location: item.location.name,
  //       Dept: item.department.name,
  //       Vendor: item.vendor.legalName,
  //       Requester: item.requester.name,
  //       'PO Budget Amount': item?.currency?.code + ' ' + item?.total?.toFixed(2),
  //       Status: item.requisitionState,
  //       'Required Date': item.deliveryDate,
  //     }));
  //     setToExport(csvData);
  //   };
  //   if (totalRows === 0) {
  //     console.log('no results for export');
  //   } else {
  //     getExp();
  //   }
  // }, [filterText, triggerRefetch, totalRows, activeCompany.id]);

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        placeholder="Search by Ref No"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText, resetPaginationToggle],
  );

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <Text type="subtitle" weight={500}>
          My Request Dashboard
        </Text>
        <br />
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}>
            {filterCmp}
            <div className={generalStyles.filters}>
              <Filter
                control={control}
                filterData={filterData}
                setTriggerRefetch={setTriggerRefetch}
                setValue={setValue}
              />
            </div>
          </div>
          {!!accessRequisition?.create ? (
            <div className={generalStyles.addItemButton}>
              <AddButton $click={() => navigate('/requisitions/create')} />
            </div>
          ) : null}
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
        <Modal
          $show={showRecallModal}
          $close={closeModal}
          $title="Recall Request"
          $radius={12}
          $closableOutside
        >
          <ModalInner>
            <form onSubmit={handleSubmit(recallRequisition)} className={styles.modalInner}>
              <div className="inp-container">
                <Controller
                  name="reason"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Recall reason is required',
                    },
                  }}
                  render={({ field }) => (
                    <Textarea
                      placeholder="Enter the reason here"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      $label="Reason to Recall the Request"
                      $labelRequired
                      $counter
                      $counterMax={500}
                      {...field}
                    />
                  )}
                />
                {errors.reason && <p className="error-message">{errors.reason?.message}</p>}
              </div>
              <br />
              <div className={generalStyles.pageButtons}>
                <Button.Main $primary $style="blue" type="submit" disabled={!isValid}>
                  Save
                </Button.Main>
              </div>
            </form>
          </ModalInner>
        </Modal>
        <Modal
          $show={showDiscardModal}
          $close={() => setShowDiscardModal(false)}
          $title={'Discard'}
          $radius={16}
          $noCloseSign
        >
          <div className={modalStyles.modalTextOnly} style={{ textAlign: 'center' }}>
            <Text type="subtitle">Do you want to continue to permanently delete this entry?</Text>
            <div
              className={generalStyles.pageButtons + ' ' + style.legalEntitySetupBtns}
              style={{ justifyContent: 'center' }}
            >
              <Button.Main
                $primary
                $style="blue"
                type="button"
                onClick={() => setShowDiscardModal(false)}
              >
                No
              </Button.Main>
              <Button.Main
                $primary
                $style="gray"
                type="button"
                onClick={() => discardRequisition(itemId)}
              >
                Yes
              </Button.Main>
            </div>
          </div>
        </Modal>
      </Box>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Requisitions;
