import React, {useMemo} from 'react';
import DataTableBase from "../../../UI/General/DataTableBase";
import Text from "../../../UI/Typography/Text";
import moment from "moment";
import generalStyles from "../../../../styles/general.module.css";
import Status from "../../../UI/Typography/Status";

const ApprovalTab = ({data}) => {
  console.log(data)
  const columns = useMemo(() => [
    {
      name: 'Approver Name',
      cell: row => <span data-content={"Approver Name"} className={generalStyles.tableValue}><span>{row?.userId === "SYSTEM" ? "System Approved" : row?.approverName ?? "-"}</span></span>,
    },
    {
      name: 'Date & Time',
      cell: row => <span data-content={"Date & Time"} className={generalStyles.tableValue}><span>{row.dateApproved ? moment(row.dateApproved).format('DD MMM, YYYY | hh:mm A') : "-"}</span></span>,
    },
    {
      name: 'Status',
      cell: row => (
        <span data-content={"Status"} className={generalStyles.tableValue}>
          <Text weight={500} type={"body-2"}>
            <Status value={row?.status} wordCount={1} pill/>
          </Text>
        </span>
      )
    },
  ], [])

  return (
    <DataTableBase
      data={data}
      columns={columns}
      pagination={false}
    />
  );
};

export default ApprovalTab;
