import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Input from '../../components/UI/Forms/Input';
import SpmsService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { nf } from '../../utils/nf';
import Status from '../../components/UI/Typography/Status';
import Filter from '../../components/UI/General/Filter';
import { useForm } from 'react-hook-form';
import moment from 'moment';

const Payments = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const activeCompany = useStore((state) => state.activeCompany);

  const [trigger, setTrigger] = useState(false);
  const [filterText, setFilterText] = useState(undefined);

  const {
    control,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const columns = useMemo(
    () => [
      {
        name: 'Invoice Number',
        wrap: true,
        sortable: true,
        selector: (row) => row?.invoiceNumber,
        cell: (row) => (
          <span data-content={'Invoice Number'} className={generalStyles.tableValue}>
            <Link className={generalStyles.tableLink} to={'/purchase-orders/' + row.purchaseOrderId} state={{ tabToSwitch: 'payment', invoiceId: row.invoiceId }}>
              <span>{row?.invoiceNumber}</span>
            </Link>
          </span>
        )
      },
      {
        name: 'Po Number',
        cell: (row) => (
          <span data-content={'Po Number'} className={generalStyles.tableValue}>
            <span>{row?.purchaseOrderNo}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.purchaseOrderNo,
      },
      {
        name: 'Title',
        wrap: true,
        sortable: true,
        selector: (row) => row?.title,
        cell: (row) => {
          const title = row.title.length > 20 ? `${row.title.slice(0, 20)}...` : row.title;
          return (
            <span data-content={'Title'} className={generalStyles.tableValue}>
              <span>{title}</span>
            </span>
          );
        },
      },
      {
        name: 'Vendor',
        cell: (row) => (
          <span data-content={'Vendor'} className={generalStyles.tableValue}>
            <span>{row?.vendor?.legalName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.vendor?.legalName,
      },
      {
        name: 'Due Date',
        sortable: true,
        selector: (row) => row?.invoiceDueDate,
        format: (row) => moment(row?.invoiceDueDate).format('DD/MM/YYYY'),
        cell: (row) => (
          <span data-content={'Due Date'} className={generalStyles.tableValue}>
            <span data-tag="allowRowEvents">{moment(row?.invoiceDueDate).format('DD/MM/YYYY')}</span>
          </span>
        ),
      },
      {
        name: 'Status',
        sortable: true,
        selector: (row) => row?.paymentStatus,
        cell: (row) => (
          <span
            data-content={'Status'}
            className={generalStyles.tableValue}
            data-tag="allowRowEvents"
          >
            <Status value={row?.paymentStatus} pill />
          </span>
        ),
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return (
            <span data-content={'Total Amount'} className={generalStyles.tableValue}>
              <span>{cur + ' ' + nf.format(row?.total)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.total,
      },
    ],
    [tab],
  );

  const getData = useCallback(
    (page, size) => {
      const filterValues = getValues();
      const search = {
        paymentStatus: filterValues.status?.value?.toUpperCase() ?? undefined,
        companyId: activeCompany.id,
        purchaseOrderState: 'APPROVED',
        invoiceStatus: "APPROVED",
        purchaseOrderNo: filterText,
        vendorId: filterValues.vendor?.value,
        requesterId: filterValues.requester?.value,
      };
      return SpmsService.getAllPurchaseOrdersInvoices(page, size, search);
    },
    [trigger, filterText],
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);


  const filterData = [
    // {
    //   name: 'location',
    //   label: 'Location',
    //   isMulti: false,
    //   fetchType: 'location',
    // },
    // {
    //   name: 'department',
    //   label: 'Department',
    //   isMulti: false,
    //   fetchType: 'department',
    // },
    {
      name: 'vendor',
      label: 'Vendor',
      isMulti: false,
      fetchType: 'vendor',
    },
    // {
    //   name: 'requester',
    //   label: 'Requester',
    //   isMulti: false,
    //   fetchType: 'user',
    // },
    {
      name: 'status',
      label: 'Status',
      isMulti: false,
      options: [
        { label: 'Outstanding', value: 'outstanding' },
        { label: 'Partially Paid', value: 'partially_paid' },
        { label: 'Paid', value: 'paid' },
        { label: 'Closed', value: 'closed' },
      ],
    },
  ];

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#fff"
        placeholder="Search by Ref No"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <>
      <Box $mobExtend $asHolder>
        <Text type="subtitle" weight={500}>
          Payment Overview
        </Text>
        <br />
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}>
            {filterCmp}
            <div className={generalStyles.filters}>
              <Filter
                control={control}
                filterData={filterData}
                setTriggerRefetch={setTrigger}
                setValue={setValue}
              />
            </div>
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote columns={columns || []} selectableRows={false} fetchData={getData} />
        </Suspense>
      </Box>
    </>
  );
};

export default Payments;
