import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import DataTable from 'react-data-table-component';
import Checkbox from '../Forms/Checkbox';
import TablePagination from './TablePagination';
import TableNoData from './TableNoData';
import generalStyles from '../../../styles/general.module.css';

const customStyles = {
  responsiveWrapper: {
    style: {
      borderRadius: '8px',
      '@media (max-width: 1023px)' : {
        borderRadius: 0,
      }
    },
  },
  head: {
    style: {
      display: 'none',
      color: '#0A1217',
      fontSize: '14px',
      background: 'none',
      fontWeight: 500,
      '@media all and (min-width: 1024px)': {
        display: 'flex',
      },
    },
  },
  headRow: {
    style: {
      backgroundColor: '#145FF51A',
      minHeight: '54px',
    },
  },
  rows: {
    style: {
      position: 'relative',
      flexDirection: 'column',
      gap: '16px',
      minHeight: '54px',
      padding: '20px 0 4px 0',
      fontSize: '14px',
      fontWeight: 500,
      color: '#0A1217CC',
      '&:not(:last-of-type)': {
        borderBottomColor: '#dddddd',
      },
      '@media all and (min-width: 1024px)': {
        flexDirection: 'row',
        fontSize: '14px',
        gap: 0,
        padding: '12px 0',
      },
    },
    selectedHighlightStyle: {
      '&:nth-of-type(n)': {
        color: '#000000',
        backgroundColor: 'rgba(69,186,188,0.1)',
        borderBottomColor: '#dddddd',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: 'transparent',
      borderBottomColor: '#dddddd',
      color: '#000000',
      outline: 'none',
      '@media all and (min-width: 1024px)': {
        backgroundColor: '#F3F7FF',
      },
    },
  },
  expanderRow: {
    style: {
      backgroundColor: '#fff',
      borderBottom: '1px solid #dddddd',
    },
  },
  cells: {
    style: {
      position: 'static',
      '@media all and (min-width: 1024px)': {
        position: 'relative',
      },
    },
  },
};

const selectProps = {
  indeterminate: (isIndeterminate) => isIndeterminate.toString(),
  $size: 16,
};

const customSort = (rows, selector, direction) => {
  return rows.sort((rowA, rowB) => {
    const aField =
      typeof selector(rowA) === 'number' ? selector(rowA) : selector(rowA).toString().toLowerCase();
    const bField =
      typeof selector(rowB) === 'number' ? selector(rowB) : selector(rowB).toString().toLowerCase();
    let comparison = 0;
    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }
    return direction === 'desc' ? comparison * -1 : comparison;
  });
};

const DataTableBase = ({ className, toNoData, ...props }) => (
  <StyleSheetManager shouldForwardProp={isPropValid}>
    <DataTable
      className={className ? className : generalStyles.tableRegular}
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      selectableRowsHighlight
      responsive
      highlightOnHover
      expandableRowsHideExpander
      customStyles={customStyles}
      pagination={props?.data?.length > 10}
      paginationComponent={TablePagination}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 30, 50]}
      defaultSortFieldId={props.defaultSortFieldId || null}
      defaultSortAsc={props.defaultSortAsc}
      sortFunction={customSort}
      noDataComponent={<TableNoData cmp={toNoData} />}
      {...props}
    />
  </StyleSheetManager>
);

export default DataTableBase;
