import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import style from "../../../styles/insights.module.css";
import Text from "../../UI/Typography/Text";
import Select from "react-select";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import s2pmsService from "../../../services/spmsService.service";
import moment from "moment";
import {CustomTooltipLine} from "./Tooltips";
import TableNoData from "../../UI/General/TableNoData";
import {readableTitleFromBackend} from "../../../utils/readableTitleFromBackend";
import Box from '../../UI/General/Box';
import { nf } from '../../../utils/nf';

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle">{readableTitleFromBackend(payload.value, 2)}</text>
    </g>
  );
}

const ChartOverall = ({departments, periods, companyId, currency}) => {
  const [values, setValues] = useState(null)
  const [chartData, setChartData] = useState(null)
  const {handleSubmit, control} = useForm({
    mode: 'onChange',
    defaultValues: {
      period: periods[0],
      department: departments[0]
    },
    values
  });

  const loadData = useCallback((departmentId, start, end = new Date()) => {
    if (companyId) {
      let currentDate = moment();
      let previousMonthDate = currentDate.subtract(start, 'month');
      let search = {
        companyId: companyId,
        startDate: previousMonthDate,
        endDate: end
      }
      if (departments.length > 0 && departmentId !== 0){
        search.departmentId = departmentId
      }
      s2pmsService.insightsOverallSpent(search).then(r => {
        if (r.data.message === "Operation Successful") {
          const monthlySpendReport = r.data.data.monthlySpendReport?.map(el => ({...el, date: moment().month(el.month-1).format('MMM')+'_'+el.year}))?.sort((a, b) => a.year - b.year || a.month - b.month);
          let data = {...r.data.data, monthlySpendReport }
          setChartData(data)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }, [companyId, departments])

  const onSubmit = (data) => {
    loadData(data?.department?.value, data?.period?.value)
  }

  useEffect(() => {
    setValues({department: departments[0]})
  }, [departments])

  useEffect(() => {
    loadData(undefined, periods[0].value)
  }, [loadData, periods])

  return (
    <Box $mobExtend $asHolder $noOverflow className={style.box}>
      <form className={style.chartBlock}>
        <div className={style.groupHead}>
          <div className={style.groupTitle}>
            <Text type={'subtitle'} weight={500}>Total Spend</Text>
            <Text type={'body-2'} className={style.colorSecondary}>Approved Spend</Text>
          </div>
          <div className={style.actionsHorizontal}>
            <Controller
              name={'period'}
              control={control}
              render={({ field}) => (
                <Select
                  {...field}
                  className={'react-select-container small'}
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Period"
                  options={periods}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
            <Controller
              name={'department'}
              control={control}
              render={({ field}) => (
                <Select
                  {...field}
                  className={'react-select-container small'}
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Select Department"
                  options={departments}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
          </div>
        </div>
        {!!chartData?.totalInvoicedAmount ? (
          <div className={style.overallLegend}>
            <div className={style.overallLegendItem}>
              <div className={style.legendItem}>
                <span className={style.legendItemBullet} style={{backgroundColor: '#3B82F6' }}/>
                <span className={style.legendItemText}>Total invoiced amount</span>
              </div>
              <div className={style.overallLegendPrice}>{currency} {nf.format(chartData?.totalInvoicedAmount)}</div>
            </div>
            {/*<div className={style.overallLegendItem}>*/}
            {/*  <div className={style.legendItem}>*/}
            {/*    <span className={style.legendItemBullet} style={{backgroundColor: '#3B82F6' }}/>*/}
            {/*    <span className={style.legendItemText}>Total invoiced amount</span>*/}
            {/*  </div>*/}
            {/*  <div className={style.overallLegendPrice}>{currency} {chartData?.totalInvoicedAmount}</div>*/}
            {/*</div>*/}
          </div>
        ) : null}
        {chartData?.monthlySpendReport && chartData?.monthlySpendReport?.length > 0 ? (
          <ResponsiveContainer width="100%" aspect={3}>
            <ComposedChart
              width={400}
              height={200}
              data={chartData?.monthlySpendReport}
              margin={{
                top: 0,
                right: 0,
                left: 10,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey='date' tick={<CustomizedAxisTick />} />
              <YAxis label={{ value: `Value (${currency})`, angle: -90, position: 'insideLeft', style: {textAnchor: "middle"} }} />
              <Tooltip content={<CustomTooltipLine/>}/>
              <Bar dataKey="totalInvoicedAmount" barSize={50} fill="#6E9BF5" />
              {/*<Line type="monotone" dataKey="totalInvoicedAmount" stroke="#F59E0B" activeDot={{ r: 5 }} />*/}
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <TableNoData/>
        )}
      </form>
    </Box>
  );
};

export default ChartOverall;
