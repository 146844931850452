import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import style from '../../styles/log_reg.module.css';
import Box from '../../components/UI/General/Box';
import Text from '../UI/Typography/Text';
import Input, { InputCode, Password } from '../UI/Forms/Input';
import * as Button from '../UI/Forms/Button';
import Icon from '../UI/General/Icon';
import { useForm, Controller } from 'react-hook-form';
import { useSearchParams, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../services/API';
import Toast from '../UI/General/Toast';
import Modal from '../UI/Modal/Modal';
import requestsServiceService from '../../services/requestsService.service';
import { regExps } from '../../utils/regExps';
import GradientText from '../UI/General/GradientText';

const Login = ({ setUser, setStatus }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [countDown, setCountDown] = useState(30);

  const {
    handleSubmit,
    control,
    setFocus,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onChange' });
  const [verifiedUser, setVerifiedUser] = useState(false);
  const [userName, setUserName] = useState(null);
  const [showResendVerificationModal, setShowResendVerificationModal] = useState(false);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  async function logIn({ email, password }) {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    try {
      setUserName(email);
      const user = await Auth.signIn(email, password);
      if (!!user?.attributes?.email_verified){
        console.log(user.attributes.email);
        if (typeof window.gr === 'function') {
          console.log('✅ gr function is defined');
          window.gr("track", "conversion", { email: user.attributes.email });
        } else {
          console.log('⛔️ gr function is NOT defined');
        }
      }

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setStatus('new-password');
      }
      if (user.challengeName === 'MFA_SETUP') {
        setStatus('topt');
      }
      setUser(user);
    } catch (error) {
      console.log('error signing in', error);
      console.log('message', error.message);
      if (error.message === 'User is not confirmed.') {
        resendConfirmation(email)
        setShowResendVerificationModal(true);
      }
      setIsButtonsBlocked(false);
      setToast({
        opened: true,
        message: error.message !== undefined ? error.message : error,
        type: 'fail',
      });
    }
  }

  useEffect(() => {
    console.log(searchParams.get('token'));
    console.log(searchParams.get('reset'));
    if (!!searchParams.get('token') && searchParams.get('reset') === null){
      setStatus('new-password')
    }
  }, [searchParams])

  useEffect(() => {
    if (!verifiedUser) {
      let link = searchParams.get('verificationLink');
      if (link != null) {
        console.log(
          baseUrl +
            '/users/confirm-sign-up-with-link?confirmationLink=' +
            window.location.href
              .split('?verificationLink=%3Ca%20href=')[1]
              .split('%3EVerify%20Your%20Email%3C/a%3E')[0],
        );
        setVerifiedUser(true);
        axios
          .get(
            baseUrl +
              '/users/confirm-sign-up-with-link?confirmationLink=' +
              window.location.href
                .split('?verificationLink=%3Ca%20href=')[1]
                .split('%3EVerify%20Your%20Email%3C/a%3E')[0],
          )
          .then((res) => {
            console.log('success');
          })
          .catch((err) => {
            console.log('err');
            console.log(err);
            setToast({
              opened: true,
              message: err,
              type: 'fail',
            });
          });
      } else setVerifiedUser(true);
    }
  }, []);


  useEffect(() => {
    if (countDown > 0) {
      const timer = setTimeout(() => setCountDown(countDown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countDown]);

  const resendConfirmation = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    console.log(userName ?? email);
    requestsServiceService
      .resendConfirmation(userName ?? email)
      .then((res) => {
        if (res.status === 200 && res.data.message === 'Operation Successful') {
          setCountDown(30);
          setToast({
            opened: true,
            message: res.data.data,
            type: 'success',
            cb: () => setToast((toast) => ({ ...toast, opened: false })),
          });
        }
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err?.response?.data?.message ?? err?.response?.data?.errorMessage,
          type: 'fail',
          cb: () => setToast((toast) => ({ ...toast, opened: false })),
        });
      });
  };

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .confirmSignUp(userName, data.code.join(''))
      .then((res) => {
        setToast({
          opened: true,
          message: res.data.data,
          type: 'success',
          cb: () => {
            return setIsButtonsBlocked(false), setShowResendVerificationModal(false);
          },
        });
      })
      .catch((error) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message:
            error.response.data.message !== undefined
              ? error.response.data.message
              : error.response.data.error,
          type: 'fail',
        });
      });
  };

  return (
    <>
      <div className={style.authContent}>
        <div className={style.authHeader}>
          <Text type="h2" weight={600}>
            Welcome Back!
          </Text>
          <GradientText $from="#313439b2" $to="#3778ffb2">
            <Text type="h5" weight={400}>
              {!!searchParams.get('reset') ? "Your password was reset. You can log in using your new password." : "Sign in to your account"}
            </Text>
          </GradientText>
        </div>
        <form onSubmit={handleSubmit(logIn)} className={style.authForm}>
          <div className={style.inputs + ' ' + style.inputsColomn}>
            <div className="inp-container">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value: regExps.email,
                    message: 'Email is not valid',
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Business Email"
                    $iconName={'mail'}
                    $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.email && <p className="error-message">{errors.email?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Password
                    placeholder="Password *"
                    $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.password?.type === 'required' && (
                <p className="error-message">Password is required</p>
              )}
            </div>
          </div>
          <div className={style.btnBefore + ' ' + style.btnBeforeLogin}>
            <Button.ActionLabeled
              $primary
              $full
              $style="blue"
              type="button"
              onClick={() => setStatus('forgot')}
            >
              <Text type="body-2" weight={600}>
                Forgot Password?
              </Text>
            </Button.ActionLabeled>
          </div>
          <div className={style.btn}>
            <Button.Main
              $primary
              $full
              $style="blue"
              type="submit"
              disabled={!isValid || !isDirty || isButtonsBlocked}
            >
              <span>Sign In</span>
            </Button.Main>
          </div>
        </form>
        <div className={style.btnAfter}>
          <Text type="body-1" weight={500}>
            Don’t have an account?
          </Text>
          <Button.ActionLabeled
            $primary
            $full
            $style="blue"
            type="button"
            onClick={() => setStatus('sign-up')}
          >
            <Link to="/register">
              <Text type="body-1" weight={600}>
                Sign up
              </Text>
            </Link>
          </Button.ActionLabeled>
        </div>
      </div>
      <Modal $show={showResendVerificationModal} $radius={16} $close={() => setShowResendVerificationModal(false)} $closableOutside>
        <div className={style.modal}>
          <div className={style.authHeaderFull}>
            <Text type="h2">Your account has not been confirmed.</Text>
            <GradientText $from="#313439B2" $to="#3778ffb2">
              <Text className={style.authHeaderSubText} type="body-1" weight={500}>
                To confirm your account, enter the 6-digit code that we sent to{' '}
                <a href={`mailto:${userName}`}>{userName}</a>. Please check you Spam folder as well.
              </Text>
            </GradientText>
          </div>
          <form className={style.authForm}>
            <div className={style.inputsCode}>
              {Array(6)
                .fill(0)
                .map((_, index) => (
                  <Controller
                    key={index}
                    name={`code.${index}`}
                    control={control}
                    rules={{
                      required: true,
                      minLength: 1,
                      maxLength: 1,
                      pattern: /^[0-9]*$/,
                    }}
                    valueAsNumber
                    defaultValue=""
                    render={({ field }) => (
                      <InputCode
                        {...field}
                        type="text"
                        maxLength={1}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d$/.test(value)) {
                            setValue(`code.${index}`, value, { shouldValidate: true });
                            if (index < 5) {
                              setFocus(`code.${index + 1}`);
                            }
                          } else {
                            setValue(`code.${index}`, '', { shouldValidate: true });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Backspace') {
                            if (!field.value && index > 0) {
                              setFocus(`code.${index - 1}`);
                            }
                          }
                          if (/^\d$/.test(e.key) && field.value) {
                            setValue(`code.${index}`, e.key, { shouldValidate: true });
                            if (index < 5) {
                              setFocus(`code.${index + 1}`);
                            }
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                ))}
            </div>
            <div className={style.btn + ' ' + style.btnBefore}>
              <Button.Main
                disabled={countDown !== 0}
                $primary
                $full
                $style="blank"
                $group
                type="button"
                onClick={() => {
                  resendConfirmation();
                }}
              >
                {countDown !== 0 && (
                  <span>
              <Text weight={400} type="body-1">
                00:{String(countDown).padStart(2, '0')}
              </Text>
            </span>
                )}
                <span>
            <Text weight={400} type="body-1">
              Resend code
            </Text>
          </span>
              </Button.Main>
              <Button.Main
                onClick={handleSubmit(onSubmit)}
                $primary
                $full
                $style="blue"
                type="submit"
                disabled={!isValid || isButtonsBlocked}
              >
                Confirm & Sign in
              </Button.Main>
            </div>
          </form>
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Login;
