import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import SpmsService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import { nf } from '../../utils/nf';
import Search from '../../components/UI/General/Search';
import Filter from '../../components/UI/General/Filter';
import { useForm } from 'react-hook-form';
import Status from '../../components/UI/Typography/Status';

const Invoices = () => {
  const { tab } = useParams();
  const activeCompany = useStore((state) => state.activeCompany);
  const [trigger, setTrigger] = useState(false);
  const [filterText, setFilterText] = useState(undefined);

  const { control, setValue, getValues } = useForm({
    mode: 'onChange',
  });

  const columns = useMemo(
    () => [
      {
        name: 'Ref No',
        wrap: true,
        sortable: true,
        selector: (row) => row?.purchaseOrderNo,
        cell: (row) => (
          <span data-content={'Ref No'} className={generalStyles.tableValue}>
            <Link to={'/purchase-orders/' + row.id} state={{ tabToSwitch: 'invoices' }}>
              <span>{row?.purchaseOrderNo}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'Title',
        wrap: true,
        sortable: true,
        selector: (row) => row?.title,
        cell: (row) => {
          const title = row.title.length > 20 ? `${row.title.slice(0, 20)}...` : row.title;
          return (
            <span data-content={'Title'} className={generalStyles.tableValue}>
              <span>{title}</span>
            </span>
          );
        },
      },
      {
        name: 'Location',
        cell: (row) => (
          <span data-content={'Location'} className={generalStyles.tableValue}>
            <span>{row?.locationName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.branchName,
      },
      {
        name: 'Department',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row?.department?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.department?.name,
      },
      {
        name: 'Vendor',
        cell: (row) => (
          <span data-content={'Vendor'} className={generalStyles.tableValue}>
            <span>{row?.vendor?.legalName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.vendor?.legalName,
      },
      {
        name: 'Requester',
        cell: (row) => (
          <span data-content={'Requester'} className={generalStyles.tableValue}>
            <span>{row?.requester?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.requester?.name,
      },
      {
        name: 'Status',
        sortable: true,
        selector: (row) => row?.invoiceStatus,
        cell: (row) => (
          <span
            data-content={'Status'}
            className={generalStyles.tableValue}
            data-tag="allowRowEvents"
          >
            <Status value={row?.invoiceStatus} pill />
          </span>
        ),
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return (
            <span data-content={'Total Amount'} className={generalStyles.tableValue}>
              <span>{cur + ' ' + nf.format(row?.total)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.total,
      },
    ],
    [tab],
  );

  const getData = useCallback(
    (page, size) => {
      const filterValues = getValues();
      const search = {
        invoiceStatus: filterValues.status?.value ?? undefined,
        companyId: activeCompany.id,
        purchaseOrderState: 'APPROVED',
        purchaseOrderNo: filterText,
        departmentId: filterValues.department?.value,
        locationId: filterValues.location?.value,
        vendorId: filterValues.vendor?.value,
        requesterId: filterValues.requester?.value,
      };
      return SpmsService.getPurchaseOrders(size, page, search);
    },
    [trigger, filterText],
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  const filterData = [
    {
      name: 'location',
      label: 'Location',
      isMulti: false,
      fetchType: 'location',
    },
    {
      name: 'department',
      label: 'Department',
      isMulti: false,
      fetchType: 'department',
    },
    {
      name: 'vendor',
      label: 'Vendor',
      isMulti: false,
      fetchType: 'vendor',
    },
    {
      name: 'requester',
      label: 'Requester',
      isMulti: false,
      fetchType: 'user',
    },
    {
      name: 'status',
      label: 'Status',
      isMulti: false,
      options: [
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Pending Approval', value: 'PENDING_APPROVAL' },
        { label: 'Outstanding', value: 'NOT_INVOICED' },
        { label: 'Rejected', value: 'REJECTED' },
      ],
    },
  ];

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        placeholder="Search by Ref No"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <>
      <Box $mobExtend $asHolder>
        <Text type="subtitle" weight={500}>
          Invoice Overview
        </Text>
        <br />
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}>
            {filterCmp}
            <div className={generalStyles.filters}>
              <Filter
                control={control}
                filterData={filterData}
                setTriggerRefetch={setTrigger}
                setValue={setValue}
              />
            </div>
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote columns={columns || []} selectableRows={false} fetchData={getData} />
        </Suspense>
      </Box>
    </>
  );
};

export default Invoices;
