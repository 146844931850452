import { Fragment, useEffect, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import generalStyles from '../../styles/general.module.css';
import Label from '../UI/Forms/Label';
import Input from '../UI/Forms/Input';
import Text from '../UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { regExps } from '../../utils/regExps.js';

const StreetAndCity = ({ control, errors, $hasLabel }) => {
  return (
    <>
      <div className="inp-container">
        <Label $isRequired={$hasLabel} $title="Street" />
        <Controller
          name="street"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Street is required',
            },
            maxLength: {
              value: 50,
              message: 'Street must be at most 50 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Street"
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.street && <p className="error-message">{errors.street?.message}</p>}
      </div>
      <div className="inp-container">
        <Label $isRequired={$hasLabel} $title="City" />
        <Controller
          name="city"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'City is required',
            },
            maxLength: {
              value: 50,
              message: 'City must be at most 50 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="City"
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.city && <p className="error-message">{errors.city?.message}</p>}
      </div>
    </>
  );
};

const StateAndPostcode = ({ control, errors, $hasLabel }) => {
  return (
    <>
      <div className="inp-container">
        <Label $isRequired={$hasLabel} $title="State" />
        <Controller
          name="state"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'State is required',
            },
            maxLength: {
              value: 50,
              message: 'State must be at most 50 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="State"
              $labelRequired={$hasLabel}
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.state && <p className="error-message">{errors.state?.message}</p>}
      </div>
      <div className="inp-container">
        <Label $isRequired={$hasLabel} $title="Post Code" />
        <Controller
          name="postalCode"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Post Code is required',
            },
            maxLength: {
              value: 20,
              message: 'Post Code must be at most 20 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Code"
              $labelRequired={$hasLabel}
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.postalCode && <p className="error-message">{errors.postalCode?.message}</p>}
      </div>
    </>
  );
};

const LocationAutocomplete = ({ control, errors, setValue, $hasLabel, $fullFields, trigger }) => {
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(query);
  const [showDetails, setShowDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [disableDropDrown, setDisableDropDrown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropDownRef = useRef(null);
  const street = useWatch({ name: 'street', control });

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!!street) {
      setShowDetails(true);
    }
  }, [street]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(query);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    const getLocation = async () => {
      if (!debouncedValue || disableDropDrown) return;
      try {
        setFetchError(false);
        setIsLoading(true);
        const response = await fetch(
          `https://api.geoapify.com/v1/geocode/autocomplete?text=${debouncedValue}&limit=5&format=json&apiKey=a7cef2c8cd07499cbfee34cadc3118d1`,
        );
        const json = await response.json();
        const newOptions = json.results.map((item) => {
          return {
            label: item.address_line1,
            value: item,
            text: item.address_line2,
          };
        });
        setDynamicOptions(newOptions);
      } catch (err) {
        setFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getLocation();
  }, [debouncedValue]);

  const onLocationChange = (address) => {
    setIsOpen(false);
    setShowDetails(true);
    setValue('address', address.address_line1);
    setValue('city', address.city || '');
    setValue('street', address.street || '');
    setValue('country', address.country || '');
    setValue('state', address.state || '');
    setValue('postalCode', address.postcode || '');
    trigger();
  };

  return (
    <>
      <div className="inp-container">
        <Label $title="Address" />
        <div ref={dropDownRef}>
          <Controller
            name="address"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Address is required',
              },
            }}
            render={({ field }) => (
              <div className="inp-container">
                <Input
                  {...field}
                  type="text"
                  placeholder="Search for your address"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  onChange={(e) => {
                    field.onChange(e);
                    const value = e.target.value;
                    setQuery(value);
                  }}
                  onFocus={() => setIsOpen(true)}
                />
                {isOpen && debouncedValue && !isLoading && !disableDropDrown && (
                  <div className={styles.locationOptions}>
                    {!!dynamicOptions.length ? (
                      dynamicOptions.map((option, index) => (
                        <button
                          className={styles.locationOption}
                          onClick={() => onLocationChange(option.value)}
                          key={`${option.value.lat}-${index}`}
                        >
                          <Text type={'body-2'} weight={600}>
                            {option.label}
                          </Text>
                          <Text type={'body-3'}>{option.text}</Text>
                        </button>
                      ))
                    ) : (
                      <span className={styles.locationOption + ' ' + styles.noOptions}>
                        <Text type={'body-2'} weight={600}>
                          {fetchError ? 'Something went wrong' : 'No results'}
                        </Text>
                        <Button.ActionLabeled
                          $primary
                          $full
                          $style="blue"
                          type="button"
                          onClick={() => {
                            setShowDetails(true);
                            setIsOpen(false);
                            if (fetchError) {
                              setDisableDropDrown(true);
                            }
                          }}
                        >
                          <Text type="body-2" weight={500}>
                            Enter address manually
                          </Text>
                        </Button.ActionLabeled>
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          />
        </div>
        {errors.address && <p className="error-message">{errors.address?.message}</p>}
      </div>
      {/* {showDetails && ( */}
      <>
        {$fullFields ? (
          <>
            <StreetAndCity control={control} errors={errors} $hasLabel />
          </>
        ) : (
          <div className={generalStyles.fieldsTwo}>
            <StreetAndCity control={control} errors={errors} $hasLabel />
          </div>
        )}
        {$fullFields ? (
          <>
            <StateAndPostcode control={control} errors={errors} $hasLabel />
          </>
        ) : (
          <div className={generalStyles.fieldsTwo}>
            <StateAndPostcode control={control} errors={errors} $hasLabel />
          </div>
        )}
        <div className="inp-container">
          <Label $isRequired={$hasLabel} $title="Country" />
          <Controller
            name="country"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: 'Country is required',
              },
              maxLength: {
                value: 50,
                message: 'Country must be at most 50 characters',
              },
              pattern: {
                value: regExps.alphaNumericalSpecial,
                message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Country *"
                $labelRequired={$hasLabel}
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.country && <p className="error-message">{errors.country?.message}</p>}
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default LocationAutocomplete;
