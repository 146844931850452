import React from 'react';
import styled from 'styled-components';

const H1 = styled.h1`
  font-weight: ${({ $weight }) => ($weight ? $weight : '700')};
  font-size: 32px;
  line-height: 1.1em;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
  @media all and (min-width: 1024px) {
    font-size: 36px;
  }
`;
const H2 = styled.h2`
  font-weight: ${({ $weight }) => ($weight ? $weight : '700')};
  font-size: 28px;
  line-height: 1.25em;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
  @media all and (min-width: 1024px) {
    font-size: 30px;
  }
`;
const H3 = styled.h3`
  font-weight: ${({ $weight }) => ($weight ? $weight : '700')};
  font-size: 20px;
  line-height: 1.25em;
  letter-spacing: -0.48px;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
  @media all and (min-width: 1024px) {
    font-size: 24px;
  }
`;
const H4 = styled.h4`
  font-weight: ${({ $weight }) => ($weight ? $weight : '700')};
  font-size: 18px;
  line-height: 1.25em;
  letter-spacing: -0.4px;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
  @media all and (min-width: 1024px) {
    font-size: 20px;
  }
`;
const H5 = styled.h5`
  font-weight: ${({ $weight }) => ($weight ? $weight : '700')};
  font-size: 16px;
  line-height: 1.25em;
  letter-spacing: -0.4px;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
`;
const SubTitle = styled.p`
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  margin: 0;
  font-size: 18px;
  line-height: normal;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
`;
const StyledP = styled.p`
  margin: 0;
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  line-height: 1.4em;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
`;
const StyledSpan = styled.span`
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
`;
const Body1 = styled(StyledP)`
  font-size: 16px;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
`;
const Body2 = styled(StyledP)`
  font-size: 14px;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
`;
const Body3 = styled(StyledP)`
  font-size: 12px;
  background: ${({ $inherit }) => ($inherit ? 'inherit' : '')};
`;
const Text = ({ type, children, weight, inherit, ...rest }) => {
  switch (type) {
    case 'h1':
      return (
        <H1 $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </H1>
      );
    case 'h2':
      return (
        <H2 $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </H2>
      );
    case 'h3':
      return (
        <H3 $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </H3>
      );
    case 'h4':
      return (
        <H4 $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </H4>
      );
    case 'h5':
      return (
        <H5 $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </H5>
      );
    case 'subtitle':
      return (
        <SubTitle $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </SubTitle>
      );
    case 'body-1':
      return (
        <Body1 $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </Body1>
      );
    case 'body-2':
      return (
        <Body2 $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </Body2>
      );
    case 'body-3':
      return (
        <Body3 $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </Body3>
      );
    default:
      return (
        <StyledSpan $weight={weight} $inherit={inherit} {...rest}>
          {children}
        </StyledSpan>
      );
  }
};

export default Text;
