import React, {useState} from 'react';
import { Auth } from 'aws-amplify';
import style from '../../styles/log_reg.module.css';
import Text from '../UI/Typography/Text';
import { Controller, useForm } from 'react-hook-form';
import Input from '../UI/Forms/Input';
import * as Button from '../UI/Forms/Button';
import GoogleReCaptcha from "../UI/Forms/GoogleReCaptcha";
import { regExps } from '../../utils/regExps';
import GradientText from '../UI/General/GradientText';
import Toast from '../UI/General/Toast';
import Icon from '../UI/General/Icon';
import rs from '../../services/requestsService.service'

const ForgetPassword = ({ setStatus, setUserName }) => {
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onChange",
  });
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  async function forgotPassword({ email }) {
    setToast((item) => ({ ...item, opened: false }));
    try {
      // const data = await Auth.forgotPassword(email, {
      //   deliveryMethod: 'email',
      // });
      console.log('run');
     await rs.forgotPassword(email).then(r => {
        setUserName(email);
        setStatus('check-email');
      })
      // recaptchaRef.current.reset();
    } catch (err) {
      console.log(err);
      setToast({
        opened: true,
        message: err,
        type: 'fail',
      })
    }
  }

  // console.log(Auth);
  return (
    <>
      <div className={style.authContent}>
        <div className={style.authHeader}>
          <Text type="h2" weight={600}>Forgot password?</Text>
          <GradientText $from="#313439b2" $to="#3778ffb2">
            <Text type="h5" weight={400}>Enter your account’s email address and we’ll send you a link to reset your password</Text>
          </GradientText>
        </div>
        <form onSubmit={handleSubmit(forgotPassword)} className={style.authForm}>
          <div className={style.inputs + ' ' + style.inputsColomn}>
            <div className='inp-container'>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value: regExps.email,
                    message: 'Email is not valid',
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Business Email"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                    $iconName="mail"
                    {...field}
                  />
                )}
              />
              {errors.email && <p className="error-message">{errors.email?.message}</p>}
            </div>
            <GoogleReCaptcha setCaptchaResponse={setCaptchaResponse}/>
            <Button.Main
              disabled={!isDirty || !isValid || !captchaResponse}
              $primary
              $full
              $style="blue"
              type="submit"
            >
              <span>Send Reset Link</span>
            </Button.Main>
            <div className={style.centerBtn}>
              <Button.ActionLabeled onClick={() => setStatus('sign-in')}>
                <Icon $width={20} $height={20} $icon="arrow-right" $color="#145ff5"/>
                <span>Back to Login</span>
              </Button.ActionLabeled>
            </div>
          </div>
        </form>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  )
};

export default ForgetPassword;
