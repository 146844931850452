import React, { forwardRef } from 'react';
import styled from 'styled-components';

const FileLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  font-weight: 500;
  color: #145ff5;
  border: 1px solid #145ff5;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  & + span {
    color: #8a8b9d;
  }
`;
const FileWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  width: 100%;
  margin-bottom: 0;
  padding: ${({ $small }) => ($small ? '9px' : '35px')};
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: ${({ $isInvalid }) => ($isInvalid ? '1px solid #cd3e27' : '1px solid #acaec9')};
  font-size: 14px;
  overflow-y: auto;
  &:hover ${FileLabel} {
    background-color: #145ff5;
    color: #ffffff;
  }
  & input[type='file'] {
    display: none;
  }
  & img {
    height: 100px;
  }
`;

const File = forwardRef((props, ref) => {
  const { $picture, $small, $isInvalid, ...other } = props;
  return (
    <FileWrapper $small $isInvalid={props.$isInvalid}>
      {$picture && true && $picture !== '' ? (
        <img src={$picture} alt={other.name} />
      ) : (
        <>
          <FileLabel>Choose File</FileLabel>
          <span>No file chosen</span>
        </>
      )}
      <input type="file" {...other} ref={ref} />
    </FileWrapper>
  );
});

export default File;
