import React, { useState } from 'react';
import generalStyles from '../../../../styles/general.module.css';
import InvoicesTable from '../Invoices/InvoicesTable';
import InvoicesCreate from '../Invoices/InvoicesCreate';

const InvoicesTab = (props) => {
  const [createMode, setCreateMode] = useState({ createEdit: false, invoiceId: undefined });

  return (
    <div className={generalStyles.top}>
      {!createMode.createEdit ? (
        <InvoicesTable props={props} setCreateMode={setCreateMode} />
      ) : (
        <InvoicesCreate
          $trigger={props.$trigger}
          setCreateMode={setCreateMode}
          invoiceId={createMode.invoiceId}
        />
      )}
    </div>
  );
};

export default InvoicesTab;
