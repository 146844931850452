import { useEffect, useMemo, useState } from 'react';
import spmsServiceService from '../../services/spmsService.service';
import Box from '../../components/UI/General/Box';
import BackButton from '../../components/shared/BackButton';
import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/requisition.module.css';
import poStyles from '../../styles/purchaseOrders.module.css';
import generalStyles from '../../styles/general.module.css';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import Input from '../../components/UI/Forms/Input';
import SelectUsers from '../../components/shared/SelectUsers';
import * as Button from '../../components/UI/Forms/Button.jsx';
import Icon from '../../components/UI/General/Icon';
import Toast from '../../components/UI/General/Toast.jsx';
import Label from '../../components/UI/Forms/Label.jsx';
import Documents from '../../components/UI/Forms/Documents.jsx';
import moment from 'moment';

const ReceivingItem = ({ item, index, control, errors, clearRow }) => {
  const [showNote, setShowNote] = useState(false);

  return (
    <>
      <div className={styles.itemsReceiving}>
        <Text type="body-2" weight={400}>
          {item.description}
        </Text>
        <Text type="body-2" weight={400}>
          {item.uom}
        </Text>
        <div className="inp-container">
          <Controller
            name={`poItems.${index}.passedQuantity`}
            control={control}
            defaultValue={''}
            rules={{
              required: {
                value: item.receivedQuantity < item.quantity,
                message: 'Required',
              },
              maxLength: {
                value: 10,
                message: 'Maximum 10 characters',
              },
              validate: {
                onlyAllowed: (v) => /^[0-9]*$/.test(v) || 'Numeric characters only',
                onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                $small
                disabled={item.receivedQuantity >= item.quantity}
                placeholder="Enter Passed Items"
                className={errors.hasOwnProperty(field.name) && 'error'}
              />
            )}
          />
          {errors?.poItems?.[index]?.passedQuantity && (
            <p className="error-message">{errors?.poItems?.[index]?.passedQuantity?.message}</p>
          )}
        </div>
        <div className="inp-container">
          <Controller
            name={`poItems.${index}.failedQuantity`}
            control={control}
            defaultValue={''}
            rules={{
              required: {
                value: item.receivedQuantity < item.quantity,
                message: 'Required',
              },
              maxLength: {
                value: 10,
                message: 'Maximum 10 characters',
              },
              validate: {
                onlyAllowed: (v) => /^[0-9]*$/.test(v) || 'Numeric characters only',
                onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                $small
                disabled={item.receivedQuantity >= item.quantity}
                placeholder="Enter Failed Items"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors?.poItems?.[index]?.failedQuantity && (
            <p className="error-message">{errors?.poItems?.[index]?.failedQuantity?.message}</p>
          )}
        </div>
        <Text type="body-2" weight={400}>
          {`${item.receivedQuantity}/${item.quantity}`}
        </Text>
        <div className={styles.itemActions}>
          <Button.Action
            $variant="circle"
            $style="lightGrayishBlue"
            $width={32}
            $height={32}
            onClick={() => setShowNote(!showNote)}
            type="button"
          >
            <Icon $width={20} $height={20} $icon="add-note" $color="#145FF5" />
          </Button.Action>
          <Button.Action
            $variant="circle"
            $style="lightGrayishBlue"
            $width={32}
            $height={32}
            type="button"
            onClick={() => clearRow(index)}
          >
            <Icon $width={20} $height={20} $icon="delete" $color="#145FF5" />
          </Button.Action>
        </div>
      </div>
      {showNote ? (
        <div className={styles.itemNote}>
          <Text weight={500} type={'body-1'} className={styles.itemNoteLabel}>
            Notes
          </Text>
          <Text weight={500} type={'body-2'}>
            {item?.itemNotes === '' ? 'There is no notes to display' : item?.itemNotes}
          </Text>
        </div>
      ) : null}
    </>
  );
};

const ReceivingView = () => {
  const [values, setValues] = useState(null);
  const { orderId, receivedId } = useParams();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [receivedItem, setReceivedItem] = useState([]);
  const [triggerRefetch, setTriggerRefetch] = useState([]);
  const [isButtonBlocked, setIsButtonsBlocked] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  useEffect(() => {
    const fetchData = async () => {
      return await spmsServiceService.getPurchaseOrder(orderId);
    };
    if (orderId) {
      fetchData().then((r) => {
        if (r.data.message === 'Operation Successful') {
          const { notes, ...rest } = r.data.data;
          setValues({ ...rest, purchaseOrderNotes: notes });
          if (receivedId) return;
          setValue(
            'poItems',
            rest.purchaseOrderItems.map((item) => ({ purchaseOrderItemId: item.id })),
          );
        }
      });
    }
    fetchData();
  }, [orderId, triggerRefetch]);

  useEffect(() => {
    if (!receivedId || !values) return;
    spmsServiceService.getReceiveItemPurchaseOrder(orderId, receivedId).then((r) => {
      if (r.data.message === 'Operation Successful') {
        setReceivedItem(r.data.data);
        const receivedItem = r.data.data;
        const itemValues = {
          receivedBy: {
            label: receivedItem.receivedBy.name,
            value: receivedItem.receivedBy.name,
            userId: receivedItem.receivedBy.userId,
          },
          receivedDate: new Date(receivedItem.receivedDate),
          poItems: values.purchaseOrderItems.map((item) => {
            const reqItem = receivedItem.receivedItems.find(
              (reqItem) => reqItem.purchaseOrderItem.id === item.id,
            );
            return {
              id: reqItem.id,
              purchaseOrderItemId: reqItem.purchaseOrderItem.id,
              passedQuantity: reqItem.passedQuantity,
              failedQuantity: reqItem.failedQuantity,
            };
          }),
        };
        reset(itemValues);
      }
    });
  }, [values]);

  const totalCost = useMemo(() => {
    return values?.purchaseOrderItems
      ? values?.purchaseOrderItems.reduce(
          (acc, current) => acc + (parseFloat(current.totalCost) || 0),
          0,
        )
      : 0;
  }, [values]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data) => {
    // setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    let newReceivingAttachments = [];
    if (data.attachments !== undefined && data.attachments.length > 0) {
      const files = data.attachments.map(async (item) => ({
        name: item.name.split(' ').join('-'),
        document: await getBase64(item.attachment),
      }));
      newReceivingAttachments = await Promise.all(files).then((result) => result);
    }
    const requestBody = {
      receivedDate: new Date(data.receivedDate).toISOString(),
      receivedBy: {
        userId: data.receivedBy.userId,
      },
      notes: '',
      items: data.poItems
        .map((item) => ({
          id: item.id,
          purchaseOrderItemId: item.purchaseOrderItemId,
          passedQuantity: parseInt(item.passedQuantity),
          failedQuantity: parseInt(item.failedQuantity),
        }))
        .filter((poItem) => poItem.passedQuantity !== ''),
      attachments: newReceivingAttachments,
    };
    console.log('requestBody', requestBody);
    if (receivedId) {
      spmsServiceService
        .updateReceiveItemsPurchaseOrder(orderId, receivedId, requestBody)
        .then((r) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: 'Received Items has been updated successfully',
            type: 'success',
          });
          reset({}, { keepValues: false });
          setTriggerRefetch((state) => !state);
        })
        .catch((reason) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.errors[0].errorMessage,
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createReceiveItemsPurchaseOrder(orderId, requestBody)
        .then((r) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: 'Received Items has been submitted successfully',
            type: 'success',
          });
          reset({}, { keepValues: false });
          setTriggerRefetch((state) => !state);
        })
        .catch((reason) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.errors[0].errorMessage,
            type: 'fail',
          });
        });
    }
  };
  const files = (files) =>
    files.map((file) => ({
      attachment: file,
      name: file.name,
    }));

  const receiveAll = () => {
    setValue(
      'poItems',
      values.purchaseOrderItems.map((item) => {
        const pendingQuantity = item.quantity - item.receivedQuantity;
        return item.receivedQuantity >= item.quantity
          ? { ...item }
          : { ...item, passedQuantity: pendingQuantity, failedQuantity: 0 };
      }),
    );
  };

  const clearRow = (index) => {
    setValue(`poItems.${index}.passedQuantity`, '');
    setValue(`poItems.${index}.failedQuantity`, '');
  };
  return (
    <Box $mobExtend $asHolder $noOverflow>
      <div className={generalStyles.top}>
        <div>
          <BackButton />
        </div>
        <div className={`${generalStyles.title} ${generalStyles.underline}`}>
          <div className={generalStyles.titleText}>
            <Text type="subtitle" weight={500}>
              {`Receipt for ${values?.title} (${values?.purchaseOrderNo})`}
            </Text>
            <Text type="body-1" weight={500}>
              {`Vendor ${values?.vendor?.legalName} - ${totalCost}`}
            </Text>
          </div>
          {values?.fulfillmentStatus !== 'FULFILLED' && (
            <div className={generalStyles.addItemButton}>
              <Button.Main
                $mid
                $style="blue"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={isButtonBlocked}
              >
                Save
              </Button.Main>
            </div>
          )}
        </div>
        {values && (
          <div className={generalStyles.top}>
            <div className={generalStyles.fieldsThree}>
              <div className={poStyles.topRow}>
                <Text weight={600} type="body-2">
                  Requester
                </Text>
                <div className={styles.requisitionValue}>
                  <Text weight={400} type="body-2">
                    {values?.requester?.name}
                  </Text>
                </div>
              </div>
              <div className={poStyles.topRow}>
                <Text weight={600} type="body-2">
                  Location
                </Text>
                <div className={styles.requisitionValue}>
                  <Text weight={400} type="body-2">
                    {values?.location?.name}
                  </Text>
                </div>
              </div>
              <div className={poStyles.topRow}>
                <Text weight={600} type="body-2">
                  Department
                </Text>
                <div className={styles.requisitionValue}>
                  <Text weight={400} type="body-2">
                    {values?.department?.name}
                  </Text>
                </div>
              </div>
              {values.fulfillmentStatus !== 'FULFILLED' && (
                <>
                  <div>
                    <div className="inp-container">
                      <Label $title="Received Date" />
                      <Controller
                        name="receivedDate"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Received Date is required',
                          },
                          maxLength: {
                            value: 10,
                            message: 'Maximum 10 characters',
                          },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                          <DatePicker
                            {...rest}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText="Select Date"
                            selected={rest.value}
                            wrapperClassName="custom-datepicker"
                            customInput={
                              <Input
                                {...rest}
                                className={errors.hasOwnProperty(rest.name) && 'error'}
                              />
                            }
                            onChange={(e) => {
                              rest.onChange(e);
                              trigger(rest.name);
                            }}
                          />
                        )}
                      />
                      {errors.receivedDate && (
                        <p className="error-message">{errors.receivedDate?.message}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="inp-container">
                      <Label $title="Receiver" />
                      <Controller
                        name="receivedBy"
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: {
                            value: true,
                            message: 'Receiver is required',
                          },
                        }}
                        render={({ field: { ref, ...rest } }) => (
                          <SelectUsers
                            {...rest}
                            selectRef={ref}
                            className={
                              errors.hasOwnProperty(rest.name)
                                ? 'react-select-container error'
                                : 'react-select-container'
                            }
                          />
                        )}
                      />
                      {errors.receivedBy && (
                        <p className="error-message">{errors.receivedBy?.message}</p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {values?.fulfillmentStatus !== 'FULFILLED' && (
              <div>
                <Label $title="Upload Good received note" $isRequired />
                <Documents
                  control={control}
                  fieldName="attachments"
                  filesSchema={files}
                  error={errors?.attachments?.message || errors?.attachments?.root?.message}
                  clearErrors={clearErrors}
                />
              </div>
            )}
            {/* {tab === 'documents' && <QuotesTab data={values?.attachments} />} */}

            <div>
              {values?.fulfillmentStatus !== 'FULFILLED' && (
                <div className={styles.itemsAdd}>
                  <Button.Main $style="blue" $reverseColor type="button" onClick={receiveAll}>
                    <Text weight={400}>Receive All</Text>
                  </Button.Main>
                </div>
              )}
              <div className={styles.itemsContainer}>
                <div className={styles.itemsReceiving}>
                  <Text type="body-2" weight={500}>
                    Item Name
                  </Text>
                  <Text type="body-2" weight={500}>
                    Measure of Unit
                  </Text>
                  <Text type="body-2" weight={500}>
                    Pass
                  </Text>
                  <Text type="body-2" weight={500}>
                    Failed
                  </Text>
                  <Text type="body-2" weight={500}>
                    Received
                  </Text>
                </div>
                <>
                  {values.purchaseOrderItems.map((item, index) => (
                    <ReceivingItem
                      key={item.id}
                      item={item}
                      index={index}
                      control={control}
                      errors={errors}
                      clearRow={clearRow}
                    />
                  ))}
                </>
              </div>
            </div>
          </div>
        )}
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </Box>
  );
};

export default ReceivingView;
