import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, Navigate, useSearchParams } from 'react-router-dom';
import { useStore } from '../../store/store';
import SideBar from '../SideBar';
import * as Button from '../../components/UI/Forms/Button';
import Header from '../Header';
import style from '../../styles/companiesModal.module.css';
import styles from '../../styles/loggedInLayout.module.css';
import generalStyles from '../../styles/general.module.css';
import Modal from '../UI/Modal/Modal';
import Text from '../UI/Typography/Text';
import { Controller, useForm } from 'react-hook-form';
import Input from '../UI/Forms/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyBasicSchema } from '../../utils/validation/companySchema';
import Select from 'react-select';
import requestsServiceService from '../../services/requestsService.service';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../UI/General/Toast';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import { Auth } from 'aws-amplify';
import { useIdle } from '../../hooks/useIdle';

import smartLook from 'smartlook-client';
import { currencies } from '../../constants/currencies';

const LoggedInSuccess = () => {
  const user = useStore((state) => state.user);
  const countries = useStore((state) => state.countries);
  const updateUser = useStore((state) => state.updateUser);
  const customCompanies = useStore((state) => state.user?.customCompanies);
  const roles = useStore((state) => state.user?.roles);
  const setCompany = useStore((state) => state.setCompany);
  const setActiveCompany = useStore((state) => state.setActiveCompany);
  const setFullCompanies = useStore((state) => state.setFullCompanies);
  const activeCompany = useStore((state) => state.activeCompany);
  const company = useStore((state) => state.company);
  const navigate = useNavigate();
  const fullCompanies = useStore((state) => state.fullCompanies);
  const fetchCountries = useStore((state) => state.fetchCountries);
  const fetchRegions = useStore((state) => state.fetchRegions);
  const setSteps = useStore((state) => state.setSteps);
  const stepsDone = useStore((state) => state.stepsDone);
  const [show, setShow] = useState(true);
  const [basicSetup, setBasicSetup] = useState(false);
  const { pathname } = useLocation();
  const [showSidebarMenu, setShowSidebarMenu] = useState(false);
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  // const {
  //   handleSubmit,
  //   control,
  //   formState: { errors, isValid },
  //   watch,
  //   setValue,
  // } = useForm({
  //   mode: 'onChange',
  //   resolver: yupResolver(companyBasicSchema),
  // });
  // const companyCountry = watch('country');

  // useEffect(() => {
  //   if (!countries || !companyCountry) return undefined;
  //   const selectedCountryCurrency = countries.find(
  //     (country) => country.value === companyCountry.value,
  //   )?.currency;
  //   setValue('defaultCurrency', { label: selectedCountryCurrency, value: selectedCountryCurrency });
  // }, [countries, companyCountry]);

  // const updateCompanyDetails = useCallback(
  //   async (companyId) => {
  //     const res = await spmsServiceService.getCompany(companyId);
  //     const company = res.data.data;
  //     if (company.parent?.id) {
  //       setCompany(company.parent.id);
  //     } else {
  //       setCompany(companyId);
  //     }
  //     setActiveCompany(companyId);
  //     setSteps();
  //   },
  //   [setCompany, setSteps],
  // );

  useEffect(() => {
    // const checkFullSetupCompany = async () => {
    //   const setupCompaniesIds = [];
    //   await Promise.all(
    //     customCompanies.map(async (company) => {
    //       const res = await spmsServiceService.getMetrics(company.companyId);
    //       const metrics = res.data.data;
    //       if (metrics.hasBranches && metrics.hasDepartments && metrics.hasGl) {
    //         const { companyId, companyName } = company;
    //         setupCompaniesIds.push({ companyId, companyName });
    //       }
    //     }),
    //   );
    //   setFullCompanies(setupCompaniesIds);
    //   if (setupCompaniesIds.length === 1) {
    //     updateCompanyDetails(setupCompaniesIds[0].companyId);
    //   }
    // };
    // if (!activeCompany) {
    //   checkFullSetupCompany();
    // } else {
    //   setCompany(customCompanies?.find((item) => item.company.mainCompany === true)?.companyId);
    // }
    setActiveCompany(customCompanies?.find((item) => item.company.mainCompany === true)?.companyId);
    setCompany(customCompanies?.find((item) => item.company.mainCompany === true)?.companyId);
  }, [setCompany, customCompanies]);

  useEffect(() => {
    fetchCountries();
    fetchRegions();
  }, [fetchCountries, fetchRegions]);

  useEffect(() => {
    if (!stepsDone) {
      setSteps();
    }
  }, [setSteps, stepsDone, company]);

  // useEffect(() => {
  //   const selfSetup = sessionStorage.getItem('selfsetup');
  //   setBasicSetup(!selfSetup);
  // }, []);

  // const proceedSelfSetup = (data) => {
  //   setIsButtonsBlocked(true);
  //   navigate('/company-setup', { state: { data } });
  //   setShow(false);
  //   sessionStorage.setItem('selfsetup', 'true');
  // };

  // const onSubmit = async (data) => {
  //   setIsButtonsBlocked(true);
  //   setToast((item) => ({ ...item, opened: false }));
  //   const requestBody = {
  //     name: data.name,
  //     country: data.country.value,
  //     industry: data.industry.value,
  //     defaultCurrency: data.defaultCurrency.value,
  //   };
  //   spmsServiceService
  //     .createDummyCompany(requestBody)
  //     .then((res) => {
  //       updateCompanyDetails(res.data.data.id);
  //       setFullCompanies([{ companyId: res.data.data.id, companyName: res.data.data.name }]);
  //       if (user) {
  //         requestsServiceService
  //           .getUserDetailsById(user.id)
  //           .then((res) => {
  //             updateUser(res.data.data);
  //           })
  //           .catch((err) => console.log(err));
  //       }
  //       setToast({
  //         opened: true,
  //         message: 'Company created',
  //         type: 'success',
  //       });
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //       setIsButtonsBlocked(false);
  //       setToast({
  //         opened: true,
  //         message: err.response?.data?.message,
  //         type: 'fail',
  //       });
  //     });
  // };

  useEffect(() => {
    setShowSidebarMenu(false);
    setShowHeaderMenu(false);
  }, [pathname]);

  const accessSubscription = useSubscriptionAllowed();

  const [trialDaysLeft, setTrialsDaysLeft] = useState(undefined);
  const [showExpireSoonModal, setShowExpireSoonModal] = useState(false);

  useEffect(() => {
    const today = new Date();
    const trialEnd = new Date(accessSubscription?.company?.trialEndsAt);
    const trialDifference = Math.round((trialEnd.getTime() - today.getTime()) / (1000 * 3600 * 24));
    setTrialsDaysLeft(trialDifference);
    if (
      trialDifference === 7 ||
      trialDifference === 3 ||
      accessSubscription?.company?.state === 'EXPIRED'
    ) {
      setShowExpireSoonModal(true);
    }
  }, [accessSubscription?.company?.trialEndsAt, accessSubscription?.company?.state]);

  const countForSmartLook = useRef(0);
  useEffect(() => {
    if (
      user !== undefined &&
      accessSubscription !== undefined &&
      accessSubscription?.company?.state !== 'EXPIRED' &&
      countForSmartLook.current < 1
    ) {
      countForSmartLook.current = countForSmartLook.current + 1;
      smartLook.identify(user.id, {
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
        package: accessSubscription?.plan?.name,
        currency: accessSubscription?.company?.currency,
        price: accessSubscription?.company?.total,
      });
    }
  }, [accessSubscription, user]);

  // const closeShowExpireSoon = () => {
  //   setShowExpireSoonModal(false);
  //   updateCompanyDetails(
  //     customCompanies?.find((item) => item.company.mainCompany === true)?.companyId,
  //   );
  // };

  async function logout() {
    console.log('Logout');
    await Auth.signOut().then(() => {
      sessionStorage.removeItem('selfsetup');
    });
  }

  const idle = useIdle(60 * 60 * 1000); // 1 hour

  // useEffect(() => {
  //   if (!idle) return;
  //   logout();
  // }, [idle]);

  useEffect(() => {
    const redirectPath = sessionStorage.getItem('redirectPath');
    if (pathname === redirectPath) {
      sessionStorage.removeItem('redirectPath');
    }
  }, [pathname]);

  // useEffect(() => {
  //   if ((user !== undefined || user !== null) && (customCompanies !== undefined || customCompanies !== null)){
  //     const cc = customCompanies?.find((item) => item.company.mainCompany === true)
  //     if (cc?.companyId !== undefined){
  //       window.userGuiding.identify('1Ax69i57j0j69i60l4', {
  //         name: user?.firstName +' '+ user?.lastName,
  //         email: user?.email,
  //         company: {
  //           id: cc?.companyId,
  //           name: cc?.companyName
  //         }
  //       })
  //     }
  //   }
  // },[user, customCompanies])

  return (
    <div className={styles.wrapper}>
      {stepsDone ? (
        <>
          <SideBar showMenu={showSidebarMenu} />
          <main className={styles.content}>
            <Header
              setShowSidebarMenu={setShowSidebarMenu}
              setShowHeaderMenu={setShowHeaderMenu}
              showHeaderMenu={showHeaderMenu}
              showSidebarMenu={showSidebarMenu}
            />
            <section className={styles.contentInner}>
              <Outlet />
            </section>
          </main>
        </>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

const LoggedIn = () => {
  const location = useLocation();
  const [search] = useSearchParams();
  const user = useStore((state) => state.user);
  let toLocation;
  if (search.get('source') !== null) {
    toLocation = location;
  } else {
    toLocation = '';
  }
  return user !== null ? (
    <LoggedInSuccess />
  ) : (
    <Navigate to={'/'} replace state={{ from: toLocation }} />
  );
};

export default LoggedIn;
