import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Input from '../../components/UI/Forms/Input';
import generalStyles from '../../styles/general.module.css';
import SpmsService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { nf } from '../../utils/nf';
import Search from '../../components/UI/General/Search';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import { useForm } from 'react-hook-form';
import Filter from '../../components/UI/General/Filter';

const Receiving = () => {
  const { tab } = useParams();
  const activeCompany = useStore((state) => state.activeCompany);
  const [filterText, setFilterText] = useState(undefined);
  const [trigger, setTrigger] = useState(false);

  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const columns = useMemo(
    () => [
      {
        name: 'Ref',
        wrap: true,
        sortable: true,
        selector: (row) => row?.purchaseOrderNo,
        cell: (row) => {
          let from;
          switch (tab) {
            case 'not_fulfilled':
              from = 'rnf';
              break;
            case 'partially_fulfilled':
              from = 'rpf';
              break;
            case 'fulfilled':
              from = 'rf';
              break;
            default:
              from = 'rnf';
          }
          return (
            <span data-content={'Ref'} className={generalStyles.tableValue}>
              <Link to={'/purchase-orders/' + row.id} state={{ tabToSwitch: 'receiving' }}>
                <span>{row.purchaseOrderNo}</span>
              </Link>
            </span>
          );
        },
      },
      {
        name: 'Title',
        wrap: true,
        sortable: true,
        selector: (row) => row?.title,
        cell: (row) => {
          const title = row.title.length > 20 ? `${row.title.slice(0, 20)}...` : row.title;

          return (
            <span data-content={'Title'} className={generalStyles.tableValue}>
              <span>{title}</span>
            </span>
          );
        },
      },
      {
        name: 'Location',
        cell: (row) => (
          <span data-content={'Location'} className={generalStyles.tableValue}>
            <span>{row?.locationName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.branchName,
      },
      {
        name: 'Department',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row?.department?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.department?.name,
      },
      {
        name: 'Vendor',
        cell: (row) => (
          <span data-content={'Vendor'} className={generalStyles.tableValue}>
            <span>{row?.vendor?.legalName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.vendor?.legalName,
      },
      {
        name: 'Requester',
        cell: (row) => (
          <span data-content={'Requester'} className={generalStyles.tableValue}>
            <span>{row?.requester?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.requester?.name,
      },
      {
        name: 'Status',
        cell: (row) => (
          <span data-content={'Status'} className={generalStyles.tableValue}>
            <span>{readableTitleFromBackend(row?.fulfillmentStatus)}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row.fulfillmentStatus,
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return (
            <span data-content={'Total Amount'} className={generalStyles.tableValue}>
              <span>{cur + ' ' + nf.format(row.total)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.total,
      },
    ],
    [tab],
  );

  const filterData = [
    {
      name: 'location',
      label: 'Location',
      isMulti: false,
      fetchType: 'location',
    },
    {
      name: 'department',
      label: 'Department',
      isMulti: false,
      fetchType: 'department',
    },
    {
      name: 'vendor',
      label: 'Vendor',
      isMulti: false,
      fetchType: 'vendor',
    },
    {
      name: 'status',
      label: 'Status',
      isMulti: false,
      options: [
        { label: 'Not Fulfilled', value: 'not_fulfilled' },
        { label: 'Partially Fulfilled', value: 'partially_fulfilled' },
        { label: 'Fulfilled', value: 'fulfilled' },
      ],
    },
  ];

  const getData = useCallback(
    (page, size) => {
      const filterValues = getValues();
      const search = {
        fulfillmentStatus: filterValues.status?.value?.toUpperCase() ?? undefined,
        purchaseOrderState: 'APPROVED',
        companyId: activeCompany?.id,
        title: filterText,
        departmentId: filterValues.department?.value,
        locationId: filterValues.location?.value,
        vendorId: filterValues.vendor?.value,
      };
      return SpmsService.getPurchaseOrders(size, page, search);
    },
    [trigger, filterText, activeCompany?.id],
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        placeholder="Search by title"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <>
      <Box $mobExtend $asHolder>
        <Text type="subtitle" weight={500}>
          Receiving Dashboard
        </Text>
        <br />
        <div className={generalStyles.tabsSearch}>
          {filterCmp}
          <div className={generalStyles.filters}>
            <Filter
              control={control}
              filterData={filterData}
              setTriggerRefetch={setTrigger}
              setValue={setValue}
            />
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote columns={columns || []} selectableRows={false} fetchData={getData} />
        </Suspense>
      </Box>
    </>
  );
};

export default Receiving;
