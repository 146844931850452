import { Controller, useForm } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import { useStore } from '../../store/store.js';
import { yupResolver } from '@hookform/resolvers/yup';
import spmsServiceService from '../../services/spmsService.service.js';
import { regExps } from '../../utils/regExps.js';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { useEffect, useState } from 'react';

const contactInfoSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .matches(regExps.email, 'Invalid email address')
    .max(50, 'E-mail must be at most 50 characters'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Phone number is invalid', (value) => {
      return isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || '');
    }),
  name: yup
    .string()
    .required('Name is required')
    .matches(regExps.userName, 'Invalid name')
    .max(50, 'Name must be at most 50 characters'),
  websiteUrl: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Website Url cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(regExps.websiteUrl, 'Invalid characters')
    .max(50, '50 characters max'),
});

const VendorContactOnboarding = ({ nextStep, setToast }) => {
  const user = useStore((state) => state.user);
  const setSteps = useStore((state) => state.setSteps);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useForm({ mode: 'onChange', resolver: yupResolver(contactInfoSchema) });

  useEffect(() => {
    setValue('email', user.email);
  }, [user]);

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    if (!isDirty) {
      nextStep();
      return;
    }
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = {
      name: data.name,
      contactType: 'ENTERED_BY_VENDOR',
      email: data.email,
      phoneNumber: data.phoneNumber,
      websiteUrl: data.websiteUrl,
      socialAccounts: {
        fb: data.fb,
        instagram: data.instagram,
        linkedIn: data.linkedIn,
        twitter: data.twitter,
      }
    };
    spmsServiceService
      .createVendorContact(user.externalId, requestBody)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Saved',
          type: 'success',
          cb: () => {
            setIsButtonsBlocked(false);
            nextStep();
          },
        });
        setSteps();
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Contact Information
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text inherit type="h5" weight={600}>
            Add your contact information
          </Text>
        </GradientText>
      </div>
      <div>
        <form className={styles.inputs}>
          <div className="inp-container">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="email"
                  placeholder="Business Email *"
                  disabled
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.email && <p className="error-message">{errors.email?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  placeholder="Enter Phone Number *"
                  limitMaxLength={true}
                  international={true}
                  className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                />
              )}
            />
            {errors.phoneNumber && <p className="error-message">{errors.phoneNumber?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Primary Contact Full Name *"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.name && <p className="error-message">{errors.name?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="websiteUrl"
              control={control}
              defaultValue=""
              rules={{
                pattern: {
                  value: regExps.websiteUrl,
                  message: 'Website URL is not valid',
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Website URL (optional)"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.websiteUrl && <p className="error-message">{errors.websiteUrl?.message}</p>}
          </div>
          <div className={generalStyles.fieldsTwo}>
            <div className="inp-container">
              <Controller
                name="linkedIn"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="LinkedIn (optional)"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.linkedIn && (
                <p className="error-message">{errors.linkedIn?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="fb"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Facebook (optional)"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.fb && (
                <p className="error-message">{errors.fb?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="instagram"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Instagram (optional)"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.instagram && (
                <p className="error-message">{errors.instagram?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="twitter"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="X (Twitter) (optional)"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.twitter && (
                <p className="error-message">{errors.twitter?.message}</p>
              )}
            </div>
          </div>
        </form>
        <Button.Main
          $primary
          $full
          $style="blue"
          $iconRight
          type="submit"
          disabled={isButtonsBlocked}
          onClick={handleSubmit(onSubmit)}
        >
          {/* {company && !isDirty ? 'Continue' : 'Save & Continue'} */}
          Save & Continue
        </Button.Main>
      </div>
    </div>
  );
};

export default VendorContactOnboarding;
