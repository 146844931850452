export const unitOptions = [
  { label: 'Piece', value: 'Piece' },
  { label: 'Box', value: 'Box' },
  { label: 'Case', value: 'Case' },
  { label: 'Hour', value: 'Hrs' },
  { label: 'Day', value: 'Days' },
  { label: 'Month', value: 'Months' },
  { label: 'Dozen', value: 'Dozen' },
  { label: 'Kilogram (kg)', value: 'Kilogram' },
  { label: 'Gram (g)', value: 'Gram' },
  { label: 'Liter (L)', value: 'Liter' },
  { label: 'Milliliter (mL)', value: 'Milliliter' },
  { label: 'Meter (m)', value: 'Meter' },
  { label: 'Centimeter (cm)', value: 'Centimeter' },
  { label: 'Square Meter (m²)', value: 'Square Meter' },
  { label: 'Cubic Meter (m³)', value: 'Cubic Meter' },
];
