import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Tooltip from '../General/Tooltip';

const Label = styled.label`
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
  gap: 16px;
`;
const LabelText = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dddddd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    width: ${({ $size }) => ($size ? $size + 'px' : '30px')};
    height: ${({ $size }) => ($size ? $size + 'px' : '30px')};
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const SwitchContainer = styled.span`
  position: relative;
  display: inline-block;
  width: ${({ $size }) => ($size ? $size * 2 + 8 + 'px' : '60px')};
  height: ${({ $size }) => ($size ? $size + 8 + 'px' : '34px')};

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  & input:checked + ${Slider} {
    background-color: #145ff5;
  }
  & input:checked + ${Slider}:before {
    -webkit-transform: ${({ $size }) =>
      $size ? 'translateX(' + $size + 'px)' : 'translateX(26px)'};
    -ms-transform: ${({ $size }) => ($size ? 'translateX(' + $size + 'px)' : 'translateX(26px)')};
    transform: ${({ $size }) => ($size ? 'translateX(' + $size + 'px)' : 'translateX(26px)')};
  }
`;

const Required = styled.span`
  color: #cd3e27;
`;

const Switch = forwardRef((props, ref) => {
  const { $size, $reverse, $labelRequired, $tooltip, ...other } = props;
  return (
    <Label onClick={(e) => e.stopPropagation()} $reverse={$reverse}>
      <SwitchContainer $size={$size}>
        <input type="checkbox" {...other} ref={ref} />
        <Slider $size={$size} />
      </SwitchContainer>
      {other.label && other.label !== '' ? (
        <LabelText>
          <span>{other.label}</span>
          {$labelRequired && <Required>*</Required>}
          {$tooltip && <Tooltip text={$tooltip} />}
        </LabelText>
      ) : null}
    </Label>
  );
});

export default Switch;
