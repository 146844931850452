import Box from '../../components/UI/General/Box';
import DataTableBase from '../../components/UI/General/DataTableBase';
import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/vendorProfile.module.css';
import generalStyles from '../../styles/general.module.css';
import { useEffect, useMemo, useState } from 'react';
import Icon from '../../components/UI/General/Icon';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { Controller, useForm } from 'react-hook-form';
import Textarea from '../../components/UI/Forms/Textarea.jsx';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import OrdersTab from '../../components/Admins/VendorProfile/OrdersTab.jsx';
import DeliveryTab from '../../components/Admins/VendorProfile/DeliveryTab.jsx';
import Label from '../../components/UI/Forms/Label.jsx';
import Documents from '../../components/UI/Forms/Documents.jsx';
import PaymentTab from '../../components/Admins/VendorProfile/PaymentTab.jsx';
import spmsServiceService from '../../services/spmsService.service.js';
import Loading from '../../components/Loading.js';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary.jsx';
import TabsSlider from '../../components/UI/General/TabsSlider.jsx';
import InvoicesTab from '../../components/Admins/PurchaseOrders/Tabs/InvoicesTab.jsx';
import PaymentsTab from '../../components/Admins/PurchaseOrders/Tabs/PaymentsTab.jsx';
import ReceivingTab from '../../components/Admins/PurchaseOrders/Tabs/ReceivingTab.jsx';

const OrderDetailedView = () => {
  const [tab, setTab] = useState('notes');
  const [newFiles, setNewFiles] = useState([]);
  const [orderData, setOrderData] = useState(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!orderId) return;
    spmsServiceService.getPurchaseOrder(orderId).then((res) => setOrderData(res.data.data));
  }, [orderId]);
  console.log('orderData', orderData);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const files = (files) =>
    files.map((file) => ({
      id: null,
      attachment: file,
      name: '',
      type: 'VENDOR_DOC',
    }));

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = function () {
        resolve(filereader.result);
      };
      filereader.onerror = function (error) {
        console.log('Error: ', error);
        reject(error);
      };
    });
  };

  const readFiless = async () => {
    let myFiles = [];
    for (var i = 0; i < newFiles.length; i++) {
      let attachment = await readFile(newFiles[i][0]);
      myFiles.push({
        attachment: attachment,
        name: newFiles[i][0].name,
        type: 'VENDOR_DOC',
      });
    }
    return myFiles;
  };

  const columns = useMemo(
    () => [
      {
        name: 'Description',
        sortable: true,
        wrap: true,
        selector: (row) => row.description,
        cell: (row) => (
          <span data-content={'Description'} className={generalStyles.tableValue}>
            {row.description}
          </span>
        ),
      },
      {
        name: 'Gl Code',
        selector: (row) => row.glAccount.glCode,
        wrap: true,
        cell: (row) => (
          <span data-content={'Gl Code'} className={generalStyles.tableValue}>
            GL-{row.glAccount.glCode}
          </span>
        ),
      },
      {
        name: 'Quantity',
        selector: (row) => row.quantity,
        wrap: true,
        cell: (row) => (
          <span data-content={'Quantity'} className={generalStyles.tableValue}>
            {row.quantity}
          </span>
        ),
      },
      {
        name: 'Unit Price Excl Tax',
        selector: (row) => `$ ${row.unitPrice}`,
        sortable: true,
        cell: (row) => (
          <span data-content={'Unit Price Excl Tax'} className={generalStyles.tableValue}>
            {row.unitPrice}
          </span>
        ),
      },
      {
        name: 'Tax',
        selector: (row) => 'USD',
        sortable: true,
        cell: (row) => (
          <span data-content={'Tax'} className={generalStyles.tableValue}>
            USD
          </span>
        ),
      },
      {
        name: 'Subtotal',
        selector: (row) => row.subtotal,
        sortable: true,
        cell: (row) => (
          <span data-content={'Subtotal'} className={generalStyles.tableValue}>
            $ {row.subtotal}
          </span>
        ),
      },
      {
        name: '',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Button.Action
              $variant="circle"
              $style="lightGrayishBlue"
              $width={32}
              $height={32}
              onClick={() => {}}
              type="button"
            >
              <Icon $width={20} $height={20} $icon="add-note" $color="#45BABC" />
            </Button.Action>
          </div>
        ),
      },
    ],
    [],
  );

  const onSubmit = async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    console.log(data);
    const updatedPO = {
      purchaseOrderState: orderData.purchaseOrderState,
      title: orderData.title,
      requester: orderData.requester,
      purchaseOrderNotes: data.notes,
      companyId: orderData.company.id,
      branchId: orderData.branchId,
      departmentId: orderData.department.id,
      requestOnBehalfOf: orderData.requestOnBehalfOf,
      deliveryDate: orderData.deliveryDate,
      currency: orderData.currency,
      vendorId: orderData.vendor.id,
      purchaseOrderItems: orderData.purchaseOrderItems.map((item) => ({
        ...item,
        taxId: item.tax.id,
        generalLedgerId: item.glAccount.id,
      })),
      deliveryAddress: orderData.deliveryAddress,
      purchaseOrderAttachments: orderData.purchaseOrderAttachments ?? [],
    };
    spmsServiceService
      .updatePurchaseOrder(orderId, updatedPO)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Purchase Order updated',
          type: 'success',
        });
        navigate('/vendor-orders');
      })
      .catch((err) => {
        setToast({
          opened: true,
          message:
            err.response.data.message !== undefined
              ? err.response.data.message
              : err.response.data.error,
          type: 'fail',
        });
      });
  };

  const countTotal = () => {
    if (!orderData) return;
    const subtotal = orderData.purchaseOrderItems.reduce((acc, curr) => acc + curr.subtotal, 0);
    const taxtotal = orderData.purchaseOrderItems.reduce((acc, curr) => acc + curr.taxTotal, 0);
    const totalCost = orderData.purchaseOrderItems.reduce((acc, curr) => acc + curr.totalCost, 0);
    return { subtotal, taxtotal, totalCost };
  };

  if (!orderData) {
    return <Loading />;
  }

  return (
    <>
      <Box $radius={12} $mobExtend $asHolder className={styles.wrapper}>
        <div className={styles.orderTitle}>
          <Text weight={600} type="h2">
            {orderData.title}
          </Text>
          <Text style={{ color: '#45BABC' }} weight={600} type="h4">
            {orderData.purchaseOrderNo}
          </Text>
        </div>
        <div className={styles.orderInfo}>
          <div className={styles.orderInfoItem}>
            <div className={styles.orderItemName}>
              <Text weight={600}>PO Title</Text>
            </div>
            <Text weight={500}>{orderData.title}</Text>
          </div>
          <div className={styles.orderInfoItem}>
            <div className={styles.orderItemName}>
              <Text weight={600}>Requester</Text>
            </div>
            <Text weight={500}>{orderData.requester.name}</Text>
          </div>
          <div className={styles.orderInfoItem}>
            <div className={styles.orderItemName}>
              <Text weight={600}>Delivery Date</Text>
            </div>
            <Text weight={500}>{orderData.deliveryDate}</Text>
          </div>
          <div className={styles.orderInfoItem}>
            <div className={styles.orderItemName}>
              <Text weight={600}>Company</Text>
            </div>
            <Text weight={500}>{orderData.company.name}</Text>
          </div>
          <div className={styles.orderInfoItem}>
            <div className={styles.orderItemName}>
              <Text weight={600}>Branch</Text>
            </div>
            <Text weight={500}>{orderData.branchName}</Text>
          </div>
          <div className={styles.orderInfoItem}>
            <div className={styles.orderItemName}>
              <Text weight={600}>Request on Behalf of</Text>
            </div>
            <Text weight={500}>{orderData.requestOnBehalfOf.name}</Text>
          </div>
        </div>
        <DataTableBase
          data={orderData.purchaseOrderItems || []}
          columns={columns}
          expandOnRowClicked
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 15, 20, 30]}
        />
        <Summary
          subtotal={countTotal().subtotal}
          taxTotal={countTotal().taxtotal}
          totalCost={countTotal().totalCost}
          currency={'USD'}
        />
        <div>
          <TabsSlider className={generalStyles.tabSection}>
            <span onClick={() => setTab('notes')}>Notes</span>
            <span onClick={() => setTab('orders')}>Orders</span>
            <span onClick={() => setTab('delivery')}>Delivery</span>
            <span onClick={() => setTab('invoice')}>Invoice</span>
            <span onClick={() => setTab('payment')}>Payment Receipt</span>
          </TabsSlider>
          {tab === 'notes' && (
            <>
              <div className="inp-container">
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      $low
                      $counter
                      $counterMax={500}
                      placeholder="Notes text goes here"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                    />
                  )}
                />
              </div>
              <NotesTab data={orderData.purchaseOrderNotes} />
            </>
          )}
          {tab === 'orders' && <OrdersTab data={orderData} />}
          {tab === 'delivery' && <ReceivingTab shouldRefetch={() => {}} />}
          {tab === 'payment' && <PaymentsTab shouldRefetch={() => {}} />}
          {tab === 'invoice' && (
            <>
              <InvoicesTab />
              {/* <Label>Upload Invoice</Label>
              <Documents
                control={control}
                fieldName="invoiceAttachments"
                filesSchema={files}
                setNewFiles={setNewFiles}
                newFiles={newFiles}
              />
              {!!invalidFormats.length && (
                <div className={vendorStyles.formatError}>
                  <p>Unappropriate file format - {invalidFormats.join(', ')}</p>
                  <p>
                    Accepted formats: .pdf, .doc, .docx, .ppt, .pptx, .pps, .ppsx, .odt, .xls,
                    .xlsx, .PSD, .dot, .csv, .txt, .rtf, .html, .htm
                  </p>
                </div>
              )} */}
            </>
          )}
        </div>
      </Box>
      <div className={generalStyles.pageButtons}>
        <Button.Main $primary $style="pink" type="submit" onClick={handleSubmit(onSubmit)}>
          Save
        </Button.Main>
        <Button.Main
          $primary
          $style="gray"
          type="button"
          onClick={() => navigate('/vendor-orders')}
        >
          Discard
        </Button.Main>
      </div>
    </>
  );
};

export default OrderDetailedView;

const ExpandedComponent = ({ data }) => {
  return (
    <div className={styles.expanded}>
      <Text weight={500}>Notes:</Text>
      <Text weight={500}>{data.itemNotes}</Text>
    </div>
  );
};
