import { Cell, Legend, Pie, ResponsiveContainer, PieChart, Tooltip } from 'recharts';
import Text from '../Typography/Text';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';

const Chart = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  padding: 40px;
  ${({ borderRight }) => `border-${borderRight ? 'right' : 'left'}: 1px solid #d9d9d9;`}
`;
const ChartTotalRequested = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  margin-bottom: 12px;
  gap: 8px;
`;

const PieChartComponent = ({ piechartData, currency, totalAmount, borderRight }) => {
  const COLORS = ['#145FF5', '#00C46FCC'];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) + percent * 15;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentString = !!percent ? `${(percent * 100).toFixed(0)}%` : '';
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {percentString}
      </text>
    );
  };

  return (
    <Chart borderRight={borderRight}>
      <ChartTotalRequested>
        <Text type="h4" weight={600}>
          Total Spent:
        </Text>
        <NumericFormat
          value={totalAmount}
          displayType="text"
          thousandSeparator=","
          prefix={currency + ' '}
          decimalScale={2}
        />
      </ChartTotalRequested>
      <ResponsiveContainer height={160}>
        <PieChart
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          width="99%"
          height={100}
          isAnimationActive={true}
        >
          <Pie
            dataKey="value"
            data={piechartData}
            cx={'25%'}
            cy={'50%'}
            outerRadius={80}
            labelLine={false}
            label={renderCustomizedLabel}
            paddingAngle={0}
            fill="#C85D9F"
          >
            {piechartData.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            verticalAlign="top"
            iconType="circle"
            wrapperStyle={{
              zIndex: '98',
              display: 'flex',
              fontWeight: 500,
              rowGap: 10,
            }}
            align="left"
          />
          <Tooltip cursor={false} formatter={(label) => `${label} ${currency}`} />
        </PieChart>
      </ResponsiveContainer>
    </Chart>
  );
};

export default PieChartComponent;
