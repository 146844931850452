import * as yup from 'yup';
import { regExps } from '../regExps';

export const glSchema = yup.object().shape({
  glCode: yup
    .string()
    .required('Code is required')
    .matches(regExps.glCode, 'Invalid characters')
    .test('no-empty-spaces', 'Code cannot be just empty spaces', (value) => value?.trim() !== '')
    .max(50, 'Code must be at most 65 characters'),
  glDescription: yup
    .string()
    .required('Description is required')
    .matches(regExps.glDescription, 'Invalid characters')
    .test(
      'no-empty-spaces',
      'Description cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .max(50, 'Description must be at most 65 characters'),
  // glType: yup.object().shape({
  //   value: yup.string().required('Financial Type is required'),
  // }),
});
