import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import { useForm } from 'react-hook-form';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import Box from '../../components/UI/General/Box';
import { reducedText } from '../../utils/reducedText';
import Search from '../../components/UI/General/Search';
import Filter from '../../components/UI/General/Filter';

const VendorClient = () => {
  const navigate = useNavigate();
  const {
    control,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const [filterText, setFilterText] = useState(undefined);
  const activeCompany = 'd413f637-3ac1-4d02-b70d-2228d314a6dd';
  const user = useStore((state) => state.user);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [filterData, setFilterData] = useState([
    {
      name: 'location',
      label: 'Location',
      isMulti: false,
      fetchType: 'location',
    },
    {
      name: 'department',
      label: 'Department',
      isMulti: false,
      fetchType: 'department',
    },
    {
      name: 'vendor',
      label: 'Vendor',
      isMulti: false,
      fetchType: 'vendor',
    },
    {
      name: 'status',
      label: 'Status',
      isMulti: false,
      options: [
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Pending Approval', value: 'PENDING_APPROVAL' },
        { label: 'Draft', value: 'DRAFT' },
        { label: 'Denied', value: 'REJECTED' },
        { label: 'Received', value: 'RECEIVED' },
      ],
    }])

  const columns = useMemo(
    () => [
      {
        name: 'Ref No',
        sortable: true,
        wrap: true,
        cell: (row) => (
          <span data-content={'Ref No'} className={generalStyles.tableValue}>
            <Link to={'/requisitions/' + row.id}>
              <span>{row?.requestNo}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'Title',
        wrap: true,
        selector: (row) => row?.title,
        sortable: true,
        cell: (row) => {
          return (
            <span data-content={'Title'} className={generalStyles.tableValue}>
              <span>{reducedText(row?.title)}</span>
            </span>
          );
        },
      },
      {
        name: 'Location',
        cell: (row) => (
          <span data-content={'Location'} className={generalStyles.tableValue}>
            <span>{row?.location?.name ?? '-'}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.location?.name,
      },
      {
        name: 'Department',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row?.department?.name ?? '-'}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.department?.name,
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {/* {(row.requisitionState === 'Draft'.toUpperCase() ||
                    row.requisitionState === 'rejected'.toUpperCase()) && (
                    <Link to={'/requisitions/edit/' + row.id}>
                      <MenuItem>Edit</MenuItem>
                    </Link>
                  )} */}
                  {row.requisitionState === 'Approved'.toUpperCase() && (
                    <Link to={'/requisitions/' + row.id}>
                      <MenuItem>View</MenuItem>
                    </Link>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [],
  );

  const getData = useCallback((page, size) => {
      const filterValues = getValues();
      let search = {
        requisitionState: filterValues.status?.value,
        companyId: activeCompany.id,
        title: filterText,
        departmentId: filterValues.department?.value,
        locationId: filterValues.location?.value,
        vendorId: filterValues.vendor?.value,
      };
      return spmsServiceService.getRequisitions(size, page, search);
    }, [filterText, triggerRefetch, user, activeCompany.id],
  );

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        placeholder="Search by Ref No"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText, resetPaginationToggle],
  );

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <Text type="subtitle" weight={500}>Clients</Text>
        <br/>
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}>
            {filterCmp}
            <div className={generalStyles.filters}>
              <Filter
                control={control}
                filterData={filterData}
                setTriggerRefetch={setTriggerRefetch}
                setValue={setValue}
              />
            </div>
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
      </Box>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default VendorClient;
