import { Controller, useForm, useWatch } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import Select from 'react-select';
import CountryAndRegion from '../../components/UI/Forms/CountryAndRegion.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import { useStore } from '../../store/store.js';
import { useCallback, useEffect, useState } from 'react';
import { timezones } from '../../constants/timezones.js';
import { companyBasicSchema } from '../../utils/validation/companySchema.js';
import { yupResolver } from '@hookform/resolvers/yup';
import spmsServiceService from '../../services/spmsService.service.js';
import requestsServiceService from '../../services/requestsService.service';

const industryOptions = [
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Aerospace', label: 'Aerospace' },
  { value: 'Construction & Real Estate', label: 'Construction & Real Estate' },
  { value: 'Consumer Goods & Retail', label: 'Consumer Goods & Retail' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Entertainment & Media', label: 'Entertainment & Media' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare & Pharmaceuticals', label: 'Healthcare & Pharmaceuticals' },
  { value: 'Hospitality, Travel & Tourism', label: 'Hospitality, Travel & Tourism' },
  { value: 'Information Technology', label: 'Information Technology (IT)' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Non Profit & Social Services', label: 'Non Profit & Social Services' },
  { value: 'Professional Services', label: 'Professional Services' },
  { value: 'Transport & Logistics', label: 'Transport & Logistics' },
  { value: 'Telecommunication', label: 'Telecommunication' },
];

const CompanyOnboard = ({ nextStep, setToast }) => {
  const countries = useStore((state) => state.countries);
  const setActiveCompany = useStore((state) => state.setActiveCompany);
  const setCompany = useStore((state) => state.setCompany);
  const user = useStore((state) => state.user);
  const updateUser = useStore((state) => state.updateUser);
  const company = useStore((state) => state.company);
  const setSteps = useStore((state) => state.setSteps);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({ mode: 'onChange', resolver: yupResolver(companyBasicSchema) });
  const country = useWatch({ name: 'country', control });

  const createCurrencyOptions = useCallback(() => {
    if (!countries || !countries.length) return;
    const separator = '-';
    const allCurrencies = countries
      .map((country) => `${country.currencyName}${separator}${country.currency}`)
      .sort();
    const uniqueCurrencies = [...new Set([...allCurrencies])];
    return uniqueCurrencies.map((currency) => {
      const label = currency.split(separator);
      label[label.length - 1] = `(${label[label.length - 1]})`;
      return {
        label: label.join(' '),
        value: currency.split(separator).at(-1),
      };
    });
  }, [countries]);

  useEffect(() => {
    const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tzid) {
      const userTimezone = timezones.find((item) => item.utc.includes(tzid));
      if (userTimezone) {
        setValue('timezone', userTimezone);
      }
    }
    if (!country?.value || company) return;
    setValue('currency', {
      label: `${country.currencyName} (${country.currency})`,
      value: country.currency,
    });
  }, [country]);

  useEffect(() => {
    if (!company) return;
    const selTimezone = timezones.find((timezone) => timezone.value === company.timezone);
    setValue('name', company.name);
    setValue('country', { label: company.country, value: company.country });
    setValue('industry', { label: company.industry, value: company.industry });
    setValue('currency', { label: company.defaultCurrency, value: company.defaultCurrency });
    setValue('timezone', selTimezone);
  }, []);

  let count = 0

  const onSubmit = (data) => {
    if (!isDirty) {
      nextStep();
      return;
    }
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    let requestBody = {
      name: data.name,
      country: data.country.value,
      industry: data.industry.value,
      defaultCurrency: data.currency.value,
      timezone: data.timezone.label,
      poNumberSequence: 1,
      requisitionNumberSequence: 1,
      purchaseOrderProperties: {
        poNumberFormat: 'PO',
        autoGeneratedNumberAffix: 'SUFFIX',
        addCurrentDate: false,
        poDisclaimer: '',
        poPrefix: '',
        poTotalDigits: 5,
      },
      requisitionProperties: {
        requisitionNumberFormat: 'RQ',
        requisitionPrefix: '',
        requisitionTotalDigits: 5,
      },
    };
    if (company) {
      requestBody = { ...requestBody, id: company.id };
      spmsServiceService
        .updateCompany(requestBody)
        .then((res) => {
          count++
          if (count === 1){
            window.dataLayer.push({
              event: 'create_account_company',
              Company: {
                company_name: requestBody.name,                            // Name of the company
                country: requestBody.country,                          // Country where the company is located
                industry: requestBody.industry,                                // Industry to which the company belongs
                currency: requestBody.defaultCurrency,                                   // Currency used by the company
              },
            });
            console.log(window.dataLayer);
          }
          setCompany(res.data.data.id);
          setActiveCompany(res.data.data.id);
          setToast({
            opened: true,
            message: 'Saved',
            type: 'success',
            cb: () => {
              nextStep();
              setIsButtonsBlocked(false);
            },
          });
          if (user) {
            requestsServiceService
              .getUserDetailsById(user.id)
              .then((res) => {
                updateUser(res.data.data);
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createCompany(requestBody)
        .then((res) => {
          count++
          if (count === 1){
            window.dataLayer.push({
              event: 'create_account_company',
              Company: {
                company_name: requestBody.name,                            // Name of the company
                country: requestBody.country,                          // Country where the company is located
                industry: requestBody.industry,                                // Industry to which the company belongs
                currency: requestBody.defaultCurrency,                                   // Currency used by the company
              },
            });
            console.log(window.dataLayer);
          }
          setCompany(res.data.data.id);
          setActiveCompany(res.data.data.id);
          setToast({
            opened: true,
            message: 'Company created',
            type: 'success',
            cb: () => {
              nextStep();
              setIsButtonsBlocked(false);
            },
          });
          setSteps();
          if (user) {
            requestsServiceService
              .getUserDetailsById(user.id)
              .then((res) => {
                updateUser(res.data.data);
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    }
  };

  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Company
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text inherit type="h5" weight={600}>
            Add information about your company
          </Text>
        </GradientText>
      </div>
      <div>
        <form className={styles.inputs}>
          <div className="inp-container">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Company legal name *"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.name && <p className="error-message">{errors.name?.message}</p>}
          </div>
          <CountryAndRegion
            countryFieldName="country"
            control={control}
            isDisable={false}
            errors={errors}
            isCountryRequired
            placeholderCountry={'Country *'}
          />
          <div className="inp-container">
            <Controller
              name="industry"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  classNamePrefix="react-select"
                  isSearchable
                  placeholder="Industry *"
                  options={industryOptions}
                />
              )}
            />
            {errors.industry && <p className="error-message">{errors.industry?.value.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  classNamePrefix="react-select"
                  isSearchable
                  placeholder="Currency *"
                  options={createCurrencyOptions()}
                />
              )}
            />
            {errors.currency && <p className="error-message">{errors.currency?.value.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="timezone"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Timezone *"
                  options={timezones}
                />
              )}
            />
            {errors.timezone && <p className="error-message">{errors.timezone?.value.message}</p>}
          </div>
        </form>
        <Button.Main
          $primary
          $full
          $style="blue"
          $iconRight
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={isButtonsBlocked || !isValid}
        >
          {company && !isDirty ? 'Continue' : 'Save & Continue'}{' '}
        </Button.Main>
      </div>
    </div>
  );
};

export default CompanyOnboard;
