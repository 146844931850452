import { axiosInstance, baseUrl, s2pmsBaseUrl } from './API';

const integrationsService = {
  testConnection: (companyId, data) => axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/integrations/test-connection', data),
  getCompanyIntegrations: (companyId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations'),
  createIntegration: (companyId, data) => axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/integrations', data),
  getCompanyIntegration: (companyId, integrationId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations' + integrationId),
  updateCompanyIntegration: (companyId, integrationId, data) => axiosInstance.put(s2pmsBaseUrl + '/company/' + companyId + '/integrations' + integrationId, data),
  activateCompanyIntegration: (companyId, integrationId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations' + integrationId + '/activate'),
  archiveCompanyIntegration: (companyId, integrationId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations' + integrationId + '/archive'),
  // SAGE
  getSageCompanies: () => axiosInstance.get(baseUrl + '/sage/companies'),
  syncSageGls: (companyId) => axiosInstance.post(baseUrl + '/sage/sync-gls/company/{company-id}?companyId=' + companyId),
  syncSageVendors: (companyId) => axiosInstance.post(baseUrl + '/sage/sync-vendors/company/' + companyId),
};

export default integrationsService;
