import React, { useState, Suspense, useMemo, useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';
import Box from '../../components/UI/General/Box';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import { reducedText } from '../../utils/reducedText';
import { nf } from '../../utils/nf';
import budgetServiceService from '../../services/budgetService.service';
import { NumericFormat } from 'react-number-format';
import moment from 'moment/moment';

const Approve = () => {
  const { tab } = useParams();
  const [filterText, setFilterText] = useState(undefined);
  const activeCompany = useStore((state) => state.activeCompany);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const access = useAccessAllowed([
    'Requests',
    'Vendor_Management',
    'Budget_Management',
    'Invoice',
  ]);

  const columns = useMemo(() => {
    return {
      requisitions: [
        {
          name: 'Ref',
          wrap: true,
          selector: (row) => row?.requestNo,
          sortable: true,
          cell: (row) => {
            return (
              <span data-content={'Ref'} className={generalStyles.tableValue}>
                <Link to={'/requisitions/' + row.id} state={{ canApprove: true }}>
                  <span>{reducedText(row?.requestNo)}</span>
                </Link>
              </span>
            );
          },
        },
        {
          name: 'Title',
          wrap: true,
          selector: (row) => row?.title,
          sortable: true,
          cell: (row) => {
            return (
              <span data-content={'Title'} className={generalStyles.tableValue}>
                <span>{reducedText(row?.title)}</span>
              </span>
            );
          },
        },
        {
          name: 'Ref No',
          sortable: true,
          wrap: true,
          omit: tab !== 'approved',
          cell: (row) => (
            <span data-content={'Ref No'} className={generalStyles.tableValue}>
              <span>{row?.requestNo}</span>
            </span>
          ),
        },
        {
          name: 'Location',
          cell: (row) => (
            <span data-content={'Location'} className={generalStyles.tableValue}>
              <span>{row?.location?.name ?? '-'}</span>
            </span>
          ),
          sortable: true,
          selector: (row) => row?.location?.name,
        },
        {
          name: 'Department',
          cell: (row) => (
            <span data-content={'Department'} className={generalStyles.tableValue}>
              <span>{row?.department?.name ?? '-'}</span>
            </span>
          ),
          sortable: true,
          selector: (row) => row?.department?.name,
        },
        {
          name: 'Vendor',
          cell: (row) => (
            <span data-content={'Vendor'} className={generalStyles.tableValue}>
              <span>{row?.vendor?.legalName ?? '-'}</span>
            </span>
          ),
          sortable: true,
          selector: (row) => row?.vendor?.legalName,
        },
        {
          name: 'Requester',
          cell: (row) => (
            <span data-content={'Requester'} className={generalStyles.tableValue}>
              <span>{row?.requester?.name}</span>
            </span>
          ),
          sortable: true,
          selector: (row) => row?.requester?.name,
        },
        {
          name: 'Total Amount',
          cell: (row) => {
            const cur = row?.currency?.code ?? '';
            return (
              <span data-content={'Total Amount'} className={generalStyles.tableValue}>
                <span>{cur + ' ' + nf.format(row?.total)}</span>
              </span>
            );
          },
          sortable: true,
          selector: (row) => row?.total,
        },
      ],
      budget: [
        {
          name: 'Budget Name',
          sortable: true,
          selector: (row) => row.name,
          cell: (row) => {
            return (
              <span data-content={'Budget Name'} className={generalStyles.tableValue}>
                {access?.budget_management?.viewOwn ? (
                  <Link
                    to={`/budgets/${activeCompany.id}/pending-approval/${row?.id}`}
                    state={{ row }}
                  >
                    <span>{row.name}</span>
                  </Link>
                ) : (
                  <span>{row.name}</span>
                )}
              </span>
            );
          },
        },
        {
          name: 'Start Date',
          sortable: true,
          selector: (row) => row.startDate,
          cell: (row) => (
            <span data-content={'Start Date'} className={generalStyles.tableValue}>
              <span>{moment(row?.startDate).format('DD/MM/YYYY')}</span>
            </span>
          ),
          wrap: true,
        },
        {
          name: 'End Date',
          sortable: true,
          selector: (row) => row.endDate,
          cell: (row) => (
            <span data-content={'End Date'} className={generalStyles.tableValue}>
              <span>{moment(row?.endDate).format('DD/MM/YYYY')}</span>
            </span>
          ),
        },
        {
          name: 'Budget Type',
          sortable: true,
          selector: (row) => row.budgetType,
          cell: (row) => {
            const text = row?.budgetType?.split('_')[0]?.toLowerCase();
            return (
              <span data-content={'End Date'} className={generalStyles.tableValue}>
                <span>{text?.charAt(0)?.toUpperCase() + text?.slice(1)}</span>
              </span>
            );
          },
        },
        {
          name: 'Total Allocated',
          sortable: true,
          selector: (row) => row.budgetValue,
          cell: (row) => (
            <span data-content={'Total Allocated'} className={generalStyles.tableValue}>
              <NumericFormat
                value={row?.budgetValue}
                displayType="text"
                thousandSeparator=","
                prefix={`${row?.currency} `}
                decimalScale={2}
              />
            </span>
          ),
        },
        {
          name: 'Pending Approvers',
          cell: (row) => (
            <span data-content={'Pending Approvers'} className={generalStyles.tableValue}>
              <span>
                {row.approval?.approvers
                  ?.filter((approver) => approver.status === 'PENDING_APPROVAL')
                  .map((approver) => approver.approverName)
                  .join(', ')}
              </span>
            </span>
          ),
          sortable: true,
        },
      ],
      vendor: [
        {
          name: 'Vendor Name',
          selector: (row) => row.vendor.legalName,
          sortable: true,
          cell: (row) =>
            access?.vendor_management?.viewOwn ? (
              <Link
                weight={500}
                type="subtitle"
                to={'/vendors/' + activeCompany?.id + '/viewPending/' + row.vendor.id}
              >
                <span data-content={'Vendor Name'}>
                  <span>{row.vendor.legalName}</span>
                </span>
              </Link>
            ) : (
              <span>{row.vendor.legalName}</span>
            ),
        },
        {
          name: 'Category',
          sortable: true,
          selector: (row) => row.vendorCode,
          cell: (row) => (
            <span data-content={'Category'} className={generalStyles.tableValue}>
              <span>{row.vendor.category}</span>
            </span>
          ),
        },
        {
          name: 'Subcategory',
          sortable: true,
          selector: (row) => row.vendorCode,
          cell: (row) => (
            <span data-content={'Subcategory'} className={generalStyles.tableValue}>
              <span>{row.vendor.subCategory}</span>
            </span>
          ),
        },

        {
          name: 'Requester',
          sortable: true,
          selector: (row) => row.vendorCode,
          cell: (row) => (
            <span data-content={'Requester'} className={generalStyles.tableValue}>
              <span>{row.requester.name}</span>
            </span>
          ),
        },
      ],
      invoices: [
        {
          name: 'Invoice No',
          wrap: true,
          sortable: true,
          selector: (row) => row?.invoiceNumber,
          cell: (row) => (
            <span data-content={'Invoice No'} className={generalStyles.tableValue}>
              <Link
                to={'/purchase-orders/' + row.purchaseOrderId + '/invoices/' + row.invoiceId}
                state={{ place: 'approve' }}
              >
                <span>{row.invoiceNumber}</span>
              </Link>
            </span>
          ),
        },
        {
          name: 'PO No',
          sortable: true,
          selector: (row) => row?.purchaseOrderNo,
          cell: (row) => (
            <span data-content={'PO No'} className={generalStyles.tableValue}>
              <span>{row?.purchaseOrderNo}</span>
            </span>
          ),
        },
        {
          name: 'Location',
          cell: (row) => (
            <span data-content={'Location'} className={generalStyles.tableValue}>
              <span>{row?.locationName}</span>
            </span>
          ),
          sortable: true,
          selector: (row) => row?.locationName,
        },
        {
          name: 'Department',
          cell: (row) => (
            <span data-content={'Department'} className={generalStyles.tableValue}>
              <span>{row?.department?.name}</span>
            </span>
          ),
          sortable: true,
          selector: (row) => row?.department?.name,
        },
        {
          name: 'Vendor',
          cell: (row) => (
            <span data-content={'Vendor'} className={generalStyles.tableValue}>
              <span>{row?.vendor?.legalName}</span>
            </span>
          ),
          sortable: true,
          selector: (row) => row?.vendor?.legalName,
        },
        {
          name: 'Amount',
          cell: (row) => {
            const cur = row?.vendor?.vendorFinancialInfo?.currency?.code ?? '';
            return (
              <span data-content={'Amount'} className={generalStyles.tableValue}>
                <span>{cur + ' ' + nf.format(row?.total)}</span>
              </span>
            );
          },
          sortable: true,
          selector: (row) => row?.total,
        },
      ],
    };
  }, [tab, access]);

  const getData = useCallback(
    async (page, size) => {
      const servicesData = {
        requisitions: () =>
          spmsServiceService.getRequisitions(size, page, {
            requisitionState: 'PENDING_APPROVAL',
            companyId: activeCompany.id,
            title: filterText,
          }),
        budget: () =>
          budgetServiceService.searchBudgets(activeCompany?.id, page, 10, {
            approvalStatuses: ['PENDING_APPROVAL'],
            active: true,
            name: filterText,
          }),
        vendor: () =>
          spmsServiceService.getVendors(
            activeCompany?.id,
            {
              vendorName: filterText,
              vendorStatus: 'PENDING_APPROVAL',
            },
            page,
            size,
          ),
        invoices: () =>
          spmsServiceService.getAllPurchaseOrdersInvoices(page, size, {
            invoiceStatus: 'PENDING_APPROVAL',
            purchaseOrderState: 'APPROVED',
            companyId: activeCompany?.id,
            archived: false,
          }),
      };
      servicesData[tab]().then((r) => {
        console.log(r);
      });
      return servicesData[tab]();
    },
    [tab, filterText, activeCompany.id],
  );

  return (
    <Box $mobExtend $asHolder $noOverflow>
      <Text type="subtitle" weight={500}>
        Pending Approval
      </Text>
      <br />

      <div className={generalStyles.tabSection}>
        <TabsSlider>
          {access?.requests?.approve && access?.requests?.viewAllCompany ? (
            <NavLink
              to="/approve/requisitions"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Requests
            </NavLink>
          ) : null}
          {access?.budget_management?.approve && access?.budget_management?.viewAllCompany ? (
            <NavLink
              to="/approve/budget"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Budget
            </NavLink>
          ) : null}
          {access?.vendor_management?.approve && access?.vendor_management?.viewAllCompany ? (
            <NavLink
              to="/approve/vendor"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Vendor
            </NavLink>
          ) : null}
          {access?.invoice?.approve && access?.invoice?.viewAllCompany ? (
            <NavLink
              to="/approve/invoices"
              className={({ isActive }) => (isActive ? 'active' : '')}
              $islink="true"
            >
              Invoices
            </NavLink>
          ) : null}
        </TabsSlider>
      </div>
      <Suspense fallback={<h2>Loading...</h2>}>
        <DataTableBaseRemote
          columns={columns[tab] || []}
          selectableRows={false}
          paginationResetDefaultPage={resetPaginationToggle}
          fetchData={getData}
        />
      </Suspense>
    </Box>
  );
};

export default Approve;
