import { Controller, useForm } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { glSchema } from '../../utils/validation/glSchema.js';
import spmsServiceService from '../../services/spmsService.service.js';
import { useStore } from '../../store/store.js';
import { useState } from 'react';

const financialTypes = [
  {
    label: 'Income Statement',
    options: [
      { label: 'Income', value: 'Income' },
      { label: 'Expenses', value: 'Expenses' },
    ],
  },
  {
    label: 'Balance Sheet',
    options: [
      { label: 'Bank', value: 'Bank' },
      { label: 'Fixed Assets', value: 'Fixed Assets' },
      { label: 'Current Assets', value: 'Current Assets' },
      { label: 'Non-Current Assets', value: 'Non-Current Assets' },
      { label: 'Current Liabilities', value: 'Current Liabilities' },
      { label: 'Non-Current Liabilities', value: 'Non-Current Liabilities' },
      { label: 'Equity', value: 'Equity' },
    ],
  },
];

const GLOnboarding = ({ previousStep, setToast }) => {
  const companyId = useStore((state) => state.company?.id);
  const company = useStore((state) => state.company);
  const setSteps = useStore((state) => state.setSteps);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isValid },
    getValues,
  } = useForm({ mode: 'onChange', resolver: yupResolver(glSchema) });

  let count = 0

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = {
      glCode: data.glCode,
      description: data.glDescription,
      companyId,
    };
    spmsServiceService
      .createGlAccount(requestBody)
      .then((_res) => {
        count++
        if (count === 1){
          window.dataLayer.push({
            event: 'create_account_account_code',
            General_Ledger: {
              unique_cost_code: requestBody.glCode,                          // Unique cost code
              short_description_of_code: requestBody.description           // Short description of the cost code
            }
          });
        }
        setToast({
          opened: true,
          message: 'GL created',
          type: 'success',
          cb: () => {
            setIsButtonsBlocked(false);
          },
        });
        setSteps();
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };
  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Account Code
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text type="h5" weight={600}>
            Add information about your account code
          </Text>
        </GradientText>
      </div>
      <div className={styles.inputs}>
        <div className="inp-container">
          <Controller
            name="glCode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Unique cost code *"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.glCode && <p className="error-message">{errors.glCode?.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="glDescription"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Short description of code *"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.glDescription && <p className="error-message">{errors.glDescription?.message}</p>}
        </div>
        {/* <div className="inp-container">
          <Controller
            name="glType"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select financial category *"
                options={financialTypes}
              />
            )}
          />
          {errors.glType && <p className="error-message">{errors.glType?.value.message}</p>}
        </div> */}
        <Button.Main
          $primary
          $full
          $style="blue"
          $iconRight
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isButtonsBlocked}
        >
          Go to Dashboard
        </Button.Main>
      </div>
      {/* <div className={styles.formBackButton}>
        <Button.ActionLabeled $primary $full $style="blue" type="button" onClick={previousStep}>
          <Text type="body-1" weight={500}>
            Back to previous step
          </Text>
        </Button.ActionLabeled>
      </div> */}
    </div>
  );
};

export default GLOnboarding;
