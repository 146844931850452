import styles from '../../../../styles/approvalWorkflow.module.css';
import generalStyles from '../../../../styles/general.module.css';
import Rules from './Rules';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Box from '../../../../components/UI/General/Box';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Button from '../../../UI/Forms/Button.jsx';
import spmsServiceService from '../../../../services/spmsService.service.js';
import Approvers from './Approvers.jsx';
import getUsersAccess from '../helpers/getUsersAccess.js';
import Toast from '../../../UI/General/Toast.jsx';
import TabsSlider from '../../../UI/General/TabsSlider.jsx';
import { useStore } from '../../../../store/store.js';
import Text from '../../../UI/Typography/Text.jsx';
import { useLocation } from 'react-router-dom';

const CompanyContent = ({ companyId }) => {
  const [rules, setRules] = useState(null);
  const [matrix, setMatrix] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);
  const [approvers, setApprovers] = useState([]);
  const [approversOptions, setApproversOptions] = useState([]);
  const [isActiveTab, setIsActiveTab] = useState(true);
  const [tab, setTab] = useState('Requisition');
  const company = useStore((state) => state.company);
  const location = useLocation();
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      approvalHierarchy: 'DEFAULT_APPROVER',
    },
  });

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  useLayoutEffect(() => {
    if (location?.state?.tab) {
      setTab(location?.state?.tab);
    }
  }, [location]);

  const tabs = [
    { name: 'Requisition', value: 'Requisition' },
    { name: 'Invoice', value: 'PURCHASE_ORDER_INVOICE' },
    { name: 'Vendor', value: 'Vendor' },
    { name: 'Budget', value: 'Budget' },
  ];

  const approvalResourceUris = {
    Vendor: 'Vendor_Management',
    Budget: 'Budget_Management',
    Requisition: 'Requests',
    PURCHASE_ORDER_INVOICE: 'Invoice',
  };

  useEffect(() => {
    const getApprovers = async () => {
      const approvers = await getUsersAccess(companyId, 'APPROVE', [approvalResourceUris[tab]]);
      const activeApprovers = approvers.filter((approver) => approver.enabled);
      setApprovers(activeApprovers);
      setApproversOptions(
        activeApprovers.map((approver) => ({
          label: `${approver.firstName} ${approver.lastName}`,
          value: approver.id,
        })),
      );
    };
    getApprovers();
  }, [tab]);

  useEffect(() => {
    spmsServiceService.getApprovalMatrix(companyId).then((res) => {
      setMatrix(res.data.data);
    });
  }, []);

  const getApprovalMatrix = () => {
    if (!matrix) return;
    const reqModule = matrix.find((module) => module.type === tab.toUpperCase());
    spmsServiceService.getApprovalModuleById(companyId, reqModule.id, isActiveTab).then((res) => {
      const module = res.data.data;
      setCurrentModule(module);
      setRules(module.rules.slice().sort((a, b) => a.order - b.order));
      if (module.defaultApprover) {
        setValue(`${tab}-defaultApprover`, {
          label: module.defaultApprover.name,
          value: module.defaultApprover.userId,
        });
      }
    });
  };
  useEffect(() => {
    getApprovalMatrix();
  }, [isActiveTab, tab, matrix]);
  const handleApproverData = (newApprover) => {
    const { email, id, firstName, lastName } = approvers.find(
      (approver) => approver.id === newApprover.value,
    );
    return { userId: id, email, name: `${firstName} ${lastName}` };
  };

  const updateApprovalMatrix = (data) => {
    if (matrix) {
      setToast((item) => ({ ...item, opened: false }));
      const updatedModule = {
        ...currentModule,
      };
      updatedModule.defaultApprover = handleApproverData(data[`${tab}-defaultApprover`]);
      updatedModule.rules = rules.map((rule, index) => ({ ...rule, order: index + 1 }));
      spmsServiceService
        .editApprovalModuleById(companyId, currentModule.id, updatedModule)
        .then((_res) => {
          setToast({
            opened: true,
            message: 'Approval Hierarchy updated',
            type: 'success',
          });
          getApprovalMatrix();
          reset();
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.toString(),
            type: 'fail',
          });
        });
    }
  };

  const activateRule = async (ruleId) => {
    try {
      setToast((item) => ({ ...item, opened: false }));
      await spmsServiceService.activateApprovalMatrixRule(companyId, currentModule.id, ruleId);
      const res = await spmsServiceService.getApprovalModuleById(companyId, currentModule.id, true);
      const matrix = res.data.data;
      const matrixRules = matrix.rules;
      const activatedRule = matrixRules.find((rule) => rule.id === ruleId);
      const activatedRuleIndex = matrixRules.indexOf(activatedRule);
      matrixRules[activatedRuleIndex] = { ...activatedRule, order: matrixRules.length };
      const updatedModule = {
        ...matrix,
        rules: matrixRules,
      };
      await spmsServiceService.editApprovalModuleById(companyId, currentModule.id, updatedModule);
      getApprovalMatrix();
      setToast({
        opened: true,
        message: 'The rule has been activated',
        type: 'success',
      });
    } catch (err) {
      setToast({
        opened: true,
        message: err.message.toString(),
        type: 'fail',
      });
    }
  };

  const archiveRule = async (ruleId) => {
    try {
      setToast((item) => ({ ...item, opened: false }));
      await spmsServiceService.archiveApprovalMatrixRule(companyId, currentModule.id, ruleId);
      const filteredRules = rules.filter((rule) => rule.id !== ruleId);
      const updatedModule = {
        ...currentModule,
        rules: filteredRules.map((rule, index) => ({ ...rule, order: index + 1 })),
      };
      await spmsServiceService.editApprovalModuleById(companyId, currentModule.id, updatedModule);
      getApprovalMatrix();
      setToast({
        opened: true,
        message: 'The rule has been archived',
        type: 'success',
      });
    } catch (err) {
      setToast({
        opened: true,
        message: err.message.toString(),
        type: 'fail',
      });
    }
  };

  const isUpdateDisabled = () => {
    if (!rules || !rules.length) return isDirty;
    let hasOrderChanged = false;
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].order !== i + 1) {
        hasOrderChanged = true;
        break;
      }
    }
    return hasOrderChanged || isDirty;
  };
  return (
    <Box $radius={12} $mobExtend $asHolder>
      <div className={styles.title}>
        <Text type="subtitle" weight={500}>
          Approval Workflow Overview
        </Text>
      </div>

      <div className={generalStyles.tabSection}>
        <TabsSlider selected={tab}>
          {tabs.map((tab) => (
            <span
              key={tab.name}
              onClick={() => {
                setTab(tab.value);
                reset();
              }}
            >
              {tab.name}
            </span>
          ))}
        </TabsSlider>
      </div>
      <div className={styles.companyContent}>
        <Approvers
          control={control}
          errors={errors}
          approversOptions={approversOptions}
          tab={tab}
        />
        <div className={generalStyles.pageButtons}>
          <Button.Main
            disabled={!isUpdateDisabled()}
            $primary
            $style="blue"
            onClick={handleSubmit(updateApprovalMatrix)}
          >
            Update
          </Button.Main>
        </div>
        {currentModule && (
          <DndProvider backend={HTML5Backend}>
            <Rules
              setIsActiveTab={setIsActiveTab}
              companyId={companyId}
              isActiveTab={isActiveTab}
              rules={rules}
              setRules={setRules}
              currentModuleId={currentModule?.id}
              archiveRule={archiveRule}
              activateRule={activateRule}
              type={tab
                .split('_')
                .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')}
            />
          </DndProvider>
        )}
      </div>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default CompanyContent;
