import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Controller, useForm } from 'react-hook-form';
import PasswordTest from '../../UI/Forms/PasswordTest';
import { Password } from '../../UI/Forms/Input';
import Box from '../../UI/General/Box';
import generalStyles from '../../../styles/general.module.css';
import * as Button from '../../UI/Forms/Button';
import Toast from '../../UI/General/Toast';

const ProfilePassword = ({ control, errors, dirtyFields, trigger, getValues }) => {
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  return (
    <>
      <form>
        <div className={generalStyles.fieldsThree}>
          <div className="inp-container">
            <Controller
              name="currentPassword"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Current Password is required',
                },
              }}
              render={({ field }) => (
                <Password
                  placeholder="Enter Current Password"
                  $label="Current Password"
                  $labelRequired
                  $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.currentPassword && (
              <p className="error-message">{errors.currentPassword?.message}</p>
            )}
          </div>
          <div className="inp-container">
            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Password is required',
                },
                minLength: {
                  value: 8,
                  message: 'At least 8 characters',
                },
                maxLength: {
                  value: 35,
                  message: 'Maximum 35 characters',
                },
                validate: {
                  isEqualOld: (value) =>
                    value !== getValues('currentPassword') ||
                    'New password must differs from current password',
                  oneLetter: (v) => /[a-z]+/.test(v) || 'At least one lowercase letter',
                  oneCapitalLetter: (v) => /[A-Z]+/.test(v) || 'At least one capital letter',
                  oneNumber: (v) => /\d+/.test(v) || 'At least one number',
                  oneSpecialChar: (v) => /[!@#$%&?]+/.test(v) || 'At least one special character',
                  onlyAllowed: (v) => /^[A-Za-z0-9!@#$%&?]*$/.test(v) || 'Not allowed symbols',
                },
              }}
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder="Enter New Password"
                  $label="New Password"
                  $labelRequired
                  $iconColor={errors.hasOwnProperty(field.name) ? '#CD3E27' : '#8A8B9D'}
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  onChange={(e) => {
                    if (dirtyFields['repeat_password']) {
                      return field.onChange(e), trigger('repeat_password');
                    } else {
                      return field.onChange(e);
                    }
                  }}
                />
              )}
            />
            {errors.newPassword && <p className="error-message">{errors.newPassword?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="repeat_password"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Password is required',
                },
                validate: {
                  isValid: (value) => value === getValues('newPassword') || "Password don't match",
                },
              }}
              render={({ field }) => (
                <Password
                  placeholder="Re-Enter Password"
                  $label="Confirm New Password"
                  $labelRequired
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.repeat_password && (
              <p className="error-message">{errors.repeat_password?.message}</p>
            )}
          </div>
          <PasswordTest name={'newPassword'} isDirty={dirtyFields} control={control} />
        </div>
        {/* <div className={generalStyles.pageButtons + ' ' + generalStyles.btnsOutside}>
          <Button.Main $primary $style="blue" type="submit">
            Save
          </Button.Main>
          <Button.Main $primary $style="gray" onClick={() => reset()} type="button">
            Discard
          </Button.Main>
        </div> */}
      </form>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </>
  );
};

export default ProfilePassword;
