import styled from 'styled-components';

const Gradient = styled.span`
  background: ${({ $restProps }) =>
    `linear-gradient(${$restProps.deg ? $restProps.deg : '90deg'}, ${$restProps.$from}, ${
      $restProps.$to
    })`};
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  max-width: 540px;
`;

const GradientText = (props) => {
  const { children, ...restProps } = props;
  return <Gradient $restProps={restProps}>{children}</Gradient>;
};

export default GradientText;
