import { Controller, useForm } from 'react-hook-form';
import styles from '../../../../styles/requisition.module.css';
import generalStyles from '../../../../styles/general.module.css';
import Textarea from '../../../UI/Forms/Textarea';
import { regExps } from '../../../../utils/regExps';
import * as Button from '../../../UI/Forms/Button';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../../store/store';
import spmsServiceService from '../../../../services/spmsService.service';
import Text from '../../../UI/Typography/Text';
import Icon from '../../../UI/General/Icon';

const RequisitionNote = ({
  note,
  setToast,
  refetch,
  setIsButtonsBlocked,
  isButtonsBlocked,
  module,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { requisitionId } = useParams();
  const { orderId } = useParams();
  const user = useStore((state) => state.user);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const handleDate = (createdAt) => {
    const date = new Date(createdAt);
    const diff = new Date() - date;
    const daysGone = diff / (1000 * 60 * 60 * 24);
    if (daysGone >= 1) return `${Math.round(daysGone)} day${daysGone > 1 && 's'} ago`;
    const hoursGone = diff / (1000 * 60 * 60);
    if (hoursGone >= 1) return `${Math.round(hoursGone)} hour${hoursGone > 1 && 's'} ago`;
    const minutesGone = diff / (1000 * 60);
    if (minutesGone >= 1) return `${Math.round(minutesGone)} minut${minutesGone > 1 && 'es'} ago`;
    return 'just now';
  };

  const editNotesHandler = {
    request: (noteId, data) =>
      spmsServiceService.updateRequisitionNote(requisitionId, noteId, data),
    po: (noteId, data) => spmsServiceService.updatePONote(orderId, noteId, data),
  };
  const deleteNotesHandler = {
    request: (noteId) => spmsServiceService.deleteRequisitionNote(requisitionId, noteId),
    po: (noteId) => spmsServiceService.deletePONote(orderId, noteId),
  };

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    editNotesHandler[module](note.id, data)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Saved',
          type: 'success',
          cb: () => {
            setIsEditing(false);
            refetch();
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };
  const deleteNote = () => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    deleteNotesHandler[module](note.id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Deleted',
          type: 'success',
          cb: () => {
            refetch();
            setIsEditing(false);
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  return (
    <div className={`${styles.comment} ${generalStyles.underline}`}>
      <div className={styles.commentTop}>
        <div className={styles.commentTitle}>
          <Text weight={600} type="body-2">
            {note.author.name}
          </Text>
          <Text weight={400} type="body-3">
            <span>{handleDate(note.createdDate)}</span>
          </Text>
        </div>
        <div className={styles.commentsButtons}>
          {!isEditing && user.id === note.author.userId && (
            <>
              <Button.Action
                $variant="circle"
                $style="lightGrayishBlue"
                $width={40}
                $height={40}
                disabled={isButtonsBlocked}
                onClick={() => {
                  setIsEditing(true);
                  setValue('note', note.note);
                }}
                type="button"
              >
                <Icon $width={20} $height={20} $icon="edit" $color="#145FF5" />
              </Button.Action>
              <Button.Action
                $variant="circle"
                $style="lightGrayishBlue"
                $width={40}
                $height={40}
                type="button"
                disabled={isButtonsBlocked}
                onClick={deleteNote}
              >
                <Icon $width={20} $height={20} $icon="delete" $color="#F24638" />
              </Button.Action>
            </>
          )}
        </div>
      </div>
      {isEditing ? (
        <div className={styles.commentText}>
          <div className="inp-container">
            <Controller
              name="note"
              control={control}
              rules={{
                pattern: {
                  value: regExps.notes,
                  message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £",
                },
              }}
              render={({ field }) => (
                <Textarea
                  {...field}
                  $low
                  $small
                  placeholder="Notes text goes here"
                  className={errors?.note && 'error'}
                />
              )}
            />
            {errors?.note && <p className="error-message">{errors?.note?.message}</p>}
          </div>
          <div className={styles.commentsButtons}>
            <Button.Main
              $mid
              $style="blue"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isButtonsBlocked}
            >
              Save
            </Button.Main>
            <Button.Main
              $mid
              $style="gray"
              type="submit"
              onClick={() => setIsEditing(false)}
              disabled={isButtonsBlocked}
            >
              Discard
            </Button.Main>
          </div>
        </div>
      ) : (
        <Text weight={400} type="body-2">
          {note.note}
        </Text>
      )}
    </div>
  );
};

const CommentsTab = ({
  notes,
  isButtonsBlocked,
  setToast,
  refetch,
  setIsButtonsBlocked,
  module,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const { requisitionId } = useParams();
  const { orderId } = useParams();

  const addNotesHandler = {
    request: (data) => spmsServiceService.addRequisitionNote(requisitionId, data),
    po: (data) => spmsServiceService.addPONote(orderId, data),
  };

  const addNewNote = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    addNotesHandler[module]({ note: data.newNote })
      .then((r) => {
        setToast({
          opened: true,
          message: 'Saved',
          type: 'success',
          cb: () => {
            refetch();
            setValue('newNote', '');
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((reason) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message:
            reason.response.data.message !== undefined
              ? reason.response.data.message
              : reason.response.data.error,
          type: 'fail',
        });
      });
  };

  return (
    <div className={styles.commentsSection}>
      <div className={styles.commentText}>
        <div className="inp-container">
          <Controller
            name="newNote"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
              maxLength: {
                value: 500,
                message: 'Maximum 500 characters',
              },
              pattern: {
                value: regExps.notes,
                message: "Only alpha characters, numbers and - . , & : # ( ) + / '",
              },
            }}
            render={({ field }) => (
              <Textarea
                {...field}
                $low
                $small
                placeholder="Notes text goes here"
                className={errors?.newNote && 'error'}
              />
            )}
          />
          {errors?.newNote && <p className="error-message">{errors?.newNote?.message}</p>}
        </div>
        <div className={styles.commentsButtons}>
          <Button.Main
            $mid
            $style="blue"
            type="submit"
            onClick={handleSubmit(addNewNote)}
            disabled={isButtonsBlocked}
          >
            Save
          </Button.Main>
        </div>
      </div>
      {notes.map((note) => (
        <RequisitionNote
          key={note.id}
          note={note}
          setToast={setToast}
          refetch={refetch}
          setIsButtonsBlocked={setIsButtonsBlocked}
          isButtonsBlocked={isButtonsBlocked}
          module={module}
        />
      ))}
    </div>
  );
};

export default CommentsTab;
