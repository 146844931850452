import React, { useState } from 'react';
import styles from '../../../../styles/requisition.module.css';
import Icon from '../../../UI/General/Icon';
import * as Button from '../../../UI/Forms/Button';
import Text from '../../../UI/Typography/Text';
import Label from '../../../UI/Forms/Label';
import { nf } from '../../../../utils/nf';
import Tooltip from '../../../UI/General/Tooltip';

const GridItem = ({ data, currency, exchangeRate = 1, budget, showBudget = false }) => {
  console.log(data);
  const [showNote, setShowNote] = useState(false);

  const tooltipInfo = () => (
    <div className={styles.tooltipCol}>
      <div className={styles.tooltipRow}>
        <Text weight={600} type="body-3">{budget?.name}</Text>
        <Text weight={600} type="body-3">-</Text>
        <Text weight={600} type="body-3">{data?.glAccount?.glCode}</Text>
      </div>
      <div className={styles.tooltipRow}>
        <Text type="body-3">Allocated</Text>
        <Text type="body-3">{currency} {nf.format(data?.budgetVerification?.allocatedBudget * exchangeRate)}</Text>
      </div>
      <div className={styles.tooltipRow}>
        <Text type="body-3">Available</Text>
        <Text type="body-3">{currency} {nf.format(data?.budgetVerification?.available * exchangeRate)}</Text>
      </div>
      <div className={styles.tooltipRow}>
        <Text type="body-3">Requested</Text>
        <Text type="body-3">{currency} {nf.format(data?.budgetVerification?.requestedBudget * exchangeRate)}</Text>
      </div>
    </div>
  )

  const tooltipIcon = () => (
    <Button.Action $variant="circle" $style={data?.budgetVerification?.isWithinBudget === true ? "budgetOk" : data?.budgetVerification?.isWithinBudget !== false ? "budgetHigh" : "budgetDefault"} $width={32} $height={32} type="button">
      <Icon $width={16} $height={16} $icon="dollar" />
    </Button.Action>
  )

  return (
    <div className={styles.item}>
      <div className={!!showBudget ? styles.itemInner + ' ' + styles.itemsTableHeadReview : styles.itemInner}>
        {!!showBudget ? (
          <div>
            <Tooltip text={tooltipInfo()} icon={tooltipIcon()} $maxWidth={"max-content"} />
          </div>
        ) : null}
        <div>
          <Label className={styles.label} $title="Description" />
          <Text weight={500} type={'body-2'}>
            {data?.description ?? '-'}
          </Text>
        </div>
        <div>
          <Label className={styles.label} $title="Vendor Code" />
          <Text weight={500} type={'body-2'}>
            {data?.vendorCode ?? '-'}
          </Text>
        </div>
        <div>
          <Label className={styles.label} $title="Unit Of Measure" />
          <Text weight={500} type={'body-2'}>
            {data?.uom ?? '-'}
          </Text>
        </div>
        <div>
          <Label className={styles.label} $title="Gl Code" />
          <Text weight={500} type={'body-3'}>
            {data?.glAccount?.code ? data?.glAccount?.code : data?.glAccount?.glCode ?? '-'}
          </Text>
          <Text
            weight={500}
            type={'body-3'}
            title={data?.glAccount?.description}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {data?.glAccount?.description
              ? data?.glAccount?.description
              : data?.glAccount?.description ?? '-'}
          </Text>
        </div>
        <div>
          <Label className={styles.label} $title="Quantity" />
          <Text weight={500} type={'body-2'}>
            {data?.quantity ?? '-'}
          </Text>
        </div>
        <div>
          <Label className={styles.label} $title="Unit price" />
          <Text weight={500} type={'body-2'}>
            {nf.format(data?.unitPrice) ?? '-'}
          </Text>
        </div>
        <div>
          <Label className={styles.label} $title="Tax" />
          <Text weight={500} type={'body-2'}>
            {data?.tax?.type ?? 'No Tax'}
          </Text>
        </div>
        <div>
          <Label className={styles.label} $title="Subtotal" />
          <Text weight={500} type={'body-2'}>
            {nf.format(data?.subtotal) ?? '-'}
          </Text>
        </div>
        <div className={styles.itemActions}>
          <Button.Action
            $variant="circle"
            $style="lightGrayishBlue"
            $width={32}
            $height={32}
            onClick={() => setShowNote(!showNote)}
            type="button"
          >
            <Icon $width={20} $height={20} $icon="add-note" $color="#45BABC" />
          </Button.Action>
        </div>
      </div>
      {showNote ? (
        <div className={styles.itemNote}>
          <Text weight={500} type={'body-1'} className={styles.itemNoteLabel}>
            Notes
          </Text>
          <Text weight={500} type={'body-2'}>
            {data?.itemNotes === '' ? 'There is no notes to display' : data?.itemNotes}
          </Text>
        </div>
      ) : null}
    </div>
  );
};

export default GridItem;
