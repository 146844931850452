import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import Text from '../UI/Typography/Text';
import spmsServiceService from '../../services/spmsService.service';
import { useStore } from '../../store/store';

const GLOption = (props) => (
  <components.Option {...props}>
    <Text type={'body-2'} weight={600}>
      {props.data.label}
    </Text>
    <Text type={'body-3'}>{props.data.text}</Text>
  </components.Option>
);

const SelectGls = (props) => {
  const activeCompany = useStore((state) => state.activeCompany);

  const fetchGls = async (search, loadedOptions, { page }) => {
    const result = await spmsServiceService
      .getFilteredGlAccounts(activeCompany.id, page, 10, {
        active: true,
        description: search,
      })
      .catch((err) => {
        console.log(err);
        return undefined;
      });
    const finalData = result?.data?.data?.content?.map((item) => ({
      label: item.glCode,
      value: item.id,
      text: item.description,
    }));
    const hasMore =
      finalData !== undefined && result.data.data.totalElements > 0
        ? loadedOptions.length < result.data.data.totalElements
        : false;
    return {
      options: finalData || [],
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      {...props}
      debounceTimeout={500}
      loadOptions={fetchGls}
      classNamePrefix="react-select"
      isSearchable={true}
      components={{ Option: GLOption }}
      getOptionLabel={(option) => (
        <>
          <div>{option.label}</div>
          <div
            title={option.text}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {option.text}
          </div>
        </>
      )}
      placeholder="Select Account Code"
      menuPortalTarget={document.body}
      menuPosition={'absolute'}
      menuPlacement={'bottom'}
      menuShouldScrollIntoView={false}
      additional={{
        page: 0,
      }}
    />
  );
};

export default SelectGls;
