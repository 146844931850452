import React from "react";
import {Route} from "react-router-dom";
import Terms from "../pages/Terms";
import Policy from "../pages/Policy";
import CookiePolicy from '../pages/CookiePolicy';
import UsePolicy from '../pages/UsePolicy';

export const generalRoutes =
  <>
    <Route exact path="/terms" element={<Terms/>}/>
    <Route exact path="/privacy"  target="_blank" element={<Policy/>}/>
    <Route exact path="/cookie-policy"  target="_blank" element={<CookiePolicy/>}/>
    <Route exact path="/acceptable-use-policy"  target="_blank" element={<UsePolicy/>}/>
  </>
