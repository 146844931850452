import styles from '../../../../styles/approvalWorkflow.module.css';
import { Controller } from 'react-hook-form';
import Text from '../../../UI/Typography/Text';
import MultiSelectOpened from '../../../UI/Forms/MultiSelectOpened';
import React, { useEffect } from 'react';
import UsersList from './UsersList';

const ApprovalHierarchy = ({
  control,
  watch,
  errors,
  companyId,
  setValue,
  prevUsers,
  users,
  noLoa,
  prefix,
  currency,
}) => {
  const hierarchy = watch('hierarchy');
  const userList = watch(
    prefix ? `${prefix + '--'}approvers-${hierarchy.value}` : `approvers-${hierarchy.value}`,
  );
  const userOptions = users.slice().map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  useEffect(() => {
    if (!prevUsers || !prevUsers.length) return;
    const prevUsersIds = prevUsers.map((prevUser) => prevUser.userId);
    setValue(
      prefix ? `${prefix + '--'}approvers-${hierarchy.value}` : `approvers-${hierarchy.value}`,
      userOptions.slice().filter((option) => prevUsersIds.includes(option.value)),
    );
  }, [prevUsers, users]);

  const handleUserData = () => {
    if (!userList || !userList?.length) return;
    const userData = [];
    for (let i = 0; i <= userList.length; i++) {
      const reqUser = users.find((user) => userList[i]?.value === user.id);
      if (!reqUser) continue;
      else {
        userData.push({
          userName: `${reqUser.firstName} ${reqUser.lastName}`,
          loa: reqUser.customCompanies.find((company) => company.companyId === companyId)
            .limitOfAuthority,
          id: reqUser.id,
        });
      }
    }
    return userData;
  };

  const deleteUser = (id) => {
    const newApprovers = userList.filter((user) => user.value !== id);
    setValue(
      prefix ? `${prefix + '--'}approvers-${hierarchy.value}` : `approvers-${hierarchy.value}`,
      newApprovers,
    );
  };

  return (
    <div className={styles.approvalHierarchy}>
      <div>
        <Text type="subtitle" weight={500}>
          Approval Hierarchy
        </Text>
      </div>
      {hierarchy.value === 'LOA' && (
        <div className={styles.userDropDownContainer}>
          <div className={styles.userDropDown}>
            <div className="inp-container">
              <Controller
                name="approvers-LOA"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Required',
                  },
                }}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    noSelectContainer
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Select Users"
                    options={userOptions}
                    control={control}
                  />
                )}
              />
              {errors['approvers-LOA'] && (
                <p className="error-message">{errors['approvers-LOA'].message}</p>
              )}
            </div>
          </div>
          <UsersList
            type="LOA"
            users={handleUserData()}
            deleteUser={deleteUser}
            currency={currency}
          />
        </div>
      )}
      {hierarchy.value === 'Defined_List' && (
        <div className={styles.userDropDownContainer}>
          <div className={styles.userDropDown}>
            <div className="inp-container">
              <Controller
                name={
                  prefix
                    ? `${prefix + '--'}approvers-${hierarchy.value}`
                    : `approvers-${hierarchy.value}`
                }
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Required',
                  },
                }}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    noSelectContainer
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Select Users"
                    options={userOptions}
                    control={control}
                  />
                )}
              />
              {errors['approvers-Defined_List'] && (
                <p className="error-message">{errors['approvers-Defined_List'].message}</p>
              )}
            </div>
          </div>
          <UsersList users={handleUserData()} deleteUser={deleteUser} currency={currency} />
        </div>
      )}
    </div>
  );
};

export default ApprovalHierarchy;
