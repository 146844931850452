import Accordion from '../../../components/UI/General/Accordion';
import styles from '../../../styles/companySetup.module.css';
import Text from '../../../components/UI/Typography/Text';
import DepartmentBody from '../../../components/Admins/Company/Department/DepartmentBody';
import { useStore } from '../../../store/store';

const DepartmentSetup = () => {
  const company = useStore((state) => state.company);

  return (
    <div className={styles.accordionItems}>
      <DepartmentBody companyId={company.id} />
    </div>
  );
};

export default DepartmentSetup;
