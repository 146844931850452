import React, { useEffect, useRef, useState } from 'react';
import styles from '../../../styles/companySetup.module.css';
import { Controller, useForm, useWatch } from 'react-hook-form';
import spmsServiceService from '../../../services/spmsService.service';
import generalStyles from '../../../styles/general.module.css';
import Input from '../../../components/UI/Forms/Input';
import Label from '../../../components/UI/Forms/Label';
import Select, { components } from 'react-select';
import * as Button from '../../../components/UI/Forms/Button';
import { useStore } from '../../../store/store';
import requestsService from '../../../services/requestsService.service';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Toast from '../../../components/UI/General/Toast';
import Box from '../../../components/UI/General/Box';
import Text from '../../../components/UI/Typography/Text';

const UserOption = (props) => (
  <components.Option {...props}>
    <Text type="body-2" weight={600}>
      {props.data.label}
    </Text>
    <Text type="body-3" weight={400}>
      {props.data.roles.join(', ')}
    </Text>
  </components.Option>
);

const MultiValue = ({ index, ...props }) => {
  const displayLimit = 2;
  if (index < displayLimit) {
    return (
      <components.MultiValue {...props}>
        <div>{props.children}</div>
      </components.MultiValue>
    );
  }

  if (index === displayLimit) {
    return <div style={{ padding: '6px' }}>...</div>;
  }

  return null;
};

const DepartmentAdd = () => {
  const { companyId, departmentId } = useParams();
  const [values, setValues] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const reorderOptions = (allOptions, selectedOptions) => {
    const selectedSet = new Set(selectedOptions.map((option) => option.value));
    return [...selectedOptions, ...allOptions.filter((option) => !selectedSet.has(option.value))];
  };

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected || []);
  };
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      externalId: '',
      name: '',
      departmentManagerId: '',
      branches: [],
      // users: [],
    },
    values,
  });

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView(true, { block: 'nearest' });
    }
  }, [containerRef.current]);

  useEffect(() => {
    const fetchData = async () => {
      requestsService.getData(100, 0, { isEnabled: true, companyId: companyId }).then((r) => {
        const activeUsers = r.data.data.content.filter((user) => !!user.enabled);
        setAllUsers(
          activeUsers.map((user) => ({
            label: user.firstName + ' ' + user.lastName,
            value: user.username,
            roles: user.roles.map((role) => role.role.name),
          })),
        );
      });
    };
    if (companyId !== undefined) {
      fetchData();
    }
  }, []);

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const { branches, departmentManager, ...other } = data;
    const { name, externalId } = other;
    const newData = {
      name,
      externalId,
      departmentManagerId: departmentManager?.value,
      // userIds: selectedOptions.map((option) => option.value),
    };
    if (departmentId !== undefined) {
      spmsServiceService
        .updateDepartment(companyId, departmentId, newData)
        .then((r) => {
          setToast({
            opened: true,
            message: 'Department updated successfully',
            type: 'success',
            cb: () => navigate('/department-setup')
          });
        })
        .catch((error) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: error.response.data.message,
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createDepartment(companyId, newData)
        .then((r) => {
          setToast({
            opened: true,
            message: 'Department created successfully',
            type: 'success',
            cb: () => navigate('/department-setup')
          });
        })
        .catch((error) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: error.response.data.message,
            type: 'fail',
          });
        });
    }
  };

  useEffect(() => {
    const fetchEditDepartment = async () => {
      await spmsServiceService.getDepartment(companyId, departmentId).then((r) => {
        const { branches, users: departmentUsers, departmentManager, ...others } = r.data;
        let updUsers, updDepartmentManager;
        if (departmentUsers && departmentUsers?.length > 0 && allUsers !== undefined) {
          updUsers = allUsers?.filter((item) => departmentUsers.includes(item.value));
        }
        if (departmentManager && departmentManager !== '' && allUsers !== undefined) {
          updDepartmentManager = allUsers?.find((item) => item.value === departmentManager);
        }
        setValues({
          ...others,
          departmentManager: updDepartmentManager,
        });
        setSelectedOptions(allUsers?.filter((item) => departmentUsers.includes(item.value)));
      });
    };
    if (companyId && departmentId && allUsers) {
      fetchEditDepartment();
    }
  }, [companyId, departmentId, allUsers]);

  const handleOptions = () => {};

  return (
    <>
      <Box ref={containerRef} $radius={12} $mobExtend $asHolder $noOverflow>
        <div className={styles.title}>
          <Text type="subtitle" weight={500}>
            {departmentId ? 'Edit' : 'Add'} Department
          </Text>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.departmentForm}>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="externalId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Department ID is required',
                  },
                  maxLength: {
                    value: 15,
                    message: 'Maximum 15 characters',
                  },
                  validate: {
                    notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                    allowed: (v) =>
                      /^[A-Za-z0-9/-]*$/.test(v) || 'Only Alpha and Numerical characters',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Department ID"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Department ID"
                    $labelRequired
                    $tooltip="Unique identifier of the Department"
                    {...field}
                  />
                )}
              />
              {errors.externalId && <p className="error-message">{errors.externalId.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Department Name is required',
                  },
                  maxLength: {
                    value: 30,
                    message: 'Maximum 30 characters',
                  },
                  validate: {
                    notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                    allowed: (v) => /^[A-Za-z\s]*$/.test(v) || 'Only Alpha characters and space',
                    onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Department Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Department Name"
                    $labelRequired
                    $tooltip="Department Name"
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Department Manager" />
              <Controller
                name="departmentManager"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: '100 !important',
                      }),
                    }}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Department Manager"
                    options={allUsers || []}
                    hideSelectedOptions={false}
                  />
                )}
              />
            </div>
            {/*<div className="inp-container">*/}
            {/*  <Label $title="Users" />*/}
            {/*  <Controller*/}
            {/*    name="departmentUsers"*/}
            {/*    control={control}*/}
            {/*    render={({ field }) => (*/}
            {/*      <Select*/}
            {/*        {...field}*/}
            {/*        className={*/}
            {/*          errors.hasOwnProperty(field.name)*/}
            {/*            ? 'react-select-container error'*/}
            {/*            : 'react-select-container'*/}
            {/*        }*/}
            {/*        classNamePrefix="react-select"*/}
            {/*        isSearchable={false}*/}
            {/*        placeholder="Select Users"*/}
            {/*        // onMenuOpen={handleOptions}*/}
            {/*        closeMenuOnSelect={false}*/}
            {/*        isMulti*/}
            {/*        hideSelectedOptions={false}*/}
            {/*        onChange={handleSelectChange}*/}
            {/*        options={reorderOptions(allUsers, selectedOptions)}*/}
            {/*        value={selectedOptions}*/}
            {/*        isClearable={false}*/}
            {/*        components={{ MultiValue, Option: UserOption }}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  />*/}
            {/*  {errors.departmentUsers && (*/}
            {/*    <p className="error-message">{errors.departmentUsers.message}</p>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
          <br />
          <div className={styles.backOverviewButton}>
            <div className={generalStyles.pageButtons}>
              <Button.Main
                $primary
                $style={'blue'}
                type="submit"
                disabled={!isDirty || !isValid || isButtonsBlocked}
              >
                Save
              </Button.Main>
              <Button.Main
                $primary
                $style={'gray'}
                type="button"
                onClick={() => navigate('/department-setup')}
              >
                Discard
              </Button.Main>
            </div>
          </div>

          {toast.opened === true ? (
            <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
          ) : null}
        </form>
      </Box>
    </>
  );
};

export default DepartmentAdd;
