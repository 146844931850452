import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from '../Typography/Text';
import Icon from '../General/Icon';
import { useWatch } from 'react-hook-form';

const passwordSchema = [
  {
    value: /.{8,}/,
    text: '8+ characters',
  },
  {
    value: /[a-z]+/,
    text: 'A lowercase letter (A-Z)',
  },
  {
    value: /[A-Z]+/,
    text: 'An uppercase character (A-Z)',
  },
  {
    value: /\d+/,
    text: 'A number (0-9)',
  },
  {
    value: /[!@#$%&?]+/,
    text: 'A symbol (!@#$%&?)',
  },
  {
    value: /^[A-Za-z0-9!@#$%&?]*$/,
    text: 'Only alphanumeric characters or the symbols listed above are allowed.',
  },
];

const PasswordTestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 260px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 16px 24px;
`;

const PasswordTestRow = styled.div`
  display: flex;
  align-items: center;
  color: #00000066;
  font-weight: 400;
  gap: 8px;
`;

const PasswordTestIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = ({ reg, text, isDirty, name, control }) => {
  const passwordWatch = useWatch({ name: name, control });
  const [status, setStatus] = useState(null);
  useEffect(() => {
    if (isDirty[name] !== undefined) {
      if (reg.test(passwordWatch)) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    } else {
      setStatus(null);
    }
  }, [passwordWatch, isDirty, name, reg]);
  return (
    <PasswordTestRow>
      <PasswordTestIndicator>
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.00004 7.49992L7.00004 9.49992L11 5.49992M14.6667 7.49992C14.6667 11.1818 11.6819 14.1666 8.00004 14.1666C4.31814 14.1666 1.33337 11.1818 1.33337 7.49992C1.33337 3.81802 4.31814 0.833252 8.00004 0.833252C11.6819 0.833252 14.6667 3.81802 14.6667 7.49992Z"
            stroke={status ? '#3BBA6A' : '#00000066'}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PasswordTestIndicator>
      <Text type="body-3" weight={500}>
        {text}
      </Text>
    </PasswordTestRow>
  );
};

const PasswordTest = ({ name, isDirty, control }) => {
  return (
    <PasswordTestWrapper>
      <Text weight={600} type="body-2">
        Your password must contain:
      </Text>
      {passwordSchema && passwordSchema.length > 0
        ? passwordSchema.map((el, i) => (
            <Row
              key={i}
              text={el.text}
              reg={el.value}
              isDirty={isDirty}
              name={name}
              control={control}
            />
          ))
        : null}
    </PasswordTestWrapper>
  );
};

export default PasswordTest;
