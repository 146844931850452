import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/users.module.css';
import requestsServiceService from '../../services/requestsService.service';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Input from '../../components/UI/Forms/Input';
import Toast from '../../components/UI/General/Toast';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import TabsSlider from '../../components/UI/General/TabsSlider';
import AddButton from '../../components/shared/AddButton';
import Export from '../../components/shared/Export';
import Modal from '../../components/UI/Modal/Modal';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import spmsServiceService from '../../services/spmsService.service';
import Filter from '../../components/UI/General/Filter';
import ModalInner from '../../components/UI/Modal/ModalInner';
import { Controller, useForm } from 'react-hook-form';
import Textarea from '../../components/UI/Forms/Textarea';
import modalStyles from '../../styles/log_reg.module.css';
import style from '../../styles/users.module.css';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';

const rolesStatuses = {
  name: 'status',
  label: 'Status',
  isMulti: false,
  options: [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Invited', value: 'INVITED' },
    { label: 'Inactive', value: 'ARCHIVED' },
  ],
}

const Users = () => {
  const companyId = useStore((state) => state.company?.id);
  const company = useStore((state) => state.company);
  const user = useStore((state) => state.user);
  const [usersLength, setUsersLength] = useState(undefined);
  const [allCompaniesIds, setAllCompaniesIds] = useState([companyId]);
  const [showModal, setShowModal] = useState(false);
  const currentSubscription = useSubscriptionAllowed();

  const [filterText, setFilterText] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();

  const {
    control,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterData, setFilterData] = useState([])

  useEffect(()=>{
    let companiesF = [{label: company.name, value: company.id}]
    companiesF = !!company.subsidiaries ? [...companiesF, company.subsidiaries.map((sub) => ({label: sub.name, value: sub.id}))].flat() : companiesF
    if (!!allCompaniesIds) {
      requestsServiceService.getData(
        undefined,
        undefined,
        {
          companyIds: allCompaniesIds,
        },
        true,
      ).then(r => {
        const role = Array.from(new Set(r.data.data.content.map((item) => item.roles.map((el) => el.role.name)).flat())).map(r => ({label: r, value: r}))
        setFilterData([
          {
            name: 'company',
            label: 'Company',
            isMulti: false,
            options: companiesF,
          },
          {
            name: 'role',
            label: 'Role',
            isMulti: false,
            options: role,
          },
          rolesStatuses
        ])
      });
    }else {
      setFilterData([
        {
          name: 'company',
          label: 'Company',
          isMulti: false,
          options: companiesF,
        },
        rolesStatuses
      ])
    }
  },[allCompaniesIds, company])

  useEffect(() => {
    setAllCompaniesIds(
      [company.id, company.subsidiaries && company.subsidiaries.map((sub) => sub.id)].flat(),
    );
  }, [company]);

  const columns = useMemo(
    () => [
      {
        name: 'Full Name',
        selector: (row) => row.firstName + ' ' + row.lastName,
        sortable: true,
        wrap: true,
        cell: (row) => (
          <Link className={generalStyles.tableLink} to={'/users/edit/' + row.id}>
            <span data-content={'Full Name'} className={generalStyles.tableValue}>
              <span>{`${row.firstName} ${row.lastName}`}</span>
            </span>
          </Link>
        ),
      },
      {
        name: 'Email',
        wrap: true,
        sortable: true,
        selector: (row) => row.email,
        cell: (row) => (
          <span data-content={'Email'} className={generalStyles.tableValue}>
            <span>{row.email}</span>
          </span>
        ),
      },
      {
        name: 'Company',
        cell: (row) => (
          <div data-content={'Company'} className={generalStyles.tableValue}>
            {row.customCompanies?.map((cmp) => cmp.companyName).join(', ')}
          </div>
        ),
      },
      {
        name: 'Roles',
        cell: (row) => (
          <div data-content={'Roles'} className={generalStyles.tableValue}>
            <div>
              {Array.from(new Set([...row.roles.map((el) => el.role.name)])).map((role, index) => (
                <Text weight={500} key={index} type="body-2">
                  {readableTitleFromBackend(role)}
                </Text>
              ))}
            </div>
          </div>
        ),
      },
      {
        name: 'Status',
        cell: (row) => (
          <span data-content={'Status'} className={generalStyles.tableValue}>
            <span>{readableTitleFromBackend(row.status)}</span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {row.status.toString().toLowerCase() === 'active' && (
                    <Link to={'/users/edit/' + row.id}>
                      <MenuItem>Edit</MenuItem>
                    </Link>
                  )}
                  {row.status.toString().toLowerCase() === 'active' && row.id !== user.id ? (
                    <MenuItem onClick={() => resetPassword(row.email)}>Reset Password</MenuItem>
                  ) : null}
                  {row.status.toString().toLowerCase() === 'invited' && (
                    <MenuItem onClick={() => resendInvitation(row.email)}>
                      Resend Invitation
                    </MenuItem>
                  )}
                  {row.status.toString().toLowerCase() === 'invited' && (
                    <MenuItem onClick={() => deleteInvitedUser(row.id)}>Delete</MenuItem>
                  )}
                  {row.status.toString().toLowerCase() === 'active' && row.id !== user.id ? (
                    <MenuItem onClick={() => deactivateUser(row.email)}>Archive</MenuItem>
                  ) : null}
                  {row.status.toString().toLowerCase() === 'archived' && (
                    <MenuItem onClick={() => activateUser(row.email)}>Activate</MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [navigate],
  );

  const resetPassword = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .resetUserPassword({ email: email })
      .then((res) => {
        setToast({
          opened: true,
          message: 'Password resetted successfully',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.errors[0].errorMessage,
          type: 'fail',
        });
      });
  };
  const resendInvitation = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .resendInvitation(email)
      .then((res) => {
        setToast({
          opened: true,
          message: 'Invitation resent successfully',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data?.message,
          type: 'fail',
        });
      });
  };
  const deactivateUser = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .deactivateUser(email)
      .then((res) => {
        setToast({
          opened: true,
          message: 'User archived successfully',
          type: 'success',
        });
        setTrigger((state) => !state);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.errors[0].errorMessage,
          type: 'fail',
        });
      });
  };
  const activateUser = (email) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .activateUser(email)
      .then((res) => {
        setToast({
          opened: true,
          message: 'User activated successfully',
          type: 'success',
        });
        setTrigger((state) => !state);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.toString(),
          type: 'fail',
        });
      });
  };
  const deleteInvitedUser = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsServiceService
      .deleteInvitedUser(id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'User deleted successfully',
          type: 'success',
        });
        setTrigger((state) => !state);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.toString(),
          type: 'fail',
        });
      });
  };

  const getData = useCallback(async (page, size) => {
      const filterValues = getValues();
      let search = {
        companyIds: !!filterValues.company?.value ? [filterValues.company?.value] : allCompaniesIds,
        firstName: filterText,
        // lastName: filterText,
        status: filterValues.status?.value,
        role: filterValues.role?.value,
      };
      return await requestsServiceService.getData(size, page, search);
    },
    [filterText, trigger, allCompaniesIds],
  );

  const getExport = useCallback(async () => {
    const response = await requestsServiceService.getData(
      undefined,
      undefined,
      {
        companyIds: allCompaniesIds,
        title: filterText,
      },
      true,
    );
    return response.data.data.content.map((item) => ({
      'Full name': item.firstName + ' ' + item.lastName,
      Email: item.email,
      Roles: Array.from(new Set([...item.roles.map((el) => el.role.name)])).join('; '),
      Company: item.customCompanies?.map((cmp) => cmp.companyName).join('; '),
      Status: item.status,
    }));
  }, [filterText, allCompaniesIds]);

  useEffect(() => {
    spmsServiceService.getMetrics(companyId).then((res) => {
      const metrics = res.data.data;
      setUsersLength(metrics.invitedUsers + metrics.activeUsers);
    });
  }, []);

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#fff"
        placeholder="Search by Name"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <Text type="subtitle" weight={500}>
          Users Overview
        </Text>
        <br />
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}>
            {filterCmp}
            <div className={generalStyles.filters}>
              <Filter
                control={control}
                filterData={filterData}
                setTriggerRefetch={setTrigger}
                setValue={setValue}
              />
            </div>
          </div>
          <div className={generalStyles.addItemButton}>
            <Export name="Users" getDataFunction={getExport} />
            <AddButton $click={() => navigate('/users/add')} />
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
      </Box>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Users;
