import { Controller, useForm, useWatch } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import Select from 'react-select';
import CountryAndRegion from '../../components/UI/Forms/CountryAndRegion.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import { useStore } from '../../store/store.js';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import spmsServiceService from '../../services/spmsService.service.js';
import { regExps } from '../../utils/regExps.js';
import * as yup from 'yup';
import { timezones } from '../../constants/timezones.js';

const addressInfoSchema = yup.object().shape({
  addressLine2: yup
    .string()
    .required('Address 1 is required')
    .matches(regExps.alphaNumericalSpecial, 'Invalid address')
    .max(50, 'Address must be at most 50 characters'),
  addressLine3: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Address cannot be just empty spaces', (value) => value?.trim() !== '')
    .matches(regExps.alphaNumericalSpecial, 'Invalid address')
    .max(50, 'Address must be at most 50 characters'),
  streetAddress: yup
    .string()
    .required('Street is required')
    .matches(regExps.alphaNumericalSpecial, 'Invalid Street name')
    .max(50, 'Street must be at most 50 characters'),
  city: yup
    .string()
    .required('City is required')
    .matches(regExps.alphaNumericalSpecial, 'Invalid city name')
    .max(30, 'City must be at most 30 characters'),
  country: yup.object().shape({
    value: yup.string().required('Country is required'),
  }),
  state: yup
    .string()
    .required('State is required')
    .matches(regExps.alphaNumericalSpecial, 'Invalid state name')
    .max(30, 'State must be at most 30 characters'),
  zipCode: yup
    .string()
    .required('Zip code is required')
    .matches(regExps.alphaNumericalSpecial, 'Invalid zip code')
    .max(10, 'Zip code must be at most 10 characters'),
  timezone: yup.object().shape({
    value: yup.string().required('Timezone is required'),
  }),
});

const VendorAddressOnboarding = ({ nextStep, setToast }) => {
  const user = useStore((state) => state.user);
  const setSteps = useStore((state) => state.setSteps);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({ mode: 'onChange', resolver: yupResolver(addressInfoSchema) });

  const onSubmit = (data) => {
    if (!isDirty) {
      nextStep();
      return;
    }
    setIsButtonsBlocked(true);
    const requestBody = {
      address: {
        addressLine2: data.addressLine2,
        streetAddress: data.streetAddress,
        addressLine3: data.addressLine3,
        city: data.city,
        state: data.state,
        country: data.country.value,
        zipCode: data.zipCode,
      },
      timezone: data.timezone.label,
    };
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .createVendorAddress(user.externalId, requestBody)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Saved',
          type: 'success',
          cb: () => {
            setIsButtonsBlocked(false);
            nextStep();
          },
        });
        setSteps();
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  useEffect(() => {
    const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tzid) {
      const userTimezone = timezones.find((item) => item.utc.includes(tzid));
      if (userTimezone) {
        setValue('timezone', userTimezone);
      }
    }
  }, []);

  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Address Information
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text inherit type="h5" weight={600}>
            Add your address information
          </Text>
        </GradientText>
      </div>
      <div>
        <form className={styles.inputs}>
          <div className="inp-container">
            <Controller
              name="addressLine2"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Address 1 *"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.addressLine2 && <p className="error-message">{errors.addressLine2?.message}</p>}
          </div>

          <div className="inp-container">
            <Controller
              name="streetAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Address 2 *"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.streetAddress && (
              <p className="error-message">{errors.streetAddress?.message}</p>
            )}
          </div>
          <div className="inp-container">
            <Controller
              name="addressLine3"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Address 3 (Optional)"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.addressLine3 && <p className="error-message">{errors.addressLine3?.message}</p>}
          </div>
          <div className={generalStyles.fieldsTwo}>
            <div className="inp-container">
              <Controller
                name="city"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="City/Town *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.city && <p className="error-message">{errors.city?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="state"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Region/State *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.state && <p className="error-message">{errors.state?.message}</p>}
            </div>
            <div className="inp-container">
              <CountryAndRegion
                countryFieldName="country"
                control={control}
                isDisable={false}
                errors={errors}
                isCountryRequired
              />
            </div>
            <div className="inp-container">
              <Controller
                name="zipCode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Postal Code *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.zipCode && <p className="error-message">{errors.zipCode?.message}</p>}
            </div>
          </div>
          <div className="inp-container">
            <Controller
              name="timezone"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  classNamePrefix="react-select"
                  isSearchable={false}
                  placeholder="Timezone *"
                  options={timezones}
                />
              )}
            />
            {errors.timezone && <p className="error-message">{errors.timezone?.value.message}</p>}
          </div>
        </form>
        <Button.Main
          $primary
          $full
          disabled={isButtonsBlocked}
          $style="blue"
          $iconRight
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {/* {company && !isDirty ? 'Continue' : 'Save & Continue'} */}
          Save & Continue
        </Button.Main>
      </div>
    </div>
  );
};

export default VendorAddressOnboarding;
