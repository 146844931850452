import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import '../../../styles/slick.css';
import Icon from './Icon';
import * as Button from '../Forms/Button';
import styled, { css } from 'styled-components';

const StyledSlider = styled.div`
  flex: 1;
  max-width: 100%;
  & a {
    margin-bottom: -1px;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    color: #8a8c8f;
    position: relative;
    &:hover,
    &.active {
      color: #145ff5;
      font-weight: 400;
      /* border-bottom: 4px solid #145ff5; */
    }
    @media all and (min-width: 768px) {
      margin-bottom: -2px;
      padding: 0 14px 10px 14px;
      font-size: 16px;
    }
    @media all and (min-width: 1024px) {
      //padding: 0 24px 15px 24px;
      padding: 0 16px;
      font-size: 16px;
    }
  }
  & .regular.slider {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const TabbedItem = styled.div`
  display: flex;
  font-size: ${({ $small }) => ($small ? '16px' : '20px')};
  font-weight: 500;
  position: relative;
  & span {
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    color: #8a8c8f;
    position: relative;
    &:hover,
    &.active {
      color: #145ff5;
      cursor: pointer;
      font-weight: 400;
      /* border-bottom: 2px solid #145ff5; */
    }
  }
  &.active span {
    color: #145ff5;
    /* border-bottom: 2px solid #145ff5; */
  }
  /* &.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: #145ff5;
    bottom: 2px;
    left: 0;
    border-radius: 6px;
  } */
  &:first-child {
    padding-left: 0px !important;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  bottom: -1px;
  width: 74px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 2;
  ${({ $next }) =>
    $next
      ? css`
          right: -16px;
          justify-content: flex-end;
          background: linear-gradient(90deg, rgba(248, 248, 250, 0.83) 26.39%, #f8f8fa 94.76%);
        `
      : null}
  ${({ $prev }) =>
    $prev
      ? css`
          left: -16px;
          justify-content: flex-start;
          background: linear-gradient(270deg, rgba(248, 248, 250, 0.83) 26.39%, #f8f8fa 94.76%);
        `
      : null}
  &.slick-disabled {
    display: none;
  }
`;

const NextArrow = (props) => (
  <Arrow $next className={props.className}>
    <Button.Action $style="blue" $variant="circle" $width={26} $height={26} onClick={props.onClick}>
      <Icon $width={26} $height={26} $icon="chevron-right" $color="white" />
    </Button.Action>
  </Arrow>
);
const PrevArrow = (props) => (
  <Arrow $prev className={props.className}>
    <Button.Action $style="blue" $variant="circle" $width={26} $height={26} onClick={props.onClick}>
      <Icon $width={26} $height={26} $icon="chevron-left" $color="white" />
    </Button.Action>
  </Arrow>
);

const settings = {
  responsive: [
    {
      breakpoint: 9999,
      settings: 'unslick',
    },
    {
      breakpoint: 1367,
      settings: {
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: false,
        infinite: false,
        centerMode: false,
        // centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      },
    },
  ],
};

const TabsSlider = (props) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    if (props?.selected) {
      setActive(
        props.children.findIndex(
          (data) =>
            data.props?.children?.toString().toLowerCase() ===
            props?.selected?.toString().toLowerCase(),
        ),
      );
    }
  }, [props.selected]);

  let children;
  if (Array.isArray(props.children)) {
    children = props.children;
  } else {
    children = [];
    children.push(props.children);
  }

  return (
    <StyledSlider {...props}>
      <Slider {...settings}>
        {children.map((item, i) => {
          if (item && item.props?.$islink) {
            return item;
          } else {
            return (
              <TabbedItem
                key={i}
                $small={props.$small}
                className={active === i ? 'active' : ''}
                onClick={() =>
                  item.props.$isActive === undefined || item.props?.$isActive === 'true'
                    ? setActive(i)
                    : null
                }
              >
                {item}
              </TabbedItem>
            );
          }
        })}
      </Slider>
    </StyledSlider>
  );
};

export default TabsSlider;
