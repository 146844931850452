import * as yup from 'yup';
import { regExps } from '../regExps';

const validFileExtensions = ['jpg', 'jpeg', 'png', 'gif', 'ico', 'svg', 'bmp', 'pdf'];

function isValidFileType(fileName) {
  if (!fileName) return true;
  return fileName && validFileExtensions.indexOf(fileName.split('.').pop()) > -1;
}

const companyBasicSchema = yup.object().shape({
  name: yup
    .string()
    .required('Company Name is required')
    .matches(regExps.companyName, 'Invalid characters')
    .test(
      'no-empty-spaces',
      'Company Name cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .max(50, 'Company Name must be at most 50 characters'),
  country: yup.object().shape({
    value: yup.string().required('Country is required'),
  }),
  currency: yup.object().shape({
    value: yup.string().required('Currency is required'),
  }),
  industry: yup.object().shape({
    value: yup.string().required('Industry is required'),
  }),
  timezone: yup.object().shape({
    value: yup.string().required('Timezone is required'),
  }),
});
const companyExtendedSchema = companyBasicSchema.shape({
  tradingName: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Trading name cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9@&()'-\s]+$/, 'Invalid characters')
    .max(50, 'Trading name must be at most 50 characters'),
  registrationNumber: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Registration number cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters')
    .max(30, 'Registration Number must be at most 30 characters'),
  taxNumber: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'Tax number cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters')
    .max(20, 'Tax Number must be at most 20 characters'),
  companyLogo: yup
    .mixed()
    .nullable(true)
    .test('is-valid-type', 'Not a valid image type', (value) =>
      isValidFileType(value && value.name.toLowerCase()),
    ),
  allowedVarianceAmount: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : 0))
    .typeError('Only numbers allowed')
    .integer('Only integers allowed')
    .min(0, 'Min value is 0'),
  allowedVariancePercentage: yup
    .number()
    .transform((_, val) => (val !== '' ? Number(val) : 0))
    .typeError('Only numbers allowed')
    .integer('Only integers allowed')
    .min(0, 'Min value is 0'),
  poDisclaimer: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test(
      'no-empty-spaces',
      'PO Disclaimer cannot be just empty spaces',
      (value) => value?.trim() !== '',
    )
    .matches(/^[a-zA-Z0-9@&()'-\s]+$/, 'Invalid characters'),
});

export { companyBasicSchema, companyExtendedSchema };
