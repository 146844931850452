import React from 'react';
import styled from 'styled-components';
import Fader from './Fader';
import Box from '../General/Box';
import Icon from '../General/Icon';
import Text from '../Typography/Text';
import { createPortal } from 'react-dom';

const ModalStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 90%;
  transform: translate(-50%, -50%);
  @media all and (min-width: 1025px) {
    max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : '800px')};
  }
  @media all and (max-height: 700px) {
    position: static;
    margin: 0 auto;
    transform: none;
  }
  // & > div {
  //   overflow: hidden;
  // }
`;

const ModalHeader = styled.div`
  position: relative;
  padding: 20px;
  border-top-left-radius: ${({ $radius }) => ($radius ? $radius + 'px' : '16px')};
  border-top-right-radius: ${({ $radius }) => ($radius ? $radius + 'px' : '16px')};
  background-color: ${(props) => (props.$color ? props.$color : '#145FF5')};
  color: ${(props) => (props.$color ? 'inherit' : '#ffffff')};
  text-align: center;
  & svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

const Modal = (props) => {
  const close = () => {
    if (props.$onCloseCallback && !props.$noCloseSign) {
      props.$onCloseCallback();
      props.$close();
    } else {
      props.$close();
    }
  };
  const closeOnFaded = () => {
    if (props.$closableOutside && props.$close) {
      props.$close();
    }
  };
  return createPortal(
    <>
      {props.$show ? (
        <Fader $blur={props.$blur} onClick={closeOnFaded}>
          <ModalStyled $maxWidth={props.$maxWidth} onClick={(e) => e.stopPropagation()}>
            <Box $noOverflow $radius={props.$radius} $noPadding $noBorder>
              {props.$title && props.$title !== '' ? (
                <ModalHeader $color={props.$color} $radius={props.$radius}>
                  <Text type="h3" $weight={props.$weight}>
                    {props.$title}
                  </Text>
                  {props.$close && typeof props.$close === 'function' && !props.$noCloseSign ? (
                    <Icon $width={28} $height={28} $icon="close" onClick={close} />
                  ) : null}
                </ModalHeader>
              ) : null}
              {props.children}
            </Box>
          </ModalStyled>
        </Fader>
      ) : null}
    </>,
    document.getElementById('modal-root'),
  );
};

export default Modal;
