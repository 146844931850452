import styles from '../../../../styles/approvalWorkflow.module.css';
import Select from 'react-select';
import Input from '../../../UI/Forms/Input';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import spmsServiceService from '../../../../services/spmsService.service';
import getUsersAccess from '../helpers/getUsersAccess';
import * as Button from '../../../UI/Forms/Button.jsx';
import Icon from '../../../UI/General/Icon';
import { AsyncPaginate } from 'react-select-async-paginate';
import SelectDepartments from '../../../shared/SelectDepartments.jsx';
import SelectLocations from '../../../shared/SelectLocations.jsx';
import SelectVendors from '../../../shared/SelectVendors.jsx';
import SelectBudgets from '../../../shared/SelectBudgets.jsx';
import SelectUsers from '../../../shared/SelectUsers.jsx';

const insOptions = [
  { label: 'IN', value: 'IN' },
  { label: 'NOT IN', value: 'NOT_IN' },
];
const budgetTypeOptions = [
  { label: 'Annual', value: 'ANNUAL' },
  { label: 'Project', value: 'PROJECT' },
];
const amountOptions = [
  { label: '=', value: 'EQUALS' },
  { label: '<', value: 'LESS_THAN' },
  { label: '>', value: 'GREATER_THAN' },
  { label: '<=', value: 'LESS_THAN_OR_EQUAL' },
  { label: '>=', value: 'GREATER_THAN_OR_EQUAL' },
];

const SingleCondition = ({
  conditionId,
  setValue,
  control,
  watch,
  errors,
  companyId,
  deleteCondition,
  prevCondition,
  selectedSubjects,
  setIsAddConditionActive,
  moduleType,
  getValues,
}) => {
  const [conditionOptions, setConditionOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const subject = watch(`subject-${conditionId}`);
  const subjectCondition = watch(`subjectCondition-${conditionId}`);
  const conditionValue = watch(`conditionValue-${conditionId}`);

  const typeOptions = {
    REQUISITION: [
      { label: 'Amount', value: 'Amount' },
      { label: 'Location', value: 'Location' },
      { label: 'Department', value: 'Department' },
      { label: 'Budget', value: 'Budget' },
      { label: 'Requester', value: 'Requester' },
      { label: 'Vendor', value: 'Vendor' },
    ],
    PURCHASE_ORDER_INVOICE: [
      { label: 'Location', value: 'Location' },
      { label: 'Department', value: 'Department' },
      { label: 'Budget', value: 'Budget' },
      { label: 'Requester', value: 'Requester' },
      { label: 'Vendor', value: 'Vendor' },
    ],
    VENDOR: [
      { label: 'Location', value: 'Location' },
      { label: 'Department', value: 'Department' },
      { label: 'Requester', value: 'Requester' },
      { label: 'Category', value: 'VendorCategory' },
      {
        label: 'Sub Category',
        value: 'VendorSubcategory',
        hide: !selectedSubjects.find((subject) => subject.value === 'VendorCategory'),
      },
    ],
    BUDGET: [
      { label: 'Location', value: 'Location' },
      { label: 'Department', value: 'Department' },
      { label: 'Budget Type', value: 'BudgetType' },
    ],
  };

  const subjectSelectors = {
    Department: (ref, rest) => (
      <SelectDepartments {...rest} selectRef={ref} isMulti className={'react-select-container'} />
    ),
    Location: (ref, rest) => (
      <SelectLocations {...rest} selectRef={ref} isMulti className={'react-select-container'} />
    ),
    Vendor: (ref, rest) => (
      <SelectVendors {...rest} isMulti selectRef={ref} className={'react-select-container'} />
    ),
    Budget: (ref, rest) => (
      <SelectBudgets {...rest} selectRef={ref} isMulti className={'react-select-container'} />
    ),
    Requester: (ref, rest) => (
      <Select
        {...rest}
        isMulti
        ref={ref}
        isDisabled={!conditionOptions.length}
        className={
          errors.hasOwnProperty(rest.name)
            ? 'react-select-container error'
            : 'react-select-container'
        }
        classNamePrefix="react-select"
        isSearchable={false}
        placeholder="Select Requester"
        options={conditionOptions}
      />
    ),
    BudgetType: (ref, rest) => (
      <Select
        {...rest}
        isMulti
        ref={ref}
        className={
          errors.hasOwnProperty(rest.name)
            ? 'react-select-container error'
            : 'react-select-container'
        }
        classNamePrefix="react-select"
        isSearchable={false}
        placeholder="Select Budget Type"
        options={budgetTypeOptions}
      />
    ),
    VendorCategory: (ref, rest) => {
      const options = Array.from(new Set(conditionOptions.map((cat) => cat.category))).map(
        (category) => ({
          label: category,
          value: category,
        }),
      );
      return (
        <Select
          {...rest}
          isMulti
          ref={ref}
          isDisabled={!conditionOptions.length}
          className={
            errors.hasOwnProperty(rest.name)
              ? 'react-select-container error'
              : 'react-select-container'
          }
          classNamePrefix="react-select"
          isSearchable={false}
          placeholder="Select Category"
          options={options}
        />
      );
    },
    VendorSubcategory: (ref, rest) => {
      const createOptions = () => {
        let categorySubject;
        for (const [key, value] of Object.entries(getValues())) {
          if (value?.value === 'VendorCategory') {
            categorySubject = key.replace('subject-', '');
          }
        }
        const selectedCategories = getValues()[`conditionValue-${categorySubject}`];
        if (!selectedCategories) {
          return [];
        }
        const subCategoriesOptions = [];
        selectedCategories.forEach((selCategory) => {
          const newConditions = conditionOptions
            .filter((category) => category.category === selCategory?.value)
            .map((subcat) => ({
              label: subcat.subCategory,
              value: subcat.subCategory,
            }));
          subCategoriesOptions.push(newConditions);
        });
        return subCategoriesOptions.flat();
      };
      return (
        <Select
          {...rest}
          isMulti
          ref={ref}
          isDisabled={!conditionOptions.length}
          className={
            errors.hasOwnProperty(rest.name)
              ? 'react-select-container error'
              : 'react-select-container'
          }
          classNamePrefix="react-select"
          isSearchable={false}
          placeholder="Select Category"
          options={createOptions()}
        />
      );
    },
  };

  useEffect(() => {
    if (subject?.value === 'Requester')
      getUsersAccess(companyId, 'CREATE', ['Purchase_Orders', 'Requests']).then((requestors) => {
        setConditionOptions(
          requestors
            .filter((requester) => requester.status === 'ACTIVE')
            .map((requestor) => ({
              label: `${requestor.firstName} ${requestor.lastName}`,
              value: requestor.email,
            })),
        );
        setIsLoading(false);
      });
    if (subject?.value === 'VendorCategory' || subject?.value === 'VendorSubcategory')
      spmsServiceService.getVendorCategories().then((res) => {
        const categories = res.data.data;
        setConditionOptions(categories);
      });
    setIsLoading(false);
  }, [subject]);

  useEffect(() => {
    setIsAddConditionActive(!!subject && !!subjectCondition && !!conditionValue);
  }, [subject, subjectCondition, conditionValue]);

  useEffect(() => {
    if (!prevCondition) return;
    console.log('prevCondition', prevCondition);

    setValue(
      `subject-${conditionId}`,
      typeOptions[moduleType].find((option) => option.value === prevCondition.subject),
    );
    setValue(
      `subjectCondition-${conditionId}`,
      amountOptions.find((option) => option.value === prevCondition.subjectCondition) ??
        insOptions.find((option) => option.value === prevCondition.subjectCondition),
    );
    const prevConditionOptions =
      prevCondition.subject === 'Amount'
        ? prevCondition.conditionValue[0].value
        : prevCondition.conditionValue.map((option) => ({
            label: option.key,
            value: option.value,
          }));
    setValue(`conditionValue-${conditionId}`, prevConditionOptions);
  }, [prevCondition]);

  const handleSubjectChange = (e) => {
    if (subject?.value !== 'Amount' && e.value === 'Amount') {
      setValue(`subjectCondition-${conditionId}`, null);
      setValue(`conditionValue-${conditionId}`, '');
    } else if (e.value !== 'Amount' && subject?.value === 'Amount') {
      setValue(`subjectCondition-${conditionId}`, null);
      setValue(`conditionValue-${conditionId}`, null);
    } else setValue(`conditionValue-${conditionId}`, null);
  };

  const deleteButtonHandler = () => {
    deleteCondition(conditionId);
  };

  const isFieldRequired = !!subject || !!subjectCondition || !!conditionValue;
  return (
    <div className={styles.singleCondition}>
      <div className={styles.rowContainer}>
        <div className="inp-container">
          <Controller
            name={`subject-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
            }}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleSubjectChange(e);
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: '100 !important',
                  }),
                }}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select"
                isOptionDisabled={(option) =>
                  selectedSubjects.find((subject) => subject?.value === option.value)
                }
                options={typeOptions[moduleType]?.filter((option) => !option.hide) || []}
              />
            )}
          />
          {errors[`subject-${conditionId}`] && (
            <p className="error-message">{errors[`subject-${conditionId}`]?.message}</p>
          )}
        </div>
        <div className="inp-container">
          <Controller
            name={`subjectCondition-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
            }}
            render={({ field }) => (
              <Select
                {...field}
                className={
                  errors.hasOwnProperty(field.name)
                    ? 'react-select-container error'
                    : 'react-select-container'
                }
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select"
                options={subject?.value === 'Amount' ? amountOptions : insOptions}
              />
            )}
          />
          {errors[`subjectCondition-${conditionId}`] && (
            <p className="error-message">{errors[`subjectCondition-${conditionId}`]?.message}</p>
          )}
        </div>
        <div className="inp-container">
          <Controller
            name={`conditionValue-${conditionId}`}
            control={control}
            rules={{
              required: {
                value: isFieldRequired,
                message: 'Field is required',
              },
              maxLength: {
                value: 10,
                message: 'Maximum 10 characters',
              },
              validate: {
                allowed: (v) => {
                  if (subject?.value === 'Amount') {
                    return /^[0-9]+$/.test(v) || 'Numerical characters only';
                  } else return true;
                },
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <>
                {subject?.value === 'Amount' ? (
                  <Input
                    {...rest}
                    ref={ref}
                    type="text"
                    placeholder="Enter Amount"
                    className={errors.hasOwnProperty(rest.name) && 'error'}
                  />
                ) : subject?.value ? (
                  subjectSelectors[subject.value](ref, rest)
                ) : (
                  <Select
                    isMulti
                    isDisabled
                    {...rest}
                    className={
                      errors.hasOwnProperty(rest.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select"
                    options={[]}
                  />
                )}
              </>
            )}
          />
          {errors[`conditionValue-${conditionId}`] && (
            <p className="error-message">{errors[`conditionValue-${conditionId}`]?.message}</p>
          )}
          {/* {!conditionOptions.length &&
            subject?.value &&
            subject?.value !== 'Amount' &&
            !isLoading && <p className="error-message">Please create a {subject.label}</p>} */}
        </div>
      </div>

      <div className={styles.deleteButton}>
        <Button.Action
          $variant="circle"
          $style="lightGrayishBlue"
          $width={40}
          $height={40}
          onClick={deleteButtonHandler}
          type="button"
        >
          <Icon $width={20} $height={20} $icon="delete" $color="#F24638" />
        </Button.Action>
      </div>
    </div>
  );
};

export default SingleCondition;
