import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import * as Button from '../../../UI/Forms/Button';
import DataTableBase from '../../../UI/General/DataTableBase';
import { Link, useParams } from 'react-router-dom';
import { useStore } from '../../../../store/store';
import spmsServiceService from '../../../../services/spmsService.service';
import moment from 'moment';
import generalStyles from '../../../../styles/general.module.css';
import { nf } from '../../../../utils/nf';
import Text from '../../../UI/Typography/Text';
import Status from '../../../UI/Typography/Status';
import Icon from '../../../UI/General/Icon';

const InvoicesTable = ({ props, setCreateMode }) => {
  const { orderId } = useParams();
  const user = useStore((state) => state.user);
  const [tableData, setTableData] = useState([]);
  const [updateData, setUpdateDate] = useState(false);

  const deleteItem = (itemId) => {
    props.setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .deletePurchaseOrderInvoice(orderId, itemId)
      .then(() => {
        props.setToast({
          opened: true,
          message: 'Invoice has been deleted successfully',
          type: 'success',
          cb: () => (setUpdateDate((s) => !s), props.$trigger((state) => state + 1)),
        });
      })
      .catch((err) => {
        props.setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Invoice Number',
        sortable: true,
        selector: (row) => row?.invoiceNumber,
        cell: (row) => {
          if (row.approvalStatus === 'PENDING_APPROVAL') {
            return (
              <span data-content={'Invoice Number'} className={generalStyles.tableValue}>
                <Link to={'/purchase-orders/' + orderId + '/invoices/' + row.id}>
                  {row.invoiceNumber}
                </Link>
              </span>
            );
          } else {
            return (
              <span
                data-content={'Invoice Number'}
                className={generalStyles.tableValue}
                data-tag="allowRowEvents"
                onClick={() => setCreateMode((s) => ({ createEdit: true, invoiceId: row.id }))}
              >
                <span className={'link'}>{row.invoiceNumber}</span>
              </span>
            );
          }
        },
      },
      {
        name: 'Issue Date',
        sortable: true,
        selector: (row) => row.invoiceIssueDate,
        format: (row) => moment(row?.invoiceIssueDate).format('DD/MM/YYYY'),
        cell: (row) => (
          <span data-content={'Issue Date'} className={generalStyles.tableValue}>
            <span data-tag="allowRowEvents">
              {moment(row?.invoiceIssueDate).format('DD/MM/YYYY')}
            </span>
          </span>
        ),
      },
      {
        name: 'Due Date',
        sortable: true,
        selector: (row) => row?.invoiceDueDate,
        format: (row) => moment(row?.invoiceDueDate).format('DD/MM/YYYY'),
        cell: (row) => (
          <span data-content={'Due Date'} className={generalStyles.tableValue}>
            <span data-tag="allowRowEvents">
              {moment(row?.invoiceDueDate).format('DD/MM/YYYY')}
            </span>
          </span>
        ),
      },
      {
        name: 'Amount',
        sortable: true,
        selector: (row) => row?.totalInvoiceAmount,
        cell: (row) => (
          <span
            data-content={'Amount'}
            className={generalStyles.tableValue}
            data-tag="allowRowEvents"
          >
            <span data-tag="allowRowEvents">{row?.currency?.code ?? ''} {nf.format(row?.totalInvoiceAmount) ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Status',
        sortable: true,
        selector: (row) => row?.approvalStatus,
        cell: (row) => (
          <>
            <span
              data-content={'Status'}
              className={generalStyles.tableValue}
              data-tag="allowRowEvents"
            >
              <Text weight={500} type={'body-2'} data-tag="allowRowEvents">
                <Status value={row?.approvalStatus} wordCount={1} pill />
              </Text>
            </span>
            {row?.createdBy?.userId === user.id && row?.approvalStatus === 'PENDING_APPROVAL' ? (
              <div className={generalStyles.actionMenuHolder}>
                <Button.Action
                  $variant="circle"
                  $style="lightGrayishBlue"
                  $width={32}
                  $height={32}
                  onClick={() => deleteItem(row?.id)}
                  type="button"
                >
                  <Icon $width={20} $height={20} $icon="delete" $color="#4B5865" />
                </Button.Action>
              </div>
            ) : null}
          </>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    spmsServiceService.getPurchaseOrderInvoices(orderId).then((r) => {
      if (r.data.message === 'Operation Successful') {
        setTableData(r.data.data);
      }
    });
  }, [orderId, updateData]);

  return (
    <>
      <div className={styles.createButton}>
        <Button.Main
          $mid
          $style="blue"
          type="submit"
          onClick={() => setCreateMode((s) => ({ createEdit: true, invoiceId: undefined }))}
        >
          Add New
        </Button.Main>
      </div>
      <DataTableBase data={tableData} columns={columns} pagination={false} />
    </>
  );
};

export default InvoicesTable;
