import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Icon from '../General/Icon';
import Tooltip from '../General/Tooltip';

const Mark = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ $size }) => ($size ? $size + 'px' : '26px')};
  height: ${({ $size }) => ($size ? $size + 'px' : '26px')};
  border: 1px solid #c4c4c4;
  color: #ffffff;
  background-color: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
  justify-content: ${({ $reverse }) => ($reverse ? 'space-between' : 'flex-start')};
  margin-bottom: 0;
  &:hover {
    cursor: pointer;
  }
  &:hover ${Mark} {
    border-color: #45babc;
  }
  & input[type='checkbox'] {
    display: none;
  }
  & input[type='checkbox'] + ${Mark} {
    margin-right: ${({ $reverse }) => ($reverse ? 0 : '8px')};
  }
  & input[type='checkbox'].error + ${Mark} {
    border-color: #cd3e27;
  }
  & input[type='checkbox']:checked + ${Mark}, & input[indeterminate='true'] + ${Mark} {
    border-color: #145ff5;
    background-color: #145ff5;
  }
  & input[type='checkbox']:disabled + ${Mark} {
    border: 1px solid #c4c4c4;
    background-color: #c4c4c4;
  }
`;

const Required = styled.span`
  color: #cd3e27;
`;

const Checkbox = forwardRef((props, ref) => {
  const { $size, $reverse, $labelRequired, $tooltip, ...other } = props;
  return (
    <CheckboxLabel onClick={(e) => e.stopPropagation()} $reverse={$reverse}>
      <input type="checkbox" {...other} ref={ref} />
      <Mark $size={$size} $indeterminate={props?.indeterminate}>
        <Icon
          $icon={props?.indeterminate === 'true' ? 'indeterminate' : 'marked'}
          $width={$size ? $size - 2 : 24}
          $height={$size ? $size - 2 : 24}
        />
      </Mark>
      {other.label && other.label !== '' ? <span>{other.label}</span> : null}
      {$labelRequired && <Required>*</Required>}
      {$tooltip && <Tooltip text={$tooltip} />}
    </CheckboxLabel>
  );
});

export default Checkbox;
