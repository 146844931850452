import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select';
import Box from '../../components/UI/General/Box';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import style from '../../styles/vendorPortal.module.css';
import generalStyles from '../../styles/general.module.css';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as Button from '../../components/UI/Forms/Button';
import Documents from '../../components/UI/Forms/Documents';
import spmsServiceService from '../../services/spmsService.service';
import CountryAndRegion from '../../components/UI/Forms/CountryAndRegion';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store/store';
import Text from '../../components/UI/Typography/Text';
import Toast from '../../components/UI/General/Toast';
import TabsSlider from '../../components/UI/General/TabsSlider';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import s2pmsVendorPortal from '../../services/vendorPortal.service';
import { timezones } from '../../constants/timezones';
import { regExps } from '../../utils/regExps';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

const legalStructureOptions = [
  { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
  { label: 'Partnership', value: 'Partnership' },
  { label: 'Limited Liability Company (LLC)', value: 'Limited Liability Company' },
  { label: 'Corporation', value: 'Corporation' },
  { label: 'Cooperative (Co-op)', value: 'Cooperative' },
  { label: 'Public Limited Company (PLC)', value: 'Public Limited Company' },
  { label: 'Joint Venture', value: 'Joint Venture' },
  { label: 'Limited Liability Partnership (LLP)', value: 'Limited Liability Partnership' },
];

const VendorAccountEdit = () => {
  const navigate = useNavigate();
  const { externalId: vendorId } = useStore((state) => state.user);
  const countries = useStore((state) => state.countries);
  const [tab, setTab] = useState('profile');
  const [values, setValues] = useState(null)
  const [vendorCategories, setVendorCategories] = useState([]);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false);

  const vendorDefaultValues = {
    // legal
    legalName: '',
    tradingName: '',
    legalStructure: '',
    taxIdentificationNumber: '',
    countryOfIncorporation: '',
    registrationNo: '',
    category: '',
    subCategory: '',
    // contact
    websiteUrl: '',
    email: '',
    phoneNumber: '',
    name: '',
    socialAccounts: null,
    // address
    streetAddress: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    timezone: '',
    // financial info
    bankName: '',
    accountName: '',
    accountIban: '',
    sortCodeRouting: '',
    swiftBic: '',
    bankAddress: '',
    currency: '',
    vendorAttachments: null
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: vendorDefaultValues,
    values,
  });

  const categoryWatcher = useWatch({ control, name: 'category' });

  const createCurrencyOptions = useCallback(() => {
    if (!countries || !countries.length) return;
    const separator = '-';
    const allCurrencies = countries
      .map((country) => `${country.currencyName}${separator}${country.currency}`)
      .sort();
    const uniqueCurrencies = [...new Set([...allCurrencies])];
    return uniqueCurrencies.map((currency) => {
      const label = currency.split(separator);
      label[label.length - 1] = `(${label[label.length - 1]})`;
      return {
        label: label.join(' '),
        value: currency.split(separator).at(-1),
      };
    });
  }, [countries]);

  useEffect(() => {
    spmsServiceService.getVendorCategories().then((r) => {
      if (r.data.message === 'Operation Successful') {
        const categories = r.data.data;
        setVendorCategories(categories);
      }
    });
  }, []);

  useEffect(() => {
    if (!vendorId) return;
    s2pmsVendorPortal.getProfile(vendorId).then(r => {
      if (r.data.message === "Operation Successful"){
        const vendorData = r.data.data;
        const updTimezone = timezones.find((item) => item.label === vendorData.timezone);
        const vendorContacts = vendorData.vendorContacts.find(el => el.contactType === 'ENTERED_BY_VENDOR')
        console.log(vendorContacts);
        const vendorPreloadData = {
          ...vendorData,
          countryOfIncorporation: { label: vendorData.countryOfIncorporation, value: vendorData.countryOfIncorporation },
          legalStructure: { label: vendorData.legalStructure, value: vendorData.legalStructure },
          category: { label: vendorData?.category, value: vendorData?.category },
          subCategory: { label: vendorData?.subCategory, value: vendorData?.subCategory },
          // contact
          websiteUrl: vendorContacts?.websiteUrl,
          email: vendorContacts?.email,
          phoneNumber: vendorContacts?.phoneNumber,
          name: vendorContacts?.name,
          linkedIn: vendorContacts?.socialAccounts?.linkedIn,
          fb: vendorContacts?.socialAccounts?.fb,
          instagram: vendorContacts?.socialAccounts?.instagram,
          twitter: vendorContacts?.socialAccounts?.twitter,
          // address
          streetAddress: vendorData.address?.streetAddress,
          addressLine2: vendorData.address?.addressLine2,
          addressLine3: vendorData.address?.addressLine3,
          city: vendorData.address?.city,
          state: { label: vendorData.address?.state, value: vendorData.address?.state },
          country: { label: vendorData.address?.country, value: vendorData.address?.country },
          zipCode: vendorData.address?.zipCode,
          timezone: updTimezone,
          // financial info
          bankName: vendorData.vendorFinancialInfo?.bankName,
          accountName: vendorData.vendorFinancialInfo?.accountName,
          accountIban: vendorData.vendorFinancialInfo?.accountIban,
          sortCodeRouting: vendorData.vendorFinancialInfo?.sortCodeRouting,
          swiftBic: vendorData.vendorFinancialInfo?.swiftBic,
          bankAddress: vendorData.vendorFinancialInfo?.bankAddress,
          currency: { label: vendorData.vendorFinancialInfo?.currency?.code, value: vendorData.vendorFinancialInfo?.currency?.code },

        };
        console.log(vendorPreloadData);
        setValues(vendorPreloadData)
      }
    }).catch(err => {
      console.log(err);
    })
  }, [vendorId]);

  const files = (files) => files.map((file) => ({
    attachment: file,
    name: file.name,
  }));

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const submitVendor = async (data) => {
    console.log(data);
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));

    let newVendorAttachments = [];
    if (data.vendorAttachments !== undefined && data.vendorAttachments.length > 0) {
      const files = data.vendorAttachments.map(async (item) => ({
        name: item.name.split(' ').join('-'),
        document: await getBase64(item.attachment),
      }));
      newVendorAttachments = await Promise.all(files).then((result) => result);
    }

    let newData = {
      id: data.id,
      category: data.category.value,
      subCategory: data.subCategory.value,
      legalName: data.legalName,
      tradingName: data.tradingName,
      countryOfIncorporation: data.countryOfIncorporation.value,
      legalStructure: data.legalStructure.value,
      taxIdentificationNumber: data.taxIdentificationNumber,
      registrationNo: data.registrationNo,
      address: {
        addressLine2: data.addressLine2,
        addressLine3: data.addressLine3,
        streetAddress: data.streetAddress,
        city: data.city,
        state: data.state.value,
        zipCode: data.zipCode,
        country: data.country.value
      },
      vendorFinancialInfo: {
        bankName: data.bankName,
        bankAddress: data.bankAddress,
        accountName: data.accountName,
        accountIban: data.accountIban,
        sortCodeRouting: data.sortCodeRouting,
        swiftBic: data.swiftBic,
        currency: {
          code: data.currency.value
        }
      },
      vendorContacts: [
        {
          id: data.vendorContacts[0].id,
          contactType: data.vendorContacts[0].contactType,
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          websiteUrl: data.websiteUrl,
          socialAccounts: {
            fb: data.fb,
            instagram: data.instagram,
            linkedIn: data.linkedIn,
            twitter: data.twitter,
          }
        }
      ],
      timezone: data.timezone.label,
    }
    if (!!newVendorAttachments?.length){
      newData.attachments = newVendorAttachments
    }
    console.log(newData);

    await s2pmsVendorPortal.updateProfile(vendorId, newData).then(r => {
      console.log(r);
      setToast({
        opened: true,
        message: 'Your Profile has been updated',
        type: 'success',
        cb: () => navigate("/vendor-profile")
      });
    }).catch(err => {
      console.log(err);
      setIsButtonsBlocked(false)
      setToast({
        opened: true,
        message: err.response.data.message ? err.response.data.message : 'Something Went Wrong',
        type: 'fail',
      });
    })
  };

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <div className={generalStyles.top}>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>Profile Overview</Text>
            </div>
            <div className={generalStyles.addItemButton}>
              <Button.Main
                $mid
                $style="blue"
                type="submit"
                disabled={!isDirty || !isValid || isButtonsBlocked}
                onClick={handleSubmit((data) => submitVendor(data))}
              >
                Save
              </Button.Main>
              <Button.Main
                $mid
                $style="gray"
                type="button"
                onClick={() => navigate("/vendor-profile")}
              >
                Discard
              </Button.Main>
            </div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('profile')}>Profile</span>
            <span onClick={() => setTab('documents')}>Documents</span>
          </TabsSlider>
        </div>
        <div>
          {tab === 'profile' && (
            <>
              <div className={style.section + ' ' + generalStyles.underline}>
                <Text type={'subtitle'} weight={500}>Legal</Text>
                <div className={generalStyles.fieldsThree}>
                  <div className="inp-container">
                    <Controller
                      name="legalName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Legal Name is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyAllowed: (v) => /^[-a-zA-Z@&()\s]+$/.test(v) || 'Alphabetic, numeric, &()- characters and space only',
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Legal Name"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Legal Name"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.legalName && <p className="error-message">{errors.legalName?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="tradingName"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        pattern: {
                          value: /^[-a-zA-Z0-9@&()\s]+$/,
                          message: 'Alphabetic, numeric, &()- characters and space only',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Trading Name"
                          $label="Trading Name"
                          {...field}
                        />
                      )}
                    />
                    {errors.tradingName && <p className="error-message">{errors.tradingName?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Label $isRequired $title="Legal Type"/>
                    <Controller
                      name="legalStructure"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Legal Type is required',
                        },
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          classNamePrefix="react-select"
                          className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                          isSearchable
                          placeholder="Select Legal Type"
                          options={legalStructureOptions}
                          menuPortalTarget={document.body}
                          menuPosition={'absolute'}
                          menuPlacement={'bottom'}
                          menuShouldScrollIntoView={false}
                        />
                      )}
                    />
                    {errors.legalStructure && <p className="error-message">{errors.legalStructure?.value.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="taxIdentificationNumber"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Tax Identification Number is required',
                        },
                        maxLength: {
                          value: 30,
                          message: 'Maximum 30 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => /^[-a-zA-Z0-9/]+$/.test(v) || 'Alphabetic, numeric, /- characters and space only',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Tax Identification Number"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Tax Identification Number"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.taxIdentificationNumber && <p className="error-message">{errors.taxIdentificationNumber?.message}</p>}
                  </div>
                  <CountryAndRegion
                    errors={errors}
                    control={control}
                    countryFieldName={'countryOfIncorporation'}
                    countryLabel={'Country of Incorporation'}
                    isCountryRequired={true}
                    isLabel
                  />
                  <div className="inp-container">
                    <Controller
                      name="registrationNo"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Registration Number Number is required',
                        },
                        maxLength: {
                          value: 30,
                          message: 'Maximum 30 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => /^[-a-zA-Z0-9/]+$/.test(v) || 'Alphabetic, numeric, /- characters and space only',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Reg Number"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Reg Number"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.registrationNo && <p className="error-message">{errors.registrationNo?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Label $isRequired $title="Category"/>
                    <Controller
                      name="category"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Category is required',
                        }
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                          classNamePrefix="react-select"
                          isSearchable={false}
                          placeholder="Select Category"
                          menuPortalTarget={document.body}
                          menuPosition={'absolute'}
                          menuPlacement={'bottom'}
                          menuShouldScrollIntoView={false}
                          options={Array.from(new Set(vendorCategories.map((cat) => cat.category))).map(
                            (category) => ({
                              label: category,
                              value: category,
                            }),
                          )}
                          onChange={(e) => {
                            field.onChange(e);
                            setValue('subCategory', null);
                          }}
                        />
                      )}
                    />
                    {errors.category && <p className="error-message">{errors.category?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Label $isRequired $title="Sub Category"/>
                    <Controller
                      name="subCategory"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Subcategory is required',
                        }
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                          classNamePrefix="react-select"
                          isSearchable={false}
                          placeholder="Select Sub Category"
                          menuPortalTarget={document.body}
                          menuPosition={'absolute'}
                          menuPlacement={'bottom'}
                          menuShouldScrollIntoView={false}
                          options={vendorCategories
                            .filter((category) => category.category === categoryWatcher?.value)
                            .map((subcat) => ({
                              label: subcat.subCategory,
                              value: subcat.subCategory,
                            }))}
                        />
                      )}
                    />
                    {errors.subCategory && <p className="error-message">{errors.subCategory?.message}</p>}
                  </div>
                </div>
              </div>

              <div className={style.section + ' ' + generalStyles.underline}>
                <Text type={'subtitle'} weight={500}>Contact</Text>
                <div className={generalStyles.fieldsThree}>
                  <div className="inp-container">
                    <Controller
                      name="websiteUrl"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        pattern: {
                          value: regExps.websiteUrl,
                          message: 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Website URL"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Website"
                          {...field}
                        />
                      )}
                    />
                    {errors.websiteUrl && <p className="error-message">{errors.websiteUrl?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Business Email is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.email.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Business Email"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Business Email"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.email && <p className="error-message">{errors.email?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Label $isRequired $title="Business Phone"/>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Business Phone is required',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          isValid: (value) => (isValidPhoneNumber(value || '') && isPossiblePhoneNumber(value || '')) || 'Enter a valid phone number',
                        },
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          placeholder="Enter Business Phone"
                          limitMaxLength={true}
                          international={true}
                          className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                        />
                      )}
                    />
                    {errors.phoneNumber && <p className="error-message">{errors.phoneNumber?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Contact Name is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.userName.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Contact Name"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Contact Name"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.name && <p className="error-message">{errors.name?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Contact Email is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.email.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Contact Email"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Contact Email"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.email && <p className="error-message">{errors.email?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="linkedIn"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter LinkedIn"
                          $label="LinkedIn"
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="fb"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Facebook"
                          $label="Facebook"
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="instagram"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Instagram"
                          $label="Instagram"
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="twitter"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Twitter"
                          $label="Twitter"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className={style.section + ' ' + generalStyles.underline}>
                <Text type={'subtitle'} weight={500}>Address</Text>
                <div className={generalStyles.fieldsThree}>
                  <div className="inp-container">
                    <Controller
                      name="streetAddress"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Address 1 is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Address 1"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Address 1"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.streetAddress && <p className="error-message">{errors.streetAddress?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="addressLine2"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Address 2 is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Address 2"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Address 2"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.addressLine2 && <p className="error-message">{errors.addressLine2?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="addressLine3"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Address 3 is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Address 3"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Address 3"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.addressLine3 && <p className="error-message">{errors.addressLine3?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="city"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'City/Town is required',
                        },
                        maxLength: {
                          value: 30,
                          message: 'Maximum 30 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter City/Town"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="City/Town"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.city && <p className="error-message">{errors.city?.message}</p>}
                  </div>
                  <CountryAndRegion
                    errors={errors}
                    control={control}
                    countryFieldName={'country'}
                    regionFieldName={'state'}
                    isCountryRequired={true}
                    isAllRegion
                    isLabel
                  />
                  <div className="inp-container">
                    <Controller
                      name="zipCode"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Postal/ZIP Code is required',
                        },
                        maxLength: {
                          value: 10,
                          message: 'Maximum 10 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Postal/ZIP Code"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Postal/ZIP Code"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.zipCode && <p className="error-message">{errors.zipCode?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Label $isRequired $title="Timezone"/>
                    <Controller
                      name="timezone"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Timezone is required',
                        }
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                          classNamePrefix="react-select"
                          isSearchable={false}
                          placeholder="Select Timezone"
                          menuPortalTarget={document.body}
                          menuPosition={'absolute'}
                          menuPlacement={'bottom'}
                          menuShouldScrollIntoView={false}
                          options={timezones.map((item) => ({
                            label: item.label,
                            value: item.value,
                          }))}
                        />
                      )}
                    />
                    {errors.timezone && <p className="error-message">{errors.timezone?.message}</p>}
                  </div>
                </div>
              </div>

              <div className={style.section}>
                <Text type={'subtitle'} weight={500}>Financial</Text>
                <div className={generalStyles.fieldsThree}>
                  <div className="inp-container">
                    <Controller
                      name="bankName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Bank Name is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Bank Name"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Bank Name"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.bankName && <p className="error-message">{errors.bankName?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="accountName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Account Name is required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Account Name"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Account Name"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.accountName && <p className="error-message">{errors.accountName?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="accountIban"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Account Number is required',
                        },
                        maxLength: {
                          value: 35,
                          message: 'Maximum 35 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Account Number"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Account Number"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.accountIban && <p className="error-message">{errors.accountIban?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="sortCodeRouting"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Sorting Code is required',
                        },
                        maxLength: {
                          value: 6,
                          message: 'Maximum 6 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.numbers.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Sorting Code"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Sorting Code"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.sortCodeRouting && <p className="error-message">{errors.sortCodeRouting?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="swiftBic"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'SWIFT Code is required',
                        },
                        maxLength: {
                          value: 11,
                          message: 'Maximum 11 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumerical.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter SWIFT Code"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="SWIFT Code"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.swiftBic && <p className="error-message">{errors.swiftBic?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Controller
                      name="bankAddress"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Address is required',
                        },
                        maxLength: {
                          value: 255,
                          message: 'Maximum 255 characters',
                        },
                        validate: {
                          onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'Should not be empty',
                          onlyAllowed: (v) => regExps.alphaNumericalSpecial.test(v) || 'Invalid characters',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Address"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Address"
                          $labelRequired
                          {...field}
                        />
                      )}
                    />
                    {errors.bankAddress && <p className="error-message">{errors.bankAddress?.message}</p>}
                  </div>
                  <div className="inp-container">
                    <Label $isRequired $title="Currency" />
                    <Controller
                      name="currency"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Currency is required',
                        }
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                          classNamePrefix="react-select"
                          isSearchable={false}
                          placeholder="Select Currency"
                          menuPortalTarget={document.body}
                          menuPosition={'absolute'}
                          menuPlacement={'bottom'}
                          menuShouldScrollIntoView={false}
                          options={createCurrencyOptions()}
                        />
                      )}
                    />
                    {errors.currency && <p className="error-message">{errors.currency?.message}</p>}
                  </div>
                </div>
              </div>
            </>
          )}
          {tab === 'documents' && (
            <div className={style.attachmentsBlockItem}>
              <div>
                <Label $title="Upload Supporting Documents" />
                <Documents
                  control={control}
                  fieldName="vendorAttachments"
                  filesSchema={files}
                  error={errors?.vendorAttachments?.message || errors?.vendorAttachments?.root?.message}
                  required={requiredRef.current}
                  clearErrors={clearErrors}
                />
              </div>
              {!!values?.attachments?.length && <QuotesTab data={values?.attachments} />}
            </div>
          )}
        </div>
      </Box>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default VendorAccountEdit;
