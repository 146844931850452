import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import { useForm, useWatch } from 'react-hook-form';
import { useStore } from '../../store/store';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import Box from '../../components/UI/General/Box';
import { reducedText } from '../../utils/reducedText';
import AddButton from '../../components/shared/AddButton';
import Search from '../../components/UI/General/Search';
import Filter from '../../components/UI/General/Filter';
import s2pmsVendorPortal from '../../services/vendorPortal.service';
import styles from '../../styles/companySetup.module.css';
import xlsxParser from 'xlsx-parse-json';

const VendorCatalog = () => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState(undefined);
  const user = useStore((state) => state.user);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    control,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });
  const hiddenFileInput = useRef(null);
  const [filterData, setFilterData] = useState([])

  const [vendorCategories, setVendorCategories] = useState([]);
  const categoryWatcher = useWatch({ control, name: 'category' });

  useEffect(() => {
    spmsServiceService.getVendorCategories().then((res) => {
      const categories = res.data.data;
      setVendorCategories(categories);
    });
  }, []);

  useEffect(() => {
    setFilterData([{
      name: 'type',
      label: 'Type',
      isMulti: false,
      options: [{
        label: 'Product',
        value: 'PRODUCT',
      },{
        label: 'Service',
        value: 'SERVICE',
      }],
    },
    {
      name: 'category',
      label: 'Category',
      isMulti: false,
      options: Array.from(new Set(vendorCategories.map((el) => el?.category))).map((el) => ({ label: el, value: el}),
      ),
    },
    {
      name: 'subcategory',
      label: 'Subcategory',
      isMulti: false,
      options: vendorCategories.filter((category) => category.category === categoryWatcher?.value).map(el => ({
        label: el.subCategory,
        value: el.subCategory
      })),
    }])
  },[vendorCategories, categoryWatcher])

  const archive = (productId) => {
    setToast((item) => ({ ...item, opened: false }));
    s2pmsVendorPortal.archiveProduct(productId).then((r) => {
      setTriggerRefetch((state) => !state);
      setToast({
        opened: true,
        message: 'Item archived successfully.',
        type: 'success',
      });
    }).catch((err) => {
      setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    });
  }
  const activate = (productId) => {
    setToast((item) => ({ ...item, opened: false }));
    s2pmsVendorPortal.activateProduct(productId).then((r) => {
      setTriggerRefetch((state) => !state);
      setToast({
        opened: true,
        message: 'Item activated successfully.',
        type: 'success',
      });
    }).catch((err) => {
      setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    });
  }

  const columns = useMemo(
    () => [
      {
        name: 'Reference Code',
        sortable: true,
        wrap: true,
        selector: (row) => row?.refCode,
        cell: (row) => (
          <span data-content={'Reference Code'} className={generalStyles.tableValue}>
            <Link to={'/vendor-catalog/edit/' + row.id}>
              <span>{row?.refCode ?? '-'}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'Type',
        sortable: true,
        selector: (row) => row?.type,
        cell: (row) => (
          <span data-content={'Type'} className={generalStyles.tableValue}>
            <span>{row?.type ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Title',
        wrap: true,
        selector: (row) => row?.title,
        cell: (row) => {
          return (
            <span data-content={'Title'} className={generalStyles.tableValue}>
              <span title={row?.title}>{reducedText(row?.title) ?? '-'}</span>
            </span>
          );
        },
      },
      {
        name: 'Description',
        selector: (row) => row?.description,
        cell: (row) => (
          <span data-content={'Description'} className={generalStyles.tableValue}>
            <span title={row?.description}>{reducedText(row?.description) ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Category',
        sortable: true,
        selector: (row) => row?.category,
        cell: (row) => (
          <span data-content={'Category'} className={generalStyles.tableValue}>
            <span>{row?.category ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Sub Category',
        sortable: true,
        selector: (row) => row?.subCategory,
        cell: (row) => (
          <span data-content={'Sub Category'} className={generalStyles.tableValue}>
            <span>{row?.subCategory ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Unit of Measure',
        sortable: true,
        selector: (row) => row?.unitOfMeasure,
        cell: (row) => (
          <span data-content={'Unit of Measure'} className={generalStyles.tableValue}>
            <span>{row?.unitOfMeasure ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Unit Price',
        sortable: true,
        selector: (row) => row?.unitPrice,
        cell: (row) => (
          <span data-content={'Unit Price'} className={generalStyles.tableValue}>
            <span>{row?.unitPrice ?? '-'}</span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  <Link to={'/vendor-catalog/edit/' + row.id}>
                    <MenuItem>Edit</MenuItem>
                  </Link>
                  {row.isActive ? (
                    <MenuItem onClick={() => archive(row.id)}>Archive</MenuItem>
                  ) : (
                    <MenuItem onClick={() => activate(row.id)}>Activate</MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [],
  );

  // const getTemplate = () => {
  //   s2pmsVendorPortal.getTemplate().then(r => {
  //     console.log(r);
  //     const path = r.data.data;
  //     const link = document.createElement('a');
  //     link.href = path;
  //     link.download = path.toString().split('/').pop();
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   })
  // };
  //
  // const uploadImportedFile = async (file) => {
  //   setToast((item) => ({ ...item, opened: false }));
  //   try {
  //     const data = await xlsxParser.onFileSelection(file);
  //     const firstSheet = data.Sheet1;
  //     const json = JSON.stringify(firstSheet);
  //     await spmsServiceService.uploadGlBulk("companyId", json);
  //     getData(0, 10);
  //     setTriggerRefetch((triggerRefetch) => !triggerRefetch);
  //     setToast({
  //       opened: true,
  //       message: 'The Gl was imported',
  //       type: 'success',
  //     });
  //   } catch (err) {
  //     setToast({
  //       opened: true,
  //       message: err.response.data.message,
  //       type: 'fail',
  //     });
  //   } finally {
  //     hiddenFileInput.current.value = null;
  //   }
  // };

  const getData = useCallback((page, size) => {
      const filterValues = getValues();
      let search = {
        category: filterValues.category?.value,
        subCategory: filterValues.subcategory?.value,
        // "sku": "string",
        // "unitOfMeasure": "string",
        // "description": "string",
        // "isActive": true,
        type: filterValues.type?.value,
        // "maxUnitPrice": 0,
        // "minUnitPrice": 0,
        title: filterText,
        vendorId: user?.externalId,
      };
      return s2pmsVendorPortal.getCatalog(page, size, search);
    }, [filterText, triggerRefetch, user],
  );

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        placeholder="Search product/service by name"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText, resetPaginationToggle],
  );

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <Text type="subtitle" weight={500}>Product and Services Catalog</Text>
        <br/>
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}>
            {filterCmp}
            <div className={generalStyles.filters}>
              <Filter
                control={control}
                filterData={filterData}
                setTriggerRefetch={setTriggerRefetch}
                setValue={setValue}
              />
            </div>
          </div>
          <div className={generalStyles.addItemButton}>
            {/*<div className={styles.importButton}>*/}
            {/*  <Dropdown collapsible className={generalStyles.actionMenu}>*/}
            {/*    <Dropdown.Header>*/}
            {/*      <Button.Main $style="blue" type="button">*/}
            {/*        <Icon $width={20} $height={20} $icon="add" $color="white" />*/}
            {/*        <Text weight={400}>Import from Excel</Text>*/}
            {/*      </Button.Main>*/}
            {/*    </Dropdown.Header>*/}
            {/*    <Dropdown.Body>*/}
            {/*      <Menu className={styles.actionMenuList}>*/}
            {/*        <MenuItem onClick={getTemplate}>Download the Template</MenuItem>*/}
            {/*        <MenuItem onClick={() => hiddenFileInput.current.click()}>*/}
            {/*          <span>*/}
            {/*            Upload GL*/}
            {/*            <input*/}
            {/*              hidden*/}
            {/*              ref={hiddenFileInput}*/}
            {/*              className={styles.importInput}*/}
            {/*              type="file"*/}
            {/*              onChange={(e) => {*/}
            {/*                uploadImportedFile(e.target.files[0]);*/}
            {/*              }}*/}
            {/*            />*/}
            {/*          </span>*/}
            {/*        </MenuItem>*/}
            {/*      </Menu>*/}
            {/*    </Dropdown.Body>*/}
            {/*  </Dropdown>*/}
            {/*</div>*/}
            <AddButton $click={() => navigate('/vendor-catalog/add')} />
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
      </Box>

      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </>
  );
};

export default VendorCatalog;
