import React, { memo } from 'react';
import styles from '../../../../styles/purchaseOrders.module.css';
import Text from '../../../UI/Typography/Text';
import { nf } from '../../../../utils/nf';

const Summary = memo((props) => {
  return (
    <div className={styles.summary}>
      {props.subtotal !== undefined && typeof props.subtotal === 'number' && (
        <div className={styles.summaryItem}>
          <Text type="body-2">Sub Total:</Text>
          <Text type="body-2" weight={500}>
            {props?.currency} {nf.format(props.subtotal)}
          </Text>
        </div>
      )}
      {props.taxTotal !== undefined && typeof props.taxTotal === 'number' && (
        <div className={styles.summaryItem}>
          <Text type="body-2">Tax:</Text>
          <Text type="body-2" weight={500}>
            {props?.currency} {nf.format(props.taxTotal)}
          </Text>
        </div>
      )}
      {props.totalCost !== undefined && typeof props.totalCost === 'number' && (
        <div className={styles.summaryItem}>
          <Text type="body-2" weight={600}>
            Total:
          </Text>
          <Text type="body-2" weight={600}>
            {props?.currency} {nf.format(props.totalCost)}
          </Text>
        </div>
      )}
      {props.totalInvoicedAmount !== undefined && typeof props.totalInvoicedAmount === 'number' && (
        <div className={styles.summaryItem}>
          <Text type="body-2">Invoice Total:</Text>
          <Text type="body-2" weight={500}>
            {nf.format(props.totalInvoicedAmount)}
          </Text>
        </div>
      )}
      {props.totalInvoicedAmount !== undefined &&
      typeof props.totalInvoicedAmount === 'number' &&
      props.totalCost !== undefined &&
      typeof props.totalCost === 'number' ? (
        <div className={styles.summaryItem}>
          <Text type="body-2">Variance:</Text>
          <Text type="body-2" weight={500}>
            {nf.format(props.totalInvoicedAmount)} + need formula
          </Text>
        </div>
      ) : null}

      {props.amountPaid !== undefined && typeof props.amountPaid === 'number' && (
        <div className={styles.summaryItem}>
          <Text type="body-2">Amount Paid:</Text>
          <Text type="body-2" weight={500}>
            {props?.currency} {nf.format(props.amountPaid)}
          </Text>
        </div>
      )}
      {props.outstandingAmount !== undefined && typeof props.outstandingAmount === 'number' && (
        <div className={styles.summaryItem}>
          <Text type="body-2">Outstanding Amount:</Text>
          <Text type="body-2" weight={500}>
            {props?.currency} {nf.format(props.outstandingAmount)}
          </Text>
        </div>
      )}
    </div>
  );
});

export default Summary;
