import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles/purchaseOrders.module.css';
import reqStyles from '../../styles/requisition.module.css';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import Top from '../../components/Admins/PurchaseOrders/View/Top';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import Text from '../../components/UI/Typography/Text';
import GridItem from '../../components/Admins/PurchaseOrders/View/GridItem';
import ReceivingTab from '../../components/Admins/PurchaseOrders/Tabs/ReceivingTab';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import PaymentsTab from '../../components/Admins/PurchaseOrders/Tabs/PaymentsTab';
import InvoicesTab from '../../components/Admins/PurchaseOrders/Tabs/InvoicesTab';
import { useForm, useWatch } from 'react-hook-form';
import Toast from '../../components/UI/General/Toast';
import spmsServiceService from '../../services/spmsService.service';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import StatusBar from '../../components/Admins/PurchaseOrders/View/StatusBar';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { useStore } from '../../store/store';
import BackButton from '../../components/shared/BackButton';
import CommentsTab from '../../components/Admins/PurchaseOrders/Tabs/CommentsTab';

const Order = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useStore((state) => state.user);
  const [tab, setTab] = useState('overview');
  const [values, setValues] = useState(null);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [fetchPayments, setFetchPayments] = useState(false);
  const activeCompany = useStore((state) => state.activeCompany);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  // const [receivedItemsModal, setReceivedItemsModal] = useState(false);
  // const [loadPaymentModal, setLoadPaymentModal] = useState(false);
  // const [currentReceivedItem, setCurrentReceivedItem] = useState(null);
  // const [fetchReceiving, setFetchReceiving] = useState(false);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      requisitionId: 0,
      purchaseOrderState: '',
      title: '',
      requester: '',
      purchaseOrderNotes: [],
      companyId: '',
      branchId: '',
      departmentId: '',
      requestOnBehalfOf: '',
      vendorId: '',
      currency: '',
      approver: '',
      purchaseOrderItems: [],
      purchaseOrderAttachments: [],
      deliveryDate: '',
      deliveryAddress: {},
      notes: '',
    },
    values,
  });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  let isApprover = useRef(0);
  let isCurrentUserApproved = useRef(false);

  const accessPO = useAccessAllowed('Purchase_Order');

  const notesWatcher = useWatch({ name: 'notes', control });

  const removeItem = (id) => {
    const newValues = values.purchaseOrderItems.filter((el) => el.id !== id);
    setValues((state) => ({ ...state, purchaseOrderItems: newValues }));
  };

  const subtotal = useMemo(() => {
    return values?.purchaseOrderItems
      ? values?.purchaseOrderItems.reduce(
          (acc, current) => acc + (parseFloat(current.subtotal) || 0),
          0,
        )
      : 0;
  }, [values]);
  const taxTotal = useMemo(() => {
    return values?.purchaseOrderItems
      ? values?.purchaseOrderItems.reduce(
          (acc, current) => acc + (parseFloat(current.taxTotal) || 0),
          0,
        )
      : 0;
  }, [values]);
  const totalCost = useMemo(() => {
    return values?.purchaseOrderItems
      ? values?.purchaseOrderItems.reduce(
          (acc, current) => acc + (parseFloat(current.totalCost) || 0),
          0,
        )
      : 0;
  }, [values]);

  useEffect(() => {
    const fetchData = async () => {
      return await spmsServiceService.getPurchaseOrder(orderId);
    };
    if (orderId) {
      fetchData().then((r) => {
        if (r.data.message === 'Operation Successful') {
          isApprover.current =
            r.data.data.approval?.approvers?.filter((item) => item.userId === user.id).length ?? 0;
          isCurrentUserApproved.current =
            r.data.data.approval?.approvers?.find((item) => item.userId === user.id)?.status ===
              'APPROVED' ?? false;
          const { notes, ...rest } = r.data.data;
          console.log(rest);
          setValues({ ...rest, purchaseOrderNotes: notes });
        }
      });
    }
  }, [orderId, triggerUpdate]);

  useEffect(() => {
    if (location.state !== null) {
      let timer1 = setTimeout(() => setTab(location.state.tabToSwitch), 600);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [location.state]);

  const currencyOptionsPayments = useCallback(() => {
    if (activeCompany?.defaultCurrency === values?.currency?.code) {
      return [{ label: activeCompany?.defaultCurrency, value: activeCompany?.defaultCurrency }];
    } else {
      return [
        { label: activeCompany?.defaultCurrency, value: activeCompany?.defaultCurrency },
        { label: values?.currency?.code, value: values?.currency?.code },
      ];
    }
  }, [activeCompany?.defaultCurrency, values?.currency?.code]);

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div>
            <BackButton />
          </div>
          <div className={generalStyles.title}>
            <div className={styles.titleText}>
              <Text type="subtitle" weight={500}>
                {`${values?.title} (${values?.purchaseOrderNo})`}
              </Text>
              <Text type="body-1" weight={500}>
                {`Vendor ${values?.vendor?.legalName} - ${totalCost}`}
              </Text>
            </div>
            <div>{values?.purchaseOrderState === 'APPROVED' && <StatusBar data={values} />}</div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('overview')}>Overview</span>
            <span onClick={() => setTab('notes')}>Notes</span>
            <span onClick={() => setTab('documents')}>Documents</span>
            {!user?.externalId && <span onClick={() => setTab('approvals')}>Approvals</span>}
            <span onClick={() => setTab('receiving')}>Receiving</span>
            <span onClick={() => setTab('invoices')}>Invoices</span>
            <span onClick={() => setTab('payment')}>Payment</span>
            <span onClick={() => setTab('history')}>History</span>
          </TabsSlider>
        </div>
        {values && tab === 'overview' && (
          <>
            <Top data={values} />
            <br />
            <form id="poForm">
              <div className={styles.table}>
                <div className={reqStyles.itemsTableHead}>
                  <Text type="body-2" weight={500}>
                    Item Name
                  </Text>
                  <Text type="body-2" weight={500}>
                    Vendor Code
                  </Text>
                  <Text type="body-2" weight={500}>
                    Unit Of Measure
                  </Text>
                  <Text type="body-2" weight={500}>
                    Account
                  </Text>
                  <Text type="body-2" weight={500}>
                    Quantity
                  </Text>
                  <Text type="body-2" weight={500}>
                    Unit Price
                  </Text>
                  <Text type="body-2" weight={500}>
                    Tax
                  </Text>
                  <Text type="body-2" weight={500}>
                    Subtotal
                  </Text>
                </div>
                <div className={styles.tableBody}>
                  {values?.purchaseOrderItems.map((item) => (
                    <GridItem
                      key={item.id}
                      removeItem={removeItem}
                      canDelete={values?.purchaseOrderState === 'PENDING_APPROVAL'}
                      data={item}
                      id={item.id}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.summaryWrapper}>
                <Summary
                  subtotal={subtotal}
                  taxTotal={taxTotal}
                  totalCost={totalCost}
                  currency={values?.currency?.code}
                  currencyBudget={values?.budget?.currency}
                  totalBudgetCost={
                    values?.purchaseOrderState === 'APPROVED'
                      ? undefined
                      : values?.currency?.rate * totalCost
                  }
                  totalInvoicedAmount={
                    values?.purchaseOrderState === 'APPROVED'
                      ? values?.totalInvoicedAmount
                      : undefined
                  }
                />
              </div>
            </form>
          </>
        )}
        <div className={styles.attachmentsBlock}>
          {tab === 'documents' && <QuotesTab data={values?.attachments} />}
          {tab === 'history' && <HistoryTab data={values?.history} />}
          {tab === 'approvals' && <ApprovalTab data={values?.approval?.approvers} />}
          {tab === 'notes' && values && (
            <CommentsTab
              notes={values.purchaseOrderNotes}
              setIsButtonsBlocked={setIsButtonsBlocked}
              isButtonsBlocked={isButtonsBlocked}
              refetch={() => setTriggerUpdate((trigger) => !trigger)}
              setToast={setToast}
              module="po"
            />
          )}
          {values?.purchaseOrderState === 'APPROVED' && (
            <>
              {tab === 'receiving' && (
                <ReceivingTab
                  $trigger={setTriggerUpdate}
                  setToast={setToast}
                  prevLoc={location?.state?.from}
                  viewOnly={values?.purchaseOrderState === 'CLOSED'}
                  values={values}
                />
              )}
              {tab === 'invoices' && (
                <InvoicesTab
                  setToast={setToast}
                  $trigger={setTriggerUpdate}
                  prevLoc={location?.state?.from}
                />
              )}
              {tab === 'payment' && (
                <PaymentsTab
                  refetch={fetchPayments}
                  shouldRefetch={() => setFetchPayments(false)}
                  $trigger={setTriggerUpdate}
                  setToast={setToast}
                  prevLoc={location?.state?.from}
                  invoiceId={location.state?.invoiceId}
                  viewOnly={values?.purchaseOrderState === 'CLOSED'}
                />
              )}
            </>
          )}
        </div>
      </Box>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default Order;
