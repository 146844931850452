import React, { useEffect, useState } from 'react';
import Modal from '../../UI/Modal/Modal';
import ModalInner from '../../UI/Modal/ModalInner';
import styles from '../../../styles/systemRolesPermissions.module.css';
import { Controller, useForm } from 'react-hook-form';
import generalStyles from '../../../styles/general.module.css';
import * as Button from '../../UI/Forms/Button';
import MultiSelectOpened from '../../UI/Forms/MultiSelectOpened';
import Text from '../../UI/Typography/Text';

const AddUser = ({ usersData, showModal, setShowModal }) => {
  const [values, setValues] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty, submitCount },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      users: [],
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    const newData = data.users.map((item) => item.value);
    console.log('newData', newData);
  };

  useEffect(() => {
    console.log(usersData);
    if (!!usersData.length) {
      const updUser = usersData?.map((item) => ({
        label: item.firstName + ' ' + item.lastName + '=' + item.email,
        value: item.id,
      }));
      console.log(updUser);
      setValues([
        {
          label: 'Name=Email',
          options: updUser,
        },
      ]);
    }
  }, [usersData]);

  const formatGroupLabel = (data) => {
    return (
      <Text type="body-1" weight={600} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <span>{data.label.split('=')[0]}</span>
        <span>{data.label.split('=')[1]}</span>
      </Text>
    );
  };

  return (
    <Modal $show={showModal} $close={() => setShowModal(false)} $title="Search User" $radius={12}>
      <ModalInner>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.modalFormBlock}>
            <div className="inp-container">
              <Controller
                name="users"
                control={control}
                render={({ field }) => (
                  <MultiSelectOpened
                    {...field}
                    className="react-select-container"
                    placeholder="Search users by Name or Email"
                    options={values}
                    control={control}
                    formatGroupLabel={formatGroupLabel}
                    styles={{
                      groupHeading: () => ({
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        padding: '4px 28px',
                        marginRight: '20px',
                        backgroundColor: '#fff',
                      }),
                      menuList: (base) => ({ ...base, padding: 0, minHeight: 200, maxHeight: 200 }),
                    }}
                  />
                )}
              />
              {errors.users && <p className="error-message">{errors.users.message}</p>}
            </div>
          </div>
          <div className={generalStyles.pageButtons}>
            <Button.Main
              $primary
              $style="pink"
              type="submit"
              disabled={!isDirty || !isValid || submitCount > 0}
            >
              Save
            </Button.Main>
          </div>
        </form>
      </ModalInner>
    </Modal>
  );
};

export default AddUser;
