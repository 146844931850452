import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { createPortal } from 'react-dom';

const TooltipText = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  padding: 4px;
  background-color: #ecf8f8;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #333333;
  -webkit-border-radius: 8px;-moz-border-radius: 8px;border-radius: 8px;
  @media(min-width: 768px){
    top: 0;
    left: 100%;
    bottom: auto;
    right: auto;
    width: max-content;
    max-width: ${({$maxWidth}) => $maxWidth ? $maxWidth : "240px"};
  }
`
const TooltipIconWrapper = styled.span`
  position: relative;
`
const TooltipIcon = styled.span`
  display: flex;
  align-items:center;
  justify-content:center;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  -webkit-border-radius: 100%;-moz-border-radius: 100%;border-radius: 100%;
  background-color: rgba(69, 186, 188, 0.1);
  font-size: 10px;
  color: #333333;
`
const TooltipWrapper = styled.div`
  cursor: help;
  @media(min-width: 768px){
    position: relative;
    display: flex;
    z-index: 1;
  }
`

const Tooltip = ({text, icon, ...props}) => {
  const [show, setShow] = useState(false)
  const [coords, setCoords] = useState({ left: 0, top: 0 });

  const mouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    console.log(rect.width);
    setCoords({
      left: rect.left + rect.width,
      top: rect.top
    });
    setShow(true);
  };

  const mouseLeave = () => {
    setShow(false);
  };

  return (
    <TooltipWrapper
      {...props}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onTouchStart={mouseEnter}
      onBlur={mouseLeave}
    >
      <TooltipIconWrapper $show={show}>{icon ? icon : <TooltipIcon>i</TooltipIcon>}</TooltipIconWrapper>
      {show && createPortal(<TooltipText $maxWidth={props.$maxWidth} style={{ left: coords.left, top: coords.top }}>
        {text}
      </TooltipText>, document.getElementById('modal-root'))}
    </TooltipWrapper>
  );
};
export default Tooltip;
