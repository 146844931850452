import React, { useEffect, useState } from 'react';
import styles from '../../styles/dashboard.module.css';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import Icon from '../../components/UI/General/Icon';
import s2pmsVendorPortal from '../../services/vendorPortal.service';
import ChartTotalOrders from '../../components/Admins/VendorProfile/ChartTotalOrders';
import ChartCustomers from '../../components/Admins/VendorProfile/ChartCustomers';

const periods = [
  {label: "last 3 months", value: 3},
  {label: "last 6 months", value: 6},
  {label: "last 12 months", value: 12},
]

const VendorDashboard = ({user}) => {
  const [widgetData, setWidgetData] = useState(null)
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    if (!!user?.externalId){
      s2pmsVendorPortal.dashboardOrdersSummary(user.externalId).then(r => {
        if (r.data.message === "Operation Successful" && !!r?.data?.data){
          setWidgetData(r.data.data)
        }
      }).catch(err => {
        console.log(err);
      })
      s2pmsVendorPortal.getVendorCompanies(user.externalId).then(r => {
        if (r.data.message === "Operation Successful"){
          const newData = r.data.data.map(el => ({label: el.company.name, value: el.company.id}))
          newData.splice(0, 0, {label: "All", value: 0});
          setCustomers(newData)
        }
      }).catch(err => {
        console.log(err);
      })
    }
  },[])

  return (
    <div className={styles.dashboard}>
      <div className={generalStyles.fieldsTwo}>

        <ChartTotalOrders periods={periods} userId={user?.externalId} customers={customers}/>
        <ChartCustomers periods={periods} userId={user?.externalId} customers={customers}/>

        {widgetData !== null ? (
          <Box>
            <div className={styles.dashboardTitle}>
              <Text weight={600} type="subtitle">Orders</Text>
            </div>
            <div className={generalStyles.fieldsTwo}>
              <Link to={'/'} state={'test'}>
                <div className={styles.dashboardItem}>
                  <div className={styles.dashboardIcon}>
                    <Icon $icon='requests' $width={20} $height={20} $color="#fff" />
                  </div>
                  <div>
                    <Text className={styles.dashboardValue} weight={500} type="body-1">{widgetData?.newOrders ?? 0}</Text>
                    <div className={styles.dashboardSub}>
                      <Text weight={400} type="body-2">New</Text>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={'/'} state={'test'}>
                <div className={styles.dashboardItem}>
                  <div className={styles.dashboardIcon}>
                    <Icon $icon='password-check' $width={20} $height={20} $color="#fff" />
                  </div>
                  <div>
                    <Text className={styles.dashboardValue} weight={500} type="body-1">{widgetData?.accepted ?? 0}</Text>
                    <div className={styles.dashboardSub}>
                      <Text weight={400} type="body-2">Accepted</Text>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={'/'} state={'test'}>
                <div className={styles.dashboardItem}>
                  <div className={styles.dashboardIcon}>
                    <Icon $icon='close' $width={20} $height={20} $color="#fff" />
                  </div>
                  <div>
                    <Text className={styles.dashboardValue} weight={500} type="body-1">{widgetData?.rejectedOrders ?? 0}</Text>
                    <div className={styles.dashboardSub}>
                      <Text weight={400} type="body-2">Rejected</Text>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to={'/'} state={'test'}>
                <div className={styles.dashboardItem}>
                  <div className={styles.dashboardIcon}>
                    <Icon $icon='invoices' $width={20} $height={20} $color="#fff" />
                  </div>
                  <div>
                    <Text className={styles.dashboardValue} weight={500} type="body-1">{widgetData?.invoicedPendingOrders ?? 0}</Text>
                    <div className={styles.dashboardSub}>
                      <Text weight={400} type="body-2">Invoiced</Text>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </Box>
        ) : null}

      </div>
    </div>
  );
};

export default VendorDashboard;
