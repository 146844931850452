import { Controller, useForm, useWatch } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import Select from 'react-select';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import { useStore } from '../../store/store.js';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import spmsServiceService from '../../services/spmsService.service.js';
import { regExps } from '../../utils/regExps.js';
import * as yup from 'yup';

const financeInfoSchema = yup.object().shape({
  bankName: yup
    .string()
    .required('Bank Name is required')
    .matches(regExps.alphaNumericalSpecial, 'Invalid bank name')
    .max(50, 'Bank Name must be at most 50 characters'),
  bankAddress: yup
    .string()
    .required('Bank Address is required')
    .matches(regExps.alphaNumericalSpecial, 'Invalid bank address')
    .max(255, 'Bank Address must be at most 255 characters'),
  accountName: yup
    .string()
    .required('Account Name is required')
    .matches(regExps.alphaNumericalSpecial, 'Invalid account name')
    .max(50, 'Account Name must be at most 50 characters'),
  accountIban: yup
    .string()
    .required('Account Number / IBAN is required')
    .matches(regExps.alphaNumerical, 'Invalid account number / IBAN')
    .max(35, 'Account Number  / IBAN must be at most 35 characters'),
  sortCodeRouting: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Sort Code/Routing cannot be empty', (value) => value?.trim() !== '')
    .matches(/^[0-9]*$/, 'Invalid Sort Code/Routing')
    .max(6, 'Sort Code/Routing  must be at most 6 characters'),
  swiftBic: yup
    .string()
    .required('SWIFT/BIC is required')
    .matches(regExps.alphaNumerical, 'Invalid SWIFT/BIC')
    .max(11, 'SWIFT/BIC must be at most 11 characters'),
  paymentTerms: yup.object().shape({
    value: yup.string().required('Payment Terms are required'),
  }),
  earlyPaymentDiscount: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? undefined : value))
    .test('no-empty-spaces', 'Discount cannot be empty', (value) => value?.trim() !== '')
    .matches(/^[0-9]*$/, 'Invalid Discount')
    .max(2, 'Discount must be at most 2 digits'),
  currency: yup.object().shape({
    value: yup.string().required('Currency is required'),
  }),
  annualRevenue: yup.object().shape({
    value: yup.string().required('Annual Revenue is required'),
  }),
});

const paymentTermsOptions = [
  {
    label: 'Cash on Delivery',
    value: 'onDelivery',
  },
  {
    label: 'Nett 7 days',
    value: '7_days',
  },
  {
    label: 'Nett 15 days',
    value: '15_days',
  },
  {
    label: 'Nett 30 days',
    value: '30_days',
  },
  {
    label: 'Nett 45 days',
    value: '45_days',
  },
  {
    label: 'Nett 60 days',
    value: '60_days',
  },
];
const revenueOptions = [
  {
    label: 'Less than $1 million',
    value: '1m',
  },
  {
    label: '$1 million - $10 million',
    value: '1m_10m',
  },
  {
    label: '$10 million - $50 million',
    value: '10m_50m',
  },
  {
    label: '$50 million - $1 billion',
    value: '50m_1b',
  },
  {
    label: 'Over $1 billion',
    value: '1b`',
  },
];

const VendorFinanceOnboarding = ({ nextStep, setToast }) => {
  const user = useStore((state) => state.user);
  const setSteps = useStore((state) => state.setSteps);
  const countries = useStore((state) => state.countries);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(financeInfoSchema),
  });

  const onSubmit = (data) => {
    if (!isDirty) {
      nextStep();
      return;
    }
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = {
      bankName: data.bankName,
      bankAddress: data.bankAddress,
      accountName: data.accountName,
      accountIban: data.accountIban,
      sortCodeRouting: data.sortCodeRouting,
      swiftBic: data.swiftBic,
      paymentTerms: data.paymentTerms.value,
      currency: {
        code: data.currency.value,
        rate: 1,
      },
      earlyPaymentDiscount: data.earlyPaymentDiscount,
      annualRevenue: data.annualRevenue.value,
    };
    spmsServiceService
      .createVendorFinance(user.externalId, requestBody)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Saved',
          type: 'success',
          cb: () => {
            nextStep();
            setIsButtonsBlocked(false);
          },
        });
        setSteps();
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const createCurrencyOptions = useCallback(() => {
    if (!countries || !countries.length) return;
    const separator = '-';
    const allCurrencies = countries
      .map((country) => `${country.currencyName}${separator}${country.currency}`)
      .sort();
    const uniqueCurrencies = [...new Set([...allCurrencies])];
    return uniqueCurrencies.map((currency) => {
      const label = currency.split(separator);
      label[label.length - 1] = `(${label[label.length - 1]})`;
      return {
        label: label.join(' '),
        value: currency.split(separator).at(-1),
      };
    });
  }, [countries]);

  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Financial Information
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text inherit type="h5" weight={600}>
            Add your financial information
          </Text>
        </GradientText>
      </div>
      <div>
        <form className={styles.inputs}>
          <div className="inp-container">
            <Controller
              name="bankName"
              control={control}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Full Bank Name *"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.bankName && <p className="error-message">{errors.bankName?.message}</p>}
          </div>
          <div className={generalStyles.fieldsTwo}>
            <div className="inp-container">
              <Controller
                name="accountName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Account Name *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.accountName && <p className="error-message">{errors.accountName?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="accountIban"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Account Number/IBAN *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.accountIban && <p className="error-message">{errors.accountIban?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="sortCodeRouting"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Sort Code/Routing"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.sortCodeRouting && (
                <p className="error-message">{errors.sortCodeRouting?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="swiftBic"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="SWIFT/BIC *"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.swiftBic && <p className="error-message">{errors.swiftBic?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="paymentTerms"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Payment Terms *"
                    options={paymentTermsOptions}
                  />
                )}
              />
              {errors.paymentTerms && (
                <p className="error-message">{errors.paymentTerms?.value.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="earlyPaymentDiscount"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Early Payment Discount"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    {...field}
                  />
                )}
              />
              {errors.earlyPaymentDiscount && (
                <p className="error-message">{errors.earlyPaymentDiscount?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Currency *"
                    options={createCurrencyOptions()}
                  />
                )}
              />
              {errors.currency && <p className="error-message">{errors.currency?.value.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="annualRevenue"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable
                    placeholder="Annual Revenue *"
                    options={revenueOptions}
                  />
                )}
              />
              {errors.annualRevenue && (
                <p className="error-message">{errors.annualRevenue?.value.message}</p>
              )}
            </div>
          </div>
          <div className="inp-container">
            <Controller
              name="bankAddress"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Bank Address*"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  {...field}
                />
              )}
            />
            {errors.bankAddress && <p className="error-message">{errors.bankAddress?.message}</p>}
          </div>
        </form>
        <Button.Main
          $primary
          $full
          disabled={isButtonsBlocked}
          $style="blue"
          $iconRight
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {/* {company && !isDirty ? 'Continue' : 'Save & Continue'} */}
          Save & Continue
        </Button.Main>
      </div>
    </div>
  );
};

export default VendorFinanceOnboarding;
